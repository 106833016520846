module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Run-Hour Tags</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tags", "tags", tags,'multi')))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Duration</th>\n		<td>'+
((__t=(APP.UI.slider("duration",duration, 100,8760)))==null?'':__t)+
'</td>\n	</tr>\n	\n</table>\n<hr>\n<h2>Tasks</h2>\n<textarea class=\'json field tall\' id=\'tasks\'>'+
((__t=(JSON.stringify(tasks, null, '\t')))==null?'':__t)+
'</textarea>\n';
}
return __p;
};
