module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Bar Width</th>\n		<td>\n			<input type="range" min=1 max=20 step=2 id=\'bar_width\' class=\'field live\' value=\''+
((__t=(bar_width))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Curve</th>\n		<td>\n			<input type="range" min=0.1 max=5 step=0.1 id=\'curve\' class=\'field live\' value=\''+
((__t=(curve))==null?'':__t)+
'\' list=\'tickmarks\'>\n		</td>\n	</tr>\n	<tr>\n		<th></th>\n		<td>\n			'+
((__t=(APP.UI.checkbox("units", units, "Include units")))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n<datalist id=tickmarks>\n	<option>1</option>\n	<option>2</option>\n</datalist>\n';
}
return __p;
};
