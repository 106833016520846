module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'MENU\'>\n	<div class=\'menuitem\' id=\'help\'>\n			'+
((__t=(APP.Tools.icon("help")))==null?'':__t)+
'\n			Help\n		</div>\n<div class=\'menuitem\' id=\'settings\'>\n		'+
((__t=(APP.Tools.icon("settings")))==null?'':__t)+
'\n		Settings\n	</div>\n<div class=\'menuitem\' id=\'pollers\'>\n		'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
'\n		Pollers\n	</div>\n<div class=\'menuitem\' id=\'device_library\'>\n		'+
((__t=(APP.Tools.icon("device_library")))==null?'':__t)+
'\n		Devices\n	</div>\n<div class=\'menuitem\' id=\'users\'>\n		'+
((__t=(APP.Tools.icon("users")))==null?'':__t)+
'\n		Users\n	</div>\n<div class=\'menuitem\' id=\'screens\'>\n		'+
((__t=(APP.Tools.icon("screens")))==null?'':__t)+
'\n		Screens\n	</div>\n<div class=\'menuitem\' id=\'reports\'>\n		'+
((__t=(APP.Tools.icon("reports")))==null?'':__t)+
'\n		Reports\n	</div>\n<div class=\'menuitem\' id=\'files\'>\n		'+
((__t=(APP.Tools.icon("file")))==null?'':__t)+
'\n		Files\n	</div>\n<div class=\'menuitem\' id=\'network\'>\n		'+
((__t=(APP.Tools.icon("network")))==null?'':__t)+
'\n		Network\n	</div>\n<div class=\'menuitem\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n		Database\n	</div>\n<!-- <div class=\'menuitem disabled\' id=\'log\'>\n		'+
((__t=(APP.Tools.icon("th-list")))==null?'':__t)+
' Log\n	</div> -->\n\n<div id=\'machine_status\' class=\'menubox\'></div>\n\n<div id=\'poller_status\' class=\'menubox\'></div>\n\n<div class=\'menubox\'>\n\n		<table id=\'stats\'></table>\n	</div>\n\n<div id=\'exit\'>\n		'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
'\n		EXIT\n	</div>\n\n</div>\n<div id=\'CENTER\'>\n	<div id=\'header\'>\n		<h1>'+
((__t=(SETTINGS.name))==null?'':__t)+
'</h1>\n	</div>\n	<div id=\'CONTENT\' class=\'showdetails\'></div>\n</div>\n<div id=\'DETAILS\' class=\'showdetails\'></div>\n<div id=\'HELPPANEL\'></div>\n<div id=\'showhide\' class=\'tinybuttons showdetails\'>\n	'+
((__t=(APP.Tools.icon("caret-square-right")))==null?'':__t)+
'\n</div>\n<div id=\'showhelp\' class=\'tinybuttons showdetails\'>\n	'+
((__t=(APP.Tools.icon("help")))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
