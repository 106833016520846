module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Shape</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("shape",{"simple":"Simple"
			,"tank":"Tank","box":"Box",'cooling':"Cooling Tower"},shape)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label2",label2)))==null?'':__t)+
'</br>\n			'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tootip</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
