module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			'+
((__t=(APP.UI.input("title", title,"","","redraw")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tags</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Duration</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("chart_duration", {"60":"1 minute", "300":"5 Minutes","3600":"1 Hour","86400":"1 Day"}, chart_duration, "field draw") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Rings</th>\n		<td>\n			'+
((__t=(APP.UI.slider("rings",rings,15,100)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
