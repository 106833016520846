module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <h1>Settings</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("settings")))==null?'':__t)+
'</div>\n\n<p><i>Settings</i> hold a few system wide settings.</p>\n\n<p>There aren\'t (currently) many customizable settings that are supported, but watch this space.</p>\n\n<button id=\'create\' class=\'btn\'>'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New Setting</button> -->\n';
}
return __p;
};
