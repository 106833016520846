module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset>\n	<legend>Netatmo Settings</legend>\n	<table>\n		<tr>\n			<th>Client ID</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.client_id\' value=\''+
((__t=(options.client_id))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Client Secret</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.client_secret\' value=\''+
((__t=(options.client_secret))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Username</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.username\' value=\''+
((__t=(options.username))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Password</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.password\' value=\''+
((__t=(options.password))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n	</table>\n</fieldset>\n';
}
return __p;
};
