DEF.reports.DT = {};
DEF.reports.DT.Main = Backbone.Marionette.CollectionView.extend({
	template: require('./templates/main.html'),
	id      : 'REPORT'
});
DEF.reports.DT.child = Backbone.Marionette.View.extend({
	className: 'report ',
	template : require('./templates/report.html'),
	initialize() {
		setInterval(this.render.bind(this), 60000);
	},
	templateContext() {
		return {
			html: this.model.GenerateReport(this.model.get('template'), true)
		};
	},
	events: {
		click: 'ShowDetails'
	},
	modelEvents: {
		'change:template' : 'render',
		'change:name'     : 'render',
		'change:frequency': 'render'
	},
	attributes() {
		return { id: this.model.id || this.model.cid };
	},
	ShowDetails(event) {
		const cmd = 'details';
		APP.Route(`#DT/reports/${this.model.id}/${cmd}`);
		event.stopPropagation();
	}
});
DEF.reports.DT.Details = DEF.TG.Details.extend({
	className: 'reports',
	id       : 'REPORTS',
	template : require('./templates/detail.html'),
	DoAction(e) {
		console.log(e.currentTarget.id);
		switch (e.currentTarget.id) {
		case 'mailme':
			this.MailReport(APP.USER.get('email'));
			break;
		case 'mailall':
			this.MailReport(this.model.get('recipients'));
			break;
		}
	},
	DoExtraField(e) {
		const mask = 1 << $(e.currentTarget).data('value');
		let hours = this.model.get('hours') || 0;
		hours ^= mask;
		this.model.set('hours', hours);
	},
	MailReport(to) {
		const emails = to.match(/[.\w]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}/g);
		console.log(emails);

		let report = require('./templates/header.html')();
		report += this.model.GenerateReport();
		report += '<div class=\'footer\'>';
		report += `Maanually generated ${this.model.getName()} at ${new Date()}`;

		const subject = this.model.get('name');
		console.log(report);
		APP.Tools.SendMail(emails, subject, report, (err, resp, body) => {
			this.$el.find('#message').html(body);
			// console.log('ERR', err);
			// console.log('RESP', resp);
			// console.log('ODY', body);
		});
	}

});
DEF.reports.DT.Editreport = DEF.TG.Details.extend({
	className: 'reports full',
	template : require('./templates/edit.html'),
	initialize() {
		setInterval(this.onChangeField.bind(this), 60000);
	},
	templateContext() {
		// console.log('ui', this.ui);
		// console.log('ev', this.events);
		return {
			html: this.model.GenerateReport(this.model.get('template'), true)
		};
	},
	onRender() {
		$('.tinybuttons').hide();
	},
	onChangeField() {
		const report = this.model.GenerateReport(undefined, true);
		this.$el.find('#fullscreen div#template').html(report);
	}
});

DEF.reports.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html'),
	ui      : {
		create: '#create',
		cmd   : '.preset',
		tags  : '#tags',
		import: '#import'
	},
	events: {
		'click @ui.create': 'CreateModel',
		'click @ui.cmd'   : 'Command',
		'click @ui.import': 'Import'
	},
	initialize() {
		// console.log('i');
	},
	onRender() {
		// console.log('e');
	},
	Command(e) {
		let report = false;
		const id = e.currentTarget.id;
		const taglist = this.ui.tags.val();
		APP.Tools.store('reports', 'taglist', taglist);
		const tags = taglist.split(/\W+/);
		if (tags.length > 0) {
			switch (id) {
			case 'daily':
				report = "// This report shows a tag's average\n";
				report += "// per hour.  Click the '?' icon in the\n";
				report += '// upper right for formatting help.\n';
				report += '//\n';
				report += '** DAILY REPORT **\n\n';
				report += 'Here is a sample daily report.\n\n';
				report += '* Current Values (at @TIME) *\n';
				report += `@WIDGET CHART ${taglist}\n\n`;
				report += '* Average by hour *\n';
				report += `|!TIME |! ${tags.join(' |! ')} |\n`;
				report += '@LOOP DAY HOUR AVG\n';
				report += `|!@TIME | #${tags.join(' | #')} |\n`;
				report += '@ENDLOOP\n';
				report += '@TOTAL AVG\n';

				break;
			}
			if (report) {
				const model = APP.models.reports.create({
					name    : `${APP.Format.capitalize(id)} Report`,
					template: report
				});
				APP.Route(`#DT/reports/${model.cid}/details`);

				// model.on('sync', () => { APP.Route(`#DT/reports/${model.get('_id')}/details`); });
				_.delay(() => {
					APP.Route(`#DT/reports/${model.get('_id')}/details`);
				}, 500);
			}

			console.log('report', report);
		}
	}
});
