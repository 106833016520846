DEF.files = {};

DEF.files.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.files.Model,
		url       : `${SETTINGS.dburl}/files`,
		comparator: 'name',
	};
	return APP.InitializeModels('files', options, callback, constraints);
};

DEF.files.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'files',
	defaults       : {
		name   : 'name',
		path   : '',
		icon   : 'file',
		folder : false,
		created: Date.now(),
		content: '',
	},
	db_columns    : ['path', 'created'],
	db_filters    : ['icon', 'folder'],
	db_spreadsheet: ['name', 'icon', 'folder'],
	getName() {
		return this.get('name');
	},
});
