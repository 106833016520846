module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Row</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("display",["days","weeks","months"],display,"field refresh")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Aggregation</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("aggregate",{"avg":"Average","min":"Min Value","max":"Max Value"},aggregate)))==null?'':__t)+
'\n		</td>\n	</tr> \n	<tr>\n		<th></th>\n		<td>'+
((__t=(APP.UI.checkbox("weekends",weekends,"Hilight Weekends")))==null?'':__t)+
'</td>\n	</tr>\n	<!-- <tr>\n		<th>\n			Number of\n			'+
((__t=(display))==null?'':__t)+
'\n		</th>\n		<td>\n			<input class=\'field\' type=\'number\' id=\'length\' value=\''+
((__t=(length))==null?'':__t)+
'\'>\n		</td>\n	</tr> -->\n</table>\n';
}
return __p;
};
