module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <th>Title</th>\n    <td>\n      <input type=\'text\' id=\'title\' class=\'field\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n    </td>\n  </tr>\n  <tr>\n    <th>X-Axis</th>\n    <td>\n      '+
((__t=(APP.UI.chooser("xaxis", "tags", xaxis)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Y-Axis</th>\n    <td>\n      '+
((__t=(APP.UI.chooser("yaxis", "tags", yaxis)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Bucket Size</th>\n    <td>\n      '+
((__t=(APP.UI.slider("blocksize",blocksize,30,3600)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Label</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","symbol","prefix"],label)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Smooth</th>\n    <td>\n      '+
((__t=(APP.UI.slider("smooth",smooth,0,20)))==null?'':__t)+
'\n    </td>\n  </tr>\n</table>\n\n<fieldset>\n  <legend>Autoscale</legend>\n  '+
((__t=(APP.UI.checkbox("autoscaleX",autoscaleX,"X-axis")))==null?'':__t)+
'\n    '+
((__t=(APP.UI.checkbox("autoscaleY",autoscaleY,"Y-axis")))==null?'':__t)+
'\n</fieldset>\n';
}
return __p;
};
