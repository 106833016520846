module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Shape:</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("shape",{"none":"None","bridge":"Bridge", "openarrowin":"Open Arrow - In","openarrowout":"Open Arrow - Out","reducer":"Reducer"},shape,"field refresh")))==null?'':__t)+
'\n		</td>\n	</tr>\n	';
 switch (shape) {
		case "openarrowin":
		case "openarrowout":
		case "reducer":
		  
__p+='\n	<tr>\n		<th>Direction</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("dir",["up","down","left","right"],dir)))==null?'':__t)+
'\n		</td>\n	</tr>\n	';

		break;
	} 
__p+='\n	';
 switch (shape) {
			case "bridge":
			  
__p+='\n	<tr>\n		<th>Direction</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("dir",["vertical","horizontal"],dir)))==null?'':__t)+
'\n		</td>\n	</tr>\n	';

			break;
		} 
__p+='\n\n</table>\n';
}
return __p;
};
