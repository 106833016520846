module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=(APP.UI.select_from_object('cast',{
raw:"Raw",datetime:"Date/Time",date:"Date",time:"Time",livetime:"Relative",duration:"Duration"
}, cast, "field")))==null?'':__t)+
'\n\n	</legend>\n\n\n	no options\n';
}
return __p;
};
