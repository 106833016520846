module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<td rowspan=3 id=\'prefix\' class=\''+
((__t=(run_state?"running":""))==null?'':__t)+
'\'>\n			'+
((__t=(tag.getUp('prefix')))==null?'':__t)+
'\n			';
 if (show_runtime) { 
__p+='\n			<div id=\'running_state\'>\n				'+
((__t=(run_state?"online":"offline"))==null?'':__t)+
'\n			</div>\n			';
 } 
__p+='\n		</td>\n		<th>\n			Connection:\n		</th>\n		<td>\n			'+
((__t=(connection_string))==null?'':__t)+
' ('+
((__t=(protocol))==null?'':__t)+
')\n		</td>\n		<td rowspan=3 id=\'image\'>\n			'+
((__t=(APP.Format.image(image_url)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Last Update:\n		</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(updated)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Comm Status:\n		</th>\n		<td>\n			'+
((__t=(status))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n<hr>\n\n<div id=\'links\'>\n	'+
((__t=(tag.getf('d_id')))==null?'':__t)+
'\n	';
 if (manual) { 
__p+='\n	| <a href=\''+
((__t=(manual))==null?'':__t)+
'\'>'+
((__t=(APP.Tools.icon('link')))==null?'':__t)+
' Manual</a>\n	';
 } 
__p+='\n</div>\n<hr>\n\n';
 if (show_notify) { 
__p+='\n<table>\n	<tr>\n		<td class=\'text-center\'>\n			Notify me when this device:\n			<input type="checkbox" class="notification_checkbox" '+
((__t=(off?"checked":""))==null?'':__t)+
' id="off_sms">\n			'+
((__t=(APP.Tools.icon('off')))==null?'':__t)+
' Turns Off\n			<input type="checkbox" class="notification_checkbox" '+
((__t=(on?"checked":""))==null?'':__t)+
' id="on_sms">\n			'+
((__t=(APP.Tools.icon('on')))==null?'':__t)+
' Turns On\n		</td>\n	</tr>\n</table> \n<hr>\n';
  } 
__p+='\n\n<table>\n	';
 if (show_runtime) { 
__p+='\n	<tr>\n		<th>Run Hours '+
((__t=(('('+tag.getDevice().getUp('runtime_reset')+')').replace('(never)','')))==null?'':__t)+
'</th>\n		<td>'+
((__t=(APP.Format.duration(run_hours*60*60)))==null?'':__t)+
'</td>\n		<!-- <th>Run Hours (last period)</th>\n				<td>'+
((__t=(APP.Format.duration(last_run_hours*60*60)))==null?'':__t)+
'</td> -->\n	</tr>\n	';
 } 
__p+='\n	<tr>\n		<th>Data Points</th>\n		<td>'+
((__t=(points.toLocaleString()))==null?'':__t)+
'</td>\n		<th>State</th>\n		<td>'+
((__t=(state))==null?'':__t)+
'</td>\n	</tr>\n</table>\n\n<hr>\n\n<div id=\'events\'></div>\n\n';
}
return __p;
};
