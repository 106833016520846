DEF.tags.DT = {};
DEF.tags.DT.Tags = Backbone.Marionette.CollectionView.extend({
	className: 'tags',
	id       : 'TAGS'
});
 
DEF.tags.DT.Tag = Backbone.Marionette.View.extend({
	template : require('./templates/tag.html'),
	tagName  : 'div',
	className: 'tag',
	templateContext() {
		const rs = this.model.all_fields();
		if (typeof rs.bit === 'number' && this.model.isBoolean())
			rs.address += `:${rs.bit}`;
		return rs;
	},
	events: {
		click: 'ShowDetails'
	},
	modelEvents: {
		change: 'render'
	},
	onRender() {
		// Flash new values
		// console.log("render", this.model.getName())
		const el = $(this.el);

		if (el.is(':visible')) {
			//			console.log('render', this.model.getName());
			if (this.animation_timeout) {
				clearTimeout(this.animation_timeout);
				el.addClass('changed');
			}
			this.animation_timeout = setTimeout(() => {
				el.removeClass('changed');
			}, 2000);
		}

		el[this.model.get('updated') ? 'removeClass' : 'addClass']('never');

		$(this.el).attr('id', this.model.id);
		this.listenToOnce(this.model.getTagLibrary(), 'change:symbol change:name change:address change:cast change:type change:decimals change:unit', this.render);
	},
	ShowDetails() {
		APP.Route(`#DT/tags/${this.model.id}`);
	}
});

DEF.tags.DT.Details = Backbone.Marionette.View.extend({
	template: require('./templates/tag_details_layout.html'),
	regions : {
		tags   : '#tag_details',
		library: '#library_details'
	},
	onRender() {
		// if (this.model.get('updated')) {
		const td = new DEF.tags.DT.tag_details({ model: this.model });
		this.showChildView('tags', td);
		// }
		const ld = new DEF.tag_library.DT.Details({
			model: this.model.getTagLibrary()
		});
		this.showChildView('library', ld);
	}
});

DEF.tags.DT.tag_details = DEF.TG.Details.extend({
	template: require('./templates/tag_details.html'),
	templateContext() {
		const fields = this.model.all_fields();
		// fields.off_time = 0;
		// fields.on_time = 0;
		switch (fields.protocol) {
		case 'modbus':
		case 'modbustcp':
			fields.address = Number(fields.address) + Number(fields.connection.addressOffset || 0);
			break;
		}
		return fields;
	},
	Delete() {
		if (confirm(`Are you sure you want to delete '${this.model.getUp('name')}' (${this.model.getUp('symbol')})?  It will delete from all devices.`)) {
			const tl_id = this.model.get('tl_id');
			APP.models.tag_library.remove(tl_id);
			_.defer(APP.Tools.DeployTags, 1000); // todo - havce to defer due to some bug.
			APP.Route(`#DT/devices/${this.model.get('d_id')}`); // refresh doesn't exist.  and neither does the model, so route to the collection
		}
	},
	DoExtraField(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.value;
		console.log(id, val);
		this.model.SaveValue(val);
	},
	DoAction(e) {
		// console.log(e.currentTarget.id);
		switch (e.currentTarget.id) {
		case 'right':
		case 'left':
			const tags = _.invoke(APP.models.tags.filter({ tl_id: this.model.get('tl_id') }), 'get', 'tag_name');
			let index = tags.indexOf(this.model.get('tag_name'));
			console.log(index, add);
			if (e.currentTarget.id === 'right')
				index = (index + 1) % tags.length;
			else
				index = index === 0 ? tags.length - 1 : (index - 1) % tags.length;
				// index = APP.Format.wrap(index, 0, tags.length - 1);
			const tag_name = tags[index];
			console.log(tags, tag_name, index);
			APP.Route(`#DT/tags/${APP.models.tags.find({ tag_name }).id}`);
			break;
		case 'database_tl':
			this.Database('tag_library', this.model.get('tl_id'));
			break;
		}
	}
});


DEF.tags.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.model = this.model;
		return rs;
	},
	regions: {
		tags: '#tags'
	},
	ui: {
		exit   : '#exit',
		command: '.btn'
	},
	events: {
		'click @ui.command': 'Command'
	},
	onRender() {
		const tl_id = this.model.id;
		// this.tags.show(new DEF.database.DT.Table({
		// 	collection: APP.models.tags,
		// 	collection_name: "tags",
		// 	childView: DEF.database.DT.child,
		// 	filter: function(m) {
		// 		return m.get('tl_id') == tl_id;
		// 	}
		// }));
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'edit':
			APP.Route(`#DT/tags/${this.model.id}`);
			break;
		case 'exit':
			APP.Route('#');
			break;
		}
	}
});
//
// DEF.alarms = {};
// DEF.alarms.page = Backbone.Marionette.View.extend({
// 	template: require("./templates/alarm_log.html"),
// 	regions: {
// 		"events": "#alarm_log_box"
// 	},
// 	onRender: function() {
// 		this.showChildView('events', new DEF.layout.LOG_BOX.Main({
// 			collection: APP.models.events
// 		}));
// 	},
// 	ui: {
// 		"command": ".btn"
// 	},
// 	events: {
// 		"click @ui.command": "Command"
// 	},
// 	Command: function(e) {
// 		switch (e.currentTarget.id) {
// 			case "exit":
// 				APP.Route('#');
// 				break;
// 		}
// 	}
// });
