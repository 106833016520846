module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Modbus Blocks</h2>\n<div class=\'modpoll\'>\n	<table class=\'modtile\'>\n		<tr>\n		<th>Block</th>\n		<th>\n			Address\n		</th>\n		<th>Length</th>\n		<th>Polling Rate</th>\n		</tr>\n	';

				for (var b in blocks) {
					
__p+='\n		<tr>\n		<th>\n			'+
((__t=(b))==null?'':__t)+
'\n		</th>\n			<td>\n				<input type=\'text\' class=\'extrafield\' id=\'blocks\' data-field=\'address\' data-id=\''+
((__t=(b))==null?'':__t)+
'\' value=\''+
((__t=(blocks[b].address))==null?'':__t)+
'\'>\n			</td>\n			<td>\n				<input type=\'number\' class=\'extrafield\' id=\'blocks\' data-field=\'length\' data-id=\''+
((__t=(b))==null?'':__t)+
'\' value=\''+
((__t=(blocks[b].length))==null?'':__t)+
'\'>\n			</td>\n			<td>\n				<input type=\'number\' class=\'extrafield\' id=\'blocks\' data-field=\'polling_rate\' data-id=\''+
((__t=(b))==null?'':__t)+
'\' value=\''+
((__t=(blocks[b].polling_rate))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n	';

				}
				
__p+='\n	</table>\n</div>\n<button class=\'btn action\' id=\'addblock\'>\n	'+
((__t=(APP.Tools.icon('add')))==null?'':__t)+
' Add Block</button>\n';
}
return __p;
};
