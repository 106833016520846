DEF.screens = {};

DEF.screens.Initialize = function init(callback, constraints) {
	const options = {
		model: DEF.screens.Model,
		url  : `${SETTINGS.dburl}/screens`,
		comparator (m) {
			let sort = -m.get('views') || 0;
			if (m.get('kind') === 'home')
				sort = -Number.MAX_SAFE_INTEGER;
			return sort;
		},
	};
	return APP.InitializeModels('screens', options, callback, constraints);
};

DEF.screens.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: 'master_id',
	parentModule     : 'screens',
	collection_name  : 'screens',
	defaults         : {
		name: 'New Screen',
		enabled: true,
		master_id: '',
		kind     : 'standard',
		width    : '', // inheretied from master
		height   : '',
		glyph    : '', // for Button widgets and maybe other stuff.
		views    : 0,
		last_view: 0,

	},
	initialize() {
		// go find a default master screen for new Screens.
		if (!this.get('master_id') && !this.get('width') && this.get('kind') != 'master') {
			console.log(this.getName());
			const master = APP.models.screens.findWhere({ kind: 'master' });
			if (master)
				this.set({ master_id: master.id });
		}
	},
	ups           : ['width', 'height'],
	db_spreadsheet: ['name', 'kind', 'width', 'height', 'glyph'],
	db_columns    : ['kind', 'width', 'height', 'master_id'],
	db_filters    : ['kind'],
	getUp(field, parent_module, parent_id) {
		if (this.get('kind') !== 'master')
			switch (field) {
			case 'width':
			case 'height':
				if (this.get('master_id')) {
					const master = APP.models.screens.get(this.get('master_id'));
					if (master)
						return Number(master.get(field));
				}
			}

		return this.get(field);
	},
	getName() {
		return this.get('name');
	},
	getIcon() {
		return APP.Tools.icon(this.get('glyph') || 'desktop');
	},
	getWidgets() {
		const screen_id = this.id;
		const master_id = this.get('master_id');
		const widgets = APP.models.widgets.filter(m => m.get('screen_id') === screen_id || m.get('screen_id') === master_id);
		return widgets;
	},
	getLink() {
		return `#RT/${APP.Format.url(this.get('name'))}`;
	},
});
