module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>From</th>\n		<td><input type=\'datetime-local\' id=\'from\' class=\'field\'></td>\n		<td rowspan="4">\n			<button class=\'btn btn-small full-width preset\' id=\'today\'>Today</button><br>\n			<button class=\'btn btn-small full-width preset\' id=\'yesterday\'>Yesterday</button><br>\n			<button class=\'btn btn-small full-width preset\' id=\'week\'>Last Week</button><br>\n			<button class=\'btn btn-small full-width preset\' id=\'7days\'>Last 7 Days</button><br>\n			<!-- <button class=\'btn btn-small full-width preset\' id=\'month\'>Last Month</button><br> -->\n			<button class=\'btn btn-small full-width preset\' id=\'year\'>Last Year</button><br>\n			<button class=\'btn btn-small full-width preset\' id=\'ytd\'>YTD</button>\n		</td>\n		<td rowspan="4">\n			';
 x=new Date(); x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.1\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n			';
 x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.2\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n			';
 x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.3\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n			';
 x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.4\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n			';
 x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.5\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n			';
 x.setMonth(x.getMonth()-1); 
__p+='\n			<button class=\'btn btn-small full-width preset\' id=\'mon.6\'>'+
((__t=(x.toLocaleString('default',{month:'long'}) ))==null?'':__t)+
'</button><br>\n		</td>\n		</tr>\n	<tr>\n		<th>To</th>\n		<td><input type=\'datetime-local\' id=\'to\' class=\'field\'></td>\n	</tr>\n	<tr>\n		<th>Sample:</td>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("sample",{0:"raw",60:"minute","300":"5 minutes","900":"15 minutes","1800":"Half hour", "3600":"1 hour","86400":"1 day"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td></td>\n		<td>\n			<div class=\'btn center\' id=\'export\'>'+
((__t=(APP.Tools.icon('download')))==null?'':__t)+
' Download</div>\n		</td>\n	</tr>\n\n	<tr>\n		<td colspan="4">\n			<div class=\'smallnote\'>\n				'+
((__t=(APP.Tools.icon("info")))==null?'':__t)+
' The export will eventually be emailed to \n					<b>\n						'+
((__t=(APP.USER.attributes.email))==null?'':__t)+
'\n					</b>\n			</div>\n		</td>	\n	</tr>\n\n</table>\n\n<iframe style="display:none" name=\'exportwindow\'></iframe>\n\n\n<!-- <div class=\'smallnote\'>All data is stored on the server.  "Retention" defines how much data is available online for export.</div> -->';
}
return __p;
};
