module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table cellspacing=0>\n	<thead>\n		<tr>\n			<td colspan>\n				'+
((__t=(APP.Tools.icon('close','Close','link')))==null?'':__t)+
'\n			</td>\n			'+
((__t=(group))==null?'':__t)+
'\n			<td>\n				<input type=\'search\' id=\'search\' onfocus=\'this.value = this.value;\' value=\''+
((__t=(search))==null?'':__t)+
'\' placeholder=\'search...\'></input>\n				<!-- ';
 if (group) { 
__p+='\n				'+
((__t=(APP.Tools.icon('number')))==null?'':__t)+
'\n				'+
((__t=(APP.Tools.icon('boolean')))==null?'':__t)+
'\n				'+
((__t=(APP.Tools.icon('code')))==null?'':__t)+
'\n				'+
((__t=(APP.Tools.icon('totalizer')))==null?'':__t)+
'\n				';
 } 
__p+=' -->\n			</td>\n		</tr>\n	</thead>\n	<!-- '+
((__t=(APP.Tools.icon('close')))==null?'':__t)+
' -->\n	<tbody id=\'items\'></tbody>\n</table>\n';
}
return __p;
};
