module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Screens</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("settings")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New Screen</button>\n<button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Import from Other Installation</button>\n\n<p>A <i>Screen</i> is the primary box that holds all the widgets.</p>\n\n<p>Once a screen is created, widgets can be added to it. Each widget is attached to a tag (or a device), to display data in real time.</p>\n\n<p>Screens have a few kinds:\n	<dl>\n		<dt>Master<dt>\n		<dd>A screen that is displayed behind another screen.</dd>\n		<dt>Home</dt>\n		<dd>The screen loaded by default</dd>\n		<dt>Mobilehome</dt>\n		<dd>The screen loaded by default on mobile devices (if present.  Otherwise, mobile devices use "home")</dd>\n	</dl>\n\n</p>\n\n<p>If a screen has a master screen, it\'s widgets are drawn before the screen.  Master screens are useful to hold\n	a set of navigation buttons.</p>\n\n	<p>Screens require dimensions, but be advised that the screen always scales to fit whatever display is being used.\n		Dimensions, therefore, set the screen\'s aspect ratio.</p>\n\n';
}
return __p;
};
