module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'widget_edit_menu\'>\n	<div id=\'exit\' class=\'command\'>\n		'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' <span class=\'minwidth1200\'>EXIT</span>\n	</div>\n<!-- <div id=\'title\' class=\'minwidth1200\'>\n		'+
((__t=(selection[wids[0]].widget.getf('_id')))==null?'':__t)+
'\n	</div>\n -->\n<div id=\'toolbar\' class=\'\'>\n	<div id=\'dimensions\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'dimensions\'>dimensions</div>\n			<div class=\'tools\' id=\'dimensions\'>\n				 x:<input type=number step="5" title="keyboard: a s" maxlength="4" id=\'left\' value=\''+
((__t=(selection[wids[0]].widget.get("left")))==null?'':__t)+
'\'>\n				 y:<input type=number step="5" title="keyboard: e r" maxlength="4" id=\'top\' value=\''+
((__t=(selection[wids[0]].widget.get("top")))==null?'':__t)+
'\'>\n				 w:<input type=number step="5" title="keyboard: d f" maxlength="4" id=\'width\' value=\''+
((__t=(selection[wids[0]].widget.get("width")))==null?'':__t)+
'\'>\n				 h:<input type=number step="5" title="keyboard: x c" maxlength="4" id=\'height\' value=\''+
((__t=(selection[wids[0]].widget.get("height")))==null?'':__t)+
'\'>\n				 '+
((__t=(APP.Tools.icon("arrows-v")))==null?'':__t)+
':<input type=number step="1" title="Layer (higher numbers are on top of lower numbers)" maxlength="4" id=\'zindex\' value=\''+
((__t=(selection[wids[0]].widget.get("zindex")))==null?'':__t)+
'\'>\n			</div>\n	</div>\n	<div id=\'fill\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'fill\'>fill</div>\n			<div class=\'tools\' id=\'fill\'>\n				<div class=\'tool\' id=\'top\'>up</div>\n				<div class=\'tool\' id=\'bottom\'>down</div>\n				<div class=\'tool\' id=\'left\'>left</div>\n				<div class=\'tool\' id=\'right\'>right</div>\n			</div>\n	</div>\n	<div id=\'size\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'size\'>size</div>\n			<div class=\'tools\' id=\'size\'>\n				<div class=\'tool\' id=\'default\'>default</div>\n				<div class=\'tool\' id=\'square\'>square</div>\n				<div class=\'tool\' id=\'grid\'>grid</div>\n			</div>\n	</div>\n\n</div>\n<!-- <div id=\'dimensions\'>\n		x:\n		<input type=number step="5" title="keyboard: a s" maxlength="4" id=\'left\' value=\''+
((__t=(selection[wids[0]].widget.get("left")))==null?'':__t)+
'\'> y:\n		<input type=number step="5" title="keyboard: e r" maxlength="4" id=\'top\' value=\''+
((__t=(selection[wids[0]].widget.get("top")))==null?'':__t)+
'\'> w:\n		<input type=number step="5" title="keyboard: d f" maxlength="4" id=\'width\' value=\''+
((__t=(selection[wids[0]].widget.get("width")))==null?'':__t)+
'\'> h:\n		<input type=number step="5" title="keyboard: x c" maxlength="4" id=\'height\' value=\''+
((__t=(selection[wids[0]].widget.get("height")))==null?'':__t)+
'\'>\n	</div> -->\n<div id=\'commands\'>\n		<button class=\'button command\' id=\'delete\'>\n			'+
((__t=(APP.Tools.icon('delete')))==null?'':__t)+
' delete</button>\n		<button class=\'button command\' id=\'duplicate\'>\n			'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
' duplicate</button>\n	</div>\n	<div class=\'command\' id=\'widgets\'>\n		'+
((__t=(APP.Tools.icon("widgets")))==null?'':__t)+
' <span class=\'minwidth1200\'>WIDGETS</span>\n	</div>\n	';
 if (APP.USER.Can("settings")) { 
__p+='\n		<div id=\'settings\' class=\'command\'>\n			'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
'\n		</div>\n	';
 } 
__p+='\n</div>\n';
}
return __p;
};
