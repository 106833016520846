
DEF.tag_library = {};

DEF.tag_library.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.tag_library.Model,
		url       : `${SETTINGS.dburl}/tag_library`,
		comparator: 'dl_id',
		byFilter(filter) {
			var records = this.filter(filter)
			return new DEF.tag_library.Collection(records);
		}
	};
	return APP.InitializeModels('tag_library', options, callback, constraints);
};

DEF.tag_library.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: 'dl_id',
	parentModule     : 'device_library',
	collection_name  : 'tag_library',
	defaults         : {
		symbol       : 'NEW',
		name         : '',
		dl_id        : '',
		type         : 'number', // number, string, date, boolean
		cast         : 'fixed', // number(analog, unsigned..), string(caps..), date(relative, time..)
		preprocess   : 'none', // simple operators before the data is stored.
		deadband     : 5,
		address      : '',
		formula      : '',
		description  : '',
		data_rate    : 300, // minimum data rate, in seconds
		retention    : 86400, // data retention in seconds
		arm_time     : 0, // how many seconds of alarm state before it's saved
		alarm_arm    : false, // symbol used to "arm" the alarm, or false for always armed
		notifications: {} // notifdcations happen on TAG level, but this is here for .getUp support
	},
	db_search     : ['symbol', 'name', 'address', 'description', 'formula', 'cast', 'preprocess'],
	db_columns    : ['address', 'name', 'dl_id', 'type', 'cast', 'data_rate', 'retention', 'preprocess'],
	db_spreadsheet: ['symbol', 'name', 'address', 'type', 'cast', 'unit', 'data_rate', 'retention', 'formula'],
	db_filters    : ['dl_id', 'type'],
	db_tools      : {
		'Deploy Tags' : 'DeployTags',
		'Kill Orphans': 'KillOrphans'
	},
	units: ['ºF', 'ºC', 'kW', 'a'],
	getName() {
		return this.get('symbol');
	},
	getIcon() {
		return APP.Tools.icon(this.getUp('type'));
	},
	getDeviceLibrary() {
		return this.FindParent('device_library', this.get('dl_id'));
	},
	getDevice() {
		return this.FindParent('devices', this.get('d_id'));
	},
	all_fields() {
		return $.extend(true, {}, this.getDeviceLibrary().attributes, this.attributes);
	},

	/**
   * Given a "name", this geneartes the tag symbol.
   * @param {[type]} name [description]
   */
	GenerateSymbol(name) {
		name = name || this.get('name');
		let char = '';
		let out = '';
		if (typeof name === 'string') {
			name = name.replace(/_/g, ' ');
			const uppercase = (name.match(/[A-Z]/g) || []).length;
			const lowercase = (name.match(/[a-z]/g) || []).length;
			console.log(uppercase, lowercase);
			if (uppercase > lowercase && lowercase === 0)
				name = APP.Format.capitalize(name);
			console.log(name);
			if (name[0])
				out = name[0].toUpperCase();
			else
				out = 'xxx';


			const length = name.length;
			for (let i = 1; i < length; i++) {
				char = name[i];

				if (char.toUpperCase() !== char.toLowerCase()) {
					// LETTER
					if (char === char.toUpperCase())
						out += char;
				} else if (isNumber(char)) {
					// NUMBER
					out += char;
				} else if (char === ' ' && i + 1 < length) {
					out += name[i + 1].toUpperCase();
					i++;
				}
			}
		}
		out = out.replace(/\W/g, '').toUpperCase().slice(0, 10);
		const dups = APP.models.tag_library.where({ symbol: out }).length;
		if (dups)
			out += dups;
		return out;
	},

	/** Convenience type functions, returning the type of value the tag is. */
	isNumber() {
		const type = this.get('type');
		return ['number', 'totalizer'].indexOf(type) >= 0;
	},
	isBoolean() {
		return this.get('type') === 'boolean';
	},
	isString() {
		const type = this.get('type');
		return ['string', 'code'].indexOf(type) >= 0;
	},
	isDate() {
		return this.get('type') === 'date';
	}
});
