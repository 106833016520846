module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'name\'>'+
((__t=(name))==null?'':__t)+
' <span class=\'freq\'>'+
((__t=(APP.Tools.icon("clock-o")))==null?'':__t)+
' '+
((__t=(frequency))==null?'':__t)+
'</span></div>\n<div id=\'template\' class=\'GENERATED_REPORT\'>\n	<style scoped>\n		@import "'+
((__t=(SETTINGS.reportcss_url))==null?'':__t)+
'";\n	</style>\n	'+
((__t=(html))==null?'':__t)+
'\n</div>\n<div id=\'watermark\'>SAMPLE</div>\n';
}
return __p;
};
