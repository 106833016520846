module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tags</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi", {})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Program</th>\n		<td>\n			<textarea class=\'json field tall refresh\' id=\'program\'>'+
((__t=(JSON.stringify(program, null, '\t')))==null?'':__t)+
'</textarea>\n			<div class=\'help\'>\n				<li><a href="https://andyhall.github.io/wasgen/">Program Builder</a></li>\n				<li><a href="https://www.freeformatter.com/json-formatter.html#ad-output">Formatter</a></li>\n			</div>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Volume\n			</th>\n			<td>\n				'+
((__t=(APP.UI.slider("volume", volume, 0, 100,1,'field' )))==null?'':__t)+
'\n			</td>\n			</tr>\n			<tr>\n				<th>\n			Min Freq\n		</th>\n		<td>\n			'+
((__t=(APP.UI.slider("minf", minf, 1, 1000,1,'field' )))==null?'':__t)+
'\n			</td>\n	</tr>\n	<tr>\n		<th>\n			Max Freq\n		</th>\n		<td>\n			'+
((__t=(APP.UI.slider("maxf", maxf, 1, 1000,1,'field' )))==null?'':__t)+
'\n			</td>\n			</tr>\n			<tr>\n				<th>\n					Response Rate\n				</th>\n				<td>\n					'+
((__t=(APP.UI.slider("response", response, 0.1, 5,0.1,'field' )))==null?'':__t)+
'\n				</td>\n	</tr>\n\n</table>';
}
return __p;
};
