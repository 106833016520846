module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Label</th>\n		<td colspan=3>\n			'+
((__t=(APP.UI.input("label2",label2)))==null?'':__t)+
'</br>\n			'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Left In</th>\n		<td>'+
((__t=(APP.UI.chooser("leftin", "tags", leftin)))==null?'':__t)+
'</td>\n		<td>'+
((__t=(APP.UI.chooser("rightin", "tags", rightin)))==null?'':__t)+
'</td>\n		<th>Right In</th>\n	</tr>\n	<tr>\n		<th>Left Out</th>\n		<td>'+
((__t=(APP.UI.chooser("leftout", "tags", leftout)))==null?'':__t)+
'</td>\n		<td>'+
((__t=(APP.UI.chooser("rightout", "tags", rightout)))==null?'':__t)+
'</td>\n		<th>Right Out</th>\n	</tr>\n</table>';
}
return __p;
};
