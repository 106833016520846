module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'td datetime\'>'+
((__t=(APP.Format.simpletime(datetime)))==null?'':__t)+
'</div>\n<div class=\'td alarm_state\'>'+
((__t=(APP.Tools.icon(alarm_state)))==null?'':__t)+
'</div>\n<div class=\'td event\'>'+
((__t=(eventf))==null?'':__t)+
'</div>\n';
 if (long) { 
__p+='\n<div class=\'td event\'>'+
((__t=(name))==null?'':__t)+
'</div>\n<div class=\'td event '+
((__t=(alarm_state))==null?'':__t)+
'\'>'+
((__t=(APP.Lang(alarm_state)))==null?'':__t)+
'</div>\n<div class=\'td \'>'+
((__t=(prefix))==null?'':__t)+
'</div>\n';
 } 
__p+='';
}
return __p;
};
