module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <th>\n      SIze:\n    </th>\n    <td>\n      '+
((__t=(APP.UI.slider("size", size, 5, 30 ,5)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>\n      Material\n    </th>\n    <td>\n        '+
((__t=(APP.UI.select_from_object("material", ['iron','pvc'], material)))==null?'':__t)+
'\n\n    </td>\n  </tr>\n</table>\n';
}
return __p;
};
