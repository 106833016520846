module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'header\'>\n	<div class=\'rightmenu\'>\n		<div id=\'online\' title=\''+
((__t=(status))==null?'':__t)+
'\' class=\' '+
((__t=(online?"online":"offline"))==null?'':__t)+
'\'></div>\n		<div id=\'devicemenu\' class=\'submenu\'>\n			<div class=\'link menuitem tab\' data-view=\'details\' id=\'edit\'>\n				edit "'+
((__t=(prefix))==null?'':__t)+
'"\n			</div>\n			<div class=\'link menuitem tab\' data-view=\'details\' id=\'add\'>\n				add a tag\n			</div>\n		</div>\n	</div>\n\n	<span id=\'expand\'>\n		'+
((__t=(APP.Tools.icon("expand")))==null?'':__t)+
'\n	</span>\n	<span class=\'prefix\'>\n		'+
((__t=(prefix))==null?'':__t)+
'\n	</span>\n	<span class=\'connection_string\'>\n		';
 if (online) { 
__p+='\n		'+
((__t=(connection_string))==null?'':__t)+
'\n		';
 } else {
__p+='\n		<i class=\'error\'>'+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
' '+
((__t=(status))==null?'':__t)+
'</i>\n		';
 } 
__p+='\n	</span>\n</div>\n\n<div id=\'tag_box\'>\n	<div id=\'image\'>\n		'+
((__t=(APP.Format.image(image_url)))==null?'':__t)+
'\n	</div>\n	<div id=\'tags\'></div>\n	<div id=\'status_box\'></div>\n</div>\n';
}
return __p;
};
