module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <form ref=\'uploadForm\' id=\'uploadForm\' action=\'http://localhost:8000/upload\' method=\'post\' encType="multipart/form-data">\n	<input type="file" name="sampleFile" />\n	<input type=\'submit\' value=\'Upload!\' />\n</form> -->\n\nImage: <br>\n<input type=\'text\' id=\'image\' class=\'field\' value=\''+
((__t=(image))==null?'':__t)+
'\'>\n\n<fieldset>\n	<legend>Advanced</legend>\n	<table>\n		<tr>\n			<th>\n				Tag\n			</th>\n			<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single")))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>\n				Alarm Image\n			</th>\n			<td>\n				<input type=\'text\' id=\'alarm_image\' class=\'field\' value=\''+
((__t=(alarm_image))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>\n				Warning Image\n			</th>\n			<td>\n				<input type=\'text\' id=\'warning_image\' class=\'field\' value=\''+
((__t=(warning_image))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n	</table>\n</fieldset>\n';
}
return __p;
};
