module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n    <tr>\n        <th>Title</th>\n        <td>\n            <input type=\'text\' id=\'title\' class=\'field\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n        </td>\n    </tr>\n		<tr>\n			<th>Label</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n			</td>\n		</tr>\n    <tr>\n        <th>Tag</th>\n        <td>\n            '+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single","draw")))==null?'':__t)+
'\n        </td>\n    </tr>\n		<tr>\n        <th>Divisions</th>\n        <td>\n            <input type=\'range\' min=5 max=100 id=\'divisions\' value=\''+
((__t=(divisions))==null?'':__t)+
'\' class=\'field live\'>\n        </td>\n    </tr>\n		<tr>\n      <td colspan=2>\n          <input type=\'checkbox\' '+
((__t=(zero?"checked":""))==null?'':__t)+
' id=\'zero\' class=\'field\'>\n          Include Zero Values\n        </td>\n</tr>\n\n</table>\n';
}
return __p;
};
