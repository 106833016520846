module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Source Tags</th>\n		<th>Destination Tags</th>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.UI.chooser("source", "tags", source,'multi')))==null?'':__t)+
'\n		</td>\n		<td>\n			'+
((__t=(APP.UI.chooser("dest", "tags", dest,'multi')))==null?'':__t)+
'\n		</td>\n	</tr>\n		<tr>\n			<th>Label</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("label",["tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n			</td>\n		</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'autoscale\' value=\'true\' '+
((__t=(autoscale? "checked": ""))==null?'':__t)+
' class=\'field live\'> Autoscale\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
