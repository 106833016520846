module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n<div id=\'fullscreen\'>\n	<div id=\'template\' class=\'GENERATED_REPORT\'>\n		<style scoped>\n			@import "'+
((__t=(SETTINGS.reportcss_url))==null?'':__t)+
'";\n		</style>\n		'+
((__t=(html))==null?'':__t)+
'\n	</div>\n	<div>\n		<textarea class="field full tall live nowrap" id="template">'+
((__t=(template))==null?'':__t)+
'</textarea>\n	</div>\n</div>\n';
}
return __p;
};
