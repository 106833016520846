DEF.files.DT = {};
DEF.files.DT.Main = Backbone.Marionette.CollectionView.extend({
	id      : 'FILES',
	template: require('./templates/main.html')
});
DEF.files.DT.child = Backbone.Marionette.View.extend({
	className: 'file',
	template : require('./templates/file.html'),
	events   : {
		click: 'ShowDetails'
	},
	modelEvents: {
		change: 'render'
	},
	attributes() {
		return { id: this.model.id };
	},
	ShowDetails(e) {
		const cmd = 'details';
		APP.Route(`#DT/files/${this.model.id}/${cmd}`);
		e.stopPropagation();
	}
});

DEF.files.DT.Details = DEF.TG.Details.extend({
	id      : 'FILES',
	template: require('./templates/detail.html'),
	DoAction(e) {
		switch (e.currentTarget.id) {
		case 'download':
			if (this.model.get('content')) {
				//		window.location = `data:text/html;charset=utf-8,${encodeURI(this.model.get('content'))}`;
				const file_path = `data:text/html;charset=utf-8,${encodeURI(this.model.get('content'))}`;
				const a = document.createElement('A');
				a.href = file_path;
				a.download = this.model.get('name');
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			}			else if (this.model.get('path')) { window.location = this.model.get('path'); }
			break;
		}
	}
});
DEF.files.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html')
});
