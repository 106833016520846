module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>\n	'+
((__t=(APP.Tools.icon("users")))==null?'':__t)+
'\n		'+
((__t=(name))==null?'':__t)+
'\n</h1>\n\n<h2>User Details</h2>\n\n<div id=\'details\'>\n	<table class=\'table\'>\n		<tr>\n			<th rowspan=6 class=\'notification\'>\n				Avatar:\n				<br>\n				';
 if (image_url.indexOf('http')>0) { 
__p+='\n					'+
((__t=(APP.UI.fontawesome('image_url',image_url)))==null?'':__t)+
'\n				';
 } else { 
__p+='\n					'+
((__t=(APP.Tools.icon(image_url)))==null?'':__t)+
'\n				';
 } 
__p+='\n			</th>\n		</tr>\n		<tr>\n			<th>Name:</th>\n			<td>\n				';
 if (_id===APP.USER.id) { 
__p+='\n					<input type=\'text\' class=\'field\' id=\'name\' value=\''+
((__t=(name))==null?'':__t)+
'\'>\n					';
 } else { 
__p+='\n						'+
((__t=(name))==null?'':__t)+
'\n							';
 } 
__p+='\n			</td>\n			<th>Phone:</th>\n			<td>\n				';
 if (_id===APP.USER.id) { 
__p+='\n					<input type=\'phone\' class=\'field\' id=\'phone\' value=\''+
((__t=(phone))==null?'':__t)+
'\'>\n					';
 } else { 
__p+='\n						<a href="tel:'+
((__t=(phone))==null?'':__t)+
'">\n							'+
((__t=(phone))==null?'':__t)+
'\n						</a>\n						';
 } 
__p+='\n			</td>\n		</tr>\n		<tr>\n			<th>Email:</th>\n			<td>\n				';
 if (_id===APP.USER.id) { 
__p+='\n					<input type=\'email\' class=\'field\' id=\'email\' value=\''+
((__t=(email))==null?'':__t)+
'\'>\n					';
 } else { 
__p+='\n						<a href=\'mailto:'+
((__t=(email))==null?'':__t)+
'\'>\n							'+
((__t=(email))==null?'':__t)+
'\n						</a>\n						';
 } 
__p+='\n			</td>\n			<th>Homepage:</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_collection("home", APP.models.screens, "name", "name", home)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Pageloads:</th>\n			<td>\n				'+
((__t=(APP.Format.number(pageloads)))==null?'':__t)+
'\n			</td>\n			<th>Page Views:</th>\n			<td>\n				'+
((__t=(APP.Format.number(pageviews)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Last Visit:</th>\n			<td>\n				'+
((__t=(APP.Format.datetime(last_visit)))==null?'':__t)+
'\n			</td>\n			<th>Last Login:</th>\n			<td>\n				'+
((__t=(APP.Format.datetime(last_login)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Permissions:</th>\n			<td colspan=3>\n				';

				var fields =  Object.keys(perm);
				for (var f in perm) {
					if (perm[f])
						print(APP.Tools.icon(f)+" "+f+"&nbsp;&nbsp;")
				}


				
__p+='\n			</td>\n		</tr>\n	</table>\n</div>\n\n\n\n<h2>Subscribed Tags for Notifications</h2>\n<div id=\'notifications\'></div>\n\n\n<div href="#" class=\'btn\' id=\'exit\'>\n	'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Back</div>\n<div href="#" class=\'btn\' id=\'edit\'>\n	'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' Edit</div>\n<div class=\'btn\' id=\'logout\'>\n	'+
((__t=(APP.Tools.icon("sign-out")))==null?'':__t)+
' Logout</div>\n';
}
return __p;
};
