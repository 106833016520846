module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var off_time = off_time || 0;
var on_time = on_time || 0;

__p+='\n\n<h1>'+
((__t=(tag_name))==null?'':__t)+
'</h1>\n\n<table class=\'\' cellspacing=0>\n	<tr class=\'nav\'>\n		<td class=\'arrow action\' id=\'left\'>'+
((__t=(APP.Tools.icon('arrow-circle-left')))==null?'':__t)+
'</td>\n		<td class=\'arrow action\' id=\'right\'>'+
((__t=(APP.Tools.icon('arrow-circle-right')))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>\n			Tag Name\n		</th>\n		<td>\n			<input type=\'text\' id=\'tag_name\' class=\'field\' value=\''+
((__t=(tag_name))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Address\n		</th>\n		<td>\n			<input type=\'text\' id=\'address\' class=\'field\' value=\''+
((__t=(address))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Value\n		</th>\n		<td>\n			'+
((__t=(valuef))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Alarm State\n		</th>\n		<td>\n			'+
((__t=(alarm_state))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Updated / Changed\n		</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(updated)))==null?'':__t)+
' / '+
((__t=(APP.Format.livetime(changed)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Points\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(points)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Points (archive)\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(points_archive)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Rates / Deadbands\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(rates)))==null?'':__t)+
' / '+
((__t=(APP.Format.number(deadbands)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Earliest Data\n		</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(earliest_data)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Earliest Archive\n		</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(earliest_data_archive)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Off Time\n			</th>\n			<td>\n				'+
((__t=(APP.Format.duration(off_time*3600)))==null?'':__t)+
'\n			</td>\n			</tr>\n			<tr>\n				<th>\n					On Time\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(on_time*3600)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Color\n		</th>\n		<td>\n			<input type=\'color\' class=\'fied\' id=\'color\' value=\''+
((__t=(color))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n</table>\n\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
' tag db\n	</div>\n	<div class=\'link action\' id=\'database_tl\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
' library db\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
' delete\n	</div>\n\n</div>\n';
}
return __p;
};
