module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>'+
((__t=(APP.Tools.icon("tag_library")))==null?'':__t)+
' '+
((__t=(symbol))==null?'':__t)+
' - '+
((__t=(name))==null?'':__t)+
'</h1>\n<div class=\'note\'>'+
((__t=(APP.Tools.icon("info-circle")))==null?'':__t)+
' This page is very much a work-in-progress.  Feedback welcomed.</div>\n\n<h2>Detail</h2>\n\n<div id=\'details\' class=\'flex\'>\n	';

		var fields =  Object.keys(model.attributes);
		for (var f in fields) {

		var field = model.attributes[fields[f]];
		
__p+='\n		<div>\n			<div class=\'label\'>'+
((__t=(fields[f]))==null?'':__t)+
'</div><div class=\'value\'>'+
((__t=(model.getf(fields[f])))==null?'':__t)+
'</div>\n		</div>\n	';
 } 
__p+='\n</div>\n\n\n<h2>Tags</h2>\n<div id=\'tags\'></div>\n\n\n<div class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n<div class=\'btn\' id=\'edit\'>'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' Edit</div>\n';
}
return __p;
};
