module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <h3>Notifications for\n	'+
((__t=(APP.Tools.icon("tag")))==null?'':__t)+
'\n		'+
((__t=(tag_name))==null?'':__t)+
'\n</h3> -->\n<strong>Mobile Number:</strong>\n<input type="tel" class="user_settxings" required="required" id="phone" value="'+
((__t=(APP.USER.get('phone')))==null?'':__t)+
'">\n<hr>\n<table class="notify_form" width="100%">\n	<tbody>\n		<tr>\n			<td valign="top">\n				<strong>'+
((__t=(APP.Tools.icon('sms')))==null?'':__t)+
' SMS Messages:</strong>\n			</td>\n			<td valign="top">\n				<strong>'+
((__t=(APP.Tools.icon('phone')))==null?'':__t)+
' Phone Call:</strong>\n			</td>\n		</tr>\n\n		<tr>\n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.normal_sms))==null?'':__t)+
' id="normal_sms"> '+
((__t=(APP.Tools.icon('normal')))==null?'':__t)+
' '+
((__t=(APP.Lang('Normal')))==null?'':__t)+
'\n			</td>\n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.normal_phone))==null?'':__t)+
' id="normal_phone"> '+
((__t=(APP.Tools.icon('normal')))==null?'':__t)+
' '+
((__t=(APP.Lang('Normal')))==null?'':__t)+
'\n			</td>\n			\n		</tr>\n		<tr>\n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.warning_sms))==null?'':__t)+
' id="warning_sms"> '+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
' '+
((__t=(APP.Lang('Warning')))==null?'':__t)+
'\n			</td> \n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.warning_phone))==null?'':__t)+
' id="warning_phone"> '+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
' '+
((__t=(APP.Lang('Warning')))==null?'':__t)+
'\n			</td> \n		</tr>\n		<tr>\n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.alarm_sms))==null?'':__t)+
' id="alarm_sms"> '+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
' '+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
'\n			</td>\n			<td>\n				&nbsp;&nbsp;<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.alarm_phone))==null?'':__t)+
' id="alarm_phone"> '+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
' '+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td>\n				<div class=\'button notifycmd\' id=\'smstest\'>test sms</div>\n			</td>\n			<td>\n				<div class=\'button notifycmd\' id=\'calltest\'>test phone</div>\n			</td>\n		</tr>\n\n		<tr>\n			<td id=\'subscribers\' colspan=2></td>\n		</tr>\n\n		<tr>\n			<td colspan=2>\n				';
 if (!window.SETTINGS.twilio) {  
__p+='\n					<div class=\'note alarm\'>\n						'+
((__t=(APP.Tools.icon("alarm")))==null?'':__t)+
' SMS API is not set up\n					</div>\n					';
 } else {
__p+='\n						<div class=\'smallnote\'>\n							'+
((__t=(APP.Tools.icon("info")))==null?'':__t)+
' SMS notifications are sent from\n								<b>\n									'+
((__t=(window.SETTINGS.twilio.phone))==null?'':__t)+
'\n								</b>\n						</div>\n						';
 } 
__p+='\n			</td>\n		</tr>\n\n		<!-- <tr>\n			<td colspan="2">\n				<input type="checkbox" class="notification_checkbox" id="sms_at_night" checked="checked"> Notify at Night? (8pm-8am)\n			</td>\n		</tr> -->\n	</tbody>\n</table>\n';
}
return __p;
};
