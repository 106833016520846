DEF.settings.DT = {};
// DEF.settings.DT.mainx = Backbone.Marionette.CollectionView.extend({
// 	template       : require('./templates/main.html'),
// 	templateContext: {
// 		version: require('../../../version.html'),
// 	},
// 	className: 'settings',
// 	tagName  : 'table',
// 	id       : 'SETTINGS',
// });

DEF.settings.DT.Main = Backbone.Marionette.View.extend({
	template: require('./templates/main2.html'),
	defaults: {
		autorefresh       : 86400,
		hide_extras_margin: 60,
		installation      : 'Telegauge',
		pulse_time        : 60,
		stale_time        : 5,
		warning           : 'warning',
		alarm             : 'alarm',
		normal            : 'normal'
	},
	templateContext() {
		const custom = [];
		const rs = this.defaults;
		for (const r in rs) // apply settings
			rs[r] = APP.GetSetting(r, rs[r]);

		const usersettings = APP.models.settings.pluck('key');
		for (const u in usersettings)
			if (usersettings[u] && !rs[usersettings[u]]) {
				custom.push(usersettings[u]);
				rs[usersettings[u]] = APP.GetSetting(usersettings[u]);
			}

		rs.custom = custom;
		rs.version = require('../../../version.html');
		return rs;
	},
	ui: {
		field  : '.field',
		new_key: '#new_key'
	},
	events: {
		'change @ui.field': 'Save'
	},
	className: 'settings',
	tagName  : 'div',
	id       : 'SETTINGS',
	onRender() {
		_.defer(() => {
			APP.root.getRegion('main').currentView.ToggleDetails(false);
		});
	},
	onDestroy() {
		if (APP.root.getRegion('main').currentView)
			APP.root.getRegion('main').currentView.ToggleDetails(true);
	},
	Save(e) {
		let refresh = false;
		let key = e.currentTarget.id;
		const val = e.currentTarget.value;
		console.log(e.currentTarget.id, e.currentTarget.value);

		if (key == 'new_value') {
			key = this.ui.new_key.val();
			refresh = true;
		}

		const setting = APP.models.settings.get(key);
		if (setting)
			setting.set('value', val);
		else
			APP.models.settings.create({ key, value: val });
		if (refresh)
			this.render();
	}
});

// DEF.settings.DT.child = Backbone.Marionette.View.extend({
// 	className: 'setting',
// 	tagName  : 'tr',
// 	template : require('./templates/setting.html'),
// 	events   : {
// 		click: 'ShowDetails',
// 	},
// 	modelEvents: {
// 		change: 'render',
// 	},
// 	attributes() {
// 		return { id: this.model.id };
// 	},
// 	ShowDetails(e) {
// 		const cmd = 'details';
// 		APP.Route(`#DT/settings/${this.model.id}`);
// 		event.stopPropagation();
// 	},
// });

// DEF.settings.DT.Details = DEF.TG.Details.extend({
// 	template: require('./templates/details.html'),
// });
DEF.settings.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html')
});
