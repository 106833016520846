module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<td colspan=2>\n			'+
((__t=(APP.UI.select_from_object("mode",["exclude","include"],mode)))==null?'':__t)+
' these screens:\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			'+
((__t=(APP.UI.chooser("screens", "screens", screens,"multi")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Wrap</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("wrap",{"wrap":"Multi Line","nowrap":"Single Line"},wrap)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Align</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("align",["left","center","right"],align)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<Td></Td>\n		<td>\n			'+
((__t=(APP.UI.checkbox("show_glyph",show_glyph,"Include Glyph")))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
