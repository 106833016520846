module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'db_menu\'>\n	<div id=\'tag_library\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('tag_library')))==null?'':__t)+
' <span class=\'minwidth1800\'>Tag Library</span></div>\n<div id=\'tags\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('tags')))==null?'':__t)+
' <span class=\'minwidth1800\'>Tags</span></div>\n<div id=\'device_library\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('device_library')))==null?'':__t)+
' <span class=\'minwidth1800\'>Device Library</span></div>\n<div id=\'devices\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' <span class=\'minwidth1800\'>Devices</span></div>\n<div id=\'users\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('users')))==null?'':__t)+
' <span class=\'minwidth1800\'>Users</span></div>\n<div id=\'screens\' class=\'menuitem\'>\n			'+
((__t=(APP.Tools.icon('screens')))==null?'':__t)+
' <span class=\'minwidth1800\'>Screens</span></div>\n<div id=\'widgets\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('widgets')))==null?'':__t)+
' <span class=\'minwidth1800\'>Widgets</span></div>\n<div id=\'pollers\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' <span class=\'minwidth1800\'>Pollers</span></div>\n<div id=\'data\' class=\'menuitem\'>\n		'+
((__t=(APP.Tools.icon('data')))==null?'':__t)+
' <span class=\'minwidth1800\'>Data</span></div>\n<div id=\'files\' class=\'menuitem\'>\n				'+
((__t=(APP.Tools.icon('files')))==null?'':__t)+
' <span class=\'minwidth1800\'>Files</span></div>\n<div id=\'reports\' class=\'menuitem\'>\n						'+
((__t=(APP.Tools.icon('reports')))==null?'':__t)+
' <span class=\'minwidth1800\'>Reports</span></div>\n<div id=\'network\' class=\'menuitem\'>\n	'+
((__t=(APP.Tools.icon('network')))==null?'':__t)+
' <span class=\'minwidth1800\'>Network</span></div>\n<div id=\'settings\' class=\'menuitem\'>\n	'+
((__t=(APP.Tools.icon('settings')))==null?'':__t)+
' <span class=\'minwidth1800\'>Settings</span></div></div>\n<div id=\'db_filter\'></div>\n\n<div class=\'main_content\' id=\'data\'></div>\n';
}
return __p;
};
