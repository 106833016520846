module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var title=title||"";
var display=display||"";
var d_id=d_id||"";

__p+='\n\n	<table>\n		<tr>\n			<th>Title</th>\n			<td>\n				<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Filter</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("filter", {"":"All","number":"Number","boolean":"Boolean","code":"Code"},filter, "field") ))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td colspan=2>\n				<hr>\n			</td>\n		</tr>\n		<tr>\n			<th>By Device</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("d_id", "devices", d_id)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n';
}
return __p;
};
