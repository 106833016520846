DEF.network.DT = {};
DEF.network.DT.Main = Backbone.Marionette.CollectionView.extend({
	id      : 'NETWORK',
	template: require('./templates/main.html')
});
DEF.network.DT.child = Backbone.Marionette.View.extend({
	className: 'network',
	template : require('./templates/device.html'),
	events   : {
		click: 'ShowDetails'
	},
	modelEvents: {
		change: 'render'
	},
	attributes() {
		return { id: this.model.id };
	},
	ShowDetails(e) {
		const cmd = 'details';
		APP.Route(`#DT/network/${this.model.id}/${cmd}`);
		e.stopPropagation();
	},
	onDomRefresh() {
		this.$el.removeClass('offline online').addClass(this.model.get('status').toLowerCase());
	}
});

DEF.network.DT.Details = DEF.TG.Details.extend({
	id      : 'NETWORK',
	template: require('./templates/detail.html'),
	DoAction(e) {
		switch (e.currentTarget.id) {
		case 'download':
			window.location = this.model.get('path');
		}
	}
});
DEF.network.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html'),
	ui      : {
		command: '.command'
	},
	events: {
		'click @ui.command': 'DoCommand'
	},
	DoCommand(e) {
		console.log(e.currentTarget.id);
		switch (e.currentTarget.id) {
		case 'clear':
			APP.models.network.where({ status: 'OFFLINE' }).forEach((m) => {
				APP.models.network.remove(m);
			});
			break;
		case 'reset':
			APP.models.network.where({ }).forEach((m) => {
				APP.models.network.remove(m);
			});
			break;
		}
	}
});
