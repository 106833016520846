module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var _id = _id||"";

__p+='\n<td class=\'model_name nowrap\'>\n	<span class=\'model_link link\' data-link="#'+
((__t=(collection))==null?'':__t)+
'/'+
((__t=(_id))==null?'':__t)+
'">\n		'+
((__t=(icon))==null?'':__t)+
'\n	</span>\n	'+
((__t=(model_name))==null?'':__t)+
'\n</td>\n\n';

for (var c = 0; c<cols.length; c++) {
	var col = cols[c];
	//var val = attr[col];
	print ("<td class='"+col+"'>"+fields[c]+"</td>");
}

__p+='';
}
return __p;
};
