module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Network</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("network")))==null?'':__t)+
'</div>\n\n<p>Telegauge keeps an eye on the local networks. </p>\n\n<button id=\'clear\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("delete")))==null?'':__t)+
' Delete Offline Devices</button>\n\n	<button id=\'reset\' class=\'btn command\'>\n		'+
((__t=(APP.Tools.icon("refresh")))==null?'':__t)+
' Delete All Devices</button>\n	';
}
return __p;
};
