module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Image URL\n		</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'image\' value=\''+
((__t=(image))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Size</th>\n		<td>\n			'+
((__t=(APP.UI.slider("size", size, -2, 2)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Margin</th>\n		<td>\n			'+
((__t=(APP.UI.slider("margin", margin, -99, 100)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Noise</th>\n		<td>\n			'+
((__t=(APP.UI.slider("noise", noise, 0,10)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th></th>\n		<td>\n			'+
((__t=(APP.UI.checkbox("shadow", shadow, "Draw Shadow")))==null?'':__t)+
'\n				<br>\n				'+
((__t=(APP.UI.checkbox("value", value, "Draw Value")))==null?'':__t)+
'\n					<br>\n					'+
((__t=(APP.UI.checkbox("unit", unit, "Draw Unit")))==null?'':__t)+
'\n						<br>\n		</td>\n	</tr>\n</table>\n<img style=\'max-width: 250px;\' src=\''+
((__t=(image))==null?'':__t)+
'\'>\n';
}
return __p;
};
