module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>kW</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("kw", "tags", kw)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>kVA</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("kva", "tags", kva)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>kVAR</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("kvar", "tags", kvar)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>PF</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("pf", "tags", pf)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
