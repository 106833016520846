module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	var icons = {
		'a':APP.Tools.icon('arrow-circle-down'),
		'd':APP.Tools.icon('arrow-circle-up')
	}

__p+='\n<table>\n	<thead>\n		<tr>\n			<th>\n				'+
((__t=(APP.Tools.icon(collection)))==null?'':__t)+
'\n					'+
((__t=(collection.replace("_"," ")))==null?'':__t)+
'\n			</th>\n			';

				for (var c =0; c<cols.length; c++) {
					var col = cols[c];
					switch(col) {
						case 't_id':
							col="Tag";
							break;
						case 'tl_id':
							col="tag library";
							break;
						case 'dl_id':
							col="device library";
							break;
						case 'd_id':
							col="device"
							break;
						case 'poller_id':
							col="poller";
							break;
					}
					var sort = "";
					var regx = new RegExp(col+'\\|(.)\\&');
					var match = (APP.Tools.store("dbsort",collection)||"").match(regx);
					if (match)
						sort = icons[match[1]];
					//console.log(col, regx,match);
					print("<th id='"+col+"' class='sort "+col+"'>"+col.replace("_"," ")+sort+"</th>");
				}
			
__p+='\n		</tr>\n	</thead>\n	<tbody id=\'data\'></tbody>\n</table>\n';
}
return __p;
};
