
DEF.layout = DEF.layout || {};
require('./tooltip.js');

DEF.layout.RT = Backbone.Marionette.View.extend({
	id       : 'RT',
	className: 'bottom',
	template : require('./templates/rt.html'),
	regions  : {
		screen_box : '#SCREEN_BOX',
		menu_box   : '#MENU_BOX',
		log_box    : '#EXTRA_BOX #event_log_box',
		alarm_box  : '#EXTRA_BOX #alarm_list_box',
		edit_box   : '#EDIT_BOX',
		tooltip_box: '#TOOLTIP_BOX'
	},
	events: {
		keyup: 'onKey'
	},
	initialize() {
		$(window).resize(this.ScaleScreen.bind(this));
	},
	onRender() {
		this.showChildView('menu_box', new DEF.layout.RT_MENU({}));

		// this.extras = true;
		// this.HideExtras();

		// this.screen = this.GetScreen();
		// this.showChildView('screen_box', new DEF.screens.RT.Main({
		// 	model: this.screen,
		// }));
	},
	onDomRefresh() {
		this.ScaleScreen();
	},
	ShowExtras() {
		// return false;
		if (!this.extras) {
			// console.time('showextras');
			try {
				$('#EXTRA_BOX').removeClass('hideborder');
				this.extras = [
					this.showChildView('alarm_box', new DEF.layout.ALARM_BOX.Main({
						collection: APP.models.tags,
						template  : _.template("<div id='list'></div>")
					})),
					this.showChildView('log_box', new DEF.layout.LOG_BOX.Main({
						collection: APP.models.events
					}))
				];
			}		catch (e) {
				console.warn('Bug #2.8.69');
			}
			// console.timeEnd('showextras');
		}
	},
	HideExtras() {
		if (this.extras) {
			console.time('hideextras');
			const ab = this.getRegion('alarm_box');
			if (ab)
				ab.empty();
			const lb = this.getRegion('log_box');
			if (lb)
				lb.empty();
			$('#EXTRA_BOX').addClass('hideborder');
			this.extras = false;
			console.timeEnd('hideextras');
		}
		$('#EXTRA_BOX').addClass('hideborder');
	},

	/**
	 * Find a screen with the given name, or Home or mobilehome
	 * @param  {string} name Screen name, or "false" to find a home
	 * @return {object}      A screen, or undefined
	 */
	GetScreen(name) {
		// debugger;
		const screens = APP.models.screens.where();
		const out = {};
		const mobile = matchMedia('only screen and (max-width: 760px)').matches;

		if (!name) // see if there's a user pref
			name = APP.USER.get(mobile ? 'mobilehome' : 'home');
		if (name) {
			name = APP.Format.url(name);
			for (const s in screens)
				if (name === APP.Format.url(screens[s].get('name')))
					return screens[s];
		}

		for (const s in screens) {
			const screen = screens[s];
			if (screen.get('kind') === 'mobilehome')
				out.mobile = screen;
			if (screen.get('kind') === 'home')
				out.home = screen;
		}

		if (mobile && out.mobile)
			return out.mobile;
		return out.home;
	},
	SetScreen(name, prefix) {
		if (this.widget_load_time) {
			console.log('Widget load time', Date.now() - this.widget_load_time, 'ms');
			delete this.widget_load_time;
		}
		$('#qr').removeClass('zoomed');

		$('#RT #menu #screens .preview').removeClass('selected');
		const screen = this.GetScreen(name);
		if (screen) {
			$(`#RT #menu #screens #${screen.id} .preview`).addClass('selected');
			_.defer(() => {
				this.screen = screen;
				APP.current_screen = screen;
				APP.current_screen.svg = false; // reset the Vector canvas
				APP.current_screen.prefix = prefix;

				const screen_id = screen.id;
				const master_id = screen.get('master_id');
				const WidgetFilter = function WidgetFilter (m) {
						return m.get('screen_id') === screen_id || m.get('screen_id') === master_id;
				};

				// Check all the widgets this screen requires to see if they are loaded.
				// const widgets = APP.models.widgets.filter(WidgetFilter);
				const widgets = APP.models.widgets.byScreen(screen_id, master_id);

				if (!APP.EnsureWidgets(widgets.models, this.SetScreen.bind(this, name, prefix)))
					return;

				console.time(`setscreen ${name}`);
				const dom = document.getElementsByTagName('*').length;

				const screen_view = new DEF.screens.RT.Main({
					model: screen,
					// collection: APP.models.widgets,
					collection: widgets,
					// viewFilter: WidgetFilter,
					// viewFilter (view, index, children) { //TODO - this renders every widget, but only displays some!
					// 	m = view.model;
					// 	return (
					// 		m.get('screen_id') === screen_id ||
					// 		m.get('screen_id') === master_id
					// 	)
					// },
					prefix,
				})
				APP.current_screen_view = screen_view;
				// console.log("sv>",screen_view.model.attributes)
				this.showChildView('screen_box', screen_view);
				screen.set({ last_view: Date.now(), views: (screen.get('views') || 0) + 1 });

				this.ScaleScreen();
				$(`#RT #menu #screens #${screen.id} .preview`).addClass('selected');

				console.timeEnd(`setscreen ${name}`);
				console.log('  DOM change', document.getElementsByTagName('*').length - dom);
			});
		} else if (name) { // screen name not found, so try again for defaults
			this.SetScreen(false);
		}
	},
	ScaleScreen() {
		if (this.screen) {
			let hide_margin = 40;
			if (APP.models.settings.get('hide_extras_margin'))
				hide_margin = APP.models.settings.get('hide_extras_margin').get('value');
			const w = this.screen.getUp('width');
			const h = this.screen.getUp('height');
			const W = $(window).width() - 2; // 2 = CSS borde;
			let H = $(window).height() - 2; // - 35;

			if (APP.design_time)
				H -= 50;


			const scale = Math.min(W / w, H / h);

			const size = { width: `${w * scale}px`, height: `${h * scale}px` };
			$('#SCREEN_BOX').css(size);

			const $rt = $('#RT');
			// Require 40 extra pixels to show the extra stuff
			if (W - (w * scale) + H - (h * scale) < hide_margin) {
				this.HideExtras();
			} else {
				_.defer(this.ShowExtras.bind(this));
				// this.ShowExtras();
				if ((w * scale) + 1 >= W) {
					if (!$rt.hasClass('bottom'))
						$rt.removeClass('right').addClass('bottom');
				} else if (!$rt.hasClass('right')) {
					$rt.removeClass('bottom').addClass('right');
				}
				if ($('#event_log_box').width() < 150)
					$('#event_log_box').addClass('none').removeClass('wide narrow');
				else if ($('#event_log_box').width() < 300)
					$('#event_log_box').addClass('narrow').removeClass('wide none');
				else
					$('#event_log_box').addClass('wide').removeClass('narrow none');
			}
		}
	},
	StartEditScreen() {
		console.log('edit');
		$('.widget').addClass('edit');
	},
	EndEditScreen() {
		$('.widget').removeClass('edit');
	}

});

DEF.layout.RT_MENU = Backbone.Marionette.View.extend({
	id      : 'MENU',
	template: require('./templates/rt_menu.html'),
	templateContext() {
		return { qr: this.GetQRCode() };
	},
	ui: {
		command   : '.command',
		burger    : '#BURGER',
		menu      : '#menu',
		user      : '#user',
		preview   : '.preview',
		qr        : '#qr',
		fullscreen: '#fullscreen'
	},
	regions: {
		screens: '#screens',
		devices: '#devices',
		pollers: '#pollers'
	},
	events: {
		'click @ui.command'   : 'Command',
		'click @ui.burger'    : 'ToggleMenu',
		'click @ui.user'      : 'GoUser',
		'click @ui.qr'        : 'ToggleQR',
		'click @ui.fullscreen': 'ReqFullscreen'
	},
	initialize() {
		$(document).on('keyup', this.DoKey.bind(this));
	},
	onRender() {
		this.listenTo(APP, 'paintscreen', this.RefreshQR);

		this.showChildView('screens', new DEF.screens.DT.Main({
			collection: APP.models.screens,
			childView : DEF.screens.DT.child,
			viewFilter(m) {
				return m.model.get('kind') !== 'master' && m.model.get('enabled');
			}
			// viewComparator(m) {
			// 	let sort = Date.now() - (m.get('last_view') || 0);
			// 	if (m.get('kind') === 'home')
			// 		sort = 0;
			// 	return sort;
			// },
		}));

		this.showChildView('devices', new Backbone.Marionette.CollectionView({
			collection: APP.models.devices,
			childView : DEF.devices.DT.small_device,
			filter(m) {
				return m.get('enabled');
			},
			viewComparator (m) {
				return m.model.get('dl_id') + m.model.get('prefix');
			}
		}));

		this.showChildView('pollers', new Backbone.Marionette.CollectionView({
			collection: APP.models.pollers,
			childView : DEF.pollers.DT.small_poller
		}));
	},
	onDomRefresh() {
		// _.defer(this.InitSideScroller.bind(this));
	},
	GoUser() {
		APP.Route(`#users/${APP.USER.id}`);
	},
	ToggleMenu() {
		$(this.ui.menu).slideToggle({ duration: 100, easing: 'swing' });
		$(this.ui.burger).toggleClass('active');
		if (APP.current_screen.get('kind') !== 'master')
			this.ScrollIntoView(this.get_current_screen_index());
	},
	ReqFullscreen() {
		const element = document.documentElement;
		const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

		element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
		document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

		isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
		if (isFullscreen)
			$('#commands #fullscreen').removeClass('active');
		else
			$('#commands #fullscreen').addClass('active');
	},
	GetQRCode() {
		const qr = require('qr-image');
		let url = SETTINGS.posturl;
		if (!url) {
			console.error('POST url not set');
			return '';
		}
		if (APP && APP.current_screen)
			url += `/#RT/${APP.Format.url(APP.current_screen.getName())}`;
		const svg_string = qr.imageSync(url, { type: 'svg' });
		return svg_string;
	},
	RefreshQR() {
		$('#qr #qrimg').html(this.GetQRCode());
	},
	ToggleQR() {
		console.log('rq click');
		this.RefreshQR();
		$(this.ui.qr).toggleClass('zoomed');
	},
	ScrollIntoView(index) {
		if (index) {
			// console.log('scroll', index);
			const css_margin = 40;
			const window_width = $('#SCREENS').width();
			const start_offset = $('#SCREENS .preview')[0].offsetLeft;
			const total_width = document.getElementById('SCREENS').scrollWidth + start_offset + css_margin;
			const offset = $('#SCREENS .preview')[index].offsetLeft - start_offset;

			const scrollto = (total_width - window_width) * offset / total_width;
			$('#SCREENS').animate({ scrollLeft: scrollto }, 500); // scroll to view
		}
	},
	InitSideScroller() {
		const slider = document.querySelector('#SCREENS');
		let isDown = false;
		let startX;
		let scrollLeft;

		slider.addEventListener('mousedown', (e) => {
			isDown = true;
			slider.classList.add('active');
			startX = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mousemove', (e) => {
			if (!isDown)
				return;
			e.preventDefault();
			const x = e.pageX - slider.offsetLeft;
			const walk = (x - startX); // scroll-fast
			slider.scrollLeft = scrollLeft - walk;
			console.log(walk);
		});
	},
	DoKey(e) {
		if (APP.design_time)
			return;
		// console.log(e, e.key);
		if (e.key === 'Enter' && e.ctrlKey)
			if (APP.design_time)
				APP.Route(`#RT/${APP.current_screen.get('name')}`);
			else
				APP.Route(`#DT/edit/${APP.Format.url(APP.current_screen.get('name'))}`);

		const index = this.get_current_screen_index();
		switch (e.key) {
		case ' ':
			if (document.activeElement.tagName === 'BODY')
				this.ToggleMenu();
			break;
		case 'ArrowLeft':
			if ($(this.ui.burger).toggleClass('active'))
				this.Nav(index - 1);
			break;
		case 'ArrowRight':
			if ($(this.ui.burger).toggleClass('active'))
				this.Nav(index + 1);
			break;
		}
	},
	Nav(index) {
		if ($(this.ui.burger).hasClass('active')) {
			// const domscreen = $('#SCREENS .screen')[index];
			// console.log(domscreen);
			const screens = this.getChildView('screens').collection.filter(m => m.get('kind') !== 'master');

			index = APP.Format.wrap(index, 0, screens.length);
			const screen = screens[index];
			this.ScrollIntoView(index);
			APP.Route(`#RT/${APP.Format.url(screen.get('name'))}`);
		}
	},
	Command(e) {
		console.log(e.currentTarget.id);
		this.ToggleMenu();
		switch (e.currentTarget.id) {
		case 'edit':
			// const root = APP.root.getChildView('main');
			//	root.StartEditScreen();
			APP.Route(`#DT/edit/${APP.Format.url(APP.current_screen.get('name'))}`);
			break;
		case 'settings':
			APP.Route('#DT');
			break;
		case 'alarms':
			APP.Route('#alarms');
			break;
		case 'events':
			APP.Route('#events');
			break;
		default:
			APP.Route(`#DT/${e.currentTarget.id}`);
			break;
		}
	},
	get_current_screen_index() {
		let index = false;
		const previews = $('#RT #menu #screens .preview');
		for (const p in previews)
			if ($(previews[p]).hasClass('selected')) {
				index = parseInt(p, 10);
				break;
			}
		return index;
	}

});
