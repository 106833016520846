module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var ignorestale = ignorestale !== undefined ? ignorestale : false;

__p+='\n\n<h1>\n	'+
((__t=(APP.Tools.icon(type)))==null?'':__t)+
'\n		'+
((__t=(symbol))==null?'':__t)+
'\n</h1>\n\n<table>\n	<tr>\n		<th>Name</th>\n		<td>\n			<input type=\'text\' id=\'name\' class=\'field\' value="'+
((__t=(name))==null?'':__t)+
'" required placeholder="Name">\n		</td>\n	</tr>\n	<tr>\n		<th>Symbol\n			<button id=\'SymbolSuggestion\'>&rarr;</button>\n		</th>\n		<td>\n			<input type=\'text\' id=\'symbol\' class=\'field\' value=\''+
((__t=(symbol))==null?'':__t)+
'\' required placeholder="Symbol">\n		</td>\n	</tr>\n	<tr>\n		<th>Address</th>\n		<td>\n			<input type=\'text\' id=\'address\' class=\'field\' value=\''+
((__t=(address))==null?'':__t)+
'\' placeholder="Address">\n		</td>\n	</tr>\n	<tr>\n		<th>Min Data Rate</th>\n		<td>\n			';

			var rate = {};
			 <!-- rate[-1] = "None"; -->
			rate[30] = "30 seconds";
			rate[60] = "1 minute";
			rate[300] = "5 minutes";
			rate[600] = "10 minutes";
			rate[1800] = "30 minutes";
			rate[3600]="1 Hour";
			rate[21600]="6 Hours";
			rate[86400]="1 Day";
			rate[172800]="2 Days";
			rate[259200]="3 Days";
			rate[345600]="4 Days";
			rate[432000]="5 Days";
			rate[604800]="1 Week";
			rate[1209600]="2 Weeks";
			rate[1814400]="3 Weeks";
			rate[2628336]="1 Month";
			rate[7885008]="3 Months";
			rate[polling_default] = "Device Polling Rate ("+polling_default+" seconds)";
			var rate2 = {};
			rate2[""]=""
			for (var time in rate) { 
				console.log(time, polling_rate)
				rate2[-1] = "None";
				if (time >= polling_rate) {
					rate2[time] = rate[time];
				}
			}
			
__p+='\n				'+
((__t=(APP.UI.select_from_object('data_rate',rate2, data_rate, "field")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Data Retention</th>\n		<td>\n			';

			var retentions = {};
			retentions[0] = "none";
			retentions[3600]="1 Hour";
			retentions[21600]="6 Hours";
			retentions[86400]="1 Day";
			retentions[172800]="2 Days";
			retentions[259200]="3 Days";
			retentions[345600]="4 Days";
			retentions[432000]="5 Days";
			retentions[604800]="1 Week";
			retentions[1209600]="2 Weeks";
			retentions[1814400]="3 Weeks";
			retentions[2628336]="1 Month";
			retentions[7885008]="3 Months";
			
__p+='\n				'+
((__t=(APP.UI.select_from_object('retention',retentions, retention, "field")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>\n			Formula\n			<div style="float:right">\n				<span style="font-weight: normal; font-size:small">Ignore Stale</span>\n				<input type="checkbox" class="field" title="ignorestale"  id=\'ignorestale\' '+
((__t=(ignorestale?"checked":""))==null?'':__t)+
'>\n			</div>\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<textarea rows=1 class=\'field\' id=\'formula\'>'+
((__t=(formula))==null?'':__t)+
'</textarea>\n		</td>\n	</tr>\n</table>\n\n<datalist id=\'unitlist\'>\n	';
 for  (var u in APP.Unit) {
		if (u === "humanize" || u === "dehumanize" || u === "GetUnit")
			continue;
	
__p+='\n	<option value=\''+
((__t=(APP.Unit.humanize(u)))==null?'':__t)+
'\'>\n	';
 } 
__p+='\n</datalist>\n\n<fieldset>\n	<legend>\n		'+
((__t=(APP.UI.select_from_object('type',{
			number:"Number",boolean:"Boolean",string:"String",date:"Date",code:"Code",totalizer:"Totalizer","rate":"Rate"
		}, type, "field")))==null?'':__t)+
'\n\n			<!-- </legend> -->\n			<!-- </legend is ended in type_form, so the "cast" select is part of the legend -->\n\n			'+
((__t=(type_form))==null?'':__t)+
'\n\n\n</fieldset>\n<table>\n	<tr>\n		<th>\n			Description:\n		</th>\n	</tr>\n	<tr>\n		<td>\n			<textarea id=\'description\' class=\'field full\' placeholder="This text is displayed on the tag\'s tooltip">'+
((__t=(description))==null?'':__t)+
'</textarea>\n		</td>\n</table>\n';
}
return __p;
};
