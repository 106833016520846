module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr>\n	<th>Events</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.events.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Pollers</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.pollers.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Screens</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.screens.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Widgets</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.widgets.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Dev Lib</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.device_library.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Devices</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.devices.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Tag Lib</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.tag_library.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Tags</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.tags.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Users</th>\n	<td>\n		'+
((__t=(APP.Format.number(APP.models.users.length)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<!-- the "Exit" button can hide the bottom row -->\n	<th>&nbsp;</th>\n	<td>&nbsp;</td>\n</tr>\n';
}
return __p;
};
