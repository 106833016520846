module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'menu\'>\n	<div id=\'commands\'>\n		<div title=\'ctrl-enter to edit screen\' class=\'command '+
((__t=(APP.USER.Can("editor")?"":"disabled"))==null?'':__t)+
'\' id=\'edit\'>\n			'+
((__t=(APP.Tools.icon("edit","Edit this screen")))==null?'':__t)+
' <span class=\'label\'>editor</span>\n		</div>\n		<div class=\'command '+
((__t=(APP.USER.Can("settings")?"":"disabled"))==null?'':__t)+
'\' id=\'settings\'>\n			'+
((__t=(APP.Tools.icon("devices","Configure devices, users...")))==null?'':__t)+
' <span class=\'label\'>settings</span>\n		</div>\n		<div class=\'command half\' id=\'alarms\' title=\'Alarm log\'>\n			'+
((__t=(APP.Tools.icon("alarm")))==null?'':__t)+
'\n		</div>\n		<div class=\'command half\' id=\'events\' title=\'Event log\'>\n			'+
((__t=(APP.Tools.icon("events")))==null?'':__t)+
'\n		</div>\n		<div id=\'fullscreen\' class=\'\' title=\'Fullscreen\'>\n				'+
((__t=(APP.Tools.icon('arrows-alt')))==null?'':__t)+
'\n			</div>		\n	</div>\n	<div id=\'main_row\'>\n		<div  id=\'user\'>\n			'+
((__t=(APP.USER.getIcon()))==null?'':__t)+
'\n		</div>\n		<div id=\'screens\'></div>\n	</div>\n\n	<div id=\'status_row\'>\n		<div id=\'qr\' Xclass=\'statcommand\'>\n			<div class=\'text\'>Scan with phone to link to this screen</div>\n			<div id=\'qrimg\'>'+
((__t=(qr))==null?'':__t)+
'</div>\n			<div class=\'text\'>Don\'t forget to "<a href=\'https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/\'>Add to Homescreen</a>"</div>\n		</div>\n		<div id=\'pollers\'></div>\n		<div id=\'devices\'></div>\n	</div>\n\n\n</div>\n<div id=\'BURGER\'> '+
((__t=(APP.Tools.icon("bars")))==null?'':__t)+
'</div>\n';
}
return __p;
};
