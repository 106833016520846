module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var off_text = off_text || "off";
var on_text = on_text || "on";
var off_time= off_time || 0;
var on_time= on_time || 0;
var cycles = cycles || 0;

__p+='\n<div id=\'value\'>\n	<div id=\'off\' class=\''+
((__t=(offclass))==null?'':__t)+
'  '+
((__t=(alarm_state))==null?'':__t)+
'\'>\n		'+
((__t=(off_text))==null?'':__t)+
'\n	</div>\n	<div id=\'on\' class=\''+
((__t=(onclass))==null?'':__t)+
' '+
((__t=(alarm_state))==null?'':__t)+
'\'>\n		'+
((__t=(on_text))==null?'':__t)+
'\n	</div>\n</div>\n<table>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(updated)))==null?'':__t)+
' ago\n		</td>\n		<th>Changed</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(changed)))==null?'':__t)+
' ago\n		</td>\n	</tr>\n	<tr>\n		<td colspan=4>\n			<hr>\n		</td>\n	</tr>\n	<tr>\n		<th>'+
((__t=(APP.Format.capitalize(runtime_reset.replace('never',''))))==null?'':__t)+
' Off Time\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The total amount of time this tag was not "on".</span>\n\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(off_time*3600)))==null?'':__t)+
'\n		</td>\n		<th>'+
((__t=(APP.Format.capitalize(runtime_reset.replace('never',''))))==null?'':__t)+
' On Time\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The total amount of time this tag was "on".</span>\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(on_time*3600)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>'+
((__t=(APP.Format.capitalize(runtime_reset.replace('never',''))))==null?'':__t)+
' Cycles<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The number of times this tag changed from off to on.</span>\n		</th>\n		<td>\n			'+
((__t=(cycles))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
