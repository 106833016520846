module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n<tr>\n	<th>\n		Size:\n	</th>\n	<td>\n		'+
((__t=(APP.UI.slider("size", size, 5, 30 ,5)))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>\n		Material\n	</th>\n	<td>\n		'+
((__t=(APP.UI.select_from_object("material", ['iron','pvc'], material)))==null?'':__t)+
'\n\n	</td>\n</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Pump Speed</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
