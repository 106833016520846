module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Remote Telegauge</h2>\n<table>\n	<tr>\n		<th>DB URL</th>\n		<td><input class=\'field\' type=\'text\' id=\'connection.url\' value=\''+
((__t=(connection.url))==null?'':__t)+
'\'></td>\n	</tr>\n	<tr>\n		<th>Key Field</th>\n		<td><input class=\'field\' placeholder=\'tag_name\' type=\'text\' id=\'connection.key\' value=\''+
((__t=(connection.key))==null?'':__t)+
'\'></td>\n	</tr>\n	<tr>\n		<th>Value Field</th>\n		<td><input class=\'field\' placeholder=\'value\' type=\'text\' id=\'connection.value\' value=\''+
((__t=(connection.value))==null?'':__t)+
'\'></td>\n	</tr>\n</table>\n';
}
return __p;
};
