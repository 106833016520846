DEF.tag_library.DT = {};
DEF.tag_library.DT.Details = DEF.TG.Details.extend({
	template: require('./templates/tag_library_details.html'),
	templateContext(m) {
		const rs = this.model.all_fields();
		rs.type_form = this.GetTypeForm();
		// rs.model = this.model;
		rs.polling_default = this.model.getDeviceLibrary().getUp('polling_rate');
		console.log(rs);
		return rs;
	},
	ui: {
		field           : '.field',
		livefield       : '.field.live',
		refresh         : '.field.refresh', // refresh = re-display props when this field changes (useful for showing/hiding additional options)
		crypt           : '.crypt',
		commands        : '#details_footer .link',
		json            : 'textarea.json',
		action          : '.action',
		extra           : '.extrafield',
		name            : '.field#name',
		symbolsuggestion: '#SymbolSuggestion',
	},
	events: {
		'keyup @ui.json'            : 'ValidateJSON',
		'change @ui.field'          : 'Save',
		'change @ui.crypt'          : 'SaveCrypt',
		'input @ui.field'           : 'onInputField',
		'input @ui.livefield'       : 'Save',
		'change @ui.refresh'        : 'render',
		'click @ui.commands'        : 'Command',
		'click @ui.action'          : 'DoAction',
		'change @ui.extra'          : 'DoExtraField',
		'input @ui.name'            : 'SuggestSymbol',
		'click @ui.symbolsuggestion': 'SetSymbol',
	},

	modelEvents: {
		change       : 'TestRender',
		'change:type': 'render',
		'change:cast': 'render',
	},
	onRender() {
		this.SuggestSymbol();
	},
	SuggestSymbol(e) {
		let name = this.ui.name.value;
		if (e)
			name = e.currentTarget.value;
		this.ui.symbolsuggestion.html(`${this.model.GenerateSymbol(name)} &rarr;`);
	},
	SetSymbol() {
		const symbol = this.ui.symbolsuggestion.html().slice(0, -2);
		this.model.set('symbol', symbol);
	},
	GetTypeForm() {
		const templates = {
			number   : require('./templates/tag_type_forms/number.html'),
			boolean  : require('./templates/tag_type_forms/boolean.html'),
			string   : require('./templates/tag_type_forms/string.html'),
			date     : require('./templates/tag_type_forms/date.html'),
			code     : require('./templates/tag_type_forms/code.html'),
			totalizer: require('./templates/tag_type_forms/totalizer.html'),
			rate	 : require('./templates/tag_type_forms/rate.html')
		};
		return templates[this.model.getUp('type')](this.model.all_fields());
	},
});


DEF.tag_library.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.model = this.model;
		return rs;
	},
	regions: {
		tags: '#tags',
	},
	ui: {
		exit   : '#exit',
		logout : '#logout',
		command: '.btn',
	},
	events: {
		'click @ui.command': 'Command',
	},
	onRender() {
		const tl_id = this.model.id;
		this.showChildView('tags', new DEF.database.DT.Table({
			collection     : APP.models.tags,
			collection_name: 'tags',
			childView      : DEF.database.DT.child,
			viewFilter(m) {
				return m.model.get('tl_id') === tl_id;
			},
		}));
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'edit':
			APP.Route(`#DT/tag_library/${this.model.id}`);
			break;
		case 'exit':
			APP.Route('#');
			break;
		}
	},
});
