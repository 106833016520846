module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var prefix = prefix || ''
var name = name || ''
var tag_name = tag_name || ''
var alarm_state = alarm_state || ''



__p+='\n<div title=\''+
((__t=(prefix))==null?'':__t)+
' '+
((__t=(name))==null?'':__t)+
'\' class=\'tag_name\'>\n'+
((__t=(APP.Tools.icon(alarm_state)))==null?'':__t)+
'\n  '+
((__t=(tag_name))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
