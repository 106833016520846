module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'panel\' id=\'header\'>\n	<div class=\'level click\' id=\'alarm\'>'+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
' '+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
'</div>\n	<div class=\'level click\' id=\'warning\'>'+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
' '+
((__t=(APP.Lang('Warning')))==null?'':__t)+
'</div>\n	<div class=\'level click\' id=\'normal\'>'+
((__t=(APP.Tools.icon('normal')))==null?'':__t)+
' '+
((__t=(APP.Lang('Normal')))==null?'':__t)+
'</div>\n	<div class=\'level click\' id=\'info\'>'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' '+
((__t=(APP.Lang('Info')))==null?'':__t)+
'</div>\n	<div class=\'level click\' id=\'ack\'>'+
((__t=(APP.Tools.icon('bell-slash')))==null?'':__t)+
' '+
((__t=(APP.Lang('Ack')))==null?'':__t)+
'</div>\n	<h1>'+
((__t=(SETTINGS.name))==null?'':__t)+
' Event Log</h1>\n	<!-- <div class=\'filters\' id=\'filterrow\'>\n		';
 for (prefix of prefixes) { 
__p+='\n		<div class=\'prefix\' id=\''+
((__t=(prefix))==null?'':__t)+
'\'>'+
((__t=(APP.Format.caps(prefix)))==null?'':__t)+
'</div>\n		';
 }  
__p+='\n	</div>\n-->\n</div>\n<div class=\'panel\' id=\'page_box\'>\n	<div id=\'event_log_box\'></div>\n</div>\n<div class=\'panel\' id=\'footer\'>\n	<div href="#" class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n	<div href="#alarms" class=\'btn pull-right\' id=\'alarms\'>'+
((__t=(APP.Tools.icon("alarm")))==null?'':__t)+
' Alarms</div>\n</div>\n<div id=\'RT\'>\n	<div id=\'TOOLTIP_BOX\'></div>\n</div>\n';
}
return __p;
};
