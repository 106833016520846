DEF.events.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	regions : {
		events     : '#event_log_box',
		tooltip_box: '#TOOLTIP_BOX'
	},
	templateContext() {
		return { prefixes: this.GetPrefixes() };
	},
	onRender() {
		if (!APP.Tools.store('alarm_page', 'alarm'))
			APP.Tools.store('alarm_page', 'alarm', 'on');
		if (!APP.Tools.store('alarm_page', 'warning'))
			APP.Tools.store('alarm_page', 'warning', 'on');
		if (!APP.Tools.store('alarm_page', 'normal'))
			APP.Tools.store('alarm_page', 'normal', 'on');
		if (!APP.Tools.store('alarm_page', 'info'))
			APP.Tools.store('alarm_page', 'info', 'off');
		if (!APP.Tools.store('alarm_page', 'ack'))
			APP.Tools.store('alarm_page', 'ack', 'off');

		const collection = APP.models.events_archive.length > APP.models.events.length ? APP.models.events_archive : APP.models.events;
		console.log('events collection', collection);
		this.showChildView('events', new DEF.layout.LOG_BOX.Main({
			collection,
			long: true
		}));
	},
	onDomRefresh() {
		$('.level#alarm').addClass(APP.Tools.store('alarm_page', 'alarm'));
		$('.level#warning').addClass(APP.Tools.store('alarm_page', 'warning'));
		$('.level#normal').addClass(APP.Tools.store('alarm_page', 'normal'));
		$('.level#info').addClass(APP.Tools.store('alarm_page', 'info'));
		$('.level#ack').addClass(APP.Tools.store('alarm_page', 'ack'));
	},
	initialize() {
		const time = Date.now();
		const duration = 1000 * 60 * 60 * 36;
		const where = {
			datetime: {
				$gt : time - duration,
				$lte: time + 0
			}
		};

		APP.models.events_archive._where(where).then(() => {
			this._load_requests--;
			console.log('after load.. events lengths', APP.models.events_archive.length, 'pending', this._load_requests);
			this.render();
		}).catch((e) => {
			console.log(e);
		});
	},
	// start  : 0,
	// perpage: 500,
	ui: {
		command: '.btn',
		prefix : '.prefix',
		level  : '.level',
		search : '#search'
	},
	events: {
		'click @ui.level'  : 'Level',
		'click @ui.command': 'Command',
		'click @ui.prefix' : 'Prefix',
		'input @ui.search' : 'Search'
	},
	GetPrefixes() {
		return [...new Set(APP.models.devices.map(m => m.getUp('prefix')))];
	},
	GetFilterHTML() {
		const prefixes = this.GetPrefixes();
		console.log(prefixes);
	},
	Prefix(e) {
		console.log(e.currentTarget.id);
	},
	Search() {

	},
	Level(e) {
		const level = e.currentTarget.id;
		const state = $(e.currentTarget).hasClass('on');
		console.log(level, state);
		APP.Tools.store('alarm_page', level, state ? 'off' : 'on');
		$(`.level#${level}`).removeClass('off on').addClass(state ? 'off' : 'on');
		this.render();
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'exit':
			APP.Route('#');
			break;
		case 'alarms':
			APP.Route('#alarms');
			break;
		}
	}
	// attachHtml(collectionView, childView, index) {
	// 	if (collectionView._isBuffering)
	// 		collectionView._bufferedChildren.splice(index, 0, childView);
	// 	else if (!collectionView._insertBefore(childView, index))
	// 		collectionView._insertAfter(childView);
	// },
	// addChildView(view, index) {
	// 	if (index >= this.start && index < this.start + this.perpage)
	// 		Backbone.Marionette.CollectionView.prototype.addChildView.apply(this, arguments);
	// },
});


DEF.layout.LOG_BOX = {};
DEF.layout.LOG_BOX.child = Backbone.Marionette.View.extend({
	template : require('./templates/lineitem.html'),
	tagName  : 'div',
	className: 'tr',
	templateContext() {
		const rs = { eventf: this.model.getEvent() };
		rs.long = this.options.long;
		if (this.model.get('name'))
			rs.name = this.model.get('name');
		rs.prefix = '';
		if (this.model.get('tag_id')) {
			const tag = APP.models.tags.get(this.model.get('tag_id'));
			if (tag) {
				rs.name = tag.getUp('name');
				rs.prefix = tag.getUp('prefix');
			}
		}
		return rs;
	},
	events: {
		click: 'ShowTooltip'
	},
	onRender() {
		this.$el.addClass(this.model.get('alarm_state'));
		const delta = APP._last_event_line - this.model.get('datetime');
		if (delta > 60000)
			this.$el.addClass('timebreak');
		if (delta > 3600000)
			this.$el.addClass('timebreak2');
		APP._last_event_line = this.model.get('datetime');
	},
	ShowTooltip(e) {
		const id = this.model.get('tag_id');
		console.log(id);
		if (id) {
			this.tooltip = new DEF.layout.TOOLTIP.MAIN({
				model: APP.models.tags.get(id),
				left : e.clientX,
				top  : e.clientY
			});
			APP.root.getRegion('main').currentView.showChildView('tooltip_box', this.tooltip);
			e.stopPropagation();
		}
	}
});
DEF.layout.LOG_BOX.Main = Backbone.Marionette.CollectionView.extend({
	id        : 'event_list',
	max_length: 200,
	tagName   : 'div',
	className : 'table',
	childView : DEF.layout.LOG_BOX.child,
	childViewOptions() {
		return { long: this.options.long };
	},
	viewFilter (m) {
		m = m.model;
		const ignore = [];
		const states = ['alarm', 'warning', 'normal', 'info'];
		const include_ack = APP.Tools.store('alarm_page', 'ack') === 'on';

		if (this.options.long) // page view
			for (const s in states) {
				if (APP.Tools.store('alarm_page', states[s]) !== 'on') {
					ignore.push(states[s]);
					ignore.push(`${states[s]}_ack`);
				}
				if (!include_ack)
					ignore.push(`${states[s]}_ack`);
			}


		return ignore.indexOf(m.get('alarm_state')) === -1;
	},
	viewComparator(m) {
		// console.log(-m.get('datetime') + m.get('event'));
		return -m.model.get('datetime');// + m.get('event');
	},
	// attachHtml (collectionView, childView, index) {
	// 	debugger
	// 	if (index < this.max_length) {
	// 		if (collectionView._isBuffering)
	// 			collectionView._bufferedChildren.splice(index, 0, childView);
	// 		else
	// 		if (!collectionView._insertBefore(childView, index))
	// 			collectionView._insertAfter(childView);
	// 	} else {
	// 		this.removeChildView(childView);
	// 	}
	// }
});
