module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Reports</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("reports")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New Report</button>\n<button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Import from Other Installation</button>\n\n	<p>A <i>Report</i> contains the template used to generate a report.</p>\n\n<p>Reports are generated on a preset schedule, and emailed to a list of recipients.</p>\n\n\n<!--\n<fieldset>\n	<legend>Presets</legend>\n	<input type=\'text\' id=\'tags\' placeholder="M1_TAG1,M1_TAG2,M1_TAG3,..." value=\''+
((__t=(APP.Tools.store("reports","taglist")))==null?'':__t)+
'\'>\n	<button id=\'daily\' class=\'preset btn\'>Daily</button>\n	<button id=\'monthly\' class=\'preset btn disabled\'>Monthly</button>\n\n</fieldset>\n-->\n';
}
return __p;
};
