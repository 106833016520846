module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Telegauge Remote Monitor</h1>\n\n<h2>\n	'+
((__t=(version()))==null?'':__t)+
'\n</h2>\n\n<table>\n	<tr>\n		<th>\n			'+
((__t=(APP.Tools.icon('angle-double-right')))==null?'':__t)+
' Installation Name\n		</th>\n		<td>\n			'+
((__t=(APP.UI.input("installation", installation)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			Defines the name of this installation of Telegauge, as it appears in a few places, for example, the login screen.\n		</td>\n	</tr>\n\n		<tr>\n			<th>\n				'+
((__t=(APP.Tools.icon('normal')))==null?'':__t)+
' "normal" term\n			</th>\n			<td>\n				'+
((__t=(APP.UI.input("normal", normal)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td colspan=2 class=\'description\'>\n				What term to use for yellow "normal" level events.\n			</td>\n		</tr>		<tr>\n			<th>\n				'+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
' "warning" term\n			</th>\n			<td>\n				'+
((__t=(APP.UI.input("warning", warning)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td colspan=2 class=\'description\'>\n				What term to use for yellow "warning" level events, such as "pre-alarm".\n			</td>\n		</tr>\n		<tr>\n			<th>\n				'+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
' "alarm" term\n			</th>\n			<td>\n				'+
((__t=(APP.UI.input("alarm", alarm)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td colspan=2 class=\'description\'>\n				What term to use for red "alarm" level events, such as "shutdown".\n			</td>\n		</tr>\n\n	<tr>\n		<th>\n			'+
((__t=(APP.Tools.icon('refresh')))==null?'':__t)+
' Autorefresh\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("autorefresh", {99999999999:"never",3600:"hourly",21600:"Every Six Hours", 86400:"daily"},autorefresh)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			How often to automatically reload and refresh the screens. This is useful in situations where screens collect lots of data\n			and can become slow to respond.\n		</td>\n	</tr>\n\n	<tr>\n		<th>\n			'+
((__t=(APP.Tools.icon('stale')))==null?'':__t)+
' Stale Time\n		</th>\n		<td>\n			'+
((__t=(APP.UI.slider("stale_time",stale_time,0,50)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			<p>A tag becomes "stale" when no data has been collected in their polling time. However, slow devices often make Stale happen\n			too frequently. Increase this slider to lengthen the stale time threshold, if there are too many false stale tags.</p>\n			<p>Higher is less stale, and more tolerant of missing data.</p>\n		</td>\n	</tr>\n\n	<tr>\n		<th>\n			'+
((__t=(APP.Tools.icon('bars')))==null?'':__t)+
' Extra Margin Required Space\n		</th>\n		<td>\n			'+
((__t=(APP.UI.slider("hide_extras_margin",hide_extras_margin,0,500)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			<p>The extra space to the left (or bottom) of the screen can be used to show an event log, or an alarm box. This slider defines\n			how much empty space is required before the events or alarms are displayed.</p>\n			<p>Higher is more emtpy space, and less space to show the logs.</p>\n		</td>\n	</tr>\n\n	<tr>\n		<th>\n			'+
((__t=(APP.Tools.icon('circle')))==null?'':__t)+
' Pulse Time\n		</th>\n		<td>\n			'+
((__t=(APP.UI.slider("pulse_time",pulse_time,1,120)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			<p>If using One-Line diagrams with electron pulses, this adjusts how many pulses are used. Screens with many pulse producers\n			may slow down if there are too many pulses.</p>\n			<p>Lower is more pulses, but also more CPU.  Slide all the way to the right to disabale pulses entirely.</p>\n		</td>\n	</tr>\n\n	<tr>\n		<td colspan=2>\n			<hr>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>\n			Custom Settings\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'description\'>\n			Special, miscellaneous settings\n		</td>\n	</tr>\n	';
 for (var c in custom) { 
__p+='\n		<tr>\n			<td>\n				'+
((__t=(custom[c]))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.input(custom[c],eval(custom[c]))))==null?'':__t)+
'\n			</td>\n		</tr>\n		';
 } 
__p+='\n			<tr>\n				<td>\n					'+
((__t=(APP.UI.input("new_key","","text","new key","","")))==null?'':__t)+
'\n				</td>\n				<td>\n					'+
((__t=(APP.UI.input("new_value", "", "text", "new value")))==null?'':__t)+
'\n				</td>\n			</tr>\n</table>\n';
}
return __p;
};
