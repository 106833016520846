module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n    <tr>\n        <th>Tag</th>\n        <td>\n            '+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n        </td>\n    </tr>\n    <tr>\n  		<th>Label</th>\n  		<td>\n  			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name"],label)))==null?'':__t)+
'\n  		</td>\n  	</tr>\n    <tr>\n        <th>Border</th>\n        <td>\n            <input type=\'checkbox\' '+
((__t=(border?"checked":""))==null?'':__t)+
' id=\'border\' class=\'field\'>\n        </td>\n    </tr>\n    <tr>\n        <th>Rotate Gauge</th>\n        <td>\n            <input type=\'checkbox\' '+
((__t=(rotate?"checked":""))==null?'':__t)+
' id=\'rotate\' class=\'field\'>\n        </td>\n    </tr>\n    <tr>\n        <th>Stats</th>\n        <td>\n            <input type=\'checkbox\' '+
((__t=(stats?"checked":""))==null?'':__t)+
' id=\'stats\' class=\'field\'>\n        </td>\n    </tr>\n</table>\n<!--\n<a href="https://canvas-gauges.com/documentation/examples/">Custom Attributes\n    '+
((__t=(APP.Tools.icon("external-link")))==null?'':__t)+
'</a>\n<textarea id=\'custom\' class=\'field json\'>'+
((__t=(JSON.stringify(custom, null, "\t")))==null?'':__t)+
'</textarea> -->\n';
}
return __p;
};
