module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tags</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Size</th>\n		<td>\n			'+
((__t=(APP.UI.slider("size", size, 10,80,5)))==null?'':__t)+
'\n		</td>\n	</tr>\n\n</table>\n';
}
return __p;
};
