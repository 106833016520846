module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var alarm_ack_time = alarm_ack_time || false
var alarm_state = alarm_state || ''
var tag_name = tag_name || ''
var valuef = valuef || 0
var alarm_time = alarm_time || 0
var prefix = prefix || ''
var alarms = alarms || 0
var warnings = warnings || 0

__p+='\n<td class=\'cmd\'>\n  ';
 if (alarm_ack_time) { 
__p+='\n    '+
((__t=(APP.models.users.get(alarm_ack_id).getName()))==null?'':__t)+
' @\n      '+
((__t=(APP.Format.livetime(alarm_ack_time)))==null?'':__t)+
'\n        ';
 } else { 
__p+='\n          <button id=\'ack\' class=\'command\'>\n            '+
((__t=(APP.Tools.icon('ack')))==null?'':__t)+
' Ack</button>\n          <div id=\'tooltip\'></div>\n          ';
 } 
__p+='\n</td>\n<td class=\'tag_name\'>\n  '+
((__t=(APP.Tools.icon(alarm_state)))==null?'':__t)+
'\n    '+
((__t=(tag_name))==null?'':__t)+
'\n</td>\n<td class=\'value\'>\n  '+
((__t=(valuef))==null?'':__t)+
'\n</td>\n<td>\n  '+
((__t=(APP.Format.simpletime(alarm_time)))==null?'':__t)+
'\n</td>\n<td>\n  '+
((__t=(name))==null?'':__t)+
'\n</td>\n<td>\n  '+
((__t=(prefix))==null?'':__t)+
'\n</td>\n<td>\n  '+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
':\n    '+
((__t=(APP.Format.number(alarms)))==null?'':__t)+
'\n</td>\n<td>\n  '+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
':\n    '+
((__t=(APP.Format.number(warnings)))==null?'':__t)+
'\n</td>\n';
}
return __p;
};
