
DEF.device_library = {};


DEF.device_library.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.device_library.Model,
		url       : `${SETTINGS.dburl}/device_library`,
		comparator: 'device'
	};
	return APP.InitializeModels('device_library', options, callback, constraints);
};

DEF.device_library.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'device_library',
	defaults       : {
		device          : 'New Device',
		protocol        : 'none',
		connection      : {},
		polling_rate    : 60,
		data_rate       : 300,
		address_offset  : 0,
		image_url       : 'gears',
		runtime_reset   : 'never', // how often to reset on/off time
		alarm_arm       : false,
		primary_symbol  : false, // the tag used to show the 1 most important piece of data for a device
		running_symbol  : false, // the device is "on"
		fault_symbol    : false, // the device has a major fault.  I may use this to take a snapshot or something...
		tag_library     : [], // a list of pre-defined tags
		log_onoff       : false, // log the on/off event
		notes           : '',
		manual          : '',
		device_screen_id: false,
		manufacturer: '',
		partnumber: '',
		model: '',
		homepage_url: '',
		tag_address_url:''
	},
	db_columns    : ['protocol', 'polling_rate', 'image_url'],
	db_spreadsheet: ['device', 'protocol', 'polling_rate', 'data_rate', 'primary_symbol', 'running_symbol', 'image_url', 'manual'],
	db_filters    : ['protocol'],
	db_tools      : {
		'Deploy Tags': 'DeployTags'
	},
	getName() {
		return this.get('device');
	},
	getIcon() {
		let rs = '';
		const icon = this.get('image_url') || 'gears';
		if (icon.indexOf('http') === 0)
			rs = `<img class='icon' src='${icon}'>`;
		else
			rs = APP.Tools.icon(icon);
		return rs;
	},
	PopulateDefaultTagLibrary(defaults) {
		const dl_id = this.id;
		if (!defaults)
			defaults = this.get('tag_library');
		else
			this.set('tag_library', defaults);

		for (const d in defaults) {
			const def = defaults[d];
			const tag = APP.models.tag_library.findWhere({
				dl_id,
				symbol: def.symbol
			});
			if (!tag) {
				def.dl_id = dl_id;
				APP.models.tag_library.create(def);
				console.log('Created tag_library', def);
			}
		}
	},
	GeneratePrefix() {
		let prefix = 'X';
		const name = this.get('device');
		const uppercase = (name.match(/[A-Z]/g) || []).length;
		const lowercase = (name.match(/[a-z]/g) || []).length;
		console.log(uppercase, lowercase);
		if (uppercase < 5 && uppercase > 0)
			prefix = name.match(/[A-Z]/g).join('');
		else if (uppercase + lowercase < 5)
			prefix = name.match(/[A-Za-z]/g).join('').toUpperCase();

		prefix += APP.models.devices.where({ dl_id: this.id }).length + 1;

		return prefix;
	}
});
