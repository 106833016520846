DEF.users.DT = {}
DEF.users.DT.Main = Backbone.Marionette.CollectionView.extend({
	template: require('./templates/main.html'),
	className: 'user',
	id: 'USERS',
	childViewTriggers: {
		'select:item': 'select:item', // bubble the child's "select:item" to this view's parents
	},
	childViewEvents: {
		'select:item': 'itemSelected',
	},
	// childViewEventPrefix: 'childview',
	itemSelected(user) {
		console.log('itemselec', user.model.getName())
		APP.Route(`#DT/users/${user.model.id}`)
	},
})
DEF.users.DT.child = Backbone.Marionette.View.extend({
	className: 'user',
	template: require('./templates/user.html'),
	modelEvents: {
		change: 'render',
	},
	triggers: {
		click: 'select:item',
	},
	attributes() {
		return { id: this.model.id }
	},
})

DEF.users.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html'),
})

DEF.users.DT.Details = DEF.TG.Details.extend({
	template: require('./templates/details.html'),
	onAttach() {
		if (!APP.USER.Can('settings')) $('.field').attr('disabled', 'disabled') // .addClass("readonly");
		if (!APP.USER.Can('perms')) $('.perm').attr('disabled', 'disabled') // .addClass("readonly");
	},
	DoAction(e) {
		switch (e.currentTarget.id) {
			case 'testnotify':
				this.$el
					.find('#testnotify')
					.html(`${APP.Tools.icon('working')} Send Test Notification`)
				APP.Tools.Notify(
					'test',
					`Test Message from ${APP.USER.getName()}`,
					(err, resp, body) => {
						// console.log(err, resp, body);
						this.$el.find('#message').html('Test message sent...')
						this.$el
							.find('#testnotify')
							.html(`${APP.Tools.icon('phone')} Send Test Notification`)
					},
					this.model
				)
				break
			case 'logout':
				this.model.set('cmd', 'logout')
				break
			case 'sendlink':
				this.SendLoginLink()
				break
		}
	},
	SendLoginLink() {
		this.$el
			.find('#sendlink')
			.html(`${APP.Tools.icon('working')} Send Login Info`)
		const templ = require('./templates/link_email.html')
		const options = {
			uid: this.model.id,
			name: SETTINGS.name,
			email: this.model.get('email'),
			homepage: SETTINGS.posturl,
			logo: SETTINGS.logo_url
				? `${SETTINGS.posturl}/${SETTINGS.logo_url}`
				: 'http://www.telegauge.com/static/img/logo%20with%20text.744dfe5.png',
		}
		const email = templ(options)
		const to = this.model.get('email')
		const subj = `${SETTINGS.name} Login Link`
		APP.Tools.SendMail(to, subj, email, (err, resp, body) => {
			this.$el
				.find('#sendlink')
				.html(`${APP.Tools.icon('envelope')} Send Login Info`)
			this.$el.find('#message').html(body)
			console.log('ERR', err)
			console.log('RESP', resp)
			console.log('ODY', body)
		})
	},
})

DEF.users.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	regions: {
		notifications: '#notifications',
	},
	templateContext() {
		const rs = this.model.all_fields()
		rs.model = this.model
		return rs
	},
	ui: {
		exit: '#exit',
		logout: '#logout',
		command: '.btn',
		field: '.field',
	},
	events: {
		'click @ui.command': 'Command',
		'change @ui.field': 'SaveField',
	},
	onRender() {
		const uid = this.model.id
		this.showChildView(
			'notifications',
			new DEF.database.DT.Table({
				collection: APP.models.tags,
				collection_name: 'tags',
				childView: DEF.database.DT.child,
				viewFilter(m) {
					m = m.model
					return JSON.stringify(m.getUp('notifications')).indexOf(uid) !== -1
				},
			})
		)
	},
	SaveField(e) {
		const id = e.currentTarget.id
		const val = e.currentTarget.value
		this.model.set(id, val)
		console.log(id, val)
	},
	Command(e) {
		switch (e.currentTarget.id) {
			case 'edit':
				APP.Route(`#DT/users/${this.model.id}`)
				break
			case 'exit':
				APP.Route('#')
				break
			case 'logout':
				APP.Tools.store('auth', 'uid', false)
				window.location.reload()
				break
		}
	},
})
