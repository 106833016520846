const { mode } = require('crypto-js');
const { SettingsContext } = require('twilio/lib/rest/voice/v1/dialingPermissions/settings');

DEF.device_library.DT = {};
DEF.device_library.DT.Main = Backbone.Marionette.CollectionView.extend({
	className : 'device_library',
	id        : 'DEVICE_LIBRARY',
	comparator: 'device'
});
DEF.device_library.settings = {
	modbustcp: require('../../pollers/modbustcp/views/device_library.js'),
	modbus   : require('../../pollers/modbus/views/device_library.js')
};
DEF.device_library.DT.child = Backbone.Marionette.View.extend({
	className: 'device_library',
	template : require('./templates/device_library.html'),
	regions  : {
		header       : '#header',
		devices      : '#devices',
		small_devices: '#small_devices'
	},
	ui: {
		header       : '#header',
		add          : '#device_library_menu #add',
		edit         : '#device_library_menu #edit',
		expand       : 'span#expand',
		device_box   : '#device_box',
		small_devices: '#small_devices',
		menuitems    : '#device_library_menu',
		filter       : '#typefilter .type',
		searchtoggle : '#search.filter',
		searchbox    : '#searchbox'
	},
	events: {
		'click @ui.add'         : 'CreateDevice',
		'click @ui.edit'        : 'Edit',
		'click @ui.expand'      : 'Expand',
		'change @ui.field'      : 'Save',
		'input @ui.searchbox'   : 'Search',
		'click @ui.searchtoggle': 'ToggleSearch',
		'click @ui.filter'      : 'Filter'
	},
	modelEvents: {
		'change:device': 'render',
		add            : 'render'
	},
	onRender () {
		this.RefreshView = _.debounce(() => {
			this.deviceview.render();
		}, 700);
		const options = {
			collection: APP.models.devices,
			childView : DEF.devices.DT.child,
			comparator: 'prefix',
			viewFilter    : function f(m) {
				return m.model.get('dl_id') === this.model.id;
			}.bind(this)
		};
		this.deviceview = new DEF.devices.DT.Main(options);
		if (APP.Tools.store('expand', this.model.id) !== 'hide')
			this.showChildView('devices', this.deviceview);
		options.childView = DEF.devices.DT.small_device;
		this.showChildView('small_devices', new Backbone.Marionette.CollectionView(options));

		$(this.el).attr('id', this.model.id);
		if (APP.Tools.store('expand', this.model.id) === 'hide') {
			this.$el.removeClass('expanded')
			this.ui.device_box.hide();
			this.ui.menuitems.hide();
			this.ui.expand.html(APP.Tools.icon('collapse'));
		} else {
			this.$el.addClass('expanded')
			this.ui.small_devices.hide();
		//	this.ui.filter.hide();
		}
	},
	Edit() {
		APP.Route(`#DT/device_library/${this.model.id}`);
	},
	Expand(event) {
		const visible = $(this.ui.device_box).is(':visible');
		if (!visible && !this.getChildView('devices'))
			this.showChildView('devices', this.deviceview);
		if (visible) {
			this.$el.removeClass('expanded')
		} else this.$el.addClass('expanded')

		APP.Tools.store('expand', this.model.id, visible ? 'hide' : 'show');
		this.ui.device_box.slideToggle(200);
		this.ui.small_devices.slideToggle(200);
		this.ui.menuitems.slideToggle(200);
		this.ui.expand.html(APP.Tools.icon(visible ? 'collapse' : 'expand'));
		event.stopPropagation();
	},
	Filter(e) {
		const filter = APP.Tools.store('filter', this.model.get('_id')) || ['search'];
		if (filter.indexOf(e.currentTarget.id) >= 0) {
			$(e.currentTarget).removeClass('off').addClass('on');
			filter.splice(filter.indexOf(e.currentTarget.id), 1);
		} else {
			$(e.currentTarget).removeClass('on').addClass('off');
			filter.push(e.currentTarget.id);
		}

		APP.Tools.store('filter', this.model.get('_id'), filter);
		console.log('filter', e.currentTarget.id, filter);
		this.deviceview.render();
	},
	ToggleSearch(e) {
		const filter = APP.Tools.store('filter', this.model.get('_id')) || ['search'];
		console.log('toggle', filter.indexOf('search'), filter);
		if (filter.indexOf('search') === -1) {
			this.ui.searchbox.hide(100);
			filter.push('search');
		} else {
			this.ui.searchbox.show(200);
			filter.splice(filter.indexOf('search'), 1);
		}
		APP.Tools.store('filter', this.model.get('_id'), filter);
	},
	Search(e) {
		console.log('search', e.currentTarget.value);
		APP.Tools.store('filtersearch', this.model.get('_id'), e.currentTarget.value);
		this.RefreshView(); // a throttled refresh view.
	},

	CreateDevice () {
		var tagcount = APP.models.tag_library.filter(t => t.get('dl_id') == this.model.get('_id')).length;
		if (tagcount < 20 || confirm(`This will create a new '${this.model.getName()}' device, with ${tagcount} tags.`)) {
			
			const dl_id = this.model.id;

			const prefix = this.model.GeneratePrefix();
			const json = { dl_id, prefix };

			const model = APP.models.devices.create(json);
			this.listenToOnce(APP.models.devices, 'add', APP.Tools.DeployTags);

			const int = setInterval(() => { // watch for the new tag to show up, and route to it.
				const device = APP.models.devices.find(json);
				if (device)
					if (device && device.get('_id')) {
						clearInterval(int);
						APP.Route(`#DT/devices/${device.get('_id')}`);
					}
			}, 50);
		}


		// model.once('sync', () => {
		// 	console.log('X');
		// 	debugger;
		// });
		// model.once('add', () => {
		// 	console.log('Y');
		// 	debugger;
		// });
	}

});

DEF.device_library.DT.Details = DEF.TG.Details.extend({
	className: '#DEVICE_LIBRARY',
	template : require('./templates/device_library_details.html'),
	regions  : {
		options: '#options'
	},
	onRender() {
		const protocol = this.model.getUp('protocol');
		if (DEF.device_library.settings[protocol]) {
			const settings = new DEF.device_library.settings[protocol]({
				model: this.model
			});
			this.showChildView('options', settings);
		}
	},
	DoAction(e) {
		switch (e.currentTarget.id) {
			case 'devfile':
				console.log(this.model);
				var filename = `${this.model.get('manufacturer')}_${this.model.get('partnumber')}.json`
				this.DownloadJSON(filename, this.GenerateJSON(this.model))
			break;
		}
	},
	Delete() {
		const id = this.model.id;
		if (confirm('Are you sure you want to delete this library item?'))
			if (confirm('Really?  This will destroy all devices and tags, too!')) {
				const devices = APP.models.devices.where({ dl_id: id });
				for (const d in devices) {
					const device = devices[d];
					console.log('  ', device.getName());
					const tags = APP.models.tags.where({ d_id: device.id });
					for (const t in tags) {
						const tag = tags[t];
						console.log('    ', tags[t].getName());
						tag.destroy();
					}
					device.destroy();
				}

				const tag_libraries = APP.models.tag_library.where({ dl_id: id });
				for (const tl in tag_libraries) {
					const tag_library = tag_libraries[tl];
					console.log('  ', tag_library.getName());
					tag_library.destroy();
				}

				this.model.destroy();
				APP.Tools.DeployTags();
				APP.Route(`#DT/${this.model.collection_name}`); // refresh doesn't exist.  and neither does the model, so route to the collection
			}
	},
	/**
	 * Boil down an entire device model into JSON.  I could spend time making this
	 * function all clever and short with destructuring, and stuff, but i like that it
	 * resembles the final form of the JSON, and provides readability
	 * @param {object} model the device model
	 * @returns a JSON object
	 */
	GenerateJSON (model) {
		var notes = {
			created: new Date().toLocaleString(),
			exported_by: APP.USER.getName(),
			exported_by_email: APP.USER.get('email'),
			installation: SETTINGS.name,
			installation_url: SETTINGS.posturl
		}
		var meta = { // some stuff to help in selecting the device in some UI somewhere
			category: "Miscellaneous", // power, thermal, automation, building...
			description: "",
			image_url: "",
		}
		var vendor = { // helpful references to who makes this thing
			name: model.get('manufacturer'),
			model: model.get('model'),
			part_number: model.get('part_number'),
			homepage_url: model.get('homepage_url'),
			manual_url: model.get('tag_address_url')
		}

		var device = {
			device: model.getName(),
			protocol: model.get('protocol'),
			primary_symbol: model.get('primary_symbol'),
			running_symbol: model.get('running_symbol'),
			fault_symbol: model.get('fault_symbol'),
			image_url: model.get('image_url'),
		};
		switch (model.get('protocol')) {
			case 'modbus':
				device.modbus_blocks = model.get('blocks')
				vendor.modbus_map_url = ""
				break;
		}


		var taglist = APP.models.tag_library.where({ dl_id: model.get('_id') });
		var tags = []
		// Manually populate the tags list
		for (var t of taglist) {
			var a = t.attributes
			console.log("ds",a.decimal_shift);
			var tag = {
				symbol: a.symbol,
				name: t.attributes.name,
				description: a.description,
				type: a.type,
				cast: a.cast,
				address: a.address,
				formula: a.formula,
				preprocess: a.preprocess
			}
			switch (a.type) {
				case 'totalizer':
					tag.reset_totalizer = a.reset_totalizer;
					tag.value_rate = a.value_rate;
					break;
				case 'rate':
					tag.value_rate = a.value_rate;
					// break;  <-- no break.  we need all 'number' stuff too
				case 'number':
					tag.range_high = a.range_high;
					tag.range_low = a.range_low;
					tag.warning_high = a.warning_high;
					tag.warning_low = a.warning_low;
					tag.alarm_high = a.alarm_high;
					tag.alarm_low = a.warning_low;
					tag.unit = a.unit;
					tag.decimal_shift = a.decimal_shift;
					break;
				case 'boolean':
					tag.on_text = a.on_text
					tag.off_text = a.off_text
					tag.alarm_on = a.alarm_on
					tag.alarm_off = a.alarm_off
			}
			tags.push(tag);
		}


		var json = {
			version: 1, // change this whenever there are big changes, or field renaming.  An import script could use this to identify the expected fields
			meta,
			notes,
			vendor,
			library: {
				device,
				tags
			}
		}
		return json
	},
	DownloadJSON (filename, json) {
		console.log(JSON.stringify(json))
		
		const blob = new Blob([JSON.stringify(json)], { type: "text/json" });
		const link = document.createElement("a");

		link.download = filename.replace(/[\s-]/g,'');
		link.href = window.URL.createObjectURL(blob);
		link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

		const evt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		});

		link.dispatchEvent(evt);
		link.remove()
	}

});

DEF.device_library.DT.empty_details = DEF.TG.EmptyDetails.extend({
	id      : 'DEVICE_LIBRARY',
	template: require('./templates/empty.html'),
	ui      : {
		chart  : '#chart',
		create : '#create',
		command: '.command',
		import : '#import'
	},
	getData() {
		const devices = APP.models.devices.models;
		const labels = [];
		const data = [];
		const color = [];
		for (const d in devices) {
			const device = devices[d];
			labels.push(device.getName());
			data.push(device.get('points'));
			color.push(`rgba(${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)},${parseInt(Math.random() * 255)},1)`);
		}
		const out = {
			labels,
			datasets: [
				{
					data,
					backgroundColor: color
				}]
		};
		return out;
	},
	DoCommand(e) {
		console.log(e.currentTarget.id);
		switch (e.currentTarget.id) {
		case 'import':
			APP.Route('#DT/device_library/import');
			break;
		}
	}


});

DEF.device_library.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.model = this.model;
		return rs;
	},
	regions: {
		devices    : '#devices',
		tag_library: '#tag_library'
	},
	ui: {
		command: '.btn'
	},
	modelEvents: {
		change: 'render'
	},
	events: {
		'click @ui.command': 'Command'
	},
	onRender() {
		const dl_id = this.model.id;
		this.showChildView('devices', new DEF.database.DT.Table({
			collection     : APP.models.devices,
			collection_name: 'devices',
			childView      : DEF.database.DT.child,
			viewFilter(m) {
				return m.model.get('dl_id') === dl_id;
			}
		}));

		this.showChildView('tag_library', new DEF.database.DT.Table({
			collection     : APP.models.tag_library,
			collection_name: 'tag_library',
			childView      : DEF.database.DT.child,
			viewFilter(m) {
				return m.model.get('dl_id') === dl_id;
			}
		}));
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'edit':
			APP.Route(`#DT/device_library/${this.model.id}`);
			break;
		case 'exit':
			APP.Route('#');
			break;
		}
	}
});
