module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr />\n<table>\n	<tr>\n		<th>API Key ID</th>\n		<td><input class=\'field\' placeholder=\'gZFhE...\' id=\'options.id\' value=\''+
((__t=(options.id))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>API Secret Key</th>\n		<td><input class=\'field\' placeholder=\'YGm5L...\' id=\'options.secret\' value=\''+
((__t=(options.secret))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n</table>';
}
return __p;
};
