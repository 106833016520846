module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=(valuef))==null?'':__t)+
' ('+
((__t=(value))==null?'':__t)+
')\n';
}
return __p;
};
