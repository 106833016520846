module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var blocks = blocks || false;

__p+='\n<h1 title=\'\'>\n	'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
'\n	'+
((__t=(prefix))==null?'':__t)+
'\n</h1>\n\n';
 if (!id) { 
__p+='\n<div id=\'new_record\'>\n	'+
((__t=(APP.Tools.icon("plus")))==null?'':__t)+
' Create New Device\n</div>\n';
 } 
__p+='\n\n<table>\n	<tr>\n		<th>Online</th>\n		<td>\n			'+
((__t=(online))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>State</th>\n		<td>\n			'+
((__t=(state.replace("* ",APP.Tools.icon('working')+"&nbsp;")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(state_updated)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Last Data</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(last_data)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Next Poll</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(next_poll)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Points</th>\n		<td>\n			'+
((__t=(APP.Format.number(points)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Run Time</th>\n		<td>\n			'+
((__t=(APP.Format.duration(run_hours*60*60)))==null?'':__t)+
'\n		</td>\n		</tr>\n		<tr>\n			<th>Last Period Run Time</th>\n			<td>\n				'+
((__t=(APP.Format.duration(last_run_hours*60*60)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n		<th colspan=2>Status</th>\n	</tr>\n	<tr>\n		<td colspan=2 class="device_status">\n			<div>\n				'+
((__t=(status))==null?'':__t)+
'\n			</div>\n		</td>\n	</tr>\n\n</table>\n\n<h2>Settings</h2>\n\n<table>\n	<tr>\n		<th>\n			Enabled\n		</th>\n		<td>\n			<input type=\'checkbox\' class=\'field\' id=\'enabled\' '+
((__t=(enabled? "checked": ""))==null?'':__t)+
'>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Prefix\n		</th>\n		<td>\n			<input id=\'prefix\' type=\'text \' maxlength=5 class=\'field\' value=\''+
((__t=(prefix))==null?'':__t)+
'\' placeholder=\'Prefix (D1)\'>\n		</td>\n	</tr>\n	';
 if (protocol !== "none") { 
__p+='\n	<tr>\n		<th>\n			Poller\n		</th>\n		<td>\n			';

			switch(protocol) {
				case 'global':
					print('global');
					break;
				case 'other':
					print(APP.UI.select_from_collection("poller_id",APP.models.pollers.where(),"_id","name",poller_id,"field"));
					break;
				default:
					print(APP.UI.select_from_collection("poller_id",APP.models.pollers.where({protocol:protocol}),"_id","name",poller_id,"field"))
			}
			
__p+='\n		</td>\n	</tr>\n	';
 }
__p+='\n	<tr>\n		<th>\n			Polling Rate (sec)\n		</th>\n		<td>\n			<input id=\'polling_rate\' type=\'number\' class=\'field\' value=\''+
((__t=(polling_rate))==null?'':__t)+
'\'\n				placeholder=\'polling rate (seconds)\'>\n		</td>\n	</tr>\n</table>\n<div id=\'options\'>\n	<h2>Options</h2>\n	<table>\n		<tr>\n			<td colspan=2>\n				<textarea id=\'connection\' class=\'field json\' />\n				</td>\n					</tr>\n				</table>\n			</div>\n				<h2>Notes</h2>\n				<table>\n					<tr>\n						<td colspan=2>\n							<textarea id=\'notes\' placeholder="device notes..." class=\'field full\'>'+
((__t=(notes))==null?'':__t)+
'</textarea>\n			</td>\n		</tr>\n	</table>\n	<h2>Commands</h2>\n	<button id="refresh" class="action btn">Refresh</button>\n</div>\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
' database\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
' delete\n	</div>\n\n	<div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
' duplicate\n	</div>\n</div>\n';
}
return __p;
};
