module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Device Library</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("device_library")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New Device Library</button>\n<button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Import from Other Installation</button>\n\n<button id=\'\' class=\'btn command\' disabled>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Load from DeviceFile JSON</button>\n\n	<p>The <i>Device Library</i> contains models of kinds of devices.</p>\n\n<p>Some installations have several of the same type of device, each one extends the definition of the Device Library.\n</p>\n\n<p>For example, a particular installation may have 4 meters, each monitoring a separate generator, and each manufactured by the same company. Each meter will have different connection information, but will all share the same tag definitions.</p>\n\n<p>For each Device Library, create multiple instances of each device. Each device will combine with Tag Library information to generate a list of tags.</p>\n\n\n\n\n</div>\n';
}
return __p;
};
