module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'panel\' id=\'header\'>\n	<h1>'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' '+
((__t=(name))==null?'':__t)+
' - '+
((__t=(current_machine))==null?'':__t)+
'</h1>\n</div>\n\n<div class=\'panel\' id=\'page_box\'>\n	<div id=\'notes\'>'+
((__t=(notes))==null?'':__t)+
'</div>\n	<div id=\'beadcrumbs\'>\n		<a class=\'crumb\' href=\'#\'>'+
((__t=(APP.Tools.icon('home')))==null?'':__t)+
'</a> > \n		<a class=\'crumb\' href=\'#pollers/'+
((__t=(model.id))==null?'':__t)+
'\'>'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' '+
((__t=(model.getName()))==null?'':__t)+
'</a>\n	</div>\n\n	<h2>Details</h2>\n	<div id=\'summary\'></div>\n\n	<h2>Devices</h2>\n	<div id=\'devices\'></div>\n\n	<h2>Events</h2>\n	<div id=\'event_log_box\'></div>\n</div>\n\n\n<div class=\'panel\' id=\'footer\'>\n	<div class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n	<div class=\'btn pull-right\' id=\'edit\'>'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' Edit</div>\n	<div id="restart" class="cmd btn pull-right">\n		'+
((__t=(APP.Tools.icon("refresh")))==null?'':__t)+
' Restart</div>\n	<div id="restart" class="cmd btn pull-right">\n		'+
((__t=(APP.Tools.icon("refresh")))==null?'':__t)+
' Refresh</div>\n</div>\n';
}
return __p;
};
