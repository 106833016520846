DEF.layout.DT.HelpDetails = Backbone.Marionette.View.extend({
	id: 'HELP',
	getTemplate() {
		const templ = require(`./templates/${this.options.topic}.html`);
		return templ;
	},
	onDomRefresh() {
		let html = this.$el.html();
		if (html.indexOf('(#') > 0) {
			html = html.replace(/\((#[\d.]+)\)/g, '<span class="ticket">$&</span>');
			this.$el.html(html);
		}
	},
});

DEF.layout.DT.Help = Backbone.Marionette.View.extend({
	id             : 'HELP',
	template       : require('./templates/main.html'),
	templateContext: {
		version: require('../../version.html'),
	},

});
