global.SETTINGS = require('../custom/settings.js')

window.APP = {} // DEFINE THE MAIN APP OBJECT
window.DEF = {} // HOLD THE DEFINITIONS. ALL THE MODULES, ETC...
window.WID = {} // hold all the widget DEFINITIONS

window.isNumber = require('is-number')

// JSON.stringifyAligned = require('json-align');
JSON.stringify = require('json-align')

require('@fortawesome/fontawesome-free/js/fontawesome')
require('@fortawesome/fontawesome-free/js/solid')
require('./style/style.scss')

// FOUNDATION APP
//

require('./layout.js')

require('./include/tg.model.js')
require('./include/tg.view.js')

require('./router.js')

require('./rt/rt.js')
require('./rt/alarms.view.js')


// require.ensure(["./dt/dt.js"], function() {
// 	require("./dt/dt.js");
// }, "DT");
// });
// require("./dt/dt.js");

require('./rt/splash.js')

DEF.APP = Marionette.Application.extend({
	Initialize() {
		APP.root = new DEF.RootLayout()
		const splash = new DEF.layout.Splash({})
		APP.root.showChildView('main', splash)

		global.APP.models = {}

		// if (module.hot) {
		// 	console.log('hot');
		// 	module.hot.accept('./index.js');
		// }

		this.LoadModels()
	},
	ValidateUser() {
		if (window.location.href.indexOf('#login') > 0) {
			this.Start()
		} else {
			if (APP.Tools.store('auth', 'uid') === 'false')
				APP.Tools.store('auth', 'uid', false) // #2.8.55
			let uid = APP.Tools.store('auth', 'uid') || APP.Tools.setting('autologin')
			if (!uid && APP.models.users.length === 1)
				uid = APP.models.users.at(0).get('_id')
			if (uid) {
				this.USER = APP.models.users.get(uid)
				if (this.USER) {
					this.USER.set({
						last_visit: Date.now(),
						pageloads: this.USER.get('pageloads') + 1,
						cmd: '',
					})
					_.defer(this.Start.bind(this)) // deferred so the splash screen finishes painting
					return
				}
			}
			require('./rt/login.js')
			const root = new DEF.layout.Login({})
			APP.root.showChildView('main', root)
		}
	},

	LoadModels() {
		const after = _.after(16, this.ValidateUser.bind(this))
		global.APP.models.users = DEF.users.Initialize(after)
		global.APP.models.settings = DEF.settings.Initialize(after) // TODO probably need to load before users!
		global.APP.models.device_library = DEF.device_library.Initialize(after)
		global.APP.models.tag_library = DEF.tag_library.Initialize(after)
		global.APP.models.devices = DEF.devices.Initialize(after)
		global.APP.models.tags = DEF.tags.Initialize(after)
		global.APP.models.screens = DEF.screens.Initialize(after)
		global.APP.models.widgets = DEF.widgets.Initialize(after)
		global.APP.models.pollers = DEF.pollers.Initialize(after)
		global.APP.models.network = DEF.network.Initialize(after)
		global.APP.models.events = DEF.events.Initialize(after, {
			// limit: 1,
		})
		global.APP.models.events_archive = DEF.events_archive.Initialize(after, {
			limit: 1,
		})
		global.APP.models.data = DEF.data.Initialize(after, {
			limit: 1,
		})
		global.APP.models.data_archive = DEF.data_archive.Initialize(after, {
			limit: 1,
		})
		global.APP.models.reports = DEF.reports.Initialize(after)
		global.APP.models.files = DEF.files.Initialize(after)
	},
	// Used by each collection to initialize their own collection.
	InitializeModels(collection, options, callback, constraints) {
		// console.log("Initialize>", collection, options.url);
		const timeout = SETTINGS.sync_timeout || 10000
		if (constraints) options.minimum = constraints
		let models
		let cache = localStorage.getItem(`model_${collection}`)
		cache = false
		if (cache) {
			console.log(collection, cache)
			models = JSON.parse(cache)
			APP.root
				.getRegion('main')
				.currentView.Progress(collection, 3, models.length)
			callback()
		} else {
			APP.root.getRegion('main').currentView.Progress(collection, 0)
		}

		DEF[collection].Collection = DEF.TG.Collection.extend(options)
		const data = new DEF[collection].Collection(models)

		// if (collection == 'data')
		// 	data._childAdded = function (m) { console.log('skip c', m); };
		// data._addModel = function (m) { console.log('skip m', m); };

		const start_time = Date.now();

		if (!cache) {
			// set the "failed to load" timer
			const timer = setTimeout(() => {
				console.error('failed to load (timeout)', timeout, DEF[collection].options.url);
				APP.root.getRegion('main').currentView.Progress(collection, 2)
				clearTimeout(this.reload);
				this.reload = setTimeout(() => { location.reload(); }, timeout*2);	
			}, timeout)

			data.once('sync', () => {
				APP.models[collection].is_synced = true
				APP.root.getRegion('main').currentView.Progress(collection, 1)
				console.log(collection + ": " + APP.models[collection].length + ", " + ((JSON.stringify(APP.models[collection]).length/1000)|0) + "kb, " + (Date.now() - start_time) + " ms");
				clearTimeout(timer)
				clearTimeout(this.reload);
				callback()
			})

			data.once('error', (a, b, c) => {
				console.log('ERROR', a, b, c)
			})
		}
		DEF[collection].options = options
		return data
	},
	Start() {
		console.log('START')
		console.timeStamp('RT START')
		// this.LiveDate();
		setInterval(this.LiveDate.bind(this, 'sec'), 1000)
		setInterval(this.LiveDate.bind(this, 'min'), 6000)
		setInterval(this.LiveDate.bind(this, 'hour'), 60000)
		setInterval(this.LiveDate.bind(this, 'day'), 60000)

		// this.CacheModels();
		// setInterval(this.CacheModels, 60000);

		// autorefresh on some interval because of some stuck data issues the install may have
		if (
			APP.models.settings.get('autorefresh') &&
			APP.models.settings.get('autorefresh').get('value') > 60
		)
			setInterval(
				window.location.reload.bind(window.location),
				APP.models.settings.get('autorefresh').get('value') * 1000
			)

		// refresh every 4am!
		if (window.location && window.location.reload) {
			const mid = new Date()
			const ts = mid.getTime()
			mid.setHours(24 + 4, 0, 0, 0)
			const time_til_midnight = mid - ts
			setInterval(
				window.location.reload.bind(window.location),
				time_til_midnight
			)
		}

		//		APP.Lang._init();

		document.addEventListener(
			'visibilitychange',
			() => {
				// console.log('visibility');
				if (document.hidden) {
					APP.trigger('windowhide')
					console.log('bye')
				} else {
					APP.trigger('windowshow')
					console.log('welcome back')
				}
			},
			false
		)

		this.start()
	},
	CacheModels() {
		const collections = [
			'users',
			'settings',
			'device_library',
			'tag_library',
			'devices',
			'tags',
			'screens',
			'widgets',
			'pollers',
			'network',
			'reports',
			'files',
		]
		for (const c in collections) {
			const json = JSON.stringify(APP.models[collections[c]].models, null, 0)
			localStorage.setItem(`model_${collections[c]}`, json)
		}
	},
	Log(event, alarm_state, tag, datetime, extra = {}) {
		// TODO backbone view
		console.log(event, alarm_state, tag, datetime)
		const data = {
			event,
			datetime: datetime || Date.now(),
			tag_id: tag ? tag.id : false,
			alarm_state,
		}
		_.extend(data, extra)
		APP.models.events.create(data)
		APP.models.events_archive.create(data)
	},

	/**
	 * Given a key, return something from Settings.
	 * @param {string} key name of the setting
	 */
	GetSetting(key, defaultt) {
		if (APP.models.settings.get(key))
			return APP.models.settings.get(key).get('value')
		return defaultt
	},
	Route(route) {
		if (APP.controller) {
			console.log('Route>', route)
			APP.controller.router.navigate(route, { trigger: true })
		}
	},
	Slack(msg) {
		return $.post(`/slack?message=${msg}`)
	},
	LiveDate (unit = '') {
		const sel = `.livedate.${unit}`
		$(sel).each((i, el) => {
			const $el = $(el)
			const time = APP.Format._livetime($el.data('raw'))
			unit = unit || time.split(' ')[1] || 'sec'
			$el.html(time)
			if (!$el.hasClass(unit)) $el.removeClass('ms sec min hour').addClass(unit)
		})
	},
	ShowTooltip(e) {
		const id =
			$(e.currentTarget).data('tag_id') ||
			$(e).data('tag_id') ||
			e.data('tag_id') ||
			e
		if (id) {
			this.tooltip = new DEF.layout.TOOLTIP.MAIN({
				model: APP.models.tags.get(id),
				left: e.clientX,
				top: e.clientY,
			})
			// const tooltip = this.tooltip;
			// _.defer(() => {
			APP.root
				.getRegion('main')
				.currentView.showChildView('tooltip_box', this.tooltip)
			//			});

			if (e.stopPropagation) e.stopPropagation()
		}
	},

	/**
	 * a convenience function for getting the current tag.
	 * @param {string} name tag_name or ID
	 */
	GetTag(tag_name) {
		if (tag_name[0] > 0) return APP.models.tags.get(tag_name)
		return APP.models.tags.findWhere({ tag_name })
	},

	/**
	 * Supply a list of widgets, and maybe a callback
	 * @param {[type]} widgets [description]
	 */
	EnsureWidgets(widgets, callback) {
		const loaders = {}
		let not_loaded = 0

		for (const w in widgets) {
			const widget =
				typeof widgets[w] === 'object' ? widgets[w].get('widget') : widgets[w]
			if (typeof WID[widget] !== 'function' && !loaders[widget]) {
				if (!this.widget_load_time) this.widget_load_time = Date.now()
				not_loaded++
				try {
					if (
						APP.models.device_library
							.pluck('protocol')
							.indexOf(widget.toLowerCase()) === -1
					)
						loaders[
							widget
						] = require(`bundle-loader?lazy&name=[name]!./widgets/${widget}/${widget}.js`)
					else
						loaders[
							widget
						] = require(`bundle-loader?lazy&name=[name]!./pollers/${widget.toLowerCase()}/widget/widget.js`)
				} catch (e) {
					console.error('Missing module', widget)
					APP.Route('#') // Abort to the homescreen
				}
			}
		}
		// There are unloaded widgets.  So, loop through the list, load them, and
		// after they have all been loaded, call SetScreen again.
		if (not_loaded > 0) {
			const delayed_screen = _.after(not_loaded, callback)
			const load_it = function loadit(widget, file) {
				console.log('..loaded', widget)
				WID[widget] = file
				delayed_screen()
			}
			for (const l in loaders) loaders[l](load_it.bind(this, l))

			return false
		}
		return true
	},
})

APP = new DEF.APP()

require('./include/tools.js')
require('./include/widgets.js')

APP.on('before:start', () => {})

APP.on('start', () => {
	// const bugsnag = require('bugsnag');
	// bugsnag.register('2c3b6bda7c2042edc6ac96bfeceecb4c');
	// APP.use(bugsnag.requestHandler);
	// APP.use(bugsnag.errorHandler);
	// bugsnag.notify('ErrorName', 'Test Error');

	APP.controller = new DEF.Controller()
	APP.controller.router = new DEF.Router({
		controller: APP.controller,
	})
	Backbone.history.start({})
})

APP.Initialize()
