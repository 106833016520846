module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(name))==null?'':__t)+
'</h2>\n\n<table>\n	<tr>\n		<th>Protocol</th>\n		<td>\n			'+
((__t=(protocol))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Running Time</th>\n		<td>\n			'+
((__t=(APP.Format.relativetime(poller_startstop)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Machine</th>\n		<td>\n			'+
((__t=(current_machine))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Running State</th>\n		<td>\n			'+
((__t=(running_state))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>State</th>\n		<td>\n			'+
((__t=(state.replace("* ",APP.Tools.icon('working')+"&nbsp;")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Status</th>\n		<td>\n			'+
((__t=(protocol))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n<div id=\'restart_poller\' class=\'command button\'>Refresh Poller</div>\n';
}
return __p;
};
