DEF.devices = {};

DEF.devices.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.devices.Model,
		url       : `${SETTINGS.dburl}/devices`,
		comparator: 'prefix'
	};

	return APP.InitializeModels('devices', options, callback, constraints);
};

DEF.devices.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: 'dl_id',
	parentModule     : 'device_library',
	collection_name  : 'devices',
	defaults         : {
		enabled          : true,
		dl_id            : false,
		online           : false,
		prefix           : 'X1',
		connection       : {},
		connection_string: '', // human readbable, machine generated
		points           : 0, // number of data points
		state            : 'UNTOUCHED',
		state_updated    : -1,
		polling_rate_mod : 1, // allows devices to speed up polling
		last_data        : '',
		run_hours        : 0,
		last_run_hours   : 0,
		next_poll        : false,
		poller_id        : false,
		polling_rate     : '',
		notes            : ''
	},
	db_columns    : ['dl_id', 'state', 'last_data', 'next_poll', 'poller_id'],
	db_spreadsheet: ['_id','poller_id', 'prefix', 'enabled'],
	db_filters    : ['protocol', 'dl_id', 'poller_id', 'state'],
	db_search     : ['prefix', 'protocol', 'device', 'address'],
	db_tools      : {
		'Deploy Tags': 'DeployTags'
	},
	ups: ['protocol'],
	initialize() {
		if (!this.get('poller_id') && APP.models.pollers) {
			if (this.getUp('protocol') === 'global')
				this.set({ poller_id: 'global', online: true });
			const pollers = APP.models.pollers.where({ protocol: this.getUp('protocol') });
			if (pollers.length === 1)
				this.set('poller_id', pollers[0].id);
		}
	},
	getName() {
		return `${this.getUp('device')}-${this.get('prefix')}`;
	},

	/**
	 * return HTML for an icon.  <img>...
	 * */
	getIcon() {
		return '';
	},
	getDesc() {
		return this.getUp('device');
	},
	getPoller() {
		return this.FindParent('pollers', this.get('poller_id'));
	},
	getTags() {
		return APP.models.tags.where({ d_id: this.get('_id') });
	},
	// run_hours, plus the time since last updated.
	//
	// For example, if running_symbol was a BOOL, it only gets upated when it changes
	// As a result, run_hours is slow to accumulate.
	getRunHours() {
		let hours = this.get('run_hours') || 0;
		if (this.getUp('log_onoff')) {
			const running_symbol = this.getUp('running_symbol');
			if (running_symbol) {
				const running = APP.GetTag(`${this.get('prefix')}_${running_symbol}`);
				if (running && running.getState())
					hours += (Date.now() - running.get('changed')) / (1000 * 3600);
			}
		}
		return hours;
	},

	/*
	 *  Returns if the device is on or off.
	 *
	 */
	getRunState() {
		const hours = this.get('run_hours') || 0;
		if (this.getUp('log_onoff')) {
			const running_symbol = this.getUp('running_symbol');
			if (running_symbol) {
				const running = APP.GetTag(`${this.get('prefix')}_${running_symbol}`);
				if (running)
					return running.getState();
			}
		}
		return false;
	},

	/**
	 * Get a specific tag from the device
	 * @param  {string|object} where if string: "symbol", or a query {address:"123"}
	 * @return {tag}       tag, or false
	 */
	getTag(where) {
		if (typeof where === 'object') { // gotta loop through the tags and getUp()
			const tags = this.getTags();
			for (const t in tags) {
				const tag = tags[t];
				let match = false;
				for (const w in where)
					if (tag.getUp(w) === where[w])
						match = true;

				if (match)
					return tag;
			}
			where.d_id = this.get('_id');
		} else { // where is a string, so it's a "prefix" so do the quick match.
			const prefix = this.getUp('prefix');
			where = { d_id: this.get('_id'), tag_name: `${prefix}_${where}` };
			return APP.models.tags.findWhere(where);
		}
		return false;
	},

	getDeviceLibrary() {
		return this.FindParent('device_library', this.get('dl_id'));
	},

	/**
	 * Returns ALL fields for this model, including parents.  Useful as a templateHelper
	 * @return {[type]} [description]
	 */
	all_fields() {
		const fields = $.extend(true, {}, this.getDeviceLibrary().attributes, this.getPoller().attributes, this.attributes);
		for (const f in fields) // hard to delete fields from attributes, so some of them are set to "" so getUp works
			if (fields[f] === '') // however, .extend doesn't honor that, so we have to go check for them
				fields[f] = this.getUp(f); // and .getUp them.

		return fields;
	}
});
