module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var _id = _id||"";

__p+='\n<td class=\'model_name nowrap\'>\n	<span class=\'model_link link\' data-link="#'+
((__t=(collection))==null?'':__t)+
'/'+
((__t=(_id))==null?'':__t)+
'">\n		'+
((__t=(icon))==null?'':__t)+
'\n	</span>\n	'+
((__t=(model_name))==null?'':__t)+
'\n</td>\n\n';

for (var c = 0; c<cols.length; c++) {
	var col = cols[c];
	var val = attr[col];
	if (Number(val)==val)
		val=Number(val);
	switch (typeof val) {
		case 'object':
		var out="";
			for (var v in val)
				if (val[v]===true)
			   out+=v+" "
			val=out;
		break;
		case 'boolean':
			val = val ? APP.Tools.icon("toggle-on ","true ") : APP.Tools.icon("toggle-off ","false ");
			break;
		case 'number':
			if (val>Date.now()/10)
				val = APP.Format.relativetime(val);
			//else
			//	val = APP.Format.number(val);
				break;
	}
	print ("<td class='"+col+"'>"+val+"</td>"); } 
__p+='\n';
}
return __p;
};
