module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=(APP.UI.select_from_object('cast',{
	raw:"Raw",standard:"standard",caps:"All Caps"
}, cast, "field")))==null?'':__t)+
'\n\n	</legend>\n\n	<table>\n		no options.\n	</table>\n';
}
return __p;
};
