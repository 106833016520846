module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n    <tr>\n        <th>Tag</th>\n        <td>\n            '+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n        </td>\n    </tr>\n		<tr>\n			<th>Label</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol","unit"],label)))==null?'':__t)+
'\n			</td>\n		</tr>\n</table>\n\n<table>\n    <td>\n        <input type=\'checkbox\' '+
((__t=(alarms?"checked":""))==null?'':__t)+
' id=\'alarms\' class=\'field\'>\n        Show Alarm Bands\n    </td>\n	</tr><tr>\n		<td>\n        <input type=\'checkbox\' '+
((__t=(bulb?"checked":""))==null?'':__t)+
' id=\'bulb\' class=\'field\'>\n        Draw Bulb\n    </td>\n	</tr><tr>\n		<td>\n        <input type=\'checkbox\' '+
((__t=(color?"checked":""))==null?'':__t)+
' id=\'color\' class=\'field\'>\n        Use Tag Color\n    </td>\n	</tr><tr>\n		<td>\n        <input type=\'checkbox\' '+
((__t=(units?"checked":""))==null?'':__t)+
' id=\'units\' class=\'field\'>\n        Draw Units\n    </td>\n	</tr>\n</table>\n<!--\n<a href="https://canvas-gauges.com/documentation/examples/">Custom Attributes\n    '+
((__t=(APP.Tools.icon("external-link")))==null?'':__t)+
'</a>\n<textarea id=\'custom\' class=\'field json\'>'+
((__t=(JSON.stringify(custom, null, "\t")))==null?'':__t)+
'</textarea> -->\n';
}
return __p;
};
