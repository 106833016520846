module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <th>Title</th>\n    <td>\n      <input type=\'text\' class="field nowrap" id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n    </td>\n    <tr>\n      <th colspan=2>RSS URLs</th>\n    </tr>\n    <tr>\n      <td colspan=2>\n        <textarea class="field nowrap" id=\'feeds\'>'+
((__t=(feeds))==null?'':__t)+
'</textarea>\n      </td>\n    </tr>\n</table>\n';
}
return __p;
};
