var AppRouter = require('marionette.approuter').default;

DEF.Router = AppRouter.extend({
	appRoutes: {
		'login/:id'                  : 'LoginUser',
		'DT/edit/:screen'            : 'EditScreen',
		'DT/database/:collection/:id': 'GoDatabase',
		'DT/database/:collection'    : 'GoDatabase',
		'DT/database'                : 'GoDatabase',
		'DT/help'                    : 'GoHelp',
		'DT/help/:topic'             : 'GoHelp',
		'DT/:mode/import'            : 'GoImport',
		'DT/:mode/:id/:cmd'          : 'GoDesignTime',
		'DT/:mode/:id'               : 'GoDesignTime',
		'DT/:mode'                   : 'GoDesignTime',
		DT                           : 'GoDesignTime',
		'RT/:screen/:prefix'         : 'GoToScreen',
		'RT/:screen'                 : 'GoToScreen',
		'widget/:id/:prefix'         : 'GoToWidget',
		'widget/:id'                 : 'GoToWidget',
		alarms                       : 'GoToAlarms',
		'link/*url'                  : 'GoToDocument',
		':mode/:id'                  : 'GoToModel',
		':mode'                      : 'GoToModel',
		''                           : 'GoToScreen'
	}
});

DEF.Controller = Marionette.MnObject.extend({
	GetRootView(mode, options = {}) {
		APP.trigger(mode);
		let root = APP.root.getChildView('main');
		if (!root || root.id !== mode) {
			root = new DEF.layout[mode](options);
			APP.root.showChildView('main', root);
		}
		return root;
	},
	LoginUser(id) {
		console.log('user id', id);
		const user = APP.models.users.get(id);
		if (user) {
			APP.Tools.store('auth', 'uid', user.id);
			user.set({
				last_visit: Date.now(),
				pageloads : user.get('pageloads') + 1,
				cmd       : ''
			});
			APP.USER = user;
			APP.Route('#');
		} else {
			window.location = SETTINGS.posturl; // kind of messy, but gets the job done
		}
	},
	GoHelp(topic) {
		if (!DEF.layout.DT) {
			const loader = require('bundle-loader?lazy&name=DT!./dt/dt.js');
			loader((file) => {
				console.log('..loaded DT', file);
				this.GoHelp(topic);
			});
			return;
		}

		const help = require('./help/help.view.js');
		const root = this.GetRootView('DT');
		const main = new DEF.layout.DT.Help({});
		root.showChildView('main', main);

		if (topic) {
			const details = new DEF.layout.DT.HelpDetails({ topic });
			root.showChildView('details', details);
		}
		this.ShowActive('help');
	},
	GoDatabase(collection, id) {
		let model;

		if (!DEF.layout.DT) {
			const loader = require('bundle-loader?lazy&name=DT!./dt/dt.js');
			loader((file) => {
				console.log('..loaded DT', file);
				this.GoDatabase(collection, id);
			});
			return;
		}

		if (!collection)
			collection = APP.Tools.store('db', 'last_database_tab');
		if (!collection)
			collection = 'tags';
		//		console.log("DT DATABASE", collection, id);
		const root = this.GetRootView('DT');

		if (root.mode !== `db${collection}`) {
			APP.design_time = true;
			const main = new DEF.database.DT.Main({
				collection     : APP.models[collection],
				childView      : DEF.database.DT.child,
				collection_name: collection,
				parent         : root
			});
			root.showChildView('main', main);
			root.mode = `db${collection}`;
			APP.Tools.store('db', 'last_database_tab', collection);
		}

		if (id)
			model = APP.models[collection].get(id);
		else if (DEF[collection].Model)
			model = new DEF[collection].Model();

		if (model) {
			const details = new DEF.database.DT.Details({ model });
			root.showChildView('details', details);
		}
		this.ShowActive('database', id);
	},

	GoToAlarms() {
		const root = this.GetRootView('RT');
		const page = new DEF.layout.ALARM_BOX.Main({ collection: APP.models.tags, long: true });
		APP.root.showChildView('main', page);
	},

	GoToModel(mode, id) {
		console.log(mode, id);

		const root = this.GetRootView('RT');
		if (DEF[mode].page) {
			const page = new DEF[mode].page({
				id       : mode.toUpperCase(),
				className: 'PAGE',
				model    : APP.models[mode].get(id)
			});
			APP.root.showChildView('main', page);
		} else {
			console.warn(`${mode} page doesn't exist.`);
		}
	},

	/**
	 * A mode where it shows a URL in a frame, with a "back" button or somethiung
	 * @param {*} url
	 */
	GoToDocument(url) {
		const Link = require('./include/link.js');

		const link = new DEF.TG.LinkView({ url });
		APP.root.showChildView('main', link);
	},

	GoImport(mode) {
		if (!DEF.layout.DT) {
			const loader = require('bundle-loader?lazy&name=DT!./dt/dt.js');
			loader((file) => {
				console.log('..loaded DT');
				this.GoImport(mode);
			});
			return;
		}

		this.GoDesignTime(mode);
		const main = new DEF.TG.ImportRecords({
			mode
		});
		const root = this.GetRootView('DT');
		root.showChildView('details', main);
		root.mode = mode;
	},

	GoDesignTime(mode, id, cmd) {
		if (!DEF.layout.DT) {
			const loader = require('bundle-loader?lazy&name=DT!./dt/dt.js');
			loader((file) => {
				console.log('..loaded DT');
				this.GoDesignTime(mode, id, cmd);
			});
			return;
		}

		if (!mode) {
			APP.Route(`#DT/${APP.Tools.store('dt', 'mode') || 'device_library'}`);
			return;
		}
		if (!APP.USER.Can('settings'))
			return false;


		console.log('DESIGN TIME', mode, module, id);
		const root = this.GetRootView('DT');

		let view = mode;
		if (['device_library', 'devices', 'tag_library', 'tags'].indexOf(mode) >= 0)
			view = 'device_library';

		cmd = cmd || 'Details';
		let model = false;
		if (id)
			model = APP.models[mode].get(id);

		if (root.mode !== view) {
			if (APP._editor) {
				console.log('destroy root');
				APP._editor.destroy();
				APP._editor = false;
			}

			APP.design_time = true;
			APP.Tools.store('expand', id, 'show');
			if (model && view === 'device_library') // gotta unroll the parent element to show this element
				APP.Tools.store('expand', model.get('dl_id'), 'show');

			const main = new DEF[view].DT.Main({
				collection: APP.models[view],
				childView : DEF[view].DT.child
			});
			root.showChildView('main', main);
			root.mode = view;
		}

		// else if (DEF[mode].Model)
		// 	model = new DEF[mode].Model();

		let details = false;
		if (model) {
			details = new DEF[mode].DT[APP.Format.first(cmd)]({ model });
			$('#DT #DETAILS').removeClass('empty');
		} else {
			$('#DT #DETAILS').addClass('empty');
			if (mode === 'devices')
				mode = 'device_library';
			details = new DEF[mode].DT.empty_details({
				collection_name: mode
			});
		}

		root.showChildView('details', details);

		this.ShowActive(mode, id);
		APP.Tools.store('dt', 'mode', view);
	},
	GoToWidget(widget_id, prefix) {
		APP.design_time = false;
		let widget = APP.models.widgets.get(widget_id);
		if (!widget && APP._tooltip_widget)
			widget = new DEF.widgets.Model(APP._tooltip_widget);
		console.log('wid', widget_id, prefix, APP._tooltip_widget);
		if (widget) {
			const widget_kind = widget.get('widget');
			if (!APP.EnsureWidgets([widget_kind], this.GoToWidget.bind(this, widget_id, prefix)))
				return;

			const ow = widget.attributes.width;
			const oh = widget.attributes.height;
			const ol = widget.attributes.left;
			const ot = widget.attributes.top;

			widget.attributes.width = $(window).width();
			widget.attributes.height = $(window).height();
			widget.attributes.left = 0;
			widget.attributes.top = 0;
			// if (prefix)
			// 	widget = this.ApplyWidgetPrefix(widget, prefix);
			const view = new WID[widget.get('widget')]({
				model      : widget,
				full_screen: true,
				prefix
			});
			APP.root.showChildView('main', view);
			widget.attributes.width = ow;
			widget.attributes.height = oh;
			widget.attributes.left = ol;
			widget.attributes.top = ot;
		}
	},
	ApplyWidgetPrefix(model, prefix) {
		let attr = model.attr;
		attr = JSON.stringify(attr);
		// TODO -- replace the prefix.
		console.warn('#widget preifx', prefix);
		return new DEF.widgets.Model(attr);
	},
	GoToScreen(name, prefix) {
		// console.log('gotoscreen', name, APP.design_time);
		if (APP.current_screen_view)
			APP.current_screen_view.HideTooltip();
		if (APP._editor) {
			console.log('destroy root');
			APP._editor.destroy();
			APP._editor = false;
		}
		APP.design_time = false;
		const root = this.GetRootView('RT');
		root.SetScreen(name, prefix);
	},
	EditScreen(name) {
		if (!APP.USER.Can('editor'))
			return false;
		if (!DEF.layout.DT_SCREEN) {
			const loader = require('bundle-loader?lazy&name=DT!./dt/dt.js');
			loader((file) => {
				console.log('..loaded DT');
				this.EditScreen(name);
			});
			return true;
		}

		function go_dt() { // wait for the screen to paint before continuing so the widgets are there
			APP.design_time = true;
			console.log('go_dt', this);
			const root = new DEF.layout.DT_SCREEN({});
			APP._editor = root;
			root.triggerMethod('before:render', root); // marionette5 doesn't render empty templates
			root.render();	
			root.triggerMethod('render', this); // ... so I manually trigger the methods
			$(window).trigger('resize');
			APP.trigger('DT');
		}

		if (!APP.current_screen || APP.current_screen.prefix || APP.Format.url(APP.current_screen.get('name')) !== APP.Format.url(name))
			if (APP.current_screen && APP.current_screen.prefix) { // #2.8.2.40 I have no idea why this is required.  I'll figure it out later
				this.listenToOnce(APP, 'paintscreen', () => {
					_.defer(go_dt.bind(this));
				});
				this.GoToScreen(name);
			} else {
				this.GoToScreen(name);
				this.listenToOnce(APP, 'paintscreen', () => {
					_.defer(go_dt.bind(this));
				});
			}
		else
			go_dt();


		//
		return true;
	},

	/**
	 * Assign .active class to the currently selected object as defined by the route.  Mostly for #DT
	 *
	 * this is not a route.
	 *
	 * @param  {[type]} mode [description]
	 * @param  {[type]} id   [description]
	 * @return {[type]}      [description]
	 */
	ShowActive(mode, id) {
		const shortname = SETTINGS.shortname || 'TG4';
		const title = [shortname];

		title.push(mode);

		if (id) {
			$('#MAIN #DT .active').removeClass('active');
			$(`#MAIN #DT #${id}`).addClass('active');
			if (APP.models[mode]) {
				const model = APP.models[mode].get(id);
				if (model)
					title.push(model.getName());
			}
		}

		if (['device_library', 'devices', 'tag_library', 'tags'].indexOf(mode) >= 0)
			mode = 'device_library';
		$('#DT #MENU .menuitem').removeClass('active');
		$(`#DT #MENU .menuitem#${mode}`).addClass('active');

		document.title = title.join(' - ');
	},
	LoadDT() {
		// return new Promise(function (success, failure) {
		// 	System.import('./dt/dt.js').then(function () {
		// 		System.import('./dt/dt_bottombar.js').then(function () {
		// 			success();
		// 		});
		// 	});
		// });
	}
});
