DEF.widgets.RT = {};

const prototype = {
	className: 'widget noselect',
	props    : {
		// default values for widget props
		icon: 'bar-chart',
	},
	help        : {}, // key-based help system
	alarm_colors: {
		normal : '#000',
		warning: '#FF0',
		alarm  : '#F00',
	},
	templateContext() {
		// include the widget's default props
		const out = $.extend(
			{},
			WID[this.model.attributes.widget].prototype.props,
			this.model.attributes,
		);
		return out;
	},
	modelEvents: {
		'change:customstyle':'render',
		'change:left change:top change:width change:height': 'onPosition',
		'change:width change:height'                       : 'onResize',
		'change:left change:top'                           : 'onMove',
		change                                             : 'testChange', // onResize, if not onMove or onResize
	},

	/**
   * EVENTS
   */
	onPosition() {
		this.Position();
	},
	onMove() {}, // left top`
	onResize() {
		// width height
		this.render();
	},
	onChange() {
		// everything else not left top width height
		this.render();
	},
	// don't override me, bro!
	onRender () {
		console.log('Widget Render', this.model.get('widget'), this.model.getName(), this.model.get('left'), this.model.get('top'))
		this.Position();
		this.CheckEmpty();
		this.onAfterRender();
		this.ApplyStyle();
		// this.UpdateWidgetView();
		// this.CheckPrefix(this.options.prefix);
		if (this.options.full_screen)
			this.$el.dblclick(() => {
				window.history.back();
			});
			
	},
	onAfterRender() {
		// no op.
	},
	// UpdateWidgetView() {
	// 	console.log('update', JSON.stringify(this.model.attributes));
	// },
	onDestroy() {
		$(`#${this.model.id}.overlay`).remove();
	},
	testChange() {
		// TODO dont render on change of dimensions #2.8.2.27
		if (
			this.model.hasChanged('left')
      			|| this.model.hasChanged('top')
      			|| this.model.hasChanged('width')
      			|| this.model.hasChanged('height')
		)
		//	if (this.model.hasChanged("left") || this.model.hasChanged("top"))
			return false;
		const changed = this.model.changedAttributes();
		const props = Object.keys(this.props);
		for (const c in changed) {
			if (c[0] === '_')
				continue;
			if (props.indexOf(c) >= 0) {
				this.onChange();
				return true;
			}
		}

		return false;
	},

	/**
   * Set the position of the widget
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
	Position() {
		$(this.el).attr('id', this.model.id);

		let screen_class = 'standard';
		if (APP.current_screen && this.model.get('screen_id') !== APP.current_screen.id)
			screen_class = 'master';

		this.$el.addClass(`${this.model.get('widget')} ${screen_class}`);
		// this loads in the default props.  Kind of weird to do it here, but widgets initialize themselves
		this.model.attributes = $.extend(
			{},
			WID[this.model.attributes.widget].prototype.props,
			this.model.attributes,
		);

		// shortcuts for the geometry functions
		this.L = this.model.left();
		this.T = this.model.top();
		this.R = this.model.right();
		this.B = this.model.bottom();
		this.W = this.model.width();
		this.H = this.model.height();
		this.W2 = this.W / 2;
		this.H2 = this.H / 2;
		this.CX = this.L + this.W / 2;
		this.CY = this.T + this.H / 2;

		// console.log(this.L, this.T);

		this.model.on('redraw', this.initialize.bind(this));
		//	console.log(this.props.disablesize, this.model.id);
		if (this.model.id) {
			// only "real" models get positioned.  Not things from Menu or Group
			const pos = {
				left     : `${this.L}px`,
				top      : `${this.T}px`,
				width    : `${this.W}px`,
				height   : `${this.H}px`,
				'z-index': this.model.get('zindex'),
				// position: "absolute"
			};
			this.$el.css(pos);
			$(`#${this.model.id}.overlay`).css(pos);
		}
		return false;
	},
	getTag(key = 'tag_id') {
		return this.model.getTag(key);
	},
	getProp(prop) {
		return this.model.get(prop);
	},

	/**
   * return True if there's no tag set or nothing to display
   * @return {[type]} [description]
   */
	isEmptyProps() {
		return false;
	},
	CheckEmpty() {
		if (this.isEmptyProps())
			this.$el.addClass('empty');
		else
			this.$el.removeClass('empty');
	},
	CheckPrefix(prefix) {
		console.log('opref', prefix);
	},
	ApplyStyle() {
		const style = this.model.get('customstyle');
		if (style) {
			for (const key in style)
				this.$el.css(key, style[key]);
		}
	},

	/**
   * AddTooltip to a awidget
   * @param  {[type]} tag_id a tag_id, or a string pointing to a prop that holds a tag_id
   * @return {[type]}        [description]
   */
	AddTooltip(tag_id) {
		let tag = false;

		const prop = this.model.get(tag_id);
		if (prop)
			tag = APP.models.tags.get(prop); 
		else 
			tag = APP.models.tags.get(tag_id);

		if (tag) {
			tag_id = tag.id;
			this.$el.addClass('tooltip').data('tag_id', tag_id);
		}
	},

	/**
   * Set up tag listeners.  Automatically called "onChange[FieldName]()"
   * @param {string}   field_name Name of the field containiong tag id(s)
   * @param {Function} callback   (optional) The callback to call on change
   */
	ListenToTags(field_name, callback = this.Update, events = 'change:value') {
		this._ListenToStuff('tags', field_name, callback, events);
	},
	ListenToTagLibrary(field_name, callback, events) {
		this._ListenToStuff('tag_library', field_name, callback, events);
	},
	_ListenToStuff(collection, field_name, callback, events) {
		let tag_ids = this.model.get(field_name);
		if (!_.isArray(tag_ids))
			tag_ids = [tag_ids];

		for (const t in tag_ids) {
			const ref = APP.models.tags.get(tag_ids[t]);
			if (ref) {
				let tag;
				switch (collection) {
				case 'tag_library':
					tag = ref.getTagLibrary();
					break;
				case 'tags':
					tag = ref;
				}
				if (tag) {
					// console.log('listen', tag.getName(), events);
					this.stopListening(tag, events);
					this.listenTo(tag, events, callback);
					const on = `onChange${APP.Format.camel(field_name)}`;
					if (this[on])
						this.listenTo(tag, events, this[on]);

					if (collection === 'tags' && tag.getStale()) {
						this.$el.addClass('stale');
						this.listenToOnce(tag, 'change:updated', () => {
							this.$el.removeClass('stale');
						});
					}
				}
			}
		}
	},
};

DEF.widgets.RT.base = Backbone.Marionette.CollectionView.extend(prototype);
DEF.widgets.RT.layout = Backbone.Marionette.View.extend(prototype);
