module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <th>Icon</th>\n    <td>\n			'+
((__t=(APP.UI.fontawesome("glyph",glyph)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Label</th>\n    <td>\n      <textarea class=\'field live\' type=\'text\' id=\'label\'>'+
((__t=(label))==null?'':__t)+
'</textarea>\n    </td>\n  </tr>\n  <tr>\n    <th>Style</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("style", ["plain","note","title","heading","group"], style, "field")))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Border</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("border", ["none","underline","box"], border, "field")))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Align</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("align", ["left","center","right"], align, "field")))==null?'':__t)+
'\n    </td>\n  </tr>\n</table>\n';
}
return __p;
};
