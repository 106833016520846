
DEF.layout.ALARM_BOX = {};
DEF.layout.ALARM_BOX.child = Backbone.Marionette.View.extend({
	tagName  : 'div',
	className: 'row',
	getTemplate() {
		return this.options.template;
	},
	templateContext () {
		return this.model.all_fields();
	},
	initialize() {
		// APP.models.tags.on('change', this.render, this);
	},
	modelEvents: {
		change: 'render'
	},
	triggers: {
		click: 'tooltip:click'
	},
	ui: {
		cmd: 'button.command'
	},
	events: {
		'click @ui.cmd': 'Command'
	},
	onRender() {
		this.$el.addClass(this.model.get('alarm_state'));
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'ack':
			APP.Log(`[tags:${this.model.id}] ${this.model.get('alarm_state')} acknowledged by [users:${APP.USER.id}]`, `${this.model.get('alarm_state')}_ack`, this.model.id);
			this.model.set({
				alarm_ack_time: Date.now(),
				alarm_ack_id  : APP.USER,
				alarm_state   : `${this.model.get('alarm_state')}_ack`
			});
			break;
		}
		e.stopPropagation();
	}
});


DEF.layout.ALARM_BOX.list = Backbone.Marionette.CollectionView.extend({
	id               : 'alarm_list',
	className        : 'tbody',
	childViewTriggers: {
		'tooltip:click': 'tooltip:click'
	},
	childView: DEF.layout.ALARM_BOX.child,
	childViewOptions() {
		return {
			template: this.options.template
		};
	},
	emptyView       : DEF.TG.Empty,
	emptyViewOptions: {
		message: 'No active alarms'
	},
	viewComparator (m) {
		m = m.model;
		const sort = ['alarm', 'warning', 'alarm_ack', 'warning_ack'];
		return (sort.indexOf(m.get('alarm_state')) * 1e10) - m.get('alarm_time');
	},
	viewFilter (m) {
		m = m.model;
		if (!m.getDevice().get('enabled'))
			return false;
		const ignore = ['normal', 'off'];
		const include_ack = APP.Tools.store('alarm_page', 'ack') === 'on';

		if (this.options.long) { // page view
			const states = ['alarm', 'warning', 'stale'];
			for (const s in states) {
				if (APP.Tools.store('alarm_page', states[s]) !== 'on') {
					ignore.push(states[s]);
					ignore.push(`${states[s]}_ack`);
				}
				if (!include_ack)
					ignore.push(`${states[s]}_ack`);
			}
		} else {
			ignore.push('normal', 'off', 'stale', 'alarm_ack', 'warning_ack');
		}


		return ignore.indexOf(m.get('alarm_state')) === -1;
	},
	initialize() {
		APP.models.tags.on('change:alarm_state', this.render, this);
	}
});


DEF.layout.ALARM_BOX.Main = Backbone.Marionette.View.extend({
	id       : 'ALARM_LIST',
	className: '',
	regions  : {
		list       : { el: '#list', replaceElement: true },
		tooltip_box: '#TOOLTIP_BOX'
	},
	childViewTriggers: {
		'tooltip:click': 'show:tooltip'
	},
	ui: {
		cmd: '.command'
	},
	events: {
		'click @ui.cmd': 'Command'
	},
	template: require('./templates/alarm_box_main.html'),
	onRender() {
		if (!APP.Tools.store('alarm_page', 'alarm'))
			APP.Tools.store('alarm_page', 'alarm', 'on');
		if (!APP.Tools.store('alarm_page', 'warning'))
			APP.Tools.store('alarm_page', 'warning', 'on');
		if (!APP.Tools.store('alarm_page', 'stale'))
			APP.Tools.store('alarm_page', 'stale', 'off');
		if (!APP.Tools.store('alarm_page', 'ack'))
			APP.Tools.store('alarm_page', 'ack', 'off');
		const list = new DEF.layout.ALARM_BOX.list({
			collection: this.options.collection,
			template  : this.options.long ? require('./templates/alarm_box_longitem.html') : require('./templates/alarm_box_item.html'),
			long      : this.options.long
		});
		if (this.options.long)
			this.$el.addClass('PAGE');
		this.showChildView('list', list);
	},
	onDomRefresh() {
		$('.filter#alarm').addClass(APP.Tools.store('alarm_page', 'alarm'));
		$('.filter#warning').addClass(APP.Tools.store('alarm_page', 'warning'));
		$('.filter#stale').addClass(APP.Tools.store('alarm_page', 'stale'));
		$('.filter#ack').addClass(APP.Tools.store('alarm_page', 'ack'));
	},
	onShowTooltip(view, e) {
		$(e.currentTarget).data('tag_id', view.model.id);
		APP.ShowTooltip(e);
		e.stopPropagation();
	},
	Command(e) {
		switch (e.currentTarget.id) {
		case 'exit':
			APP.Route('#');
			break;
		case 'alarm':
		case 'warning':
		case 'stale':
		case 'ack':
			this.ToggleState(e);
			break;
		case 'events':
			APP.Route('#events');
			break;
		default:
			console.log('unhandled', e.currentTarget.id);
		}
	},
	ToggleState(e) {
		const id = e.currentTarget.id;
		const state = $(e.currentTarget).hasClass('on');
		console.log(id, state);
		APP.Tools.store('alarm_page', id, state ? 'off' : 'on');
		$(`.filter#${id}`).removeClass('off on').addClass(state ? 'off' : 'on');
		this.render();
	}
});
