module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Label</th>\n		<td>\n			<input type=\'text\' id=\'label\' value=\''+
((__t=(label))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Voltage</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("symbol",{"low":"Low Voltage","medium":"Medium Voltage","switch_l":"Switch"},symbol)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n	\n'+
((__t=(APP.UI.tabs("mode",mode,{boolean:"Boolean",lookup:"Lookup"}) ))==null?'':__t)+
'\n<div class=\'tabcontent\' data-for="mode" id=\'boolean\'>\n	<table>\n		<tr>\n			<th>Closed</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("state", "tags", state, "single", {type:"boolean"})))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n</div>\n\n<div class=\'tabcontent\' data-for="mode" id=\'lookup\'>\n	<table>\n		<tr>\n			<th>State Value</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("state", "tags", state, "single",  {type:"number"})))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Opened</th>\n			<td><input type=\'number\' size=2 value=0></td>\n		</tr>\n		<tr>\n			<th>Closed</th>\n			<td><input type=\'number\' size=2 value=1></td>\n		</tr>\n		<tr>\n			<th>Syncing</th>\n			<td><input type=\'number\' size=2 value=2></td>\n		</tr>\n		<tr>\n			<th>Racked Out</th>\n			<td><input type=\'number\' size=2 value=3></td>\n		</tr>\n		<tr>\n			<th>Warning</th>\n			<td><input type=\'number\' size=2 value=4></td>\n		</tr>\n		<tr>\n			<th>Alarm</th>\n			<td><input type=\'number\' size=2 value=5></td>\n		</tr>\n	</table>\n</div>\n';
}
return __p;
};
