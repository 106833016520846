DEF.layout.DT_TAGCHOOSER_TAG = Backbone.Marionette.View.extend({
	className: 'item',
	tagName  : 'tr',
	// template: require("./templates/tag_chooser_item.html"),
	template : _.template("<td style='color:<%=color%>'><%=icon%></td><td><%=name%></td><td class='desc'><%=desc%> (<%=device%>)</td>"),
	templateContext() {
		let icon = this.model.collection_name;
		if (icon === 'tags')
			icon = this.model.getUp('type');
		return {
			icon  : APP.Tools.icon(icon),
			name  : this.model.getName(),
			desc  : this.model.getDesc(),
			device: this.model.getUp('device'),
			color : (this.model.getUp('color') || 'black')
		};
	},
	triggers: {
		click: 'select:tag'
	},
});

DEF.layout.DT_TAGCHOOSER = Backbone.Marionette.CollectionView.extend({
	id      : 'chooser',
	template: require('./templates/tag_chooser.html'),
	templateContext() {
		const out = {
			search: this.search || APP.Tools.store('chooser', 'search')
		};
		out.group = '';
		if (this.options.collection_name === 'tags')
			out.group = `<td>${APP.UI.select_from_object('prefix', APP.models.devices.pluck('prefix').sort(), APP.Tools.store('chooser', 'prefix'), 'prefixes', {
				blank: true
			})}</td>`;
		return out;
	},
	childView         : DEF.layout.DT_TAGCHOOSER_TAG,
	childViewContainer: '#items',
	childViewEventPrefix: 'childview',
	viewFilter (child) {
		child = child.model
		const search = this.search || APP.Tools.store('chooser', 'search') || '';
		const name = child.getSearch().toUpperCase();
		let prefix = false;
		if (this.options.collection_name === 'tags')
			prefix = APP.Tools.store('chooser', 'prefix');
		const value = this.options.model.get(this.options.field_id);
		if (this.options.filterkey)
			if (this.options.filtervalue.indexOf(child.getUp(this.options.filterkey)) === -1)
				return false;

		if (search === '' || name.indexOf(search.toUpperCase()) >= 0) // apply search term
			if (!prefix || name.indexOf(prefix) === 0) // apply prefix
				if ((_.isArray(value) && value.indexOf(child.id) === -1) || (!_.isArray(value) && value !== child.id)) // dont show already selected tags
					return true;

		return false;
	},
	ui: {
		search: '#search',
		prefix: '#prefix',
		close : '#icon_close'
	},
	events: {
		'keyup @ui.search' : 'Search',
		'change @ui.prefix': 'SetPrefix',
		'click @ui.close'  : 'Close'
	},
	search: '',
	onChildviewSelectTag (childView) {
		console.log("select");
		this.Select(childView.model, this.options);
	},
	onRender() {
		_.defer(() => {
			this.SetPosition();
			$(this.ui.search).focus();
		});
	},
	SetPosition() {
		let top = 0;
		if (this.$el.offset().top + this.$el.height() > $(window).height() - 40) {
			top = $(window).height() - this.$el.offset().top - this.$el.height();
			this.$el.css({ top: `${top}px` });
		}
	},
	SetPrefix(e) {
		APP.Tools.store('chooser', 'prefix', e.currentTarget.value);
		this.render();
	},
	Close() {
		this.trigger('chooser:close');
	},
	Search(e) {
		this.search = e.currentTarget.value;
		APP.Tools.store('chooser', 'search', e.currentTarget.value);
		// this._renderChildren();
		// this.setFilter(this.viewFilter, { preventRender: false })
		this.filter();
	},
	Select(tag, options) {
		const set = {};
		const field = options.field_id;
		switch (options.mode) {
		case 'multi':
			set[field] = options.model.get(options.field_id);
			if (!_.isArray(set[field]))
				set[field] = [];
			if (set[field].indexOf(tag.id) === -1)
				set[field].push(tag.id);
			options.model.set(set);
			this.render();
			break;
		case 'single':
		default:
			set[field] = tag.id;
			options.model.set(set);
			this.destroy();
			this.trigger('chooser:select');
			break;
		}
	}
});
