DEF.settings = {};

DEF.settings.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.settings.Model,
		url       : `${SETTINGS.dburl}/settings`,
		comparator: 'key'
	};
	return APP.InitializeModels('settings', options, callback, constraints);
};

DEF.settings.Model = DEF.TG.Model.extend({
	idAttribute: 'key',
	defaults   : {
		key  : 'key',
		value: 'value',
		notes: ''
	},
	collection_name: 'settings',
	db_columns     : ['key', 'value'],
	db_spreadsheet : ['key', 'value', 'notes'],
	getName() {
		return this.get('key');
	}
});
