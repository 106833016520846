// http://svgjs.com/

DEF.widgets.RT.vector = DEF.widgets.RT.base.extend({
	className        : 'widget',
	engine           : 'vector',
	template         : false,
	connector_class  : false, // name of connector for showing dt_bottombar template.  [oneline, pid...]
	split_node_widget: 'Node', // name of widget used when splitting connectors.  A "node" style widget.
	style            : { // some default line styles.
		power_on : 500,
		power_off: 3000,
		node_size: 10,
		static   : {
			'stroke-width': 2,
			stroke        : '#fff',
			opacity       : 1
		},
		selector: {
			cursor           : 'pointer',
			'stroke-width'   : 30,
			opacity          : 0,
			fill             : 'none',
			'stroke-linecap' : 'round',
			'stroke-linejoin': 'round'
		},
		node: {
			'stroke-width': 2,
			stroke        : '#aaa'
		},
		debug: {
			fill         : '#f00',
			'font-size'  : '13px',
			'font-family': 'monospace'
		},
		label: {
			fill       : '#aaa',
			'font-size': '13px'
		},
		value: {
			fill         : 'rgba(255,255,255,0.8)',
			'font-size'  : '14px',
			'font-family': 'monospace'
		},
		unit: {
			fill         : '#999',
			'font-size'  : '14px',
			'font-family': 'monospace'
		},
		title: {
			fill       : '#eee',
			'font-size': '15px'
		},
		energized: {
			'stroke-width': 4,
			stroke        : '#F00'
		},
		pop: {
			'stroke-width': 8,
			stroke        : '#F00'
		},
		dead: {
			'stroke-width': 2,
			stroke        : '#CCC'
		}
	},
	initialize() {
		this._animation_loops = {};
		this._timers = {};
		this.Initialize();
		this.listenTo(APP.current_screen, 'energize_connector', this.EnergizeNodes);
		this.listenTo(this.model, 'change:connectors', this.onConnectorChange);
		this.listenTo(this.model, 'change:nodes', this.onNodeChange);

		this.listenTo(this.model, 'destroy', this.DestroyOtherModelConnectors);

		this.listenTo(APP, 'RT', this.UndrawSelectors.bind(this));
		this.listenTo(APP, 'DT', this.DrawSelectors.bind(this));

		this.listenTo(APP, 'windowhide', this.PauseAnim.bind(this));
		this.listenTo(APP, 'windowshow', this.ResumeAnim.bind(this));

		if (!APP.current_screen._pulse_cache)
			APP.current_screen._pulse_cache = [];
		if (!this._current_pulses)
			this._current_pulses = [];
	},
	Initialize() {
		// override me bro
	},
	onRender() {
		this.Position();
		this.CheckEmpty();
		if (this.draw) {
			this.ClearCanvas();
			this.DrawConnectors();
			this.Draw();
			this.draw.move(this.L, this.T);
		}

		this.onAfterRender();
	},

	/**
	 * The widget should store all (looping) animations in this object, otherwise
	 * animation loops continue.
	 * @return {[type]} [description]
	 */
	onDestroy() { // the view.
		if (this._animation_loops)
			for (const a in this._animation_loops)
				this._animation_loops[a].stop();

		if (this._timers)
			for (const t in this._timers)
				clearInterval(this._timers[t]);

		if (this.draw)
			this.draw.clear();
	},
	PauseAnim() {
		this._is_paused = true;
		if (this._animation_loops)
			for (const a in this._animation_loops)
				this._animation_loops[a].pause();

		if (this._current_pulses.length)
			for (const p in this._current_pulses)
				this._current_pulses[p].pause();
	},
	ResumeAnim() {
		this._is_paused = false;
		if (this._animation_loops)
			for (const a in this._animation_loops)
				this._animation_loops[a].play();
		if (this._current_pulses.length)
			for (const p in this._current_pulses)
				if (this._current_pulses[p].fx.situation)
					this._current_pulses[p].play();
	},
	onAftrerRender() {
		this.Draw();
	},
	onDomRefresh() { // this is the first time we have the DOM element on the screen, so it's a special case draw
		if (!this.draw) {
			// console.log("ref");
			this.InitializeCanvas();
			this.DrawConnectors();
			this.Draw();
			this.draw.move(this.L, this.T);
		}
	},
	onNodeChange() {
		if (APP.design_time)
			this.DrawNodes();
		this.DrawConnectors();
	},
	onConnectorChange() {
		this.DrawConnectors();
		APP.current_screen_view.ComputeStateEquations();
		if (APP.design_time)
			this.DrawNodes();
	},
	InitializeCanvas() {
		let screen;
		if (this.options.full_screen) {
			APP.current_screen = {};
			const svg = require('svg.js');
			require('svg.easing.js');
			// require('@svgdotjs/svg.filter.js');
			this.draw = svg(this.options.model.id);
			screen = $('#MAIN .widget');
			this.svg = svg;
		} else if (!APP.current_screen.svg) {
			APP.current_screen.svg = require('svg.js');
			this.svg = APP.current_screen.svg;
			require('svg.easing.js');
			// require('@svgdotjs/svg.filter.js');
			console.log("vec")
			APP.current_screen.draw = APP.current_screen.svg('screen');
			screen = $('screen');
			this.draw = APP.current_screen.draw.group();
		} else {
			screen = $('screen');
			this.svg = APP.current_screen.svg;

			if (!this.draw)
				this.draw = APP.current_screen.draw.group();
		}
		screen.mousemove(this.MoveCanvas.bind(this));
		screen.mouseup(this.UnclickCanvas.bind(this)); // don't work??


		this.listenTo(APP, 'dt_addoverlays', this.DrawNodes.bind(this));

		const extensions = {
			select(size = 30) {
				return this.attr('stroke-width', 0).animate(500, 'elastic').attr({
					stroke        : '#0FF',
					'stroke-width': size,
					opacity       : 0.5
				});
			},
			unselect(time = 800) {
				return this.animate(time, '>').attr({
					opacity       : 0,
					'stroke-width': 0
				}).after(function after() {
					this.attr('stroke-width', 30);
				});
			},
			energize(energize, pop) {
				energize = Object.assign({ 'stroke-width': 4, stroke: '#F00' }, energize);
				pop = Object.assign({ 'stroke-width': 8, stroke: '#F00' }, pop);

				return this.stop().attr(pop).animate(500, '>').attr(energize);
			},
			dead(dead) {
				dead = Object.assign({ 'stroke-width': 2, stroke: '#CCC' }, dead);
				return this.stop().animate(3000, '>').attr(dead);
			}
		};
		this.svg.extend(SVG.Shape, extensions);
		this.svg.extend(SVG.G, extensions);
		this.svg.extend(SVG.Nested, extensions);
		this.svg.extend(SVG.Marker, extensions);
	},
	// EnergizePath(path) {

	// },
	ClearCanvas() {
		this.draw.clear();
	},
	onMove() {
		this.render();
	},
	onResize() {
		this.render();
		this.DrawNodes();
	},

	/**
	 *Draws a label at the specified coord.

	 * @param {number} x X
	 * @param {number} y Y
	 * @param {string} text What text to draw
	 * @param {string} align left, center, right
	 */
	draw_label(x, y, text = ' ', align = 'left') {
		const justify = {
			left  : 'start',
			center: 'middle',
			right : 'end'
		};
		//		console.log('text', text, align);
		if (!text)
			text = ' ';
		const label = this.draw.text(text).font({ anchor: justify[align] });
		label.move(x, y + 8);
		return label;
	},

	/**
	 * Draws a shaded box behind the label, for easier reading.  Call this directly after .draw_label
	 * @param {object} label a label, returned by draw_label.
	 */
	draw_label_shadow(label) {
		const w = label.length() + 6;
		if (w > 6) {
			const rect = this.draw.rect(w, 18)
				.attr({ fill: 'rgba(0,0,0,0.7)' });

			let dx = 0;
			if (label.attr('text-anchor') === 'middle')
				dx = w / 2;
			if (label.attr('text-anchor') === 'end')
				dx = w;
			rect.move(label.attr('x') - dx, label.attr('y') + 3);
			rect.backward();
		}
		return label;
	},

	DrawNodes() {
		$(`#${this.model.id}.overlay .node`).remove();
		const nodes = this.model.get('nodes') || {};
		const connectors = this.model.get('connectors') || {};

		for (const dir in nodes)
			for (const index in nodes[dir])
				if (!connectors[dir] || !connectors[dir][index] || !APP.models.widgets.get(connectors[dir][index].id)) {
					let html = '<div style=\'';
					html += `left:${nodes[dir][index].x}px;`;
					html += `top:${nodes[dir][index].y}px' `;
					html += `data-dir='${dir}' data-index='${index}' class='node ${dir}'>xx</div>`;
					$(`#${this.model.id}.overlay`).append(html);
				}


		$(`#${this.model.id}.overlay .node`).mousedown(this.ClickNode.bind(this));
		$(`#${this.model.id}.overlay .node`).mousemove(this.MoveNode.bind(this));
		$(`#${this.model.id}.overlay .node`).mouseup(this.UnclickNode.bind(this));
		$(`#${this.model.id}.overlay .node`).mouseout(this.CycleNode.bind(this));
		$(`#${this.model.id}.overlay`).mousemove(this.MoveOverlay.bind(this));
	},
	CycleNode(e) {
		if (!APP.current_screen._node_drag)
			this.UnclickNode();
		e.target.parentNode.append(e.currentTarget); // attempt to make East nodes more selectable
		// console.log("node",e);
		// debugger
	},

	/**
	 * Figure out which node was just clicked on, or hovered, and return a node object
	 * @param  {[type]} e [description]
	 * @return {[type]}   [description]
	 */
	get_node(e) {
		const dir = $(e.currentTarget).data('dir');
		const index = $(e.currentTarget).data('index');
		const nodes = this.model.get('nodes');
		const node = nodes[dir][index];


		return {
			x : node.x + this.L,
			y : node.y + this.T,
			dir,
			index,
			id: this.model.id
		};
	},

	/**
	 * Return a list of SVG connecrtors, even for incoming connectors
	 * @return {[type]} [description]
	 */
	get_conn_paths() {
		return this.model._conn_paths;
	},
	get_path_from_node(dir, index) {
		let path;
		const paths = this.get_conn_paths();
		for (const p in paths) {
			let conn_node = paths[p].data('from');
			if (conn_node.dir === dir && conn_node.index === index && conn_node.id === this.model.id) {
				path = paths[p];
				break;
			}

			conn_node = paths[p].data('to');
			if (conn_node.dir === dir && conn_node.index === index && conn_node.id === this.model.id) {
				path = paths[p];
				break;
			}
		}
		if (path)
			return path;
		return path;
	},
	get_node_other_end_widget(dir, index) {
		const connectors = this.model.get('connectors');
		let connectors2;
		if (connectors && connectors[dir] && connectors[dir][index])
			return connectors[dir][index].id;


		const widgets = APP.models.widgets.where({
			screen_id: APP.current_screen.id
		});
		for (const w in widgets) {
			const widget = widgets[w];
			connectors2 = widget.get('connectors');
			for (const d in connectors2)
				for (const i in connectors2[d])
					if (connectors2[d][i].id === this.model.id && connectors2[d][i].dir === d && connectors2[d][i].index === i)
						return widget.id;
		}
		return false;
	},

	get_view_from_widget_id(id) {
		let view;
		const children = APP.current_screen_view.children;
		if (children) {
			const model = APP.models.widgets.get(id);
			if (model)
				view = children.findByModel(model);
			return view;
		}
		return false;
	},

	ClickNode(e) {
		if (!APP.current_screen._node_drag) {
			APP.current_screen._node_drag = {
				from: this.get_node(e)
			};
			e.stopPropagation();
		}
	},
	MoveOverlay(e) {
		if (APP.current_screen._node_drag) {
			const x1 = APP.current_screen._node_drag.from.x; // / APP.current_screen.scale,
			const y1 = APP.current_screen._node_drag.from.y; // / APP.current_screen.scale;
			const x2 = e.clientX / APP.current_screen.scale;
			const y2 = e.clientY / APP.current_screen.scale;

			this.DrawTempConnector(x1, y1, x2, y2);
		}
	},
	MoveCanvas(e) {
		// console.log(e);
		$('.node').removeClass('hover');
		if (APP.current_screen._node_drag) {
			const x1 = APP.current_screen._node_drag.from.x; // / APP.current_screen.scale,
			const y1 = APP.current_screen._node_drag.from.y; // / APP.current_screen.scale;
			const x2 = e.offsetX;
			const y2 = e.offsetY;

			this.DrawTempConnector(x1, y1, x2, y2);
		}
	},
	MoveNode(e) {
		$('.node').removeClass('hover');
		$(e.currentTarget).addClass('hover');
	},
	UnclickCanvas() {
		if (APP.current_screen._connector_line)
			APP.current_screen._connector_line.remove();

		delete (APP.current_screen.selected_connector);
		delete (APP.current_screen.connector_functions);

		APP.current_screen._node_drag = false;
	},
	UnclickNode(e) {
		console.log('up', e);
		$('.node').removeClass('hover');
		if (APP.current_screen._connector_line)
			APP.current_screen._connector_line.remove();

		if (APP.current_screen._node_drag)
			this.CreateConnector(APP.current_screen._node_drag.from, this.get_node(e));

		APP.current_screen._node_drag = false;

		if (e)
			e.stopPropagation();
	},
	ClickSelector(selector) {
		if (selector.data('selected')) {
			selector.unselect();
			delete (APP.current_screen.selected_connector);
			delete (APP.current_screen.connector_functions);
			APP.root.getRegion('main').currentView.getChildView('edit_box').render();
			selector.data('selected', false);
		} else {
			const eq = this.get_connector_state_equation(selector);
			console.log(eq, this.get_energized_from_eq(eq));
			selector.select(this.selector_size);
			APP.current_screen.selected_connector = [selector];
			APP.current_screen.connector_functions = {
				delete     : this.DeleteConnector.bind(this),
				split      : this.SplitConnector.bind(this),
				startmarker: this.SetConnectorProp.bind(this, 'startmarker'),
				endmarker  : this.SetConnectorProp.bind(this, 'endmarker'),
				style      : this.SetConnectorProp.bind(this, 'style')
			};
			APP.root.getRegion('main').currentView.getChildView('edit_box').render();
			selector.data('selected', true);
		}
	},

	/**
	 * Props from the bottombar.  Half baked, of course.
	 * @param {*} prop
	 * @param {*} value
	 */
	SetConnectorProp(prop, selector, mouse, target) {
		console.log(prop, target.value);
		selector.data(prop, target.value);
		const connector = selector.parent_connector || selector;
		console.log(connector);
		const node = connector.data('from');
		const model = APP.models.widgets.get(node.id);
		if (model) {
			const connectors = model.get('connectors');
			if (connectors[node.dir][node.index]) {
				connectors[node.dir][node.index][prop] = target.value;
				model.set({ connectors }).trigger('change:connectors');
			}
		}

		// 		const nodes = [connector.data('from'), connector.data('to')];
		// 		for (const node of nodes) {
		// 			const model = APP.models.widgets.get(node.id);
		// 			if (model) {
		// 				const connectors = model.get('connectors');
		// 				for (const c in connectors)
		// 					for (const i in connectors[c]) {
		// 						console.log(connectors[c][i].id , node.id, connectors[c][i].dir,node.dir);
		// 						if (connectors[c][i].id === node.id && connectors[c][i].dir === node.dir)
		// 							connectors[c][i][prop] = target.value;
		//  }
		// 				model.set({ connectors }).trigger('change:connectors');
		// 			}
		// 		}
	},

	/**
	 * Deletes a connector between two widgets
	 * @param {[type]} selector a connector path, or a selector path (with parent_connector set to the path)
	 */
	DeleteConnector(selector) {
		const connector = selector.parent_connector || selector;
		//	console.log(connector);
		const nodes = [connector.data('from'), connector.data('to')];
		for (const n in nodes) {
			const node = nodes[n];
			const model = APP.models.widgets.get(node.id);
			if (model) {
				const connectors = model.get('connectors');
				if (connectors[node.dir]) {
					if (connectors[node.dir][node.index])
						delete connectors[node.dir][node.index];
					if (Object.keys(connectors[node.dir]).length === 0)
						delete connectors[node.dir];
				}
				model.set({
					connectors
				}).trigger('change:connectors');
			}
		}
		delete (APP.current_screen.selected_connector);
		delete (APP.current_screen.connector_functions);

		selector.unselect(100);
		connector.animate(500, '<').attr({
			'stroke-width': 20,
			opacity       : 0
		}).scale(1.05).after(connector.remove);
	},
	DestroyOtherModelConnectors() {
		const paths = this.get_conn_paths();
		for (const p in paths) {
			const path = paths[p];
			this.DeleteConnector(path);
		}
		// DeleteConnector
	},
	SplitConnector(selector, mouse, target) {
		console.log(mouse, target.value);
		this.CreateWidget(target.value, mouse.x, mouse.y, selector);
	},
	CreateWidget(widget_kind, x, y, selector) {
		if (!APP.EnsureWidgets([widget_kind], this.CreateWidget.bind(this, widget_kind, x, y)))
			return;

		const attr = {
			widget   : widget_kind,
			screen_id: APP.current_screen.id
		};
		const geo = {
			width : 30, height: 30, left  : x / APP.current_screen.scale, top   : y / APP.current_screen.scale
		};

		_.extend(attr, WID[widget_kind].prototype.props, geo);

		const widget = APP.models.widgets.create(attr);
		this.listenToOnce(APP.models.widgets, 'add', this.ConnectNewWidget.bind(this, widget, selector));
	},
	ConnectNewWidget(widget, selector) {
		const connector = selector.parent_connector || selector;
		const nodes = [connector.data('from'), connector.data('to')];
		console.log(nodes);

		const nodes2 = [
			{ dir: 'W', index: 1, id: widget.id },
			{ dir: 'E', index: 1, id: widget.id }
		];
		this.CreateConnector(nodes[0], nodes2[0]);
		this.CreateConnector(nodes[1], nodes2[1]);
		this.DeleteConnector(selector);
	},
	CreateConnector(from, to) {
		// console.log(from, to);
		if (from.id === to.id)
			return;
		const model = APP.models.widgets.get(from.id);
		// const nodes = model.get('nodes');
		const connectors = model.get('connectors') || {};
		if (!connectors[from.dir])
			connectors[from.dir] = {};

		connectors[from.dir][from.index] = {
			dir   : to.dir,
			index : to.index,
			id    : to.id,
			source: true
		};
		model.set({
			connectors
		});
		console.log('from', connectors);

		const other = APP.models.widgets.get(to.id);
		const other_connectors = other.get('connectors') || {};
		if (!other_connectors[to.dir])
			other_connectors[to.dir] = {};

		other_connectors[to.dir][to.index] = {
			dir   : from.dir,
			index : from.index,
			id    : model.id,
			source: false
		};
		other.set({
			connectors: other_connectors
		});
		console.log('to', other_connectors);
		model.trigger('change:connectors');
		console.log('energize_connector createcon');
		APP.current_screen.trigger('energize_connector');
		// this.DrawConnector(from.dir, from.x, from.y, to.dir, to.x, to.y);
		// this.DrawConnectors();
	},

	/**
	 * Draw a temporary connector from pointA to pointB, usually when dragging a new connector
	 * @param  {[type]} x1   [description]
	 * @param  {[type]} y1   [description]
	 * @param  {[type]} x2   [description]
	 * @param  {[type]} y2   [description]
	 * @return {[type]}      [description]
	 */
	DrawTempConnector(x1, y1, x2, y2) {
		console.log(x1, x2, y1, y2);
		if (APP.current_screen._connector_line)
			APP.current_screen._connector_line.remove();

		APP.current_screen._connector_line = APP.current_screen.draw
			.line(x1, y1, x2, y2)
			.attr(this.style.static)
			.marker('start', 5, 5, (mark) => {
				mark.circle(5).attr({
					fill: 'white'
				});
			});
	},

	/**
	 * Draw a connector from pointA to pointB
	 * @param  {[type]} dir1 [description]
	 * @param  {[type]} x1   [description]
	 * @param  {[type]} y1   [description]
	 * @param  {[type]} dir2 [description]
	 * @param  {[type]} x2   [description]
	 * @param  {[type]} y2   [description]
	 */
	DrawConnector(dir1, x1, y1, dir2, x2, y2, conn1) {
		const path = this.get_connector_path(dir1, x1, y1, dir2, x2, y2);
		let line = APP.current_screen.draw.path();
		if ((conn1.startmarker === 'arrow'))
			line.marker('start', 20, 20, (add) => {
				add.path('M16 10 l 0 -2 l -4 2 l 4 2 z');
			});
		if ((conn1.endmarker === 'arrow'))
			line.marker('end', 20, 20, (add) => {
				add.path('M4 10 l 0 -2 l 4 2 l -4 2 z');
				// add.path('M3 7 l 7 3 l -7 3').attr(this.style.static).fill('none');
			});
		line = this.DrawConnectorPath(line, path);

		// console.log(conn1.style);
		switch (conn1.style) {
		case 'electrical':
			line.attr({ 'stroke-dasharray': 10 });
			break;
		case 'hot':
			line.attr({ stroke: '#F00' });
			break;
		}


		return line;
	},
	DrawConnectorPath(line, path) {
		// path = this.get_path_from_coords(path);
		// const line = APP.current_screen.draw.polyline(path).attr(this.style.static);

		line.plot(path).attr(this.style.static);
		line.back();
		line.fill('none');


		// if (!APP.design_time) {
		// 	const shadow = APP.current_screen.draw.path();
		// 	shadow.plot(path).attr({ stroke: 'rgba(0, 0, 0, 0.5)', fill: 'none', 'stroke-width': 10 });
		// 	shadow.back();
		// }

		// line.filter((add) => {
		// 	const blur = add.offset(0, 1).in(add.$sourceAlpha).gaussianBlur(1);

		// 	add.blend(add.$source, blur);
		// });
		return line;
	},
	UndrawConnectors() {
		if (this.model._conn_paths)
			for (const c in this.model._conn_paths)
				if (this.model._conn_paths[c].data('from').id === this.model.id) {
					// Check other end, and erase it's conn path.
					const other = APP.models.widgets.get(this.model._conn_paths[c].data('to').id);
					if (other)
						for (const c2 in other._conn_paths)
							if (other._conn_paths[c2].data('from').id === this.model.id)
								other._conn_paths.splice(c2, 1);


					// now erase the conn path locally
					this.model._conn_paths[c].remove();
					if (this.model._conn_paths[c].selector)
						this.model._conn_paths[c].selector.remove();
					this.model._conn_paths.splice(c, 1);
				}
	},

	/**
	 * Draw all the widget's connectors
	 * @return {[type]} [description]
	 */
	DrawConnectors() {
		const nodes = this.model.get('nodes');
		const connectors = this.model.get('connectors') || {};
		// console.log('draw', this.model.getName(), this.model._conn_paths);
		this.UndrawConnectors();

		if (connectors && APP.current_screen.draw)
			for (const dir in connectors)
				for (let index in connectors[dir]) {
					index = Number(index); // index was a string
					if (connectors[dir][index].source) {
						const to = connectors[dir][index];
						const other = APP.models.widgets.get(to.id);
						// if a widget this widget is connected to has moved.  LIsten for it to move, and cause it to redraw
						this.stopListening(other, 'change:width change:height change:left change:top');
						this.listenTo(other, 'change:width change:height change:left change:top', this.DrawConnectors.bind(this));
						if (other) {
							const to_node = other.get('nodes');
							if (nodes && nodes[dir] && nodes[dir][index] && to_node && to_node[to.dir] && to_node[to.dir][to.index]) {
								const x1 = nodes[dir][index].x + this.L;
								const y1 = nodes[dir][index].y + this.T;
								const x2 = to_node[to.dir][to.index].x + other.get('left');
								const y2 = to_node[to.dir][to.index].y + other.get('top');
								const line = this.DrawConnector(dir, x1, y1, to.dir, x2, y2, connectors[dir][index], to);
								line.data('from', {
									id    : this.model.id,
									dir,
									index,
									source: true
								});
								line.data('to', {
									id    : to.id,
									dir   : to.dir,
									index : to.index,
									source: false
								});
								line.data('pulse_count', 0);
								line.click(this.ClickConnector.bind(this, line));

								if (APP.design_time) {
									const path = this.get_connector_path(dir, x1, y1, to.dir, x2, y2);
									line.selector = this.AddSelectorPath(line, path);
								}

								if (!this.model._conn_paths)
									this.model._conn_paths = [];
								this.model._conn_paths.push(line);
								if (!other._conn_paths)
									other._conn_paths = [];
								other._conn_paths.push(line);
							}
						}
					}
				}
	},
	ClickConnector(line) {
		console.log(line.data('from'), line.data('to'));
		console.log(this.get_connector_state_equation_from_node(line.data('from')));
		console.log(this.get_energized_from_eq(this.get_connector_state_equation_from_node(line.data('from')), 1));
		console.log(this.get_energized_from_eq(this.get_connector_state_equation_from_node(line.data('from')), 2));
		console.log(this.get_energized_from_eq(this.get_connector_state_equation_from_node(line.data('from')), 0));
	},
	AddSelectorPath(line, path) {
		const selector = APP.current_screen.draw.path(path)
			.attr(this.style.selector)
			// .attr('stroke-width', 30)
			.data('type', 'selector');
		if (this.connector_class) {
			selector.data('class', this.connector_class);
		} else {
			// You have to extend the base class, such as Node.extend(...), so it sets up the base behavior of this kind of widget
			console.warn('missing this.connector_class.  Does the widget extend the base widget (Node, PID, Pipe..)');
			selector.data('class', 'oneline');
		}

		selector.front();
		selector.click(this.ClickSelector.bind(this, selector));
		// selector.dblclick(this.DeleteConnector);
		selector.parent_connector = line;

		return selector;
	},

	DrawSelectors() {
		this.DrawConnectors();
	},
	UndrawSelectors() {
		// console.log('undraw');
	},

	/**
	 * Add nodes all at once.
	 * @param {[type]} nodes object in the form { $dir: [ { x:$x, y:$y}, ... ], ... }
	 */
	AddNodes(nodes) {
		if (nodes)
			this.model.set('nodes', nodes);
	},
	ClearNodes(dirs) {
		let nodes = {};
		if (dirs) {
			nodes = this.model.get('nodes');
			if (nodes)
				for (const d in dirs)
					delete (nodes[dirs[d]]);
		}
		this.model.set('nodes', nodes);
	},
	AddNode(x, y, dir, index = 1) {
		const nodes = this.model.get('nodes') || {};
		if (!nodes[dir])
			nodes[dir] = {};

		if (!nodes[dir][index])
			nodes[dir][index] = {};

		const trigger = (nodes[dir][index].x !== x || nodes[dir][index].y !== y);

		nodes[dir][index].x = x;
		nodes[dir][index].y = y;
		this.model.set('nodes', nodes);
		// console.log('nodes2', nodes);
		if (trigger)
			this.model.trigger('change:nodes');
	},

	/**
	 * Given a connector, determine it's energized state.
	 * @param  {path} conn connector path
	 * @return {string}     connector equation fragment
	 */
	get_connector_state_equation(conn) {
		let eq = false;
		const from = conn.data('from');
		const to = conn.data('to');

		// APP.current_screen._eq_cache = {};
		// this._eq_cache = {};

		const eq1 = this.get_connector_state_equation_from_node(from);
		const eq2 = this.get_connector_state_equation_from_node(to);
		//	console.log('[EQ1]', eq1, '[EQ2]', eq2);
		if (eq1 === true)
			eq = eq2;
		else if (eq2 === true)
			eq = eq1;
		else if (eq1 && !eq2)
			eq = eq1;
		else if (eq2 && !eq1)
			eq = eq2;
		else if (eq1 === eq2)
			eq = eq1;
		else if (eq1 === -1)
			eq = eq2;
		else if (eq2 === -1)
			eq = eq1;
		// else if (eq1 === true || eq2 === true) eq = true;
		else if (eq1 && eq2)
			eq = `${eq1} || ${eq2}`;

		// if (false) { // Show the State Equation
		// 	const label = `eq:${eq}`;
		// 	const eqlabel = this.draw_label((this.W / 2) - 20, (this.H / 2) + 5, label, 'center').attr(this.style.debug);
		// 	this.draw_label_shadow(eqlabel);
		// }
		return eq;
	},

	/**
	 * Return the state equation, given just a node.  Internally uses GetStateEquation, which
	 * should be overridden
	 * @param  {node} node node.dir,node.index,node.id
	 * @return {striung}      returns a state equation fragment.
	 */
	get_connector_state_equation_from_node(node) {
		let eq = false;
		if (!node || !APP.models.widgets.get(node.id)) // handle missing nodes, or incomplete connections
			return false;
		// It is possible for connectors to loop.  If so, this function could get called more than X times in
		// a row.  If so, abort, or you'll go forever.
		if (APP.current_screen._eq_count < 300) {
			APP.current_screen._eq_count++;
			const view = this.get_view_from_widget_id(node.id);
			if (view)
				eq = view.GetStateEquation(node.dir, node.index);
			// console.log(node, eq);
			// eq += `[${view.GetNodePower()}]`;
		}
		return eq;
	},

	/**
	 * An experiment to see if nodes can see which way power SHOULD flow.
	 * If there's a generator in one direction, an electron should probably
	 * not go down that path
	 *
	 * Replace with a positive value for sources, and negative values for sinks.
	 */
	GetNodePower() {
		return false;
	},

	/**
	 * Return a part of a state equation, as if coming in on this dir/index.
	 * @param {char} dir   node dir
	 * @param {index} index Node index
	 */
	GetStateEquation(dir, index) {
		// Override me bro
		console.log('state eq', dir, index);
		return false;
	},

	/**
	 * Given an SVG path energize it, or don't.  Returns true, if there was a change in state.
	 * @param {path} path      SVG path
	 * @param {bool} energized energized state
	 */
	EnergizePath(path, energized) {
		if (path && path.data('energized') !== energized) {
			if (energized) {
				path.energize(this.style.energized, this.style.pop);
				if (path.reference('marker-end'))
					path.reference('marker-end').attr({ markerUnits: 'userSpaceOnUse' }).energize(this.style.energized);
				if (path.reference('marker-start'))
					path.reference('marker-start').attr({ markerUnits: 'userSpaceOnUse' }).energize(this.style.energized);
			} else {
				path.dead(this.style.dead);
				if (path.reference('marker-end'))
					path.reference('marker-end').attr({ markerUnits: 'userSpaceOnUse' }).dead(this.style.dead);
				if (path.reference('marker-start'))
					path.reference('marker-start').attr({ markerUnits: 'userSpaceOnUse' }).dead(this.style.dead);
			}
			path.data('energized', energized);
			return true;
		}
		return false;
	},

	/**
	 * The node has been energized, following energizing it's connector, via the state equations.
	 * Use this fuction to energize parts of the shape of the widget.
	 * @param {node} node node.dir,node.id,node.index
	 */
	EnergizeNode(node) {
		console.log('energize', node);
		// override me bro
	},

	/**
	 * Energize the connector path, and then invoce EnergizeNode on each end
	 */
	EnergizeNodes() {
	//	console.log('energizenodes');
		let view;
		let node;
		// console.log(this.model.getName(), this.model._conn_paths);
		for (const c in this.model._conn_paths) {
			const path = this.model._conn_paths[c];
			const eq = path.data('eq');
			const energized = this.get_energized_from_eq(eq);
			const power = this.get_power_from_eq(eq);
			if (this.EnergizePath(path, energized)) {
				node = path.data('from');
				if (node) {
					view = this.get_view_from_widget_id(node.id);
					if (view)
						view.EnergizeNode(node, energized);
				}
				node = path.data('to');
				if (node) {
					view = this.get_view_from_widget_id(node.id);
					if (view)
						view.EnergizeNode(node, energized);
				}
			}
		}
	},

	/**
	 * Given a equation, evaluate it.
	 * @param  {string} eq $G1_KW + $G2_KW
	 * @return {int}    debug_mode: 0, nothing.  1=tagnames, 2=truthiness
	 */
	get_energized_from_eq(eq, debug_mode = 0) {
		const EVAL = 0;
		const TAGNAMES = 1;
		// const TRUTHINESS = 2;
		let state;
		// if (!APP._eq_tag_cache)
		// 	APP._eq_tag_cache = {};
		let tag;
		if (typeof eq === 'string' && eq.length > 0) {
			// const terms = eq.match(/\$(\w*)/g);
			const terms = eq.match(/[a-f\d]{24}/ig);
			if (terms) {
				for (const tag_id of terms) {
					tag = APP.models.tags.get(tag_id);
					if (debug_mode === TAGNAMES)
						eq = eq.replace(tag_id, tag.getName());
					else
						eq = eq.replace(tag_id, tag.getState());
				}
				if (debug_mode !== EVAL)
					console.log(eq);
				else
					state = eval(eq);
			}
			return state;
		}
		return eq;
	},
	get_power_from_eq(eq, debug_mode = 0) {
		const EVAL = 0;
		const TAGNAMES = 1;		let state;
		let tag;
		if (typeof eq === 'string' && eq.length > 0) {
			// const terms = eq.match(/\$(\w*)/g);
			const terms = eq.match(/[a-f\d]{24}/ig);
			if (terms) {
				for (const tag_id of terms) {
					tag = APP.models.tags.get(tag_id);
					if (debug_mode === TAGNAMES)
						eq = eq.replace(tag_id, tag.getName());
					else
						eq = eq.replace(tag_id, tag.getState());
				}
				if (debug_mode !== EVAL)
					console.log(eq);
				else
					state = eval(eq);
			}
			return state;
		}
		return eq;
	},

	/**
	 * Generates the connector path
	 * @param  {string} dir1 NEWS
	 * @param  {float} x1   source X
	 * @param  {float} y1   source Y
	 * @param  {string} dir2 NEWS
	 * @param  {float} x2   destination X
	 * @param  {float} y2   destination Y
	 * @return {array}      A path array suitable for .polyline
	 */
	get_connector_path(dir1, x1, y1, dir2, x2, y2) {
		let path = `M${x1},${y1}`;
		let path2 = '';
		const bit = this.connector_padding || 15;
		let quadrant = 1;
		// determine the quadrant that p2 is in, if p1 is the origin
		if (x1 < x2)
			quadrant = (y1 > y2) ? 1 : 4;
		else
			quadrant = (y1 > y2) ? 2 : 3;

		if (x1 === x2)
			return `${path}v${y2 - y1}`;
		if (y1 === y2)
			return `${path}h${x2 - x1}`;

		// add a little $bit length tail coming out of each point
		switch (dir1) { // add a leading tail bit
		case 'N': path += `v${-bit}`; y1 -= bit; break;
		case 'E': path += `h${bit}`; x1 += bit; break;
		case 'W': path += `h${-bit}`; x1 -= bit; break;
		case 'S': path += `v${bit}`; y1 += bit; break;
		}
		switch (dir2) { // add a trailing tail bit
		case 'N': path2 += `L${x2},${y2 - bit}v${bit}`; y2 -= bit; break;
		case 'E': path2 += `L${x2 + bit},${y2}h${-bit}`; x2 += bit; break;
		case 'W': path2 += `L${x2 - bit},${y2}h${bit}`; x2 -= bit; break;
		case 'S': path2 += `L${x2},${y2 + bit}v${-bit}`; y2 += bit; break;
		}

		// Figure the deltas between each point;
		const dx = (x2 - x1);
		const dy = (y2 - y1);
		const dx2 = dx / 2; // half way
		const dy2 = dy / 2; // half way

		// work out the actual path.
		const key = dir1 + dir2 + quadrant;
		switch (key) {
		case 'NN1': path += `v${dy}`; break;
		case 'NN2': path += `v${dy}`; break;
		case 'NN3': path += `h${dx}`; break;
		case 'NN4': path += `h${dx}`; break;
		case 'NE1': path += `v${dy2}h${dx}`; break;
		case 'NE2': path += `v${dy}`; break;
		case 'NE3': path += `h${dx2}v${dy}`; break;
		case 'NE4': path += `h${dx}`; break;
		case 'NW1': path += `v${dy}`; break;
		case 'NW2': path += `v${dy2}h${dx}`; break;
		case 'NW3': path += `h${dx}`; break;
		case 'NW4': path += `h${dx}`; break;
		case 'NS1': path += `v${dy2}h${dx}`; break;
		case 'NS2': path += `v${dy2}h${dx}`; break;
		case 'NS3': path += `h${dx2}v${dy}`; break;
		case 'NS4': path += `h${dx2}v${dy}`; break;
		case 'EN1': path += `h${dx2}v${dy}`; break;
		case 'EN2': path += `v${dy}`; break;
		case 'EN3': path += `v${dy2}h${dx}`; break;
		case 'EN4': path += `h${dx}`; break;
		case 'EE1': path += `h${dx}`; break;
		case 'EE2': path += `v${dy}`; break;
		case 'EE3': path += `v${dy}`; break;
		case 'EE4': path += `h${dx}`; break;
		case 'EW1': path += `h${dx2}v${dy}`; break;
		case 'EW2': path += `v${dy2}h${dx}`; break;
		case 'EW3': path += `v${dy2}h${dx}`; break;
		case 'EW4': path += `h${dx2}v${dy}`; break;
		case 'ES1': path += `h${dx}`; break;
		case 'ES2': path += `v${dy2}h${dx}`; break;
		case 'ES3': path += `v${dy}h${dx}`; break;
		case 'ES4': path += `v${dy}h${dx}`; break;
		case 'WN1': path += `v${dy}h${dx}`; break;
		case 'WN2': path += `v${dy}h${dx}`; break;
		case 'WN3': path += `h${dx}`; break;
		case 'WN4': path += `v${dy2}h${dx}`; break;
		case 'WE1': path += `v${dy2}h${dx}`; break;
		case 'WE2': path += `h${dx2}v${dy}`; break;
		case 'WE3': path += `h${dx2}v${dy}`; break;
		case 'WE4': path += `v${dy2}h${dx}`; break;
		case 'WW1': path += `v${dy}`; break;
		case 'WW2': path += `h${dx}`; break;
		case 'WW3': path += `h${dx}`; break;
		case 'WW4': path += `v${dy}`; break;
		case 'WS1': path += `v${dy2}h${dx}`; break;
		case 'WS2': path += `h${dx}`; break;
		case 'WS3': path += `v${dy}`; break;
		case 'WS4': path += `v${dy}`; break;
		case 'SN1': path += `h${dx2}v${dy}`; break;
		case 'SN2': path += `h${dx2}v${dy}`; break;
		case 'SN3': path += `v${dy2}h${dx}`; break;
		case 'SN4': path += `v${dy2}h${dx}`; break;
		case 'SE1': path += `h${dx}`; break;
		case 'SE2': path += `h${dx2}v${dy}`; break;
		case 'SE3': path += `v${dy}`; break;
		case 'SE4': path += `v${dy2}h${dx}`; break;
		case 'SW1': path += `h${dx2}v${dy}`; break;
		case 'SW2': path += `h${dx}`; break;
		case 'SW3': path += `v${dy2}h${dx}`; break;
		case 'SW4': path += `v${dy}`; break;
		case 'SS1': path += `h${dx}`; break;
		case 'SS2': path += `h${dx}`; break;
		case 'SS3': path += `v${dy}`; break;
		case 'SS4': path += `v${dy}`; break;
		}
		path += `${path2}`;
		return path;
	},

	/**
	 * simplifies the path, by combining similar elements.
	 * @param {string} path
	 */
	simplify_connector_path(path) {
		let out = '';
		let X = 0;
		let Y = 0;
		let x = 0;
		let y = 0;
		const parts = path.match(/[MmLlSsQqLlHhVvCcSsQqTtAaZz,]|[\d.-]+/g);
		while (parts.length) {
			const part = parts.shift();
			switch (part) {
			case 'M':
				X = Number(parts.shift());
				parts.shift();
				Y = Number(parts.shift());
				out = `M${X},${Y}`;
				break;
			case 'h':
				if (parts[1] === 'h') {
					x = Number(parts[0]) + Number(parts[2]);
					X += x;
					out += `h${x}`;
					parts.shift();
					parts.shift();
					parts.shift();
				} else {
					x = Number(parts.shift());
					X += x;
					out += `h${x}`;
				}
				break;
			case 'v':
				if (parts[1] === 'v') {
					y = Number(parts[0]) + Number(parts[2]);
					Y += y;
					out += `v${y}`;
					parts.shift();
					parts.shift();
					parts.shift();
				} else {
					y = Number(parts.shift());
					Y += y;
					out += `v${y}`;
				}
				break;
			case 'L':
				x = Number(parts.shift());
				parts.shift();
				y = Number(parts.shift());
				if (Y === y) {
					if (parts[0] === 'h') {
						x += Number(parts[1]);
						parts.shift();
						parts.shift();
					}
					out += `h${x - X}`;
				}
				if (X === x) {
					if (parts[0] === 'v') {
						y += Number(parts[1]);
						parts.shift();
						parts.shift();
					}
					out += `v${y - Y}`;
				}
			}
		}
		return out;
	},

	/**
	 * Given a dir, gives the dir on the opposite direciton
	 * @param  {char} dir NEWS
	 * @return {char}     SWEN
	 */
	get_opposite_dir(dir) {
		const dirs = {
			N: 'S',
			E: 'W',
			S: 'N',
			W: 'E'
		};

		return dirs[dir];
	},


	path: {
		M(x, y) {
			return ` M ${x | 0} ${y | 0}`;
		},
		m(x, y) {
			return ` m ${x | 0} ${y | 0}`;
		},
		L(x, y) {
			return ` L ${x | 0} ${y | 0}`;
		},
		l(x, y) {
			return ` l ${x | 0} ${y | 0}`;
		},
		// Circle.  But instead of using the .circle() raph method, this circle is used to construct paths.
		C(x, y, r) {
			r |= 0;
			return `${this.M((x | 0) + r, (y | 0))}a ${r} ${r} 0 1 0 -${2 * r} 0 ${r} ${r} 0 1 0 ${2 * r} 0`;
			// return "M "+x+" "+y+" m -"+r+", 0 a "+r+","+r+" 0 1,1 ("+r * 2+"),0 a r,r 0 1,1 -("+r * 2+"),0";
		},
		P(cx, cy, r, startAngle, endAngle) {
			const rad = Math.PI / 180;
			const x1 = cx + (r * Math.cos(-startAngle * rad));
			const x2 = cx + (r * Math.cos(-endAngle * rad));
			const y1 = cy + (r * Math.sin(-startAngle * rad));
			const y2 = cy + (r * Math.sin(-endAngle * rad));
			return ['M', cx, cy, 'L', x1, y1, 'A', r, r, 0, +(endAngle - startAngle > 180), 0, x2, y2, 'z'];
		},
		A(cx, cy, r, startAngle, endAngle, clockwise) {
			// startangle: 0=3 o'clock.  endangle, counter clockwise around
			const rad = Math.PI / 180;
			const x1 = cx + (r * Math.cos(-startAngle * rad));
			const x2 = cx + (r * Math.cos(-endAngle * rad));
			const y1 = cy + (r * Math.sin(-startAngle * rad));
			const y2 = cy + (r * Math.sin(-endAngle * rad));

			const thing1 = +(Math.abs(endAngle - startAngle) > 180);
			const thing2 = clockwise ? 1 : 0;

			return ` ${['M', x1, y1, 'A', r, r, 0, thing1, thing2, x2, y2].join(' ')}`;
		}
	},

	/**
	 * Determine how long between pulses.  This is an attempt to make each pulse be "100kw" instead of "1/10th of range_high"
	 * @param  {tag} tag Current "energized" tag
	 * @return {int}       Time in milliseconds
	 */
	get_pulse_time(tag) {
		const percent = tag.getPercent();
		let base = 60;
		if (APP.models.settings.get('pulse_time'))
			base = APP.models.settings.get('pulse_time').get('value');
		if (Number(base) === 120)
			return false;
		let time = 100 * base * (1.1 - percent);
		time = APP.Format.clamp(time, 100, 100000);
		//		console.log(time, base);
		return time;
		//	return (1.1 - tag.getPercent()) * 2000;
	},

	/**
	 * Send a pulse OUT of a node.  If the pulse already exists, pass it as third arg
	 * @param {char} dir   Node dir
	 * @param {int} index Node index
	 * @param {path} pulse (optional) a pulse to be recycled, to avoid creating lots and lots
	 */
	PulseNode(dir, index, pulse) {
		// console.log('pulse', dir, index);
		if (this._is_paused)
			return;
		if (APP.design_time || document.hidden) {
			if (pulse)
				this.PopPulse(pulse);
			return;
		}
		if (document.visibilityState === 'hidden')
			return;
		const path = this.get_path_from_node(dir, index);
		if (!path)
			return;
		const length = path.length();

		// const ease = pulse ? '' : '<';
		const ease = '';

		if (!pulse && APP.current_screen._pulse_cache)
			pulse = APP.current_screen._pulse_cache.pop();
		if (!pulse && APP.current_screen.draw) {
			pulse = APP.current_screen.draw.circle(10).fill('#F00'); // .stroke('black').attr({ 'stroke-width': 3 });
			this._current_pulses.push(pulse);
		}
		if (!pulse)
			return;
		let age = pulse.data('age'); // Pulses might get stuck in a loop, so kill them after a while.
		if (!age) {
			age = 0;
			if (!this._my_pulses)
				this._my_pulses = [];
			this._my_pulses.push(pulse);
		}
		age++;
		pulse.data('age', age);
		path.data('pulse_count', path.data('pulse_count') + 1);
		//		pulse.attr({ r: pulse.attr('r') - 0.1 })4
		if (age > 20) {
			pulse.data('age', 0);
			this.PopPulse(pulse, 0);
			// console.log('death by age');
			return;
		}

		const node = this.model.get('nodes')[dir][index];
		// const ox = this.L + node.x,
		// 	oy = this.T + node.y;
		const speed = 4;
		const destination = this.model.get('connectors')[dir][index];

		pulse.center(node.x + this.L, node.y + this.T); // .scale(1).opacity(1);
		pulse.animate(length * speed, ease).reverse(!destination.source).during((pos, morph, eased) => {
			const p = path.pointAt(eased * length);
			pulse.center(p.x, p.y);
			// console.log(p.x,p.y);
		}).after(() => {
			path.data('pulse_count', path.data('pulse_count') - 1);
			const view = this.get_view_from_widget_id(destination.id);
			if (view)
				view.PulseArrivedAtNode(destination.dir, destination.index, pulse);
		});
	},
	PulseArrivedAtNode(dir, index, pulse) {
		// Override me bro
		this.PulseNode(dir, index, pulse); // bounce it.
	},
	PopPulse(pulse, time = 300) {
		pulse.animate(time, '>').scale(5).opacity(0.0).after(() => {
			pulse.opacity(1).scale(1).move(-10, 10);
			pulse.data('dircache', {});
			pulse.data('age', 0);
			if (APP.current_screen._pulse_cache)
				APP.current_screen._pulse_cache.push(pulse);
			//	delete pulse;
		});
	},
	PopPulses(pulses) {
		for (const p in pulses) {
			const pulse = pulses[p];
			pulse.stop();
			this.PopPulse(pulse);
			// pulse.animate(500).radius(0).opacity(0);
			// APP.current_screen._pulse_cache.push(pulse);
		}
	}

});
