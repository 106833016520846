module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'details\'></div>\n\n<div id=\'pollersettings\'></div>\n<h2>Notes</h2>\n<textarea id=\'notes\' class=\'field full\'>'+
((__t=(notes))==null?'':__t)+
'</textarea>\n\n<h2>Network Devices</h2>\nThe network scan has found these devices on the network compatible with the\n'+
((__t=(protocol))==null?'':__t)+
' protocol (if any).\n	<br />\n	<br />\n	<div id=\'network_devices\'></div>\n';
}
return __p;
};
