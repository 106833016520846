module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'panel\' id=\'header\'>\n	<div class=\'filter command click\' id=\'alarm\'>\n		'+
((__t=(APP.Tools.icon('alarm')))==null?'':__t)+
'\n		'+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
'\n	</div>\n	<div class=\'filter command click\' id=\'warning\'>\n		'+
((__t=(APP.Tools.icon('warning')))==null?'':__t)+
'\n		'+
((__t=(APP.Lang('Warning')))==null?'':__t)+
'\n	</div>\n	<div class=\'filter command click\' id=\'stale\'>\n		'+
((__t=(APP.Tools.icon('stale')))==null?'':__t)+
' Stale</div>\n	<div class=\'filter command click\' id=\'ack\'>\n		'+
((__t=(APP.Tools.icon('bell-slash')))==null?'':__t)+
' Ack</div>\n	<h1>'+
((__t=(SETTINGS.name))==null?'':__t)+
' Alarm List</h1>\n</div>\n<div class=\'panel\' id=\'page_box\'>\n	<table>\n		<thead>\n			<tr>\n				<th>Acknowledge</th>\n				<th>Tag</th>\n				<th>Value</th>\n				<th>Alarm Time</th>\n				<th>Name</th>\n				<th>Device</th>\n				<th colspan=2>'+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
'/'+
((__t=(APP.Lang('Warning')))==null?'':__t)+
' count</th>\n			</tr>\n		</thead>\n		<tbody id=\'list\'>\n		</tbody>\n	</table>\n</div>\n<div class=\'panel\' id=\'footer\'>\n	<div href="#" class=\'btn command\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n	<div href="#" class=\'btn command pull-right\' id=\'events\'>\n		'+
((__t=(APP.Tools.icon("events")))==null?'':__t)+
' Events</div>\n</div>\n<div id=\'RT\'>\n	<div id=\'TOOLTIP_BOX\'></div>\n</div>\n';
}
return __p;
};
