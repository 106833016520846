module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var lat=lat||"30.164";
var long=long||"-81.325";
var zoom=zoom||8;
  var views = {
		"temperature":"Temperature",
		"apparent_temperature":"Feels Like",
		"radar":"Weather Radar",
		"precipitation_intensity":"Rain Intensity",
		"wind_speed":"Wind Speed",
		"wind_gust":"Wind Gusts",
		"dew_point":"Dew Point",
		"uv_index":"UV Index",
		"sea_level_pressure":"Pressure",
		"ozone_du":"Ozone"
	};
var view = view || "radar";
var controls = controls || "false";

__p+='\n\n<table>\n  <tr>\n    <th>Lat/Long</th>\n    <td>\n      <input class=\'field\' type=\'text\' id=\'lat\' value=\''+
((__t=(lat))==null?'':__t)+
'\'>/\n      <input class=\'field\' type=\'text\' id=\'long\' value=\''+
((__t=(long))==null?'':__t)+
'\'>\n    </td>\n  </tr>\n  <tr>\n    <th>Zoom</th>\n    <td>\n      <input class=\'field\' type=\'range\' min=\'2\' max=\'11\' id=\'zoom\' value=\''+
((__t=(zoom))==null?'':__t)+
'\'>\n    </td>\n  </tr>\n  <tr>\n    <th>Default view</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("view", views, "view", "field")))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Controls</th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("controls", {true:"Yes",false:"No"}, "controls", "field")))==null?'':__t)+
'\n    </td>\n  </tr>\n</table>\n';
}
return __p;
};
