module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Style</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("style",["default","transparent"],style,"field")))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n\n'+
((__t=(APP.UI.tabs("mode",mode,{screen:"Screen",file:"File",url:"URL"}) ))==null?'':__t)+
'\n<div class=\'tabcontent\' data-for="mode" id=\'screen\'>\n	<!-- '+
((__t=(APP.UI.select_from_collection("go_to_screen", APP.models.screens, "_id", "name",go_to_screen) ))==null?'':__t)+
' -->\n	'+
((__t=(APP.UI.chooser("go_to_screen", "screens", go_to_screen)))==null?'':__t)+
'\n	<input type=\'button\' value=\'+ Create New Screen\' id=\'newscreen\' onclick="(function() {\n							var name = prompt(\'Name the new screen\');\n							if (name) {\n								APP.models.screens.add({\n									name:name,\n									master_id: APP.models.screens.where({\'kind\':\'master\'}).shift().id\n								})\n							}\n						})()">\n\n</div>\n\n<div class=\'tabcontent\' data-for="mode" id=\'file\'>\n	'+
((__t=(APP.UI.chooser("go_to_file", "files", go_to_file)))==null?'':__t)+
'\n</div>\n<div class=\'tabcontent\' data-for="mode" id=\'url\'>\n	'+
((__t=(APP.UI.input('go_to_url',go_to_url,"text","http://...")))==null?'':__t)+
'\n</div>\n\n<table>\n	<tr>\n		<th>Prefix Override</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'prefix\' placeholder=\'default:screen name\' value=\''+
((__t=(prefix))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'label\' placeholder=\'default:screen name\' value=\''+
((__t=(label))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Align</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("align",["left","center","right"],align)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Icon</th>\n		<td>\n			'+
((__t=(APP.UI.fontawesome("glyph",glyph)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			'+
((__t=(APP.Lang('Warning')))==null?'':__t)+
' State\n		</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("warning_tag","tags",warning_tag)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			'+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
' State\n		</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("alarm_tag","tags",alarm_tag)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'disabled\' value=\'true\' '+
((__t=(disabled? "checked": ""))==null?'':__t)+
' class=\'field live\'> Disable\n		</td>\n	</tr>\n	<!-- <tr>\n				<th>Link (optional)</th>\n				<td>\n					<input class=\'field\' type=\'text\' id=\'link\' value=\''+
((__t=(link))==null?'':__t)+
'\' placeholder=\'http://...\'>\n				</td>\n			</tr> -->\n</table>\n';
}
return __p;
};
