module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var title=title||"";
var display=display||"";
var d_id=d_id||"";

__p+='\n\n	<table>\n		<tr>\n			<th>Title</th>\n			<td>\n				<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Label</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("label",["tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Cell Size</th>\n			<td>\n				'+
((__t=(APP.UI.slider("flex_basis", flex_basis, 0,100)))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Filter by Type</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("filter", {"":"All","number":"Number","boolean":"Boolean","string":"String","date":"Date"},filter, "field") ))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>Limit by '+
((__t=(APP.Lang('Alarm')))==null?'':__t)+
' Levels</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("limit",{"":"All Tags", "alarm":"Tags with "+APP.Lang('Alarm')+" Levels", "warning":"Tags with "+APP.Lang('Warning')+"  Levels","alarmwarning":"Tags with "+APP.Lang('Alarm')+" or "+APP.Lang('Warning')+" levels", "normal":"Tags with Only "+APP.Lang('Normal')+" Levels"}, limit, "field")))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>\n				Show Normal (green) State\n			</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("show_normal",{"never":"Never","on":"Only when Active", "always":"Always"}, show_normal)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n\n	'+
((__t=(APP.UI.tabs("mode",mode,{bydev:"Device",bydevlib:"Device Library",bytag:"Tags"}) ))==null?'':__t)+
'\n\n		<div class=\'tabcontent\' data-for="mode" id=\'bytag\'>\n			'+
((__t=(APP.UI.chooser("t_ids", "tags", t_ids,"multi")))==null?'':__t)+
'\n		</div>\n\n		<div class=\'tabcontent\' data-for="mode" id=\'bydev\'>\n			'+
((__t=(APP.UI.chooser("d_id", "devices", d_id)))==null?'':__t)+
'\n		</div>\n\n		<div class=\'tabcontent\' data-for="mode" id=\'bydevlib\'>\n			'+
((__t=(APP.UI.chooser("dl_id", "device_library", dl_id)))==null?'':__t)+
'\n		</div>\n';
}
return __p;
};
