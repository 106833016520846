module.exports = DEF.TG.Details.extend({
	template: require('./device_library.html'),
	templateContext() {
		const blocks = this.model.get('blocks') || [];
		return {
			blocks,
		};
	},
	DoExtraField(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.value;
		const field = e.currentTarget.dataset.field;
		const index = e.currentTarget.dataset.id;
		console.log('>', id, val, field, index);
		const blocks = this.model.get('blocks');
		const block = blocks[index] || {};
		block[field] = val | 0;
		this.model.set('blocks', blocks);
		this.render();
	},
	DoAction(e) {
		const id = e.currentTarget.id;
		console.log(id);
		switch (id) {
		case 'addblock':
			const blocks = this.model.get('blocks') || [];
			blocks.push({ address: 30001, length: 20 });
			this.model.set('blocks', blocks);
			this.render();
			break;
		}
	},
});
