module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=(icon))==null?'':__t)+
'\n  <meter min=0 max=1 value=\''+
((__t=(percent))==null?'':__t)+
'\' title=\''+
((__t=(tag_name))==null?'':__t)+
' '+
((__t=(value))==null?'':__t)+
'\' low=0.8 high=0.9 optimum=0.2>\n';
}
return __p;
};
