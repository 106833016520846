module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<hr />\n<table>\n	<tr>\n		<th>Sender Address</th>\n		<td><input class=\'field\' placeholder=\'192.168.0.0\' id=\'connection.sender\' value=\''+
((__t=(connection.sender))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>APDU Timeout (ms)</th>\n		<td><input class=\'field\' placeholder=\'6000\' id=\'connection.timeout\' value=\''+
((__t=(connection.timeout))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n</table>';
}
return __p;
};
