
DEF.TG.LinkView = Backbone.Marionette.View.extend({
	id       : 'LINK',
	className: 'PAGE',
	template : require('../templates/link.html'),
	templateContext() {
		const rs = { url: this.get_url(this.options.url) };
		return rs;
	},
	ui: {
		exit    : '#exit',
		download: '#download'
	},
	events: {
		'click @ui.exit'    : 'Exit',
		'click @ui.download': 'Download'
	},

	/**
	 * Convert a File record into a URL
	 *
	 * @param {String} url a URL, or a name of a record in the Files database
	 */
	get_url(url) {
		let file;
		file = APP.models.files.get(this.options.url);
		if (file) {
			url = file.get('path');
		} else {
			file = APP.models.files.findWhere({ name: this.options.url });
			if (file)
				url = file.get('path');
		}
		console.log('url', url);
		// if (url.substr(0, 4) !== 'http')
		//	url = `${SETTINGS.posturl}/${url}`;
		return url;
	},
	Download() {
		window.location.href = this.get_url(this.options.url);
	},
	Exit() {
		APP.Route('#');
	}
});
