module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>\n	'+
((__t=(name))==null?'':__t)+
'\n</h1>\n';
 if (!id) { 
__p+='\n<div id=\'new_record\'>\n	'+
((__t=(APP.Tools.icon("plus")))==null?'':__t)+
' Create New User</div>\n';
 } 
__p+='\n\n<table>\n	<tr>\n		<th>User</th>\n		<td>\n			<input type=\'text\' class=\'field\' id=\'name\' value=\''+
((__t=(name))==null?'':__t)+
'\' placeholder=\'Name\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Email</th>\n		<td>\n			<input type=\'email\' class=\'field\' id=\'email\' value=\''+
((__t=(email))==null?'':__t)+
'\' placeholder=\'Email\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Phone Number</th>\n		<td>\n			<input type=\'phone\' class=\'field\' id=\'phone\' value=\''+
((__t=(phone))==null?'':__t)+
'\' placeholder=\'###-###-####\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Image</th>\n		<td>\n			<input type=\'text\' class=\'field\' id=\'image_url\' value=\''+
((__t=(image_url))==null?'':__t)+
'\' placeholder="URL or icon">\n			'+
((__t=(APP.UI.fontawesome("icon2",image_url,"image_url")))==null?'':__t)+
'\n\n		</td>\n	</tr>\n</table>\n\n<h2>Password</h2>\n<table>\n	<tr>\n		<th>Password</th>\n		<td>\n			<input type=\'text\' class=\'crypt\' id=\'password\' value=\'\' placeholder=\'(Encrypted)\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Password Hint</th>\n		<td>\n			<input type=\'text\' class=\'field\' id=\'password_hint\' value=\''+
((__t=(password_hint))==null?'':__t)+
'\'\n				placeholder=\'Displayed on login page\'>\n		</td>\n	</tr>\n</table>\n\n<h2>Custom Homescreen</h2>\n<table>\n	<tr>\n		<th>Home on Computer</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("home", APP.models.screens, "name", "name", home)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Home on Mobile</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("mobilehome", APP.models.screens, "name", "name", mobilehome)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n\n';
 if (id) { 
__p+='\n<h2>Commands</h2>\n<button class=\'btn action\' id=\'logout\'>\n	'+
((__t=(APP.Tools.icon('sign-out')))==null?'':__t)+
' Log This User Out</button>\n<button class=\'btn action\' id=\'testnotify\'>\n	'+
((__t=(APP.Tools.icon('phone')))==null?'':__t)+
' Send Test Notification</button>\n<button class=\'btn action\' id=\'sendlink\'>\n	'+
((__t=(APP.Tools.icon('envelope')))==null?'':__t)+
' Send Login Info</button>\n<div class=\'message\' id=\'message\'></div>\n\n<h2>Stats</h2>\n\n<table>\n	<tr>\n		<th>Last Login</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(last_login)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Last Visit</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(last_visit)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Page Loads</th>\n		<td>\n			'+
((__t=(pageloads))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Page Views</th>\n		<td>\n			'+
((__t=(pageviews))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Notifications</th>\n		<td>\n			'+
((__t=(notifications))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n\n\n<h2>Permissions</h2>\n<dl>\n	<dt>\n		<input type=\'checkbox\' '+
((__t=(perm.editor? "checked": ""))==null?'':__t)+
' id=\'perm.editor\' class=\'perm field\'>\n		'+
((__t=(APP.Tools.icon("editor")))==null?'':__t)+
' Edit Screens\n	</dt>\n	<dd>\n		User can edit widgets and screens\n	</dd>\n	<dt>\n		<input type=\'checkbox\' '+
((__t=(perm.settings? "checked": ""))==null?'':__t)+
' id=\'perm.settings\' class=\'perm field\'>\n		'+
((__t=(APP.Tools.icon("settings")))==null?'':__t)+
' Edit Settings\n	</dt>\n	<dd>\n		User can create tags and devices on the Settings screens.\n	</dd>\n	<dt>\n		<input type=\'checkbox\' '+
((__t=(perm.notify? "checked": ""))==null?'':__t)+
' id=\'perm.notify\' class=\'perm field\'>\n		'+
((__t=(APP.Tools.icon("notify")))==null?'':__t)+
' Recieve Notifications\n	</dt>\n	<dd>\n		User can receive notifications on his or her mobile device (or email)\n	</dd>\n	<dt>\n		<input type=\'checkbox\' '+
((__t=(perm.dataexport? "checked": ""))==null?'':__t)+
' id=\'perm.dataexport\' class=\'perm field\'>\n		'+
((__t=(APP.Tools.icon("dataexport")))==null?'':__t)+
' Export Data\n	</dt>\n	<dd>\n		User can export data from any tag.\n	</dd>\n	<dt>\n		<input type=\'checkbox\' '+
((__t=(perm.perms? "checked": ""))==null?'':__t)+
' id=\'perm.perms\' class=\'perm field\'>\n		'+
((__t=(APP.Tools.icon("perms")))==null?'':__t)+
' Grant Permissions\n	</dt>\n	<dd>\n		User can set other user\'s permissions\n	</dd>\n\n</dl>\n\n';
  } 
__p+='\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
' database\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
' delete\n	</div>\n\n	<div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
' duplicate\n	</div>\n</div>\n';
}
return __p;
};
