DEF.events = {};

DEF.events.Initialize = function init(callback, constraints) {
	const options = {
		model: DEF.events.Model,
		url  : `${SETTINGS.dburl}/events`
		// comparator(m) {
		// 	return -m.get('datetime');
		// },
	};
	return APP.InitializeModels('events', options, callback, constraints);
};

DEF.events.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'events',
	defaults       : {
		event      : '',
		datetime   : false,
		tag_id     : false,
		alarm_state: 'normal'
	},

	/**
	 Return the event field, with special HTML added for effect
	 * */
	getEvent() {
		let event = this.get('event');
		let matches2 = false;
		let tag;
		const matches = event.match(/\[(\w*?):(.*?)\]/g);
		if (matches)
			for (const m in matches) {
				matches2 = matches[m].match(/\[(\w*?):(.*?)\]/);
				if (matches2) {
					const field = matches2[1];
					let id = matches2[2]; // may hold value
					let replace = false;

					switch (field) {
					case 'value':
						if (id == 'true')
							id = true;
						if (id == 'false')
							id = false;
						tag = APP.models.tags.get(this.get('tag_id'));
						if (tag)
							replace = tag.getValue(id);
						else
							replace = id;
						event = event.replace(`[${field}:${id}]`, `<span class='name'>${replace}</span>`);


						break;
					default:
						if (APP.models[field] && APP.models[field].get(id)) {
							tag = APP.models[field].get(id);
							if (tag) {
								replace = tag.getName();
								event = event.replace(`[${field}:${id}]`, `<span title='${tag.getUp('prefix')} ${tag.getUp('name')}' class='name'>${replace}</span>`);
							}
						}
					}
				}
			}

		event = event.replace(/(alarm|warning|normal)/g, '<span class="$1">$1</span>');
		event = event.replace('>alarm<', `>${APP.Lang('alarm')}<`);
		event = event.replace('>warning<', `>${APP.Lang('warning')}<`);
		event = event.replace('>normal<', `>${APP.Lang('normal')}<`);
		return event;
	},
	getName() {
		return this.get('event');
	}
});


DEF.events_archive = {};
DEF.events_archive.Initialize = function init(callback, constraints) {
	const options = {
		model: DEF.events.Model,
		url  : `${SETTINGS.dburl}/events_archive`
		// comparator: 'datetime',
	};
	return APP.InitializeModels('events_archive', options, callback, constraints);
};

DEF.events_archive.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'events_archive',
	defaults       : {
		event      : '',
		datetime   : false,
		tag_id     : false,
		alarm_state: 'normal'
	}
});
