module.exports = DEF.TG.Details.extend({
	template: require('./device.html'),
	templateContext() {
		let connection = { // defaults
			host            : '1127.0.0.1',
			port            : 502,
			timeout         : 10,
			reconnectTimeout: 60,
			unitId          : 1,
			addressOffset   : 0,
		};
		connection = _.extend(connection, this.model.get('connection'));
		const blocks = this.model.getUp('blocks') || [];
		const blocks_status = this.model.get('blocks_status') || [];
		const modblocks = [];
		for (const b in blocks) {
			const block = blocks[b];

			let block_state = 'FAIL';
			if (blocks_status[b]) {
				block_state = blocks_status[b].state;
				if ((Date.now() - blocks_status[b].datetime) > this.model.getUp('polling_rate') * 1000)
					block_state = 'FAIL';
			}
			if (!this.model.get('online'))
				block_state = 'FAIL';
			if (!this.model.get('enabled'))
				block_state = '';


			let address = parseInt(block.address, 10);
			const address_string = address.toString().padStart(5, '0');
			const type = ` -t ${address_string[0]}`;
			address = parseInt(address_string.slice(1), 10);

			let cmd = 'modpoll ';
			cmd += ` ${connection.host}`;
			if (parseInt(connection.port, 10) !== 502)
				cmd += ` -p ${connection.port}`;

			if (parseInt(connection.unitId) !== 1)
				cmd += ` -a ${connection.unitId}`;
			cmd += ` -c ${block.length}`;
			cmd += ` -r ${parseInt(connection.addressOffset) + parseInt(address)}`;
			cmd += ` ${type}`;
			let icon = '';

			switch (block_state) {
				case 'SUCCESS':
					icon = 'circle-check';
					break;
				case 'FAIL':
					icon = 'circle-xmark';
					break;
				}
	
			modblocks[b] = {
				modpoll    : cmd,
				address    : block.address,
				length     : block.length,
				state      : block_state,
				status     : blocks_status[b] && blocks_status[b].status,
				datetime   : blocks_status[b] && blocks_status[b].datetime,
				status_icon: icon,
			};
		}
		return {
			connection,
			modblocks,
		};
	},
	DoExtraField(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.value;
		const field = e.currentTarget.dataset.field;
		const index = e.currentTarget.dataset.id;
		console.log('>', id, val, field, index);
		const blocks = this.model.get('blocks');
		const block = blocks[index] || {};
		block[field] = val;
		this.model.set('blocks', blocks);
		this.render();
	},
	DoAction(e) {
		const id = e.currentTarget.id;
		console.log(id);
		switch (id) {
		case 'addblock':
			const blocks = this.model.get('blocks') || [];
			blocks.push({ address: 30001, length: 20 });
			this.model.set('blocks', blocks);
			this.render();
			break;
		case 'defaults':
			if (confirm('Are you sure?  This will erase these blocks and install the defaults.'))
				this.model.set('blocks', '');
		}
	},
});
