module.exports = DEF.TG.Details.extend({
	template: require('./device.html'),
	templateContext() {
		let connection = { // defaults
			host            : '127.0.0.1',
			port            : 502,
			timeout         : 10,
			reconnectTimeout: 60,
			unitId          : 1,
			addressOffset   : 0
		};
		connection = _.extend(connection, this.model.get('connection'));
		const blocks = this.model.getUp('blocks') || [];
		const blocks_status = this.model.get('blocks_status') || [];
		const modblocks = [];
		for (const b in blocks) {
			const block = blocks[b];

			let block_state = 'FAIL';
			if (blocks_status[b])
				if (blocks_status[b].state === 'SUCCESS')
					block_state = 'SUCCESS';
			// if ((Date.now() - blocks_status[b].datetime) > this.model.getUp('polling_rate') * 5000)
			// 	block_state = 'FAIL';

			if (!this.model.get('online'))
				block_state = 'FAIL';
			if (!this.model.get('enabled'))
				block_state = '';


			let address = parseInt(block.address, 10);
			const address_string = address.toString().padStart(5, '0');
			const type = ` -t${address_string[0]}`;
			address = parseInt(address_string.slice(1), 10);

			let cmd = 'modpoll ';
			if (connection.type == 'serial') {
				cmd += ` ${connection.host}`;
				cmd += ` -b${connection.baudRate}`;
				cmd += ` -d${connection.dataBits}`;
				cmd += ` -p${connection.parity}`;
				cmd += ` -s${connection.stopBits}`;
				if (parseInt(connection.unitId) !== 1)
					cmd += ` -a${connection.unitId}`;
			} else {
				cmd += ` ${connection.host}`;
				if (parseInt(connection.port, 10) !== 502)
					cmd += ` -p${connection.port}`;
			}

			if (parseInt(connection.unitId) !== 1)
				cmd += ` -a ${connection.unitId}`;
			cmd += ' -1'; // only poll once
			cmd += ` ${type}`;
			cmd += ` -c${block.length}`;
			cmd += ` -r${parseInt(connection.addressOffset) + parseInt(address)}`;
			let icon = '';

			switch (block_state) {
			case 'SUCCESS':
				icon = 'circle-check';
				break;
			case 'FAIL':
				icon = 'circle-xmark';
				break;
			}

			modblocks[b] = {
				modpoll    : cmd,
				address    : block.address,
				length     : block.length,
				state      : block_state,
				status     : blocks_status[b] && (blocks_status[b].status || blocks_status[b].state),
				datetime   : blocks_status[b] && blocks_status[b].datetime,
				status_icon: icon
			};
		}
		// console.log(modblocks);

		return {
			connection,
			modblocks
		};
	},
	// DoExtraField(e) {
	// 	console.log(id, val);
	// 	const id = e.currentTarget.id;
	// 	const val = e.currentTarget.value;
	// 	const field = e.currentTarget.dataset.field;
	// 	const index = e.currentTarget.dataset.id;
	// 	// console.log('>', id, val, field, index);
	// 	// const blocks = this.model.get('blocks');
	// 	// const block = blocks[index] || {};
	// 	// block[field] = val;
	// 	// this.model.set('blocks', blocks);
	// 	// this.render();
	// },
	DoAction(e) {
		const id = e.currentTarget.id;
		e.currentTarget.select();
		document.execCommand('copy');
		// e.currentTarget.setSelectionRange(0, 0);
	}

});
