module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' id=\'title\' class=\'field\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single","draw")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Aggregation</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("aggregate",["avg","min","max"],aggregate,"field refresh")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Division</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("division",["days","weeks","months"],division,"field refresh")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Count</th>\n		<td>\n			'+
((__t=(APP.UI.input("count", count,"number")))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>';
}
return __p;
};
