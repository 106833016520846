module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.picklabel('label',label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Shape</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("shape",{circle:"Circle",square:"Square"},shape)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Liquid</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("liquid",{tag:"Tag Color",water:"Water",diesel:"Diesel",oil:"Oil", lng:"LNG"},liquid)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Housing Thickness</th>\n		<td>\n			'+
((__t=(APP.UI.slider("thickness", thickness, 0, 20,2)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Rise and Fall Speed</th>\n		<td>\n			'+
((__t=(APP.UI.slider("rise", rise, 50, 2000)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Frequency</th>\n		<td>\n			'+
((__t=(APP.UI.slider("freq", freq, 1, 20)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Amplitude</th>\n		<td>\n			'+
((__t=(APP.UI.slider("amp", amp, 0, 10)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Speed</th>\n		<td>\n			'+
((__t=(APP.UI.slider("speed", speed, 0, 98)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Bubbles</th>\n		<td>\n			'+
((__t=(APP.UI.slider("bubbles", bubbles, 0, 100, 1)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.UI.checkbox("alarms", alarms, "Alarm Levels")))==null?'':__t)+
'\n\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
