module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'widget_edit_menu\'>\n	<div id=\'exit\' class=\'command\'>\n		'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' <span class=\'minwidth1200\'>EXIT</span>\n	</div>\n<div id=\'toolbar\'>\n	<div id=\'align\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'align\'>align</div>\n			<div class=\'tools\' id=\'align\'>\n				<div class=\'tool\' id=\'top\'>top</div>\n				<div class=\'tool\' id=\'bottom\'>bottom</div>\n				<div class=\'tool\' id=\'left\'>left</div>\n				<div class=\'tool\' id=\'right\'>right</div>\n			</div>\n	</div>\n	<div id=\'resize\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'resize\'>resize</div>\n			<div class=\'tools\'>\n				<div class=\'options\'>Min</div>\n				<div class=\'tool\' id=\'min_width\'>width</div>\n				<div class=\'tool\' id=\'min_height\'>height</div>\n				<div class=\'options\'>Max</div>\n				<div class=\'tool\' id=\'max_width\'>width</div>\n				<div class=\'tool\' id=\'max_height\'>height</div>\n			</div>\n	</div>\n	<div id=\'stack\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'stack\'>stack</div>\n			<div class=\'tools\'>\n				<div class=\'tool\' id=\'top\'>top</div>\n				<div class=\'tool\' id=\'bottom\'>bottom</div>\n				<div class=\'tool\' id=\'left\'>left</div>\n				<div class=\'tool\' id=\'right\'>right</div>\n				<div class=\' options\'><input type=\'checkbox\' id=\'stack_include_padding\' '+
((__t=(APP.Tools.store("dt","stack_include_padding")>0?"checked":""))==null?'':__t)+
'>Include Padding</input></div>\n			</div>\n	</div>\n	<div id=\'distribute\' class=\'toolset\'>\n			<div class=\'label button\' data-set=\'distribute\'>distribute</div>\n			<div class=\'tools\'>\n				<div class=\'tool\' id=\'horizontal\'>horizontal</div>\n				<div class=\'tool\' id=\'vertical\'>vertical</div>\n			</div>\n	</div>\n</div>\n<div id=\'commands\' class=\'minwidth1200\'>\n		<button class=\'button command\' id=\'delete\'>\n			'+
((__t=(APP.Tools.icon('delete')))==null?'':__t)+
' delete</button>\n		<button class=\'button command\' id=\'duplicate\'>\n			'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
' duplicate</button>\n	</div>\n	<div class=\'command\' id=\'widgets\'>\n		'+
((__t=(APP.Tools.icon("widgets")))==null?'':__t)+
' <span class=\'minwidth1200\'>WIDGETS</span>\n	</div>\n	';
 if (APP.USER.Can("settings")) { 
__p+='\n		<div id=\'settings\' class=\'command\'>\n			'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
'\n		</div>\n	';
 } 
__p+='\n</div>\n';
}
return __p;
};
