module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","symbol","prefix"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Animation</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("animation",{"none":"None","linear":"Linear","bounce":"Bounce","dequint":"ease"},animation)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n<table>\n	<tr>\n		<th class=\'pull-center\'>\n			Scale\n		</th>\n		<th class=\'pull-center\'>\n			Needle\n		</th>\n	</tr>\n	<tr>\n		<td>\n			<input type="range" min=0 max=360 step=1 value=\''+
((__t=(startangle))==null?'':__t)+
'\' id=\'startangle\' class=\'field live\' list=\'tickmarks\'>\n		</td>\n		<td>\n			<input type="range" min=0 max=100 step=1 value=\''+
((__t=(needlestart))==null?'':__t)+
'\' id=\'needlestart\' class=\'field live\' list=\'needlemarks\'>\n		</td>\n	</tr>\n	<tr>\n		<td>\n			<input type="range" min=0 max=360 step=1 value=\''+
((__t=(ticksangle))==null?'':__t)+
'\' id=\'ticksangle\' class=\'field live\' list=\'tickmarks\'>\n		</td>\n		<td>\n			<input type="range" min=0 max=100 step=1 value=\''+
((__t=(needleend))==null?'':__t)+
'\' id=\'needleend\' class=\'field live\' list=\'needlemarks\'>\n		</td>\n	</tr>\n</table>\n<datalist id=tickmarks>\n	<option>45</option>\n	<option>90</option>\n	<option>180</option>\n	<option>225</option>\n	<option>270</option>\n</datalist>\n<datalist id=needlemarks>\n	<option>0</option>\n	<option>20</option>\n	<option>50</option>\n	<option>80</option>\n	<option>100</option>\n</datalist>\n\n<fieldset>\n	<legend>Options</legend>\n	<table>\n		<tr>\n			<td>\n				'+
((__t=(APP.UI.checkbox("border", border, "Border")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("alarms", alarms, "Alarms")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("stats", stats, "Stats")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("value", value, "Value")))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<td>\n				'+
((__t=(APP.UI.checkbox("ticks", ticks, "Ticks")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("ring", ring, "Ring")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("numbers", numbers, "Numbers")))==null?'':__t)+
'\n			</td>\n			<td>\n				'+
((__t=(APP.UI.checkbox("hub", hub, "Hub")))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n\n</fieldset>\n\n<fieldset>\n	<legend>Experimental</legend>\n	<table>\n		<tr>\n			<th>Arc Wdith</th>\n			<td>\n				<input type="range" min=0 max=50 step=1 value=\''+
((__t=(progress))==null?'':__t)+
'\' id=\'progress\' class=\'field live\' list=\'tickmarks\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Ticks</th>\n			<td>\n				'+
((__t=(APP.UI.input("majorticks",majorticks,'text','0,10,20,30...','comma separated list of numbers', "field live")))==null?'':__t)+
'\n			</td>\n		</tr>\n		<tr>\n			<th>\n				'+
((__t=(APP.UI.checkbox("pf", pf, "  ")))==null?'':__t)+
'\n			</th>\n			<td>\n				Enable "Power Factor" mode\n			</td>\n		</tr>\n	</table>\n</fieldset>\n';
}
return __p;
};
