DEF.network = {};

DEF.network.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.network.Model,
		url       : `${SETTINGS.dburl}/network`,
		comparator: 'ip',
	};
	return APP.InitializeModels('network', options, callback, constraints);
};

DEF.network.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'network',
	defaults       : {
		name       : '',
		status     : 'offline',
		status_time: 0,
		machine    : '', // which machine is part of this network.
		icon       : 'question',
		vendor     : '', // MAC lookup
		mac        : '',
		ip         : '',
		openPorts  : false,
		osNmap     : '',
		protocol   : '',
		created    : Date.now(),
	},
	db_columns    : ['ip', 'vendor', 'mac', 'protocol', 'status', 'status_time'],
	db_filters    : ['status', 'vendor', 'protocol'],
	db_spreadsheet: ['name', 'icon', 'vendor', 'mac', 'ip'],
	initialize() {
	//	if (this.get('icon') === 'question')
		// this.GuessDevice();

		// if (!this.get('protocol'))
		// 	this.GuessProtocol();
	},
	known_ports: {
		502 : 'modbustcp',
		2455: 'Wago',
		515 : 'printer',
	},
	// Substring of "vendor", mapped to a .known_device
	known_vendors: {
		'Belkin International': 'Wemo',
		'ComAp s.r.o'         : 'modbus',
		Lantronix             : 'NET485',
		Acromag               : 'modbus',
		Apple                 : 'Apple',
		Dell                  : 'computer',
		Shenzhen              : 'webcam', // FOSCam
		Vivotek               : 'webcam',
		'Philips Lighting'    : 'Hue',
		Cisco                 : 'router',
		Netgear               : 'router',
		Konica                : 'printer',
		Canon                 : 'printer',
		Ubiquiti              : 'webcam',
		VMware                : 'computer',
		Hewlett               : 'computer',
		Microsoft             : 'Windows',
		Computer              : 'computer',
		Avaya                 : 'phone',
		Rockwell              : 'plc',
		ABB                   : 'breaker',
		Advantech             : 'plc',
		'Red Lion'            : 'hmi',
		Beckhoff              : 'generator',
	},
	// first few chars of MAC, mapped to a .known_device
	known_mac: {
		'70:EE:50': 'Netatmo',
		'18:B4:30': 'Nest',
		'78:F2:9E': 'Telegauge', // PEGATRON CORPORATION
	},
	// Subtring of OS mapped to a .known_device
	known_os: {
		AirPort: 'router',
		Cisco  : 'router',
		Apple  : 'Apple',
		Windows: 'Windows',
		Linux  : 'Linux',
		Brother: 'printer',
		printer: 'printer',
		VMware : 'computer',
		Server : 'server',
		Phone  : 'phone',
	},

	// the "known" lists above are mapped this list.  Capitalized items are brand
	// names.  Lowercase are generics.
	known_devices: {
		Telegauge: {
			icon    : 'server',
			protocol: 'telegauge',
		},
		Apple: {
			icon: 'apple',
		},
		Windows: {
			icon: 'windows',
		},
		Linux: {
			icon: 'linux',
		},
		modbus: {
			protocol: 'modbus',
			icon    : 'tachometer',
		},
		Hue: {
			icon: 'lightbulb',
		},
		NET485: {
			protocol: 'modbustcp',
			icon    : 'bolt',
		},
		Nest: {
			icon    : 'thermometer-half',
			protocol: 'nest',
		},
		Netatmo: {
			protocol: 'netatmo',
			icon    : 'cloud',
		},
		router: {
			icon: 'sitemap',
		},
		Sonos: {
			icon: 'music',
		},
		Wemo: {
			protocol: 'wemo',
			icon    : 'bolt',
		},
		webcam: {
			icon: 'video-camera',
		},
		printer: {
			icon    : 'print', protocol: 'printer',
		},
		computer: {
			icon: 'desktop',
		},
		phone    : { icon: 'phone' },
		plc      : { icon: 'server', protocol: 'modbus' },
		hmi      : { icon: 'desktop' },
		breaker  : { icon: 'toggle-on' },
		generator: { icon: 'bolt', protocol: 'modbus' },
	},

	getName() {
		return this.get('name') || this.get('ip');
	},
	GuessDevice() {
		let guess = false;

		// only guess devices that are online and created within the last 5 days
		if (this.get('status') === 'online' && this.get('created') > Date.now() - (1000 * 3600 * 24 * 5)) {
			const os = this.get('osNmap');
			if (os)
				for (const o in this.known_os)
					if (os.toUpperCase().indexOf(o.toUpperCase()) !== -1)
						guess = this.known_os[o];

			const mac = this.get('mac');
			if (mac)
				for (const m in this.known_mac)
					if (mac.indexOf(m) === 0)
						guess = this.known_mac[m];

			const vendor = this.get('vendor');
			if (vendor)
				for (const v in this.known_vendors)
					if (vendor.toUpperCase().indexOf(v.toUpperCase()) !== -1)
						guess = this.known_vendors[v];

			if (guess)
				this.set(this.known_devices[guess]);
				// console.log('Device guess passed', guess);
			else
				console.log('Device guess failed', os, this.get('ip'), this.get('_id'));
				// this.set({ icon: 'exclaimation' });
		}
	},
	GuessProtocol() {
		let protocol = false;
		const ports = this.get('openPorts');
		if (ports)
			for (const p in ports) {
				const port = ports[p];
				if (this.known_ports[port.port])
					protocol = this.known_ports[port.port];
			}
		if (protocol)
			this.set({ protocol });
	},
});
