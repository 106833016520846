DEF.layout.TOOLTIP = {};

DEF.layout.TOOLTIP.CONTENT = Backbone.Marionette.View.extend({
	templateContext() {
		const rs = this.model.all_fields();
		rs.tag = this.model;
		return rs;
	},
	modelEvents: {
		change: 'render'
	}
});

/*

  ██████╗ ██████╗ ███╗   ██╗███╗   ██╗███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
 ██╔════╝██╔═══██╗████╗  ██║████╗  ██║██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
 ██║     ██║   ██║██╔██╗ ██║██╔██╗ ██║█████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
 ██║     ██║   ██║██║╚██╗██║██║╚██╗██║██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
 ╚██████╗╚██████╔╝██║ ╚████║██║ ╚████║███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝


*/
DEF.layout.TOOLTIP.CONNECTIONS = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'connections',
	template: require('./templates/tooltips/connections.html'),
	templateContext() {
		let state = 'normal';
		let poller_name = '(no poller)';
		const poller = this.model.getPoller();
		if (poller) {
			state = poller.getAlarmState();
			poller_name = poller.getName();
		} else { state = 'none'; }

		return {
			poller_state: state,
			poller_name,
			tag         : this.model
		};
	},
	ui: {
		box: '.box'
	},
	events: {
		'click @ui.box': 'Click'
	},
	GetAlarmState(state) {
		switch (state) {
		case 'OFFLINE':
			state = 'alarm';
			break;
		default:
		}
		return state;
	},
	Click(e) {
		const mode = e.currentTarget.id;
		switch (mode) {
		case 'tag':
			this.options.parent.ShowContent(this.model.getUp('type'));
			break;
		default:
			this.options.parent.ShowContent(mode);
		}
	}

});

/*

 ███╗   ██╗ ██████╗ ████████╗██╗███████╗██╗   ██╗
 ████╗  ██║██╔═══██╗╚══██╔══╝██║██╔════╝╚██╗ ██╔╝
 ██╔██╗ ██║██║   ██║   ██║   ██║█████╗   ╚████╔╝
 ██║╚██╗██║██║   ██║   ██║   ██║██╔══╝    ╚██╔╝
 ██║ ╚████║╚██████╔╝   ██║   ██║██║        ██║
 ╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚═╝╚═╝        ╚═╝


*/
DEF.layout.TOOLTIP.NOTIFY = Backbone.Marionette.View.extend({
	getTemplate() {
		const num = require('./templates/tooltips/notify_number.html');
		const boo = require('./templates/tooltips/notify_boolean.html');
		return this.model.isNumber() ? num : boo;
	},
	templateContext() {
		const rs = this.model.all_fields();
		rs.checked = {};
		try {
			for (const a of ['alarm', 'warning', 'normal', 'off', 'on'])
				for (const m of ['phone', 'sms']) {
					const list = this.model.get(`notifications.${a}.${m}`);
					if (list && list.indexOf(APP.USER.id) >= 0)
						rs.checked[`${a}_${m}`] = 'checked';
				}
		} catch (err) {
			console.error('tell warren to fix nested undefined objects in highway', err);
		}
		return rs;
	},
	ui: {
		check: '.notification_checkbox',
		testsms : '#smstest',
		testcall : '#calltest',
		user : '.user_settings',
		subs : '#subscribers'
	},
	events: {
		'click @ui.check': 'SetNotifaction',
		'click @ui.testsms' : 'TestSMS',
		'click @ui.testcall' : 'TestCall',
		'blur @ui.user'  : 'SetUserVal'
	},
	initialize() {
		this.listenTo(APP.USER, 'change:phone', this.render);
	},
	onRender() {
		if (APP.USER.get('phone').length < 10)
			this.ui.test.attr('disabled', 'disabled').addClass('disabled'); // todo why doesn't CSS handle :disabled
		this.DrawSubscribers();
	},
	DrawSubscribers() {
		const users = {};
		const nots = this.model.get('notifications');
		console.log(nots);
		this.ui.subs.html('fart');
		for (const state in nots) {
			for (const mode in nots[state]) {
				for (const u in nots[state][mode]) {
					const uid = nots[state][mode][u];
					if (!users[uid]) {
						users[uid] = {};
					}
					if (!users[uid][state]) {
						users[uid][state] = {};
					}
					users[uid][state][mode] = true;
				}
			}
		}
		console.log(">", users);

		let html = '<div style="display:flex">';
		for (const uid in users)
			if (APP.models.users.get(uid)) {
				html += `<div class='subscriber'>`
				html += `${APP.models.users.get(uid).getName()}`
				html += `<div class='states'>`
				for (var state in users[uid]) {
					for (var mode in users[uid][state])
						html += `<div class="state ${state}">${APP.Tools.icon(mode, mode+' '+state)}</div>`
				}
				html += `</div>`
				html += "</div>"
			}

		html += '</div>';
		this.ui.subs.html(html);
	},
	TestSMS(e) {
		$(e.currentTarget).html(APP.Tools.icon('working'));
		APP.Tools.Notify('test', 'Test Notification', (a, b, c) => {
			$(e.currentTarget).html('test sms');
			console.log(a, b, c);
		});
	},
	TestCall(e) {
		$(e.currentTarget).html(APP.Tools.icon('working'));
		APP.Tools.Call('test', 'Test Call', (a, b, c) => {
			$(e.currentTarget).html('test phone');
			console.log(a, b, c);
		});
	},
	SetUserVal(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.value;

		const user = APP.USER;
		user.set(id, val);
		APP.Log(`${id} set to '${val}'`);
	},
	SetNotifaction(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.checked;
		const [alarm, mode] = id.split('_');
		const u_id = APP.USER.id;

		// this.model.set('notifications."+alarm+".'+u_id); // TODO of course it didn't work

		let notifications = this.model.get('notifications');
		if (!notifications)
			notifications = {};
		if (!notifications[alarm])
			notifications[alarm] = {};
		if (!notifications[alarm][mode])
			notifications[alarm][mode] = [];

		if (val && notifications[alarm][mode].indexOf(u_id) === -1)
			notifications[alarm][mode].push(u_id);
		if (!val && notifications[alarm][mode].indexOf(u_id) > -1)
			notifications[alarm][mode].splice(notifications[alarm][mode].indexOf(u_id), 1);

		this.model.set('notifications', notifications);
		console.log("SetNotifications>", notifications)

		this.render();
		//	debugger
	}
});

DEF.layout.TOOLTIP.ISSUE = DEF.layout.TOOLTIP.CONTENT.extend({
	template: require('./templates/tooltips/issue.html'),
	ui      : {
		submit: '#submit',
		title : '#title',
		desc  : '#desc'
	},
	modelEvents: {}, // disable the default "refresh" mechanism from CONTENT
	events     : {
		'click @ui.submit': 'Submit'
	},
	Submit() {
		this.$el.find('#submit').html(`${APP.Tools.icon('working')} Submit`);
		APP.Log(
			`[users:${APP.USER.id}] submitted a problem about [tags:${this.model.id}] report: ${this.ui.title.val()}`,
			'info',
			this.model
		);
		APP.Tools.SendMail(window.SETTINGS.emails.bugreport, `${this.model.get('tag_name')} - ${this.ui.title.val()}`, this.ui.desc.val(), (x, y, z) => {
			this.$el.find('#result').html('Issue submitted to the project tracker');
			this.$el.find('#submit').html(`${APP.Tools.icon('bug')} Submit`);
			this.$el.find('#title').val('');
			this.$el.find('#desc').val('');
		});
	}
});

/*

 ███████╗██╗  ██╗██████╗  ██████╗ ██████╗ ████████╗
 ██╔════╝╚██╗██╔╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
 █████╗   ╚███╔╝ ██████╔╝██║   ██║██████╔╝   ██║
 ██╔══╝   ██╔██╗ ██╔═══╝ ██║   ██║██╔══██╗   ██║
 ███████╗██╔╝ ██╗██║     ╚██████╔╝██║  ██║   ██║
 ╚══════╝╚═╝  ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝


*/
DEF.layout.TOOLTIP.EXPORT = DEF.layout.TOOLTIP.CONTENT.extend({
	template: require('./templates/tooltips/export.html'),
	ui      : {
		export: '#export.btn',
		sample: '#sample',
		from  : '#from',
		to    : '#to',
		save  : '.field',
		preset: '.preset'
	},
	modelEvents: {}, // disable the default "refresh" mechanism from CONTENT
	events     : {
		'click @ui.export' : 'Export',
		'change @ui.sample': 'SampleChange',
		'change @ui.save'  : 'Save',
		'click @ui.preset' : 'SetPreset'
	},
	onRender() {
		const from = APP.Tools.store('export', 'from') || 86400000;
		const to = APP.Tools.store('export', 'to') || 0;
		console.log('ex', from, to);
		const sample = APP.Tools.store('export', 'sample') || 0;

		const offset = new Date().getTimezoneOffset() * 60 * 1000;

		const fromstring = new Date(Date.now() - from - offset).toJSON().slice(0, 19);
		const tostring = new Date(Date.now() - to - offset).toJSON().slice(0, 19);
		console.log(fromstring, tostring);
		this.ui.sample.val(sample);
		this.ui.from.val(fromstring);
		this.ui.to.val(tostring);

		this.SetPreset(false, APP.Tools.store('export', 'preset'));
	},
	Export() {
		// const day = 86400 * 1000;

		// 	"path": "/export/:tag_id/:sample/:from/:to",
		const tag_name = this.model.getName();
		const tag_id = this.model.id;
		const sample = this.ui.sample.val();
		const from = (Date.now() - new Date(this.ui.from.val()).getTime());
		const to = (Date.now() - new Date(this.ui.to.val()).getTime());
		const source = from > (this.model.getUp('retention') * 1000) ? 'data_archive' : 'data';

		console.log(from, to);

		const uri = SETTINGS.posturl || '';
		const url = `${uri}/export/${[tag_name, source, tag_id, sample, from, to, APP.USER.get('email')].join('/')}`;
		console.log('export', url);
		window.open(url);
	},
	Save(e) {
		// const day = 86400;
		switch (e.currentTarget.id) {
		case 'from':
		case 'to':
			APP.Tools.store('export', e.currentTarget.id, Date.now() - new Date(e.currentTarget.value).getTime());
			APP.Tools.store('export', 'preset', 0);
			break;
		default:
			APP.Tools.store('export', e.currentTarget.id, e.currentTarget.value);
			break;
		}
	},
	SetPreset(e, id) {
		const now = Date.now();
		const year = new Date().getFullYear();
		const day = 1000 * 60 * 60 * 24;
		const date = new Date();
		let from = Date.now();
		let to = Date.now();
		if (id === undefined)
			id = e.currentTarget.id;
		console.log('preset', id);
		switch (id) {
		case 'year':
			from = new Date(year - 2, 11, 31).getTime();
			to = new Date(year - 0, 0, 1).getTime();
			break;
		case 'ytd':
			from = new Date(year - 1, 11, 31).setHours(23, 0, 0, 0);
			to = new Date(now).getTime();
			break;
		case 'month':
			to = date.setDate(0);
			from = new Date(new Date(to).setDate(1)).setHours(0, 0, 0, 0);
			to = new Date(to).setHours(24, 0, 0, 0);
			break;
		case 'week':
			date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
			date.setHours(0, 0, 0, 0);
			from = date.setDate(date.getDate() - 7);
			to = date.setDate(date.getDate() + 7);
			break;
		case '7days':
			from = to - (day * 7);
			break;
		case '24hr':
			from = to - (day * 1);
			break;
		case 'yesterday':
			from = new Date(now - day).setHours(0, 0, 0, 0);
			to = new Date(now - day).setHours(24, 0, 0, 0);
			break;
		case 'today':
			from = new Date(now).setHours(0, 0, 0, 0);
			to = new Date(now).getTime();
			break;
		case 'mon.1':
		case 'mon.2':
		case 'mon.3':
		case 'mon.4':
		case 'mon.5':
		case 'mon.6': // / this code is so stupid.  but it's friday afternoon
			to = date.setDate(0);
			from = new Date(new Date(new Date(to).setDate(1)).setHours(0, 0, 0, 0));
			from.setMonth(from.getMonth() - id.split('.')[1] + 1);
			from = from.getTime();
			to = new Date(new Date(to).setHours(24, 0, 0, 0));
			to.setMonth(to.getMonth() - id.split('.')[1] + 1);
			to = to.getTime();


			break;
		default:
			from = 0;
			to = 0;
		}
		console.log(id, from, to);
		APP.Tools.store('export', 'preset', id);
		const offset = new Date().getTimezoneOffset() * 60 * 1000;

		if (from + to > 0) {
			const fromstring = new Date(from - offset).toJSON().slice(0, 19);
			const tostring = new Date(to - offset).toJSON().slice(0, 19);
			this.ui.from.val(fromstring);
			this.ui.to.val(tostring);
		}
	}
});

/*

 ██████╗  █████╗ ████████╗ █████╗
 ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
 ██║  ██║███████║   ██║   ███████║
 ██║  ██║██╔══██║   ██║   ██╔══██║
 ██████╔╝██║  ██║   ██║   ██║  ██║
 ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝


*/
// DEF.layout.TOOLTIP.NOROW = Backbone.Marionette.View.extend({
// 	template: require('./templates/tooltips/norow.html')
// });

// DEF.layout.TOOLTIP.DATAROW = Backbone.Marionette.View.extend({
// 	className: 'row',
// 	tagName  : 'tr',
// 	template : require('./templates/tooltips/datarow.html'),
// 	templateContext() {
// 		const val = this.model.get('v');
// 		return {
// 			valuef     : this.options.parent.getValue(val),
// 			alarm_state: this.options.parent.getAlarmState(val)
// 		};
// 	}
// });
DEF.layout.TOOLTIP.DATA = Backbone.Marionette.View.extend({
	id       : 'data',
	tagName  : 'table',
	lines    : 200,
	template : require('./templates/tooltips/data.html'),
	// childView: DEF.layout.TOOLTIP.DATAROW,
	// emptyView: DEF.layout.TOOLTIP.NOROW,
	modelname: 'data',
	viewComparator(m) {
		return -m.get('d');
	},
	modelEvents: {
		'change:last_write_time': 'LoadNewData'
	},
	ui: {
		more: '#more'
	},
	events: {
		'click @ui.more': 'More'
	},
	onRender() {
		$('#data.command').html(`${APP.Tools.icon('data')} data`);
		this.loading_data = false;
		this.LoadData({ t: this.model.id });
	},
	initialize() {
		console.log('init');
		this.latest = Date.now();
		this.earliest = Date.now() - (1000 * this.model.getUp('data_rate') * 100);
		this.collection = APP.models[this.modelname];
		// $('#TOOLTIP #content').bind('scroll', this.Scroll.bind(this));
	},
	attachElContent() {
		this.$el.html(APP.Tools.icon('working'));
		_.defer(this.GenerateTable.bind(this));
	},
	More() {
		this.lines += 100;
		this.render();
	},
	GenerateTable () {
		let lastv = null ;
		let max = this.lines;
		let html = '<table>';
		this.collection.comparator = this.viewComparator;
		this.collection.sort();
		const tid = this.model.get('_id');
		const collection = this.collection.where({ t: tid });

		let m = collection[0]
		let classs = `${this.options.parent.model.getAlarmState(m.get('v'))}`;
		html = "<tr>"
		// html += `<td>${APP.Format.simpletime(m.get('d'))}</td>`;
		// html += `<td>${m.get('r')}</td>`;
		// html += `<td class='value ${classs}'>${this.options.parent.model.getValue(m.get('v'))}</td>`;
		for (m of collection) {
			var delta = lastv - m.get('v');
			classs = `${this.options.parent.model.getAlarmState(m.get('v'))}`;
			if (lastv !== null)
				html += `<td>Δ${['','+'][+(delta > 0)]}${(delta).toLocaleString()}</td>`
			html += '</tr>';
			html += '<tr>';
			html += `<td>${APP.Format.simpletime(m.get('d'))}</td>`;
			html += `<td>${m.get('r')}</td>`;
			html += `<td class='value ${classs}'>${this.options.parent.model.getValue(m.get('v'))}</td>`;
			max--;
			lastv = m.get('v');
			if (!max)
				break;
		}
		html += "</tr>";
		html += "<input type='button' id='more' value='Load More...'></input>";
		$('#data.command').html(`${APP.Tools.icon('data')} data`);
		this.$el.html(html);
		this.model.RefreshStats();
		return html;
	},
	onDestroy() {
		console.log('Destroy');
		$('#TOOLTIP #content').unbind('scroll');
	},
	LoadData(where) {
		this.loading_data = true;
		$(`#${this.modelname}.command`).html(`${APP.Tools.icon('working')} ${this.modelname}`);
		console.log(`tooltip ${this.modelname} before`, APP.models[this.modelname].length, new Date(this.latest), where);
		APP.models.data._where(where).then(this.GenerateTable.bind(this));
	},
	LoadNewData() {
		console.log('refresh');
		const where = {
			t: this.model.id,
			d: {
				$gt: Date.now()
			}
		};
		console.log(where);
		_.delay(this.LoadData.bind(this, where), 1000);
	},
	Scroll() {
		// const elem = $('#TOOLTIP #content');
		// console.log(elem[0].scrollHeight - elem[0].scrollTop);
		// if (elem[0].scrollHeight - elem[0].scrollTop <= elem[0].clientHeight) {
		// // if (elem[0].scrollHeight - elem.scrollTop() <= elem.outerHeight() + 150) {
		// 	this.latest = this.earliest;
		// 	this.earliest -= 1000 * 60 * 60;
		// 	const where = {
		// 		t: this.model.id,
		// 		d: {
		// 			$lt: this.latest,
		// 			$gt: this.earliest
		// 		}
		// 	};
		// 	//	if (!this.loading_data)
		// 	_.defer(this.LoadData.bind(this, where));
		// }
	}
});

/*

 ███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗
 ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
 █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗
 ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║
 ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║
 ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝


*/
DEF.layout.TOOLTIP.EVENTS = DEF.layout.TOOLTIP.DATA.extend({
	id       : 'events',
	tagName  : 'table',
	template : _.template("<div id='events'></div>"),
	childView: DEF.layout.LOG_BOX.child,
	emptyView: DEF.layout.TOOLTIP.NOROW,
	modelname: 'events_archive',
	filter(m) {
		return m.get('tag_id') === this.model.get('_id');
	},
	GenerateTable() {
		let max = this.lines;
		let html = '<table>';
		this.collection.comparator = this.viewComparator;
		this.collection.sort();
		const tid = this.model.get('_id');
		const collection = this.collection.where({ tag_id: tid });

		for (const m of collection) {
			const classs = `${m.get('alarm_state')}`;
			html += '<tr>';
			html += `<td>${APP.Format.simpletime(m.get('datetime'))}</td>`;
			html += `<td class='${classs}'>${m.getEvent()}</td>`;
			html += '</tr>';
			max--;
			if (!max)
				break;
		}
		html += "<input type='button' id='more' value='Load More...'></input>";
		$('#data.command').html(`${APP.Tools.icon('data')} data`);
		this.$el.html(html);
		return html;
	},
	LoadData() {
		$('#events.command').html(`${APP.Tools.icon('working')} events`);
		const where = {
			tag_id  : this.model.id,
			datetime: {
				$lt: this.latest,
				$gt: this.earliest
			}
		};
		console.log(JSON.stringify(where));
		// console.log(`tooltip ${this.modelname} before`, APP.models[this.modelname].length, new Date(this.latest), where);
		APP.models[this.modelname]._where(where).then(this.GenerateTable.bind(this));
	}


});
DEF.layout.TOOLTIP.EVENTSx = Backbone.Marionette.View.extend({
	id      : 'events',
	tagName : 'table',
	template: _.template("<div id='events'></div>"),
	regions : {
		log_box: '#events'
	},
	initialize() {
		APP.models.events._where({
			tag_id: this.model.id
		}).then(this.render.bind(this));
	},
	start  : 0,
	perpage: 40,
	onRender() {
		const id = this.model.get('_id');
		console.log('rend', id, this.model.getName());
		this.showChildView('log_box', new DEF.layout.LOG_BOX.Main({
			collection: APP.models.events_archive,
			emptyView : DEF.layout.TOOLTIP.NOROW,
			filter(m) {
				return m.get('tag_id') === id;
			}
		}));
	},
	Refresh() {
		console.log('tooltip events before', APP.models.events.length);
		APP.models.data._where({
			tag_id  : this.model.id,
			datetime: {
				$gt: this.latest
			}
		}).then(this.render.bind(this));
		this.latest = Date.now();
	}
});

/*

 ███╗   ███╗ ██████╗ ██████╗ ███████╗██╗     ███████╗
 ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██║     ██╔════╝
 ██╔████╔██║██║   ██║██║  ██║█████╗  ██║     ███████╗
 ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ██║     ╚════██║
 ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████╗███████║
 ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝╚══════╝


*/
DEF.layout.TOOLTIP.DEVICE_LIBRARY = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'device_library',
	template: require('./templates/tooltips/device_library.html')
});
DEF.layout.TOOLTIP.TAG_LIBRARY = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'tag_library',
	template: require('./templates/tooltips/tag_library.html')
});

DEF.layout.TOOLTIP.DEVICE = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'device',
	template: require('./templates/tooltips/device.html'),
	regions : {
		log_box: '#events'
	},
	templateContext() {
		const rs = this.model.all_fields();
		rs.tag = this.model;
		const device = this.model.getDevice();
		rs.show_notify = APP.USER.Can('notify') && device.getUp('log_onoff');
		rs.show_runtime = device.getDeviceLibrary().get('log_onoff');
		rs.off = device.get('notifications.off.sms');
		rs.on = device.get('notifications.on.sms');
		rs.run_hours = device.getRunHours();
		rs.run_state = device.getRunState();
		return rs;
	},
	ui: {
		notify: '.notification_checkbox'
	},
	events: {
		'click @ui.notify': 'SetNotify'
	},
	initialize() {
		APP.models.events._where({
			tag_id: this.model.id
		}).then(this.render.bind(this));
	},
	onRender() {
		const ids = this.model.getDevice().getTags().map(m => m.get('_id'));
		this.showChildView('log_box', new DEF.layout.LOG_BOX.Main({
			collection: APP.models.events,
			emptyView : DEF.layout.TOOLTIP.NOROW,
			viewFilter (m) {
				m = m.model;
				return ids.indexOf(m.get('tag_id')) !== -1;
			}
		}));
	},
	SetNotify(e) {
		console.log(e.currentTarget.id, e.currentTarget.checked);
		const dev = this.model.getDevice();
		const [alarm, mode] = e.currentTarget.id.split('_');
		const val = e.currentTarget.checked;
		const u_id = APP.USER.id;


		let notifications = dev.get('notifications');
		if (!notifications)
			notifications = {};
		if (!notifications[alarm])
			notifications[alarm] = {};
		if (!notifications[alarm][mode])
			notifications[alarm][mode] = [];

		if (val && notifications[alarm][mode].indexOf(u_id) === -1)
			notifications[alarm][mode].push(u_id);
		if (!val && notifications[alarm][mode].indexOf(u_id) > -1)
			notifications[alarm][mode].splice(notifications[alarm][mode].indexOf(u_id), 1);

		console.log(notifications);
		dev.set('notifications', notifications);
	}
});

DEF.layout.TOOLTIP.POLLER = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'poller',
	template: require('./templates/tooltips/poller.html'),
	templateContext() {
		return this.model.getPoller().all_fields();
	},
	initialize() {
		this.listenTo(this.model.getPoller(), 'change', this.refresh);
	}
});
// DEF.layout.TOOLTIP.WIDGET = DEF.layout.TOOLTIP.CONTENT.extend({
// 	id      : 'poller',
// 	template: require('../widgets/Gauge/props.html'),
// 	templateContext() {
// 		debugger;
// 		return this.model.getPoller().all_fields();
// 	},
// 	initialize() {
// 		this.listenTo(this.model.getPoller(), 'change', this.refresh);
// 	},
// });

/*

 ██╗  ██╗██╗███╗   ██╗██████╗ ███████╗
 ██║ ██╔╝██║████╗  ██║██╔══██╗██╔════╝
 █████╔╝ ██║██╔██╗ ██║██║  ██║███████╗
 ██╔═██╗ ██║██║╚██╗██║██║  ██║╚════██║
 ██║  ██╗██║██║ ╚████║██████╔╝███████║
 ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝


*/

DEF.layout.TOOLTIP.DATE = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'date',
	template: require('./templates/tooltips/date.html')
});

DEF.layout.TOOLTIP.STRING = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'string',
	template: require('./templates/tooltips/string.html')
});
DEF.layout.TOOLTIP.CODE = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'string',
	template: require('./templates/tooltips/string.html')
});

DEF.layout.TOOLTIP.BOOLEAN = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'boolean',
	template: require('./templates/tooltips/boolean.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.offclass = this.model.get('value') ? '' : 'active';
		rs.onclass = this.model.get('value') ? 'active' : '';
		return rs;
	}
});

DEF.layout.TOOLTIP.NUMBER = DEF.layout.TOOLTIP.CONTENT.extend({
	id: 'number',
	ui: {
		meter  : '#meter',
		unit   : '.unit',
		newunit: '#unit'
	},
	events: {
		'click @ui.unit'    : 'Unit',
		'change @ui.newunit': 'SaveUnit',
		'scroll @ui.meter'  : 'ZoomMeter'
	},
	initialize() {
		this.SVG = require('svg.js');
	},
	Unit() {
		console.log('x');
	},
	SaveUnit(e) {
		APP.USER.set(APP.Unit.dehumanize(this.model.getUp('unit')), e.currentTarget.value.trim());
		this.render();
	},
	ZoomMeter() {
		console.log('zoom'); // :(
	},
	template: require('./templates/tooltips/number.html'),
	// onRender() {
	// 	console.log(this.draw);
	// },
	templateContext() {
		const rs = this.model.all_fields();
		// const first = APP.models.data.where({ t: this.model.id }).pop();
		// const last = APP.models.data.where({ t: this.model.id }).shift();
		// rs.first = first ? first.get('d') : Date.now();
		// console.log(Date.now() - rs.first, Date.now() - last.get('d'));
		rs.tag = this.model;
		rs.unit_list = false;
		const default_unit = APP.Unit.dehumanize(rs.unit);
		const user_unit = APP.Unit.GetUnit(rs.unit);
		const units = APP.Unit[APP.Unit.dehumanize(default_unit)];
		// console.log('units', units);
		rs.valuef = this.model.getValue(rs.value, true);
		if (units) {
			rs.unit_list = Object.keys(units);
			if (user_unit !== default_unit && (user_unit === '%' || APP.Unit[default_unit][user_unit])) {
				if (user_unit === '%') {
					rs.valuef = (this.model.getPercent() * 100).toFixed(1);
				} else {
					rs.valuef = this.model.getValue(APP.Unit[default_unit][user_unit](rs.value), true);
					const stats = _.extend({}, rs.stats);
					for (const s in stats)
						stats[s] = APP.Unit[default_unit][user_unit](stats[s]);
					rs.stats = stats;
					rs.delta = APP.Unit[default_unit][user_unit](rs.delta);
					rs.delta_1hr = APP.Unit[default_unit][user_unit](rs.delta_1hr);
				}
				rs.unit = user_unit;
			}
			rs.unit_list.push('%');
		}
		rs.default_unit = default_unit;
		rs.user_unit = user_unit;
		return rs;
	},
	onDomRefresh() {
		// if (!this.draw)
		// 	this.draw = this.SVG('meter').size(600, 50);
		// _.defer(this.DrawMeter.bind(this));
		this.DrawMeter();
	},

	/**
	 * This overcomplicated pile of shit draws the giant meter below the value.  It's
	 * become quite sophisitcated and probably deserves it's own bunch of functions, but
	 * eh, it works.
	 */
	DrawMeter() {
		const draw = this.SVG('meter').size(600, 50);
		if (!draw) {
			console.log('no draw context for tooltip meter');
			return;
		}

		const default_unit = APP.Unit.dehumanize(this.model.getUp('unit'));
		const user_unit = APP.Unit.GetUnit(this.model.getUp('unit'));
		const decimals = (this.model.getUp('decimals') || 0);


		const width = $(this.ui.meter).width() - 10;
		// const height = $(this.ui.meter).height();

		const stats = this.model.get('stats') || {};
		const low = this.model.getUp('range_low');
		const high = this.model.getUp('range_high');

		const model = this.model;

		// calculate the position based on the low and high levels
		const scale = function scale(val) {
			return APP.Format.clamp((val - low) / (high - low), 0, 1) * width;
		};
		// scale the value based on the current unit selection
		const unit = function unnit(val) {
			let label = val;
			if (user_unit === '%')
				label = (model.getPercent(val) * 100) | 0;
			else if (APP.Unit[default_unit] && APP.Unit[default_unit][user_unit])
				label = APP.Unit[default_unit][user_unit](val);
			return label;
		};


		let rl = this.model.getUp('range_low');
		let rh = this.model.getUp('range_high');
		let al = this.model.getUp('alarm_low') || rl;
		let wl = this.model.getUp('warning_low') || al;
		let ah = this.model.getUp('alarm_high') || rh;
		let wh = this.model.getUp('warning_high') || ah;
		let el = this.model.getUp('expected_low');
		let eh = this.model.getUp('expected_high');
		rl = scale(rl);
		rh = scale(rh);
		al = scale(al);
		wl = scale(wl);
		ah = scale(ah);
		wh = scale(wh);
		el = scale(el);
		eh = scale(eh);
		// const std = scale(stats.stdDev);
		const avg = scale(stats.avg);
		const min = scale(stats.min);
		const max = scale(stats.max);
		const std = scale(stats.stdDev);
		// const q1 = scale(stats.q1);
		// const q2 = scale(stats.q2);
		// const q3 = scale(stats.q3);
		const value = scale(this.model.get('value'));
		// console.log('>>>', this.model.getName(), wl, wh);


		const y1 = 23; // height * 0.35;
		// if (!stats.q2)
		// 	y1 = height * 0.5;

		// draw average and standard deviation
		draw.rect(max - min, 20).move(min, y1 - 10).fill('#040').radius(4); // min max
		if (stats.avg)
			draw.rect(std * 2, 12).move(avg - std, y1 - 6).fill('#090').radius(2); // std dev

		// draw the rail
		draw.rect(width, 4).move(0, y1 - 2).fill('#aaa'); // rail

		// draw box plot
		// if (stats.q2) { // box plot
		// 	draw.rect(q2 - q1, 10).move(q1, y2 - 5).stroke('#fff');
		// 	draw.rect(q3 - q2, 10).move(q2, y2 - 5).stroke('#fff');
		// 	draw.line(min, y2, q1, y2).stroke('#FFF');
		// 	draw.line(q3, y2, max, y2).stroke('#FFF');
		// 	draw.line(min, y2 - 5, min, y2 + 5).stroke('#FFF');
		// 	draw.line(max, y2 - 5, max, y2 + 5).stroke('#FFF');
		// }


		// draw the scale and scale labels
		for (let i = 0; i < 11; i++) {
			let label = (low + ((high - low) * i / 10));
			label = unit(label);
			// if (default_unit !== user_unit)
			// 	if (user_unit === '%')
			// 		label = i * 10;
			// 	else
			// 		label = APP.Unit[default_unit][user_unit](label);

			label = APP.Format.number(label);
			let anchor = 'middle';
			let tickoffset = 0;
			if (i === 0) {
				anchor = 'start';
				tickoffset = 1;
			}
			if (i === 10) {
				anchor = 'end';
				tickoffset = -1;
			}
			draw.rect(2, 8).move((i * width / 10) - 1 + tickoffset, y1).fill('#aaa'); // the tick
			draw.plain(label).move(i * width / 10, y1 + 20).font({ size: 12, fill: '#aaa', anchor }); // the numbers
		}

		// HISTOGRAM
		if (true) {
			// console.log('data lenght', APP.models.data.length);
			let count = 0;
			// const ary = new Array(rh - rl).fill(0);
			const ary = [];
			let val = 0;
			// const ary = [];
			const data = APP.models.data.where({ t: this.model.id });
			for (const d in data) {
				val = ((data[d].get('v')) * Math.pow(10, decimals)) | 0;
				if (val > 0) {
					if (!ary[val])
						ary[val] = 0;
					count = Math.max(count, ++ary[val]);
				}
			}

			for (const d in ary)
				if (ary[d] > 0) {
					val = 7 * (ary[d] | 0) / count;
					// console.log('x', d, val, (ary[d] | 0) / count);
					draw.rect(2, val).move(scale(d / Math.pow(10, decimals)), 7 - val).fill('#999');
				}
		}

		// draw alarm ranges
		if (el || eh)
			draw.rect(eh - el, 6).move(el, y1 - 3).fill('#0f0'); // expected range

		if (rl < wl) {
			// label = APP.Unit[default_unit][user_unit](this.model.getUp('warning_low'));
			draw.rect(2, 8).move(wl - 2, y1 - 8).fill('yellow');
			draw.plain(unit(this.model.getUp('warning_low'))).move(wl - 1, y1 - 12).font({ size: 12, fill: 'yellow', anchor: 'middle' }); // the numbers
			draw.rect(wl, 6).move(0, y1 - 3).fill('yellow'); // alarm low
		}
		if (rl < al) {
			draw.rect(2, 8).move(al - 2, y1 - 8).fill('red'); // tick
			draw.plain(unit(this.model.getUp('alarm_low'))).move(al - 1, y1 - 12).font({ size: 12, fill: 'red', anchor: 'middle' }); // the numbers
			draw.rect(al, 6).move(0, y1 - 3).fill('red');
		}
		if (rh > wh) {
			draw.rect(rh - wh, 6).move(wh, y1 - 3).fill('yellow'); // warning high
			draw.rect(2, 8).move(wh, y1 - 8).fill('yellow');
			draw.plain(unit(this.model.getUp('warning_high'))).move(wh, y1 - 12).font({ size: 12, fill: 'yellow', anchor: 'middle' }); // the numbers
		}

		if (rh > ah) {
			draw.rect(rh - ah, 6).move(ah, y1 - 3).fill('red'); // alarm high
			draw.rect(2, 8).move(ah, y1 - 8).fill('red');
			draw.plain(unit(this.model.getUp('alarm_high'))).move(ah, y1 - 12).font({ size: 12, fill: 'red', anchor: 'middle' }); // the numbers
		}

		// draw value
		draw.polygon('0,0 -10,-15,10,-15,0,0').move(value - 10, y1 - 15).fill('#0FF').stroke({ color: '#000', width: 2 });
		// draw.circle(16).move(val, y1 - 8).fill('#0ff'); // value
	}
});
DEF.layout.TOOLTIP.TOTALIZER = DEF.layout.TOOLTIP.NUMBER.extend({
	id      : 'totalizer',
	template: require('./templates/tooltips/totalizer.html'),
	ui      : {
		reset  : '#reset',
		unit   : '.unit',
		newunit: '#unit'
	},
	events: {
		'click @ui.reset'   : 'Reset',
		'click @ui.unit'    : 'Unit',
		'change @ui.newunit': 'SaveUnit'
	},
	DrawMeter() {
		return false; // extended from NUMBER - totalizer has no meter.
	},
	Reset() {
		if (confirm('This will set the value of this totalizer instantly to zero.  Are you sure?'))
			this.model.ResetTotalizer();
			// this.model.set({ last_zero_value: this.model.get('value'), value: 0, last_zero_date: Date.now() });
			// const event = {
			// 	event      : `[tags:${this.model.id}] reset to 0 by [users:${APP.USER.id}]`,
			// 	datetime   : Date.now(),
			// 	tag_id     : this.model.id,
			// 	alarm_state: 'info'
			// };
			// APP.models.events.create(event);
			// APP.models.events_archive.create(event);
	}
});
DEF.layout.TOOLTIP.RATE = DEF.layout.TOOLTIP.NUMBER.extend({
	// id      : 'totalizer',
	// template: require('./templates/tooltips/rate.html'),
	// ui      : {
	// },
	// events: {
	// },
	// DrawMeter() {
	// 	return false; // extended from NUMBER - totalizer has no meter.
	// }
});


/*

 ███████╗████████╗ █████╗ ████████╗███████╗
 ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
 ███████╗   ██║   ███████║   ██║   ███████╗
 ╚════██║   ██║   ██╔══██║   ██║   ╚════██║
 ███████║   ██║   ██║  ██║   ██║   ███████║
 ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝


*/
DEF.layout.TOOLTIP.STATS = DEF.layout.TOOLTIP.CONTENT.extend({
	id      : 'stats',
	template: require('./templates/tooltips/stats.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.tag = this.model;
		const stats = {};
		const deltaavg = [];
		let last_value = false;
		const data = APP.models.data.where({ t: this.model.id });
		if (data.length) {
			for (const d of data) {
				if (!stats[d.get('r')])
					stats[d.get('r')] = 0;
				stats[d.get('r')]++;

				if (last_value === false) {
					last_value = d.get('v');
				} else {
					deltaavg.push(Math.abs(d.get('v') - last_value));
					last_value = d.get('v');
				}
			}
			// console.log(stats);
			rs.stats = {
				reasons : stats,
				avgdelta: deltaavg.reduce((p, c) => p + c, 0) / deltaavg.length
			};
		}
		return rs;
	},
	ui: {
		color: '#color'
	},
	events: {
		'change @ui.color': 'Color'
	},
	Color(e) {
		// const id = e.currentTarget.id;
		const val = e.currentTarget.value;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.model.set({ color: val });
		}, 1000); // the color picker fires on drag, which changes the tooltip and disconnects the color picker
	}
});

/*

 ███╗   ███╗ █████╗ ██╗███╗   ██╗
 ████╗ ████║██╔══██╗██║████╗  ██║
 ██╔████╔██║███████║██║██╔██╗ ██║
 ██║╚██╔╝██║██╔══██║██║██║╚██╗██║
 ██║ ╚═╝ ██║██║  ██║██║██║ ╚████║
 ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝


*/
DEF.layout.TOOLTIP.MAIN = Backbone.Marionette.View.extend({
	id      : 'TOOLTIP',
	template: require('./templates/tooltip.html'),
	templateContext() {
		return this.model.all_fields();
	},
	initialize() {
		// console.log("tooltip data before", APP.models.data.length);
		// const model_id = this.model.id;
		this.timeout1 = setTimeout(() => {
			this.ui.data.html(`${APP.Tools.icon('working')} data`);
			// const $gt = Date.now() - (1000 * this.model.getUp('retention') * 1);
			// const $lt = Date.now();
			// console.log(new Date($gt), new Date($lt));
			// APP.models.data_archive._where({
			// 	t: this.model.id,
			// 	d: {
			// 		$lt,
			// 		$gt
			// 	}
			APP.models.data._where({ t: this.model.id }).then(() => {
				console.log('top');
				if (typeof this.ui.data === 'object') { // sometimes the tooltip is dismissed
					this.ui.data.html(`${APP.Tools.icon('data')} data`);
					this.model.RefreshStats();
				}
			});
		}, 2000);
		// debugger;
		this.timeout2 = setTimeout(() => {
			this.ui.events.html(`${APP.Tools.icon('working')} events`);
			APP.models.events_archive._where({
				tag_id: this.model.id
			}).then(() => {
				if (typeof this.ui.events === 'object') // sometimes the tooltip is dismissed
					this.ui.events.html(`${APP.Tools.icon('events')} events`);
			});
		}, 4000);
	},
	ui: {
		needsdata  : '.needsdata',
		widget     : '.widget_link',
		link       : '.link',
		command    : '.command',
		data       : '#data.command',
		events     : '#events.command',
		alarm_state: '#alarm_state'
	},
	events: {
		'click @ui.widget' : 'ShowWidget',
		'click @ui.command': 'Command',
		'click @ui.link'   : 'Command'
	},
	regions: {
		header : '#header',
		content: '#content',
		footer : '#footer'
	},
	modelEvents: {
		'change:alarm_state': 'ShowAlarmState',
		'change:value'      : 'ShowAlarmState',
		'change:updated'    : 'ShowAlarmState'
	},
	onRender() {
		this.ShowContent(this.model.getUp('type'));

		this.ShowAlarmState(true);
		// this.SetPosition();
	},
	onDestroy() {
		if (this.timeout1)
			clearTimeout(this.timeout1);
		if (this.timeout2)
			clearTimeout(this.timeout2);
	},
	onDomRefresh() {
		// console.log('ref');
		this.SetPosition();
		$('#details.command').addClass('active');
		const Draggable = require('draggable');
		new Draggable(this.$el[0], { handle: this.$el.find('#title')[0], onDrag: this.WatchDrag.bind(this) });
		this.$el.css({ height: 'auto' });
	},
	WatchDrag(el) {
		// console.log($(el).position().top);
		if ($(el).position().top < 5)
			$(el).addClass('fullscreen');
		else
			$(el).removeClass('fullscreen');
	},
	ShowAlarmState() {
		let state = this.model.get('alarm_state');
		if (this.model.getStale())
			state = 'stale';

		//		this.model.set("alarm_state", "stale");
		// } else if (state == "stale") { // the model has already been set to stale in some previous view
		// 	state = this.model.getAlarmState();
		// 	this.model.set("alarm_state", state);
		// }
		if (state === this.last_alarm_state) // change:value may de-stale this tooltip
			return;
		$(this.ui.alarm_state).removeClass('normal stale off warning alarm');
		if (state === 'normal' || state === 'info') {
			$(this.ui.alarm_state).addClass('normal').html('');
		} else {
			const when = APP.Format.livetime(this.model.get(state === 'stale' ? 'updated' : 'alarm_time'));

			let html = `<div id='icon'>${APP.Tools.icon(state)}</div><div id='text'><b>${APP.Lang(state)}</b> for ${when}`;
			// if (state === 'alarm' || state === 'warning')
			if (this.model.get('alarm_ack_time')) {
				html += '<div class="ack">';
				html += APP.Tools.icon('ack');
				html += `<b>ACK: </b>${APP.models.users.get(this.model.get('alarm_ack_id')).getName()}`;
				html += ` @ ${APP.Format.simpletime(this.model.get('alarm_ack_time'))}, `;
				html += `after ${APP.Format.duration(this.model.get('alarm_ack_time') - this.model.get('alarm_time')).replace(' ago', '')}`;
				html += '</div>';
			} else {
				html += `<button class='command button' id='ack'>${APP.Tools.icon('ack')} Ack</button>`;
			}

			html += '</div>';
			$(this.ui.alarm_state).addClass(state).html(html);
		}
		//	_.defer(this.SetPosition.bind(this));
		this.last_alarm_state = state;
		// console.log(APP.models.events.where({
		// 	tag_id: this.model.id,
		// }));
		if (APP.models.events.where({ tag_id: this.model.id }).length > 0)
			if (this.ui.events.removeClass)
				this.ui.events.removeClass('disabled');
		this.SetPosition();
	},
	SetPosition() {
		if (this.$el.width() === 0)
			return false;
		let left = this.options.left;
		let top = this.options.top;

		if (left + this.$el.width() > $(window).width() - 65)
			left = $(window).width() - this.$el.width() - 65;
		if (top + this.$el.height() > $(window).height() - 40)
			top = Math.max(0, $(window).height() - this.$el.height() - 40);
		const scale = Math.min(1, $(window).height() / (this.$el.height() + 40), $(window).width() / (this.$el.width()));

		const css = {
			left,
			top,
			'border-color'    : this.model.get('color'),
			'transform-origin': '0% 0%',
			transform         : `scale(${scale},${scale})`
		};
		if ($(window).width() < 600) {
			css.left = 0;
			css.top = 0;
			css.right = 0;
			css.bottom = 0;
		}
		this.$el.css(css);
		return true;
	},
	ShowContent(mode) {
		if (!mode)
			mode = 'number';
		const name = mode.toUpperCase();
		const view = new DEF.layout.TOOLTIP[name]({
			model : this.model,
			parent: this
		});
		this.showChildView('content', view);
		this.SetPosition();
		$('.command').removeClass('active');
		if ($(`#${mode}.command`).length > 0)
			$(`#${mode}.command`).addClass('active');
		else
			$('#details.command').addClass('active');
	},
	Command(e) {
		const cmd = e.currentTarget.id;
		console.log(cmd);
		switch (cmd) {
		case 'restart_poller':
			const poller = this.model.getPoller();
			poller.set({ command: 'refresh' });
			break;
		case 'edit':
			APP.Route(`#DT/tags/${this.model.get('_id')}`);
			break;
		case 'details':
			this.ShowContent(this.model.getUp('type'));
			break;
		case 'close':
			this.destroy();
			break;
		case 'ack':
			APP.Log(
				`[tags:${this.model.id}] ${this.model.get('alarm_state')} acknowledged by [users:${APP.USER.id}]`,
				`${this.model.get('alarm_state')}_ack`,
				this.model.id
			);
			this.model.set({
				alarm_ack_time: Date.now(),
				alarm_ack_id  : APP.USER.id,
				alarm_state   : `${this.model.get('alarm_state')}_ack`
			});
			break;
		default:
			this.ShowContent(cmd);
			break;
		}
	},
	ShowWidget(e) {
		const name = e.currentTarget.id || 'Gauge';
		$('.command').removeClass('active');

		// Lazy load the widget
		if (!APP.EnsureWidgets([name], this.ShowWidget.bind(this, e)))
			return;

		const props = WID[name].prototype.props;
		if (typeof props.tag_id !== 'undefined')
			props.tag_id = this.model.id;
		else if (typeof props.tag_ids !== 'undefined')
			props.tag_ids = [this.model.id];

		props.widget = name;
		props.width = this.$el.width() - 2;
		props.height = 220;
		props.zero = false;
		const model = new DEF.widgets.Model(props);

		const view = new WID[name]({ model, parent: this });
		this.showChildView('content', view);
		$(this.regions.content).find('.widget').css('position', 'inherit');
	}
});
