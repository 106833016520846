module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'widget_edit_menu\'>\n	<div class=\'command\' id=\'exit\'>\n		'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' <span class=\'minwidth1200\'>EXIT</span>\n	</div>\n\n<div class=\'view\'>\n	One-Line Connector\n</div>\n<div class=\'view\' id=\'global\'>\n	<span class=\'title\'>Global Options</span>\n	<input type=\'checkbox\' disabled> Send Pulses\n\n</div>\n\n<div id=\'commands\'>\n		<button class=\'button command\' id=\'delete\'>\n			'+
((__t=(APP.Tools.icon('delete')))==null?'':__t)+
' delete</button>\n		<button class=\'button command\' id=\'split\'>\n			'+
((__t=(APP.Tools.icon('circle')))==null?'':__t)+
' split</button>\n	</div>\n\n<div class=\'command\' id=\'widgets\'>\n		'+
((__t=(APP.Tools.icon("widgets")))==null?'':__t)+
' <span class=\'minwidth1200\'>WIDGETS</span>\n	</div>\n';
 if (APP.USER.Can("settings")) { 
__p+='\n	<div id=\'settings\' class=\'command\'>\n			'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
'\n		</div>\n	';
 } 
__p+='\n		</div>\n';
}
return __p;
};
