module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (enabled) { 
__p+='\n<div id=\'poller\'>\n	<a href=\'#DT/pollers/'+
((__t=(poller_id))==null?'':__t)+
'/details\'>\n		'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'\n		'+
((__t=(name))==null?'':__t)+
'&nbsp;\n	</a>\n</div>\n<div id=\'state\'>\n	<!-- '+
((__t=(state.replace("* ",APP.Tools.icon('working')+"&nbsp;")))==null?'':__t)+
' -->\n	'+
((__t=(state.replace("* ","")))==null?'':__t)+
'\n\n</div>\n<div id=\'progress\'>\n	<progress id=\'timeleft\' min=0 max="'+
((__t=(polling_rate*1100))==null?'':__t)+
'">\n</div>\n<div id=\'next\'>\n</div>\n';
 } 
__p+='\n';
}
return __p;
};
