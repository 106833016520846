module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var off_text = off_text || '';
var on_text = on_text || '';
var arm_time = arm_time == undefined ? "" : 0;
var alarm_off = alarm_off || "";
var alarm_on = alarm_on || "";
var bit = bit || 0;
var alarm_notify = alarm_notify !== undefined ? alarm_notify : false;
var warning_notify = warning_notify !== undefined ? warning_notify : false;
var alarm_ticket = alarm_ticket !== undefined ? alarm_ticket : false;
var warning_ticket = warning_ticket !== undefined ? warning_ticket : false;

levels= {normal:APP.Lang("Normal"),warning:APP.Lang("Warning"),alarm:APP.Lang("Alarm"),info:APP.Lang("Info"),};

__p+='\n\n	'+
((__t=(APP.UI.select_from_object('cast',{
	raw:"Raw",bool_label:"Label",led:"LED",alarmled:"Alarm LED", switch:"Switch"
}, cast, "field")))==null?'':__t)+
'\n\n		</legend>\n\n		<table>\n			<tr>\n				<th>Pre-Process</th>\n				<td>\n					'+
((__t=(APP.UI.select_from_object('preprocess',{
						none:"raw",invert:"Invert (assert on low)",bitmask:"Bitmask"
					}, preprocess, "field refresh")))==null?'':__t)+
'\n				</td>\n			</tr>\n			';
 if(preprocess === 'bitmask') { 
__p+='\n				<tr>\n					<th>Bit</th>\n					<td>\n						'+
((__t=(APP.UI.select_from_object("bit",["",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],bit)))==null?'':__t)+
'\n					</td>\n				</tr>\n				';
 } 
__p+='\n					<tr>\n						<th>Off Label</th>\n						<th>On Label</th>\n					</tr>\n					<tr>\n						<td>\n							<input type=\'texdt\' id=\'off_text\' class=\'field\' placeholder=\'off\' value=\''+
((__t=(off_text))==null?'':__t)+
'\'>\n						</td>\n						<td>\n							<input type=\'texdt\' id=\'on_text\' class=\'field\' placeholder=\'on\' value=\''+
((__t=(on_text))==null?'':__t)+
'\'>\n						</td>\n					</tr>\n					<tr>\n						<td>\n							'+
((__t=(APP.UI.select_from_object("alarm_off",levels, alarm_off)))==null?'':__t)+
'\n						</td>\n						<td>\n							'+
((__t=(APP.UI.select_from_object("alarm_on",levels, alarm_on)))==null?'':__t)+
'\n						</td>\n					</tr>\n					<tr>\n						<th>\n							Arm time\n						</th>\n						<td>\n							<input type=\'number\' id=\'arm_time\' class=\'field\' value=\''+
((__t=(arm_time))==null?'':__t)+
'\'>\n						</td>\n					</tr>\n					<tr>\n						<th>\n							Arm Symbol\n						</th>\n						<td>\n							'+
((__t=(APP.UI.select_from_collection("alarm_arm", APP.models.tag_library.where({dl_id:dl_id}), "symbol", "name", alarm_arm)))==null?'':__t)+
'\n						</td>\n					</tr>\n					<tr>\n						<th>Browser Notify</th>\n						<td>\n							<input type=\'checkbox\' class=\'field refresh\' id=\'alarm_notify\' '+
((__t=(alarm_notify?"checked":""))==null?'':__t)+
'>\n							'+
((__t=(APP.Tools.icon('alarm','Notify if this tag goes to '+APP.Lang('alarm'))))==null?'':__t)+
'\n							<input type=\'checkbox\' class=\'field refresh\' id=\'warning_notify\' '+
((__t=(warning_notify?"checked":""))==null?'':__t)+
'>\n							'+
((__t=(APP.Tools.icon('warning','Notify if this tag goes to '+APP.Lang('warning'))))==null?'':__t)+
'\n						</td>\n					</tr>\n					';
 if (SETTINGS.emails && SETTINGS.emails.alarm_ticket) { 
__p+='\n						<tr>\n							<th>Create Ticket</th>\n							<td>\n								<input type=\'checkbox\' class=\'field refresh\' id=\'alarm_ticket\' '+
((__t=(alarm_ticket?"checked":""))==null?'':__t)+
'>\n								'+
((__t=(APP.Tools.icon('alarm','Notify if this tag goes to '+APP.Lang('alarm'))))==null?'':__t)+
'\n								<input type=\'checkbox\' class=\'field refresh\' id=\'warning_ticket\' '+
((__t=(warning_ticket?"checked":""))==null?'':__t)+
'>\n								'+
((__t=(APP.Tools.icon('warning','Notify if this tag goes to '+APP.Lang('warning'))))==null?'':__t)+
'\n							</td>\n						</tr>\n						';
 } 
__p+='\n</table>\n';
}
return __p;
};
