module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label",label,"text","G1","Label the generator with this")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<!-- '+
((__t=(APP.UI.select_from_object("shape",["generator","wind turbine"],shape)))==null?'':__t)+
' -->\n			'+
((__t=(APP.UI.tabs("shape",shape,{generator:"Generator","windturbine":"Wind Turbine"})))==null?'':__t)+
'\n\n				<div class=\'tabcontent\' data-for="shape" id=\'generator\'>\n					no unique options.\n			</div>\n				<div class=\'tabcontent\' data-for="shape" id=\'windturbine\'>\n				Blade Count:'+
((__t=(APP.UI.input("rotcount",rotcount,"number","3","Number of blades or coils")))==null?'':__t)+
'\n			</div>\n		</td>\n	</tr>\n	<tr>\n		<th>Energized</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("energized", "tags", energized)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Speed</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("speed", "tags", speed)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Speed scaling</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("rpm_scale",{"1":"1 RPM = 1 rotation","10":"10 RPM = 1 rotation","20":"20 RPM = 1 rotation"},rpm_scale)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<hr>\n			'+
((__t=(APP.UI.checkbox("loadring",loadring,"Draw Load Ring","Draws a green ring around the housing, equivalent to percent load.")))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
