module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<html>\n\n<body>\n	You have been granted access to\n	<B>'+
((__t=(name))==null?'':__t)+
'</B>. Please use the login URL below to automatically log in.\n\n	<ul>\n		<li>\n			<a href="'+
((__t=(homepage))==null?'':__t)+
'">Homepage</a> - Bookmark this for easy access\n		</li>\n		<li>\n			<a href=\''+
((__t=(homepage+"/#login/"+uid))==null?'':__t)+
'\'>Auto-Login</a> - Automatically logs you in, and remembers who you\n			are.\n		</li>\n		<li>\n			<a href=\''+
((__t=(homepage+"/#users/"+uid))==null?'':__t)+
'\'>User Settings</a> - Manage your name, phone number, etc...\n		</li>\n	</ul>\n\n	<img class=\'width:100%; max-width: 100%\' src=\''+
((__t=(logo))==null?'':__t)+
'\'>\n</body>\n\n</html>\n';
}
return __p;
};
