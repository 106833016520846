module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>SCREEN</h1>\n\n';
 if (!id) { 
__p+='\n	<div id=\'new_record\'>\n		'+
((__t=(APP.Tools.icon("plus")))==null?'':__t)+
'\n			Create New Screen\n			<hr>\n			';
 } 
__p+='\n\n				<table>\n					<tr>\n						<th>Name</th>\n						<td>\n							<input type=\'text\' id=\'name\' class=\'field\' value=\''+
((__t=(name))==null?'':__t)+
'\'>\n						</td>\n					</tr>\n					<tr>\n						<th>Icon</th>\n						<td>\n							'+
((__t=(APP.UI.fontawesome("glyph", glyph)))==null?'':__t)+
'\n						</td>\n					</tr>\n					<tr>\n						<th>Kind</th>\n						<td>\n							'+
((__t=(APP.UI.select_from_object("kind",["standard","master","home","mobilehome"],kind)))==null?'':__t)+
'\n						</td>\n					</tr>\n					<tr>\n						<th>Master Screen</th>\n						<td>\n							'+
((__t=(APP.UI.select_from_collection("master_id", APP.models.screens.where({kind:"master"}), "_id", "name", master_id)))==null?'':__t)+
'\n								';
 if (!master_id) { 
__p+='\n									<tr>\n										<th colspan=2>Dimensions\n											'+
((__t=(APP.UI.select_from_object("preset",{"":"","1200x800":"normal","1920x1200":"large","750x1334":"iPhone (tall)","1334x750":"iPhone (wide)","1024x748":"iPad"},dim,"small")))==null?'':__t)+
'\n										</th>\n									</tr>\n									<tr>\n										<td>\n											<input type=\'text\' id=\'width\' class=\'field\' value=\''+
((__t=(width))==null?'':__t)+
'\'>\n										</td>\n										<td>\n											<input type=\'text\' id=\'height\' class=\'field\' value=\''+
((__t=(height))==null?'':__t)+
'\'>\n										</td>\n									</tr>\n									';
 } 
__p+='\n	<tr>\n		<th>\n			Enabled\n		</th>\n		<td>\n			<input type=\'checkbox\' class=\'field\' id=\'enabled\' '+
((__t=(enabled? "checked": ""))==null?'':__t)+
'>\n		</td>\n	</tr>\n				</table>\n				';
 if (!id) { 
__p+='\n	</div>\n	';
 } 
__p+='\n		<h2>Current Display</h2>\n			<table>\n				<tr>\n					<th>\n						Width\n					</th>\n					<td>'+
((__t=(window.innerWidth))==null?'':__t)+
'px</td>\n				</tr>\n				<tr>\n					<th>\n						Height\n					</th>\n					<td>'+
((__t=(window.innerHeight))==null?'':__t)+
'px</td>\n				</tr>\n			</table>\n		<h2>Stats</h2>\n		<table>\n			<tr>\n				<th>Views</th>\n				<td>'+
((__t=(views))==null?'':__t)+
'</td>\n			</tr>\n			<tr>\n				<th>Last View</th>\n				<td>'+
((__t=(APP.Format.datetime(last_view)))==null?'':__t)+
'</td>\n			</tr>\n		</table>\n		<h2>Commands</h2>\n		<button id=\'view\' '+
((__t=(id? "": "disabled"))==null?'':__t)+
' class=\'action btn\'>View Screen</button>\n		<!-- <button id=\'edit\' '+
((__t=(id? "": "disabled"))==null?'':__t)+
' class=\'action btn\'>Edit Screen</button> -->\n\n		<div id=\'details_footer\'>\n			<div class=\'link\' id=\'database\'>\n				'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n					database\n			</div>\n\n			<div class=\'link\' id=\'delete\'>\n				'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
'\n					delete\n			</div>\n\n			<div class=\'link\' id=\'duplicate\'>\n				'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
'\n					duplicate\n			</div>\n		</div>\n';
}
return __p;
};
