module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Valve Shape</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("shape",{"gate":"Gate","butterfly":"Butterfly Valve","check":"Check Valve","globe":"Globe Valve","ball":"Ball Valve","three":"Three Way"},shape)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Modifier</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("mod",{"none":"None","stop":"Stop","control":"Control","safety":"Safety","label":"Label"},mod,"field refresh")))==null?'':__t)+
'\n			';
 if (mod === 'label') { 
__p+='\n			<input type=\'text\' maxlength="1" size="1" id=\'mod_label\' value=\''+
((__t=(mod_label))==null?'':__t)+
'\' class=\'field\'>\n			';
 } 
__p+='\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Valve State</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
