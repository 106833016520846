module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Align</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("align", {"left":"Left","center":"Center","right":"Right"}, align)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n			<th>Size</th>\n			<td>\n					<input type=\'range\' min=10 max=50 step=5 id=\'fontsize\' value=\''+
((__t=(fontsize))==null?'':__t)+
'\' class=\'field live\'>\n			</td>\n	</tr>\n<tr>\n	<td colspan=2>\n<fieldset>\n	<legend>Show:</legend>\n<table>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_value\' value=\'true\' '+
((__t=(show_value? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'\'>Value</span>\n		</td>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_unit\' value=\'true\' '+
((__t=(show_unit? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'\'>Unit</span>\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_name\' value=\'true\' '+
((__t=(show_name? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'\'>Name</span>\n		</td>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_description\' value=\'true\' '+
((__t=(show_description? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'\'>Description</span>\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_image\' value=\'true\' '+
((__t=(show_image? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'\'>Image</span>\n		</td>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_delta\' value=\'true\' '+
((__t=(show_delta? "checked": ""))==null?'':__t)+
' class=\'field refresh\'>\n			<span title=\'\'>Delta</span>\n		</td>\n	</tr>\n\n\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'usecolors\' value=\'true\' '+
((__t=(usecolors? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'Change the color of the value based on alarm state\'>Alarm Colors</span>\n		</td>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'show_icon\' value=\'true\' '+
((__t=(show_icon? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'Show the icon in the alarm state\'>Alarm Icon</span>\n		</td>\n	</tr>\n</table>\n</fieldset>\n	</td>\n</tr>\n';
 if (show_delta) { 
__p+='\n	<tr>\n		<th>Delta time</th>\n		<td>'+
((__t=(APP.UI.select_from_object("delta_field",{"delta":"Last Read","delta_1m":"1 minute ago","delta_1h":"1 hour ago"},delta_field)))==null?'':__t)+
'</td>\n	</tr>\n</table>\n';
 } 
__p+='\n';
}
return __p;
};
