module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var code = code || {}

__p+='\n\n'+
((__t=(APP.UI.select_from_object('cast',{
	raw:"Raw",code:"Lookup"
}, cast, "field")))==null?'':__t)+
'\n\n</legend>\n\n<table>\n	<tr>\n		<td>\n			ex: { "1":"Running", "2":"Stopped", "3":"Missing".... }\n		</td>\n	</tr>\n	<tr>\n		<td>\n			<textarea id=\'code\' placeholder=\'{ "key":"value" }\'\n				class=\'json field\'>'+
((__t=(JSON.stringify(code, null, '\t')))==null?'':__t)+
'</textarea>\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
