module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var unit = unit !== undefined  ? unit : "";
var decimals = decimals !== undefined  ? decimals : "";
var decimal_shift = decimal_shift !== undefined  ? decimal_shift : 0;
var reset= reset !== undefined ? reset : "never";
var value_rate = value_rate !== undefined ? value_rate: "0"
var reset_totalizer= reset_totalizer !== undefined ? reset_totalizer : "never"
var warning_high = warning_high !== undefined ? warning_high : "";
var alarm_high = alarm_high !== undefined ? alarm_high : "";


__p+='\n	'+
((__t=(APP.UI.select_from_object('cast',{
		raw:"Raw",analog:"Fixed" // "progress bar?"
	}, cast, "field")))==null?'':__t)+
'\n	\n</legend>\n<div class=\'note\'>The Rate class takes analog values, and computes rate of change</div>\n		<table>\n			<tr>\n				<th>Pre-Process</th>\n				<td>\n					'+
((__t=(APP.UI.select_from_object('preprocess',{
								none:"none",invert:"Invert", pint:"Parse Unsigned Int",psignedint: "Parse Signed Int", pfloatAB:"Parse Float (ABCD)", pfloatBA:"Parse Float (CDAB)",plongAB:"Parse Long (ABCD)", plongBA:"Parse Long (CDAB)",CtoF:"Celcius to Fahrenheit",FtoC:"Fahrenheit to Celcius","nounit":"Remove Unit"
							}, preprocess, "field")))==null?'':__t)+
'\n				</td>\n			</tr>\n			<tr>\n				<th>Unit</th>\n				<td>\n					<input id=\'unit\' type=\'text\' value=\''+
((__t=(unit))==null?'':__t)+
'\' class=\'field\' bad  list=\'unitlist\'>\n				</td>\n			</tr>\n			<tr>\n				<th>Deadband</th>\n				<td>\n					<input id=\'deadband\' type=\'number\' value=\''+
((__t=(deadband))==null?'':__t)+
'\' class=\'field\' placeholder=\'deadband\'>\n				</td>\n			</tr>\n			<tr>\n				<th>Decimals</th>\n				<td>\n					<input '+
((__t=(cast!=='analog'?'disabled':''))==null?'':__t)+
' id=\'decimals\' type=\'number\' value=\''+
((__t=(decimals))==null?'':__t)+
'\' class=\'field\' placeholder=\'decimals\'>\n				</td>\n			</tr>\n			<tr>\n				<th>Multiplier</th>\n				<td>\n					<!-- <input id=\'decimal_shift\' type=\'number\' value=\''+
((__t=(decimal_shift))==null?'':__t)+
'\' class=\'field\' placeholder=\'decimal shift\'> -->\n					'+
((__t=(APP.UI.select_from_object('decimal_shift',{
								"-3":"&times;0.001","-2":"&times;0.01","-1":"&times;0.1","0":"none","1":"&times;10","2":"&times;100","3":"&times;1000"
							}, decimal_shift, "field")))==null?'':__t)+
'\n				</td>\n			</tr>\n			<!-- <tr>\n				<th>Warning Level</th>\n				<td>\n					<input id=\'warning_high\' type=\'number\' value=\''+
((__t=(warning_high))==null?'':__t)+
'\' class=\'field\' placeholder=\'warning level\'>\n				</td>\n			</tr>\n			<tr>\n				<th>Alarm Level</th>\n				<td>\n					<input id=\'alarm_high\' type=\'number\' value=\''+
((__t=(alarm_high))==null?'':__t)+
'\' class=\'field\' placeholder=\'alarm level\'>\n				</td>\n			</tr> -->\n			<tr>\n				<td colspan="2">\n					<hr>\n				</td>\n			</tr>\n			<tr>\n				<th>Rate</th>\n				<td>'+
((__t=(APP.UI.select_from_object("value_rate",{ "1":"Unit per Sec","60":"Unit per Min", "3600":"Unit per Hour", "86400":"Unit per Day"},value_rate, "field")))==null?'':__t)+
'</td>\n			</tr>\n			<!-- <tr>\n				<th>Reset to Zero</th> \n				<td>\n					'+
((__t=(APP.UI.select_from_object("reset_totalizer",{never:"Never",daily:"Daily",weekly:"Weekly",monthly:"Monthly",yearly:"Yearly"},reset_totalizer, "field")))==null?'':__t)+
'<br>\n				</td>\n			</tr> -->\n		</table>\n		';
}
return __p;
};
