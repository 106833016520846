module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';


 const filter= APP.Tools.store('filter', _id) || ['search']
 
__p+='\n<div id=\'header\'>\n	<span id=\'expand\'>\n		'+
((__t=(APP.Tools.icon("expand")))==null?'':__t)+
'\n	</span>\n	&nbsp;'+
((__t=(device))==null?'':__t)+
'\n	<div class=\'rightmenu\'>\n		<div id=\'small_devices\'></div>\n		<div class=\'submenu\' id=\'device_library_menu\'>\n			<div class=\'link menuitem tab\' data-view=\'details\' id=\'edit\'>\n				'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' edit "'+
((__t=(device))==null?'':__t)+
'"\n			</div>\n			<div class=\'link menuitem tab\' data-view=\'details\' id=\'add\'>\n				'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' add a device\n			</div>\n			<div id=\'typefilter\'>\n				<div id=\'search\' class=\'filter '+
((__t=(filter.indexOf("search")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('search')))==null?'':__t)+
'\n				</div>\n				<div id=\'formula\' class=\'filter type '+
((__t=(filter.indexOf("formula")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('formula', 'Show only tags with Formulas')))==null?'':__t)+
'\n				</div>\n				<div id=\'number\' class=\'filter type '+
((__t=(filter.indexOf("number")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('number')))==null?'':__t)+
'\n				</div>\n				<div id=\'boolean\' class=\'filter type '+
((__t=(filter.indexOf("boolean")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('boolean')))==null?'':__t)+
'\n				</div>\n				<div id=\'string\' class=\'filter type '+
((__t=(filter.indexOf("string")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('string')))==null?'':__t)+
'\n				</div>\n				<div id=\'date\' class=\'filter type '+
((__t=(filter.indexOf("date")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('date')))==null?'':__t)+
'\n				</div>\n				<div id=\'code\' class=\'filter type '+
((__t=(filter.indexOf("code")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('code')))==null?'':__t)+
'\n				</div>\n				<div id=\'totalizer\' class=\'filter type '+
((__t=(filter.indexOf("totalizer")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('totalizer')))==null?'':__t)+
'\n				</div>\n				<div id=\'rate\' class=\'filter type '+
((__t=(filter.indexOf("rate")===-1?"on":"off"))==null?'':__t)+
'\'>\n					'+
((__t=(APP.Tools.icon('rate')))==null?'':__t)+
'\n				</div>\n			</div>\n			<input '+
((__t=(filter.indexOf("search")===-1?"":"hidden"))==null?'':__t)+
' type=\'search\' placeholder=\'search\' id=\'searchbox\'\n				value=\''+
((__t=(APP.Tools.store('filtersearch', _id)))==null?'':__t)+
'\'></input>\n		</div>\n	</div>\n</div>\n<div id=\'device_box\'>\n	';
 if (manufacturer) { 
__p+='\n	<div id="vendor">\n		<div style="float:right">\n			';
 if (homepage_url) { 
__p+='\n				<a target="_new" href="'+
((__t=(homepage_url))==null?'':__t)+
'">Homepage</a>\n			';
 } 
__p+='\n			';
 if (homepage_url) { 
__p+='\n				| <a target="_new" href="'+
((__t=(tag_address_url))==null?'':__t)+
'">Manual</a>\n			';
 } 
__p+='\n		</div>\n		'+
((__t=(manufacturer))==null?'':__t)+
': '+
((__t=(model))==null?'':__t)+
' ('+
((__t=(partnumber))==null?'':__t)+
')\n	</div>\n	';
 } 
__p+='\n	<div id=\'devices\'></div>\n\n</div>';
}
return __p;
};
