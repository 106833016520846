module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Color</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("color",{"tag":"Tag's Color","alarm":"Alarm State", "#F00":"red","#FF0":"yellow","#0F0":"green","#F0F":"cyan","#00F":"blue","#0FF":"cyan"},color)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
