module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>'+
((__t=(APP.Tools.icon("tag_library")))==null?'':__t)+
' '+
((__t=(symbol))==null?'':__t)+
'</h2>\n\n<table>\n	<tr>\n		<th>Symbol</th>\n		<td>\n			'+
((__t=(symbol))==null?'':__t)+
'\n		</td>\n		<th>Name</th>\n		<td>\n			'+
((__t=(name))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Type</th>\n		<td>\n			'+
((__t=(type))==null?'':__t)+
'\n		</td>\n		<th>Cast</th>\n		<td>\n			'+
((__t=(cast))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Address</th>\n		<td>\n			'+
((__t=(address))==null?'':__t)+
'\n		</td>\n		<th>Preprocessor</th>\n		<td>\n			'+
((__t=(preprocess))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Device</th>\n		<td class=\'link\' id=\'device_library\'>\n			'+
((__t=(APP.Tools.icon('device_library')))==null?'':__t)+
'\n				'+
((__t=(APP.models.device_library.get(dl_id).getName()))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
