module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label",label,'text','label','','live')))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Direction</th>\n		<td>\n			'+
((__t=(APP.UI.radio_from_object("dir",{"N":"North","E":"East","W":"West","S":"South"},dir)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Screen</th>\n		<td>\n			<!-- '+
((__t=(APP.UI.select_from_collection("go_to_screen", APP.models.screens, "_id", "name",go_to_screen) ))==null?'':__t)+
' -->\n			'+
((__t=(APP.UI.chooser("go_to_screen", "screens", go_to_screen)))==null?'':__t)+
'\n			<input type=\'button\' value=\'+ Create New Screen\' id=\'newscreen\' onclick="(function() {\n					var name = prompt(\'Name the new screen\');\n					if (name) {\n						APP.models.screens.add({\n							name:name,\n							master_id: APP.models.screens.where({\'kind\':\'master\'}).shift().id\n						})\n					}\n				})()">\n\n		</td>\n	</tr>\n	<tr>\n		<th>State</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("state", "tags", state)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
