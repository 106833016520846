module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'imgbox\'>\n<div class=\'headshot\'>\n'+
((__t=(APP.Format.image(image_url, 'fa-9x')))==null?'':__t)+
'\n</div>\n<div class=\'labels\'>\n'+
((__t=(name))==null?'':__t)+
'\n	<div class=\'last_visit\'>\n		'+
((__t=(APP.Format.livetime(last_visit)))==null?'':__t)+
'\n	</div>\n</div>\n<div id=\'perms\'>\n	';

	var list = ["editor","settings","notify","dataexport","perms"]
	for (var l in list) {
		var p = list[l];
		print("<div class='perm "+(perm[p]?"enabled":"disabled")+"' title='"+p+"'>"+APP.Tools.icon(p)+"</div>");
	}
	
__p+='\n</div>\n';
}
return __p;
};
