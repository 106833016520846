DEF.data_archive = {};
DEF.data = {};

DEF.data.Initialize = function init(callback, constraints) {
	const options = {
		model: DEF.data.Model,
		url  : `${SETTINGS.dburl}/data`
		// Comparators are disbaled in backbone.highway #2.8.3.1.17
		// comparator(m) {
		// 	return -m.get('d');
		// },
	};

	return APP.InitializeModels('data', options, callback, constraints);


	// if (constraints) {
	// 	options.minimum = constraints;
	// }
	// DEF.data.Collection = DEF.TG.Collection.extend(options);
	// var collection = new DEF.data.Collection(),
	// 	start_time = Date.now();
	// collection.once("sync", function() {
	// 	if (process.stdout)
	// 		process.stdout.write(".");
	// 	else
	// 		APP.root.getRegion('main').currentView.Progress('data');
	// 	//		debugger
	// 	callback();
	// });
	// return collection;
};

DEF.data.Model = DEF.TG.Model.extend({
	// initialize: function () {
	// 	console.log("m");
	// },
	idAttribute      : '_id',
	parentIdAttribute: 't',
	parentModule     : 'tags',
	collection_name  : 'data',
	defaults         : {
		t: '',
		v: '',
		d: '',
		r: ''
	},
	db_spreadsheet: ['d', 'v', 'r'],
	db_columns    : ['t', 'd', 'r'],
	db_filters    : ['r'],
	db_comparator(m) { return -m.get('d'); },
	// db_tools      : {
	// 	'Deploy Tags' : 'DeployTags',
	// 	'Kill Orphans': 'KillOrphans',
	// },
	getName() {
		return this.get('v');
	}
});

DEF.data_archive.Initialize = function init(callback, constraints) {
	const options = {
		model: DEF.data_archive.Model,
		url  : `${SETTINGS.dburl}/data_archive`
		// comparator(m) {
		// 	return -m.get('d');
		// },
	};

	return APP.InitializeModels('data_archive', options, callback, constraints);
	// if (constraints) {
	// 	options.minimum = constraints;
	// }
	// DEF.data_archive.Collection = DEF.TG.Collection.extend(options);
	// var collection = new DEF.data_archive.Collection(),
	// 	start_time = Date.now();
	// collection.once("sync", function() {
	// 	if (process.stdout)
	// 		process.stdout.write(".");
	// 	callback();
	// });
	// return collection;
};

DEF.data_archive.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: 't_id',
	parentModule     : 'tags',
	collection_name  : 'data_archive',
	defaults         : { // shitty field names for brevity.  Expecting lots of data
		t: '',
		v: '',
		d: ''
	},
	db_columns: ['t', 'd', 'r'],
	db_filters: ['r'],
	getName() {
		return this.get('v');
	}
});
