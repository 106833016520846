module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1 title=\'\'>\n	'+
((__t=(APP.Tools.icon('download')))==null?'':__t)+
'\n	Import\n</h1>\n\n<p>This tool allows you to import objects from other Telegauge installations.</p>\n\n\n<table>\n	<tr>\n		<th>Mode</th>\n		<td>'+
((__t=(mode))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th colspan=2>Source</th>\n	</tr>\n	<tr>\n		<td colspan=2><input class=\'field\' type=\'text\' id=\'dburl\' placeholder=\'https://dev.telegauge.com/dev\' value=\''+
((__t=(dburl))==null?'':__t)+
'\'></td>\n	</tr>\n</table>\n\n<button class=\'btn command\' id=\'scan\'>'+
((__t=(APP.Tools.icon('search')))==null?'':__t)+
' Scan</button>\n\n<fieldset style=\'display:none\'>\n	<legend>Results</legend>\n	<div id=\'results\'></div>\n</fieldset>\n';
}
return __p;
};
