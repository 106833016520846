/*
██     ██ ██ ██████   ██████  ███████ ████████      ██████ ██       █████  ███████ ███████
██     ██ ██ ██   ██ ██       ██         ██        ██      ██      ██   ██ ██      ██
██  █  ██ ██ ██   ██ ██   ███ █████      ██        ██      ██      ███████ ███████ ███████
██ ███ ██ ██ ██   ██ ██    ██ ██         ██        ██      ██      ██   ██      ██      ██
 ███ ███  ██ ██████   ██████  ███████    ██         ██████ ███████ ██   ██ ███████ ███████
*/
DEF.widgets = {};

DEF.widgets.Initialize = function init_widget(callback, constraints) {
	const options = {
		model: DEF.widgets.Model,
		url  : `${SETTINGS.dburl}/widgets`,
		byScreen(screen_id, master_id) {
			var widgets = this.filter((m) => m.get('screen_id') === screen_id || m.get('screen_id') === master_id)
			return new DEF.widgets.Collection(widgets);
		},
		comparator(m) {
			let key = m.get('screen_id') + m.get('widget');
			key = (m.get('zindex') * 10000) - m.get('width') - m.get('height');
			// console.log(m.getName(), key);
			return key;
		}
	};
	return APP.InitializeModels('widgets', options, callback, constraints);
};

DEF.widgets.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: '_id',
	parentModule     : 'screen',
	collection_name  : 'widgets',
	defaults         : {
		widget : 'Table',
		updated: false,
		changed: false,
		width  : 100,
		height : 100,
		left   : 100,
		top    : 100,
		zindex : 10,
		engine : 'html'
	},
	db_columns    : ['widget', 'screen_id', 'left', 'top', 'width', 'height'],
	db_spreadsheet: ['screen_id', 'width', 'height', 'left', 'top', 'zindex'],
	db_filters    : ['screen_id', 'widget'],

	/**
	 * Given a canvas, draw a tiny representation of the widget.  Used in the Screen previews.
	 * @param {} canvas
	 * @param {*} x
	 * @param {*} y
	 * @param {*} w
	 * @param {*} h
	 */
	DrawTinyView(canvas, x, y, w, h) {
		const widget = this.get('widget');
		const circles = ['Gauge', 'LED', 'Generator', 'Triangle', 'PhaseBalance', 'Compass', 'ArcGauge', 'PieChart', 'Radar', 'PipeHub', 'Node'];
		const triangles = ['Rampchart'];

		if (circles.indexOf(widget) >= 0) {
			const r = Math.min(w, h) / 2;
			canvas.moveTo(x + (w / 2) + r, y + (h / 2));
			canvas.arc(x + (w / 2), y + (h / 2), r, 0, 2 * Math.PI);
		} else if (triangles.indexOf(widget) >= 0) {
			// canvas.beginPath();
			canvas.moveTo(x, y + h);
			canvas.lineTo(x + w, y + h);
			canvas.lineTo(x + w, y);
			canvas.lineTo(x, y + h);
		} else {
			canvas.rect(x, y, w, h);
		}
		const fa = this._fa_unicode || this.get_fa_unicode();
		if (fa) {
			const fontsize = Math.min(w, h) * 0.75;
			canvas.font = `${fontsize}px FontAwesome`;
			const width = canvas.measureText(fa).width;
			canvas.fillText(fa, x + (w / 2) - (width / 2), y + (h / 2) + (fontsize / 3) + 1);
		}
		if (this.attributes.connectors)
			this.DrawTinyConnectors(canvas);
	},
	DrawTinyConnectors(canvas) {
		const nodes = this.get('nodes');
		const conns = this.attributes.connectors;
		for (const dir in conns) {
			const node = conns[dir][1];
			if (node) {
				const other = APP.models.widgets.get(conns[dir][1].id);
				if (other) {
					const to_node = other.get('nodes');
					if (to_node && to_node[node.dir] && node[node.dir] && to_node[node.dir][1] && nodes[dir][1]) {
						const x1 = nodes[dir][1].x + this.get('left');
						const y1 = nodes[dir][1].y + this.get('top');
						const x2 = to_node[node.dir][1].x + other.get('left');
						const y2 = to_node[node.dir][1].y + other.get('top');
						const path = DEF.widgets.RT.vector.prototype.get_connector_path(dir, x1, y1, node.dir, x2, y2);
						const draw = new Path2D(path);
						canvas.stroke(draw);
					}
				}
			}
		}
	},

	/**
	 * Convert the Font-Awesome icon to it's associated unicode;
	 */
	get_fa_unicode() {
		let code = this._fa_unicode || false;
		if (!code) {
			const icon = APP.Tools.icon(this.get('widget'));
			const match = icon.match(/fa.fa-(.*) '/);
			if (match && match[1]) {
				const fa = require('font-awesome-icon-chars');
				const name = match[1];
				if (fa)
					for (const f in fa)
						if (fa[f].id === name)
							code = String.fromCharCode(parseInt(fa[f].unicode, 16));
			}
			if (code)
				this._fa_unicode = code;
		}
		return code;
	},

	/**
	 * A convenience function to move the widget. useful in DT.
	 * @param  {[type]} left   [description]
	 * @param  {[type]} top    [description]
	 * @param  {[type]} width  [description]
	 * @param  {[type]} height [description]
	 * @return {[type]}        [description]
	 */
	Move(left, top, width, height, resize_mode, snap = true) {
		const grid = APP.Tools.store('dt', 'grid') || 10;
		const geo = this.getGeo();

		
		if (resize_mode.indexOf('resize') === -1) {
			left = this.Snap('x', left, width, snap);
			top = this.Snap('y', top, height, snap);
			width = Math.max(grid, width);
			height = Math.max(grid, height);
		} else {
			if (['w-', 'sw', 'nw'].indexOf(resize_mode.slice(0, 2)) === 0)
			left = this.Snap('x', left, width, snap);
			width = this.Snap('x', left + width, width, snap) - left;
			if (resize_mode.slice(0, 1) === 'n')
			top = this.Snap('y', top, height, snap);
			height = this.Snap('y', top + height, height, snap) - top;
			// else
			// 	height = this.Snap("y", top, height);
		}
		if (geo.square)
			width = height = Math.max(width, height);
		
		left = Math.max(0-width+1, left) | 0
		top = Math.max(0 - height + 1, top) | 0
		width = APP.Format.clamp(width, geo.min_width, geo.max_width) | 0,
		height = APP.Format.clamp(height, geo.min_height, geo.max_height) | 0

		
		const old = {
			left  : this.get('left'),
			top   : this.get('top'),
			width : this.get('width'),
			height: this.get('height')
		};
		if (old.left !== left || old.top !== top || old.width !== width || old.height !== height) {
			if (this.last_ltwh_set !== JSON.stringify({ left, top, width, height })) {
				console.log("Widget Set>", left, top, width, height);
				this.last_ltwh_set = JSON.stringify({ left, top, width, height });
				this.set({ left, top, width, height });
				// this.trigger('moved');
				// this.UpdateScreenStats();
			}
		}
	},

	/**
	 * Snaps a dimension to the closest snap line (dir[]) or grid)
	 * @param {[type]} dir [description]
	 * @param {[type]} x   [description]
	 * @param {[type]} w   [description]
	 */
	Snap(dir, x, w, snap = true) {
		const grid = APP.Tools.store('dt', 'grid') || 10;
		if (!snap || APP.Tools.store('dt', 'snap') === 'off')
			return Math.round(x / grid) * grid;
		if (APP.snap)
			for (const i in APP.snap[dir])
				if (Math.abs(x - APP.snap[dir][i]) < grid * 2)
					return APP.snap[dir][i];

		return Math.round(x / grid) * grid;
	},

	/**
	 * This stupid function looks for all the model IDs in the props and updates
	 * a lookup table for possible use later on.  For example, rebuild tags could
	 * change the model IDs (hopefully not) or copying widgets to other installations
	 */
	GenerateIDtoName() {
		const attr = JSON.stringify(this.attributes);
		const ids = attr.match(/[a-f\d]{24}/gi);
		const lookup = this.get('_tag_lookup') || {};
		for (const i in ids) {
			const id = ids[i];
			const collections = ['tags', 'devices', 'device_library'];
			for (const c in collections) {
				const collection = collections[c];
				const model = APP.models[collection].get(id);
				if (model)
					lookup[id] = `${collection}:${model.getName()}`;
			}
		}
		// console.log(lookup);
		this.set('_tag_lookup', lookup);
	},

	left() {
		return this.get('left');
	},
	top() {
		return this.get('top');
	},
	width() {
		return this.get('width');
	},
	height() {
		return this.get('height');
	},
	right() {
		return this.get('left') + this.get('width');
	},
	bottom() {
		return this.get('top') + this.get('height');
	},
	setLeft(l, preserve_right = false) {
		const set = { left: l };
		if (preserve_right)
			set.width = this.width() + (this.left() - l);
		this.set(set);
	},
	setTop(t, presrve_bottom = false) {
		const set = { top: t };
		if (presrve_bottom)
			set.height = this.height() + (this.top() - t);
		this.set(set);
	},
	setRight(r, preserve_left = false) {
		if (preserve_left)
			this.set('width', r - this.left()); 		else
			this.set('left', r - this.width());
	},
	setBottom(b, preserve_top = false) {
		if (preserve_top)
			this.set('height', b - this.top()); 		else
			this.set('top', b - this.height());
	},
	setWidth(w) {
		this.set('width', w);
	},
	setHeight(h) {
		this.set('height', h);
	},
	// ** .get doesn't seem to override, so getProp exists
	Xget(attr) {
		console.log(attr);
		const out = Backbone.Model.prototype.get.call(this, attr); // load the acutal .get.
		// if (attr == 'alarms')
		// 	debugger
		// if (_.isUndefined(out)) {
		// 	debugger
		// 	return WID[this.model.attributes.widget].prototype.props[attr];
		// }
		return out;
	},
	all_fields() {
		return $.extend({}, WID[this.attributes.widget].prototype.props, this.attributes);
	},
	getGeo() {
		const defaults = {
			min_width : 5, min_height: 5, max_width : 2000, max_height: 1500, square    : false
		};
		const geo = _.extend({}, defaults, WID[this.get('widget')].prototype.geo);
		return geo;
	},
	//
	getProp(attr) {
		// console.warn('this may not be needed anymore.  Just use the view\'s getProp');
		const props = this.all_fields();
		return props[attr];
	},
	getName() {
		const widget = this.get('widget');
		//		console.log('getname', widget, this.get('label'));
		if (this.get('go_to_screen')) {
			const screen = APP.models.screens.get(this.get('go_to_screen'));
			if (screen)
				return `Go to ${screen.getName()}`;
		}
		if (this.get('tag_id')) {
			const tag = APP.models.tags.get(this.get('tag_id'));
			if (tag)
				return `${tag.getName()} ${widget}`;

			this.set('tag_id', false); // the tag must have been deleted
		}
		if (this.get('title'))
			return this.get('title');
		if (this.get('label'))
			return this.get('label');
		if (this.get('text'))
			return this.get('text');
		if (this.get('shape'))
			return this.get('shape');
		return widget;
	},
	getIcon() {
		return APP.Tools.icon(APP.widget_icons[this.get('widget')]);
	},
	getTag(key = 'tag_id') {
		return APP.models.tags.get(this.get(key));
	},
	UpdateScreenStats() {
		const screen = APP.models.screens.get(this.get('screen_id'));
		let screens = [];
		if (screen.get('kind') === 'master')
			screens = APP.models.screens.filter('_id', this.id);

		screens.push(screen);
		for (let s = 0; s < screens.length; s++)
			screens[s].set('updated', Date.now());
	},

	/**
	 * Used when copying widgets
	 */
	GetCloneAttributes() {
		const attr = JSON.parse(JSON.stringify(this.attributes));
		if (attr.left + attr.width < APP.current_screen.get('width'))
			attr.left = Number(attr.left) + Number(attr.width);
		else
			attr.top = Number(attr.top) + Number(attr.height);

		delete attr.connectors;

		return attr;
	}


});
