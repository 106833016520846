/**
 * A View for use in DT Details
 */
DEF.TG.Details = Backbone.Marionette.View.extend({
	template_extra_fields: [],

	/**
	 * Make sure you populae the model's "ups" field to add parent attributes.
	 */
	templateContext() {
		const attr = this.model.getUps();
		attr.id = false;
		attr.collection = this.module || 'users';
		if (this.model)
			attr.id = this.model.id;
		const extras = this.templateExtras();
		_.extend(attr, extras);
		return attr;
	},

	/** allows views to include extra template fields without copying the entire
	   templateContext function
		 * */
	templateExtras() {
		return {};
	},
	ui: {
		field    : '.field',
		livefield: '.field.live',
		refresh  : '.field.refresh', // refresh = re-display props when this field changes (useful for showing/hiding additional options)
		crypt    : '.crypt',
		commands : '#details_footer .link',
		json     : 'textarea.json',
		action   : '.action',
		extra    : '.extrafield',
	},
	events: {
		'keyup @ui.json'     : 'ValidateJSON',
		'change @ui.field'   : 'Save',
		'change @ui.crypt'   : 'SaveCrypt',
		'change @ui.refresh' : 'render',
		'input @ui.field'    : 'onInputField',
		'input @ui.livefield': 'Save',
		'click @ui.commands' : 'Command',
		'click @ui.action'   : 'DoAction',
		'change @ui.extra'   : 'DoExtraField',
	},
	modelEvents: {
		change: 'TestRender',
	},
	onRender() {
		APP.root.getRegion('main').currentView.ToggleDetails(true);
		// console.log('onrender');
		this.RevertJSON();
	},
	onAttach() {
		if (!APP.USER.Can('settings'))
			$('.field').attr('disabled', 'disabled'); // .addClass("readonly");
	},
	onChangeField() {
		// override me for custom action.
	},
	onInputField() {
		// override me for custom action.
	},
	RevertJSON() {
		if (this.ui.json)
			this.ui.json.val(JSON.stringify(this.model.get($(this.ui.json).attr('id')), null, '\t'));
	},
	ValidateJSON() {
		try {
			JSON.parse(this.ui.json.val());
			this.ui.json.removeClass('badjson');
		} catch (e) {
			this.ui.json.addClass('badjson');
		}
	},

	/**
	 * See if the currently selected element has .field class, and if so,
	 * do not render!
	 */
	TestRender() {
		const edit_in_progress = Date.now() - APP._last_field_save_time < 100;
		if (!edit_in_progress)
			this.render();
		delete APP._last_field_save_time;
	},
	SaveCrypt(e) {
		const id = e.currentTarget.id;
		const value = e.currentTarget.value;

		const bcrypt = require('bcryptjs');
		const salt = bcrypt.genSaltSync(10);
		const hash = bcrypt.hashSync(value, salt);

		console.log('crypt', id, value, hash);
		e.currentTarget.value = hash;
		this.Save(e);
	},
	Save(e) {
		let val = e.currentTarget.value;
		console.log('SAVE>', e.currentTarget.type, e.currentTarget.id, e.currentTarget.value, e.currentTarget.checked);
		APP._last_field_save_time = Date.now();

		switch (e.currentTarget.type) {
		case 'textarea':
			if ($(e.currentTarget).hasClass('json'))
				val = JSON.parse(val);
			this.model.set(e.currentTarget.id, val);
			break;
		case 'checkbox':
			this.model.set(e.currentTarget.id, e.currentTarget.checked);
			break;
		default:
			if (isNumber(val))
				val = parseFloat(val);
			this.model.set(e.currentTarget.id, val);
		}

		if (_.isUndefined(this.model.id) || _.isUndefined(APP.models[this.model.collection_name].get(this.model.id)))
			// new record
			APP.models[this.model.collection_name].add(this.model);


		this.onChangeField(e);
	},
	Command(e) {
		const cmd = e.currentTarget.id;
		switch (cmd) {
		case 'database':
			this.Database();
			break;
		case 'duplicate':
			this.Duplicate();
			break;
		case 'delete':
			this.Delete();
			break;
			// default:
			// 	console.warn("say what?", cmd);
		}
	},
	Database(collection, id) {
		collection = collection || this.model.collection_name;
		id = id || this.model.id;
		APP.Route(`#DT/database/${collection}/${id}`);

		// TODO: this shows ONLY the json record, but wont save
		//		var details = new DEF.database.DT.Details({model: this.model});
		//		APP.root.getChildView("main").showChildView("details", details);
	},
	Duplicate() {
		const attr = this.model.GetCloneAttributes();
		APP.models[this.model.collection_name].create(attr);
	},
	Delete() {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Are you sure you want to delete this record?')) {
			// APP.models[this.model.collection_name].remove(this.model);
			this.model.destroy();
			APP.Tools.DeployTags();
			APP.Route(`#DT/${this.model.collection_name}`); // refresh doesn't exist.  and neither does the model, so route to the collection
		}
	},
});

DEF.TG.EmptyDetails = Backbone.Marionette.View.extend({
	className: 'empty',
	ui       : {
		create : '#create',
		command: '.command',
		import : '#import',
	},
	events: {
		'click @ui.create' : 'CreateModel',
		'click @ui.command': 'DoCommand',
		'click @ui.import' : 'Import',
	},
	CreateModel() {
		let watch;
		const collection = this.options.collection_name;
		const model = new DEF[collection].Model();
		console.log('create new', collection);

		// watch = setInterval(() => {
		// 	console.log(model);
		// 	if (model.get('_id')) {
		// 		clearInterval(watch);
		// 		APP.Route(`#DT/${collection}/${model.get('_id')}`);
		// 	}
		// }, 10);

		APP.models[collection].create(model);
		APP.models[collection].once('highway:model_created', () => {
			console.log('Model Created', model.get('_id'));
			APP.Route(`#DT/${collection}/${model.get('_id')}`);
		});
	},
	DoCommand() {
	},
	Import() {
		APP.Route(`#DT/${this.options.collection_name}/import`);
	},
});

DEF.TG.Empty = Backbone.Marionette.View.extend({
	template: require('../rt/templates/empty.html'),
	templateContext() {
		return { message: this.options.message || 'No data to display.' };
	},
});


DEF.TG.Page = Backbone.Marionette.View.extend({});

// VIEWS
require('../models/settings/view.js');
require('../models/database/view.js');
require('../models/device_library/view.js');
require('../models/tag_library/view.js');
require('../models/device/view.js');
require('../models/tag/view.js');
require('../models/user/view.js');
require('../models/widget/view.js');
require('../models/widget/view.html.js');
require('../models/widget/view.canvas.js');
require('../models/widget/view.vector.js');
require('../models/screen/view.js');
require('../models/poller/view.js');
require('../models/report/view.js');
require('../models/files/view.js');
require('../models/events/view.js');
require('../models/network/view.js');
