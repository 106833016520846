module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'widget_edit_menu\'>\n	<div class=\'command\' id=\'exit\'>\n		'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' <span class=\'minwidth1200\'>EXIT</span>\n	</div>\n<div id=\'screen\'>\n		'+
((__t=(APP.current_screen.getf('_id')))==null?'':__t)+
'\n			';

			var master_id = APP.current_screen.get('master_id');
			if (master_id) {
			var master =  APP.models.screens.get(master_id); 
__p+='\n				/\n				<a href=\'#DT/edit/'+
((__t=(master.get("name")))==null?'':__t)+
'\'>\n					'+
((__t=(master.get("name")))==null?'':__t)+
'\n				</a>\n				';
 } 
__p+='\n	</div>\n	<div id=\'views\'>\n		<div class=\'bar_box\'>\n		Grid:\n		'+
((__t=(APP.UI.select_from_object("grid", {"1":"none","5":5,"10":10,"15":15,"30":30}, APP.Tools.store("dt","grid")||10,"settings")))==null?'':__t)+
'\n	</div>\n		<div class=\'bar_box\'>\n			<input class=\'settings\' id=\'snap\' '+
((__t=(APP.Tools.store( 'dt','snap')!=="off" ? "checked": ""))==null?'':__t)+
' type=\'checkbox\' >Snap\n		</div>\n		<div class=\'bar_box\'>\n		Zoom:<input type=\'range\' min=0 max=5 step=0.01 id=\'zoom\' value='+
((__t=(zoom))==null?'':__t)+
'>\n	</div>\n		<!-- Hue:<input type=\'range\' min=0 max=360 step=1 id=\'hue\' value='+
((__t=(hue))==null?'':__t)+
'> -->\n	</div>\n\n	<div class=\'command\' id=\'widgets\'>\n		'+
((__t=(APP.Tools.icon("widgets")))==null?'':__t)+
' <span class=\'minwidth1200\'>WIDGETS</span>\n	</div>\n	';
 if (APP.USER.Can("settings")) { 
__p+='\n		<div id=\'settings\' class=\'command\'>\n			'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
'\n		</div>\n	';
 } 
__p+='\n</div>\n';
}
return __p;
};
