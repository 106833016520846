module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var hours = hours || 0;

__p+='\n\n<div class=\'tite\'>\n<h1>\n	'+
((__t=(name))==null?'':__t)+
'\n</h1>\n</div>\n\n<div class=\'settings\'>\n	<table>\n		<tr>\n			<th>Name</th>\n			<td>\n				<input type=\'text\' id=\'name\' class=\'field\' value=\''+
((__t=(name))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th colspan=2>Recipients (email1, email2...)\n				</td>\n		</tr>\n		<tr>\n			<td colspan=2>\n				<textarea class=\'field full\' id=\'recipients\'>'+
((__t=(recipients))==null?'':__t)+
'</textarea>\n				<br />\n			</td>\n		</tr>\n		<tr>\n			<th>Frequency</th>\n			<td>\n				'+
((__t=(APP.UI.select_from_object("frequency",["never","hourly", "daily", "weekly", "monthly"],frequency,"field refresh")))==null?'':__t)+
'\n			</td>\n		</tr>\n		';
 if (frequency == 'hourly') { 
__p+='\n		<tr>\n			<td colspan=2>\n				<table id=\'hours\'>\n					<tr>\n						<th>AM</th>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'1\' '+
((__t=((hours & (1<<1))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'2\' '+
((__t=((hours & (1<<2))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'3\' '+
((__t=((hours & (1<<3))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'4\' '+
((__t=((hours & (1<<4))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'5\' '+
((__t=((hours & (1<<5))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'6\' '+
((__t=((hours & (1<<6))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'7\' '+
((__t=((hours & (1<<7))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'8\' '+
((__t=((hours & (1<<8))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'9\' '+
((__t=((hours & (1<<9))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'10\' '+
((__t=((hours & (1<<10))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'11\' '+
((__t=((hours & (1<<11))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'12\' '+
((__t=((hours & (1<<12))?"checked":""))==null?'':__t)+
' ></td>\n					</tr>\n					<tr>\n						<td>&nbsp;</td>\n						<td>1</td>\n						<td>2</td>\n						<td>3</td>\n						<td>4</td>\n						<td>5</td>\n						<td>6</td>\n						<td>7</td>\n						<td>8</td>\n						<td>9</td>\n						<td>10</td>\n						<td>11</td>\n						<td>12</td>\n					</tr>\n					<tr>\n						<th>PM</th>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'13\' '+
((__t=((hours & (1<<13))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'14\' '+
((__t=((hours & (1<<14))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'15\' '+
((__t=((hours & (1<<15))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'16\' '+
((__t=((hours & (1<<16))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'17\' '+
((__t=((hours & (1<<17))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'18\' '+
((__t=((hours & (1<<18))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'19\' '+
((__t=((hours & (1<<19))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'20\' '+
((__t=((hours & (1<<20))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'21\' '+
((__t=((hours & (1<<21))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'22\' '+
((__t=((hours & (1<<22))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'23\' '+
((__t=((hours & (1<<23))?"checked":""))==null?'':__t)+
' ></td>\n						<td><input type=\'checkbox\' class=\'hours extrafield\' data-value=\'24\' '+
((__t=((hours & (1<<24))?"checked":""))==null?'':__t)+
' ></td>\n					</tr>\n				</table>\n			</td>\n		</tr>\n\n		';
 } 
__p+='\n		<tr>\n			<td colspan=2>\n				<button id=\'mailme\' class=\'action btn\'>\n					'+
((__t=(APP.Tools.icon('envelope')))==null?'':__t)+
' Send Test to Me</button>\n				<button id=\'mailall\' class=\'action btn\'>\n					'+
((__t=(APP.Tools.icon('envelope')))==null?'':__t)+
' Send Test to Everyone</button>\n				<div class=\'message\' id=\'message\'></div>\n			</td>\n		</tr>\n	</table>\n</div>\n\n<div class=\'template\'>\n	<h2>Template</h2>\n	<a id=\'fullscreen_link\' href=\'#DT/reports/'+
((__t=(_id))==null?'':__t)+
'/editreport\'>\n		'+
((__t=(APP.Tools.icon("arrows-alt")))==null?'':__t)+
' Fullscreen</a>\n	<textarea class=\'field full tall live nowrap\' id=\'template\'>'+
((__t=(template))==null?'':__t)+
'</textarea>\n</div>\n\n\n\n\n\n\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n		database\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
'\n		delete\n	</div>\n\n	<div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
'\n		duplicate\n	</div>\n</div>\n';
}
return __p;
};
