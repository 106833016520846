DEF.tags = {};

DEF.tags.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.tags.Model,
		url       : `${SETTINGS.dburl}/tags`,
		comparator: 'tag_name',
		byFilter(filter) {
			var records = this.filter(filter)
			return new DEF.tags.Collection(records);
		},
		Xget(args) {
			const model = DEF.TG.Collection.prototype.get.call(this, args);
			// Handle missing tags.  #2.8.12
			if (APP.models.tags.is_synced && typeof model === 'undefined')
				//		model = APP.models.tags.get({tag_name: "NOTAG"});
				if (!model) { // this is all really shitty.  I don't like saving the new tag to the collection database
					return new DEF.tags.Model({ tag_name: 'NOTAG' });
				}

			return model;
		}
	};
	const collection = APP.InitializeModels('tags', options, callback, constraints);
	//	collection.
	return collection;
};

DEF.tags.Model = DEF.TG.Model.extend({
	idAttribute      : '_id',
	parentIdAttribute: 'tl_id',
	parentModule     : 'tag_library',
	collection_name  : 'tags',
	// events: {
	// 	"change:alarm_state": "LogAlarmstate"
	// },
	initialize() {
		_.delay(this.CheckForStale.bind(this), 5000); // delayed because tags may load before tag_library, which holds polling rates and casts.
		this.on('change:alarm_state', this.HandleAlarmState, this);
		if (!this.get('color') || this.get('color').length === 6)
			this.set({
				color: `#${`${(((1 << 24) * Math.random()) | 0).toString(16)}0`.slice(0, 6)}` // #2.21.1.12
			});
	},
	defaults: {
		dl_id                : false,
		tl_id                : false,
		d_id                 : false,
		tag_name             : 'TAG_NAME',
		value                : '',
		last_changed_value   : 0, // used for deadbanding
		raw_value            : '', // unformatted value
		valuef               : '', // preformatted value (i may use .getValue() everywhere now`)
		alarm_state          : 'off',
		alarm_time           : '', // how long in alarm`
		alarm_ack_time       : false,
		alarm_ack_id         : false,
		alarms               : 0,
		warnings             : 0,
		normals              : 0,
		points               : 0,
		points_archive       : 0,
		zeros                : 0, // number of times a zero value was archived
		deadbands            : 0, // nbumber of times a deadband value was archived
		rates                : 0, // number of times a rate value was archived
		notifications_sent   : 0, // notifications sent
		updated              : false,
		changed              : false,
		color                : false,
		earliest_data        : false,
		earliest_data_archive: false,
		off_time             : 0,
		on_time              : 0
		// polling_rate_mod     : 1,
	},
	extra_defaults: {
		alarm_sound   : false,
		alarm_notify  : false,
		warning_notify: false,
		alarm_ticket  : false, // set fault_ticket in settings
		warning_ticket: false,
		range_high    : 100,
		range_low     : 0,
		alarm_low     : null,
		alarm_high    : null,
		warning_low   : null,
		warning_high  : null,
		absurd_low    : null,
		absurd_high   : null,
		deadband      : 1,
		delta         : 0,
		delta_1m      : 0,
		delta_1h      : 0,
		assert        : 1,
		decimal_shift : 0,
		cycles        : 0, // number of off to on transitions
		off_time      : 0,
		on_time       : 0
	},
	db_columns: [
		'address',
		'value',
		'dl_id',
		'tl_id',
		'alarm_state',
		'updated',
		'points',
		'rates',
		'deadbands',
		'zeros',
		'polling_rate',
		'average',
		'stats.stdDev'
	],
	db_spreadsheet: ['name','color', 'notifications', 'value', 'alarm_state'],
	db_search     : ['name', 'address', 'symbol', 'unit'],
	db_filters    : ['d_id', 'dl_id', 'type', 'alarm_state'],
	db_tools      : {
		'Deploy Tags' : 'DeployTags',
		'Kill Orphans': 'KillOrphans',
		'Reset Old Tags': 'ResetOldTags'
	},
	Notify(model, subs, message, method='notify') {
		const recipients = [];
		if (_.isArray(subs)) {
			const users = APP.models.users.filter(m => subs.indexOf(m.id) >= 0);
			const uri = SETTINGS.posturl;
			if (!uri)
				console.warn('Missing a post url');
			for (const u in users) {
				var perms = users[u].get('perm');
				if (perms && perms.notify) {
					users[u].set({
						notifications: users[u].get('notifications') + 1
					});
					recipients.push(`[users:${users[u].get('_id')}]`);
					let phone = `${users[u].get('phone')}`.replace(/[^0-9]/g, '');
					phone *= phone;
					const url = `${uri}/${method}/${phone}${message}`;
					const request = require('request');
					request(url, () => console.log);
				}
				else {
					console.warn(`${method.toUpperCase()} User cannot receive notification`, users[u].getName(), perms);
				}
			}
			this.set({
				notifications_sent:
					Number(this.get('notifications_sent')) + users.length
			});
			if (recipients.length) {
				// const msg = `Notifications for [tags:${model.id}] sent to ${recipients.join(', ')}. (${alarm_state})`;
				const msg = `${method.toUpperCase()}> Notifications sent to ${recipients.join(', ')} for [tags:${this.id}]`;
				console.log(msg);
				const event = {
					event      : msg,
					datetime   : Date.now(),
					tag_id     : this.id,
					device_id  : this.get('d_id'),
					alarm_state: 'info'
				};
				APP.models.events.create(event);
				APP.models.events_archive.create(event);
			}
		}
	},
	NotifyDevice(device, state) {
		const off = (state ? 'on' : 'off');
		const message = `${device.getName()} turned ${off}`;
		const sms = device.get(`notifications.${off}.sms`);
		this.Notify(device, sms, message);
	},
	NotifyTag(alarm, value) {
		alarm = alarm || this.get('alarm_state');
		let message = `/${alarm}/`;
		if (alarm) {
			const sms = this.get(`notifications.${alarm}.sms`) || [];
			if (sms.length > 0) {
				message += this.isBoolean()
					? `${this.get('tag_name')}(${this.getUp('name')}) turned ${value}`
					: `${this.get('tag_name')}(${this.getUp('name')}) at ${value}`;
				this.Notify(this.model, sms, message, 'notify');
			}
			const phone = this.get(`notifications.${alarm}.phone`) || [];
			if (phone.length > 0) {
				message += this.isBoolean()
					? `${this.getUp('prefix')} ${this.getUp('name')} turned ${value}`
					: `${this.getUp('prefix')} ${this.getUp('name')} at ${value} ${this.getUp('unit')}`;
				this.Notify(this.model, phone, message, 'call');
			}
		}
	},
	getDeviceLibrary() {
		return this.FindParent('device_library', this.get('dl_id'));
	},
	getPoller() {
		return APP.models.pollers.get(this.getDevice().get('poller_id'));
	},
	getDevice() {
		return this.FindParent('devices', this.get('d_id'));
	},
	getTagLibrary() {
		return this.FindParent('tag_library', this.get('tl_id'));
	},
	// Returns 0..1 based on where in the range the value is
	getPercent(value) {
		if (typeof value === 'undefined')
			value = this.get('value');
		if (value === null)
			return 0;
		if (this.isBoolean())
			return this.getState() ? 1 : 0;
		return (
			(value - this.getUp('range_low'))
			/ (this.getUp('range_high') - this.getUp('range_low'))
		);
	},
	// returns -1..0..1 based on where the value is
	getSignedPercent(value) {
		if (typeof value === 'undefined')
			value = this.get('value');
		return value / (this.getUp('range_high') - this.getUp('range_low'));
	},

	/**
	 * Returns True if this tag is .. true.
	 * @param {number} value
	 */
	getState(value) {
		if (typeof value === 'undefined')
			value = this.get('value');
		return !!value;
	},

	/**
	 * Returns True if this tag's device is running
	 */
	getRunning() {
		let running = false;
		const symbol = this.getUp('running_symbol');
		if (symbol) {
			const tag = APP.GetTag(`${this.getUp('prefix')}_${symbol}`);
			if (tag)
				running = tag.getState();
		}
		return running;
	},
	getUp(field, parent_module, parent_id) {
		let val = this.get(field);
		if (!_.isUndefined(val) && val !== '')
			return val;
		// if (field.indexOf('.') > 0) {
		// 	var [field, extra] = field.split('.');
		// 	console.log(field, extra);
		// }

		// Tags have TWO parents - devices and tag_library
		// console.log('getTL');
		const tl = this.getTagLibrary();
		if (tl) {
			val = tl.get(field);
			if (!_.isUndefined(val) && val !== '')
				return val;
		}

		// console.log('GetD');
		const d = this.getDevice();
		if (d) {
			val = d.get(field);
			if (!_.isUndefined(val) && val !== '')
				return val;
		}

		// console.log('getProp');
		return DEF.TG.Model.prototype.getUp.call(this, field, parent_module, parent_id);
	},

	/**
	 * A convenience function to return the current value
	 */
	val() {
		return this.get('value');
	},
	getValue(value, hide_unit) {
		if (_.isUndefined(value))
			value = this.get('value');
		if (value === null)
			value = 0;

		const cast = this.getUp('cast');
		// const type = this.getUp('type');
		const default_unit = this.getUp('unit');
		const unit = default_unit;
		// let unit = APP.Unit.GetUnit(default_unit);
		// if (unit !== default_unit && APP.Unit[default_unit][unit])
		// 	value = APP.Unit[default_unit][unit](value);
		// unit = APP.Unit.humanize(unit);

		if (this.isNumber() && this.getUp('decimals') === undefined)
			value = Number(value.toLocaleString(undefined, { useGrouping: false }));

		let out = value;
		if (cast !== 'raw')
			if (APP.Format[cast]) {
				out = APP.Format[cast](value, _.extend({}, this.getTagLibrary().attributes, this.attributes));
			} else {
				console.warn(`Missing cast '${cast}'`, this.getName(), this.id);
			}

		// sometimes formulas return "false" or some non-number which doesn't get formatted correctly
		if (isNaN(out) && this.getUp('type') === 'number')
			out = '--';
		if (typeof out === 'undefined' && this.getUp('type') === 'boolean')
			out = '';

		// console.log('getval out', out, cast);

		if (!hide_unit)
			if (this.isNumber() && unit)
				out = `<span class='value'>${out}<span class='unit'>${unit}</span></span>`;
			else
				out = `<span class='value'>${out}</span>`;
		// this.set('on_screen', Date.now());


		// if (this.isBoolean())
		// 	out = 'xx';


		return out;
	},
	getColor(shade = 0) {
		const color = this.get('color');
		if (shade)
			return APP.Tools.ShadeColor(color, shade);
		return color;
	},
	getName() {
		return this.get('tag_name');
	},
	getIcon() {
		return APP.Tools.icon(this.getUp('type'));
	},
	getDesc() {
		return this.getUp('name');
	},

	/**
   * Watch for alarm state changes and play the sound if needed.
   */
	HandleAlarmState() {
		const level = this.get('alarm_state');
		// console.log(this.getName(0), level);
		try {
			if (typeof Audio !== 'undefined' && level === 'alarm' && this.getUp('alarm_sound')) {
				const asound = require('./alarm.mp3');
				const audio = new Audio(asound);
				audio.play();
				console.log(this.getName(), 'played a sound');
				const create = {
					event      : `[tags:${this.id}] played a sound`,
					datetime   : Date.now(),
					tag_id     : this.id,
					device_id  : this.get('d_id'),
					alarm_state: 'info'
				};
				console.log('LOG>', create.event);
				APP.models.events.create(create);
				APP.models.events_archive.create(create);
			}
		} catch (e) {
			//
		}
		try {
			if (SETTINGS.emails && SETTINGS.emails.alarm_ticket && this.getUp(`${level}_ticket`)) {
				console.log('TICKET!', SETTINGS.emails.alarm_ticket);
				// SendMail('mainstreetmark@gmail.com', `${this.getName()} ${level}`, 'This guy broke!');
				const request = require('request');
				const uri = SETTINGS.posturl;
				const target = `${uri}/sendmail`;

				request.post(target, {
					form: {
						to     : SETTINGS.emails.alarm_ticket,
						subject: `${this.getName()} ${level} occurred at ${new Date().toLocaleString()}`,
						body   : `${this.getName()} had a ${level} event.  In an effort to provide good records, please describe this event, as well as (eventually) any actions taken to remedy the situation.  If possible, fill out the Issue form in Zoho as compeltely as possible.  You can also just reply to this email, and your reply will be captured in the Issue.`
					}
				});

				const create = {
					event      : `Ticket sent to ${SETTINGS.emails.alarm_ticket} because [tags:${this.id}]=${level}`,
					datetime   : Date.now(),
					tag_id     : this.id,
					device_id  : this.get('d_id'),
					alarm_state: 'info'
				};
				APP.models.events.create(create);
				APP.models.events_archive.create(create);
			}
		} catch (e) {
			//
		}
		try {
			if (Notification && this.getUp(`${level}_notify`)) {
				let note = false;
				const title = `${this.getName()}: ${APP.Lang(level)}`;
				const props = {
					body              : `Current Value:${this.getValue(undefined, true)}`,
					icon              : `${SETTINGS.posturl}/icons/apple-touch-icon-152x152.png`,
					badge             : `${SETTINGS.posturl}/icons/apple-touch-icon-152x152.png`,
					requireInteraction: true
				};
				if (Notification.permission === 'granted')
					note = new Notification(title, props);
				else if (Notification.permission !== 'denied')
					Notification.requestPermission((permission) => {
						if (permission === 'granted')
							note = new Notification(title, props);
					});
				if (note)
					note.onclick = function pop() { window.location = `${SETTINGS.posturl}#tags/${this.get('_id')}`; };
			}
		} catch (e) {
			//
		}
	},

	/**
   * When requested, this runs through the availbale data and computes the stats.
   */
	RefreshStats() {
		if (this.isNumber()) {
			console.log(this.getName(), 'stat refreshing');
			const stats = this.get('stats') || {};
			const old_avg = stats.avg;

			let data = APP.models.data.filter({ t: this.id });
			let count = data.length;
			if (count > 20) {
				data = data.filter(m => m.get('v')); // get rid of zeros
				data.sort((a, b) => a.get('v') - b.get('v')); // sort by date
				count = data.length;

				let min = Date.now();
				let max = -Date.now();
				let q1 = 0;
				let q2 = 0;
				let q3 = 0;
				let sum = 0;
				for (let d = 0; d < count; d++) {
					const value = data[d].get('v');
					sum += value - 0;
					if (d < count * 0.25)
						q1 = value;
					if (d < count * 0.5)
						q2 = value;
					if (d < count * 0.75)
						q3 = value;
					if (value < min)
						min = value - 0;
					if (value > max)
						max = value - 0;
				}
				stats.avg = sum / count;
				stats.min = min - 0;
				stats.max = max - 0;
				stats.q1 = q1 - 0;
				stats.q2 = q2 - 0;
				stats.q3 = q3 - 0;
				stats.as_of = Date.now();
				stats.count = count;

				const square_diffs = data.map((m) => {
					const diff = m.get('v') - stats.avg;
					const sqrDiff = diff * diff;
					return sqrDiff;
				});

				const square_sum = square_diffs.reduce((square, value) => square + value, 0);
				const square_avg = square_sum / square_diffs.length;

				stats.stdDev = Math.sqrt(square_avg);

				this.set({ points: count, stats });
				if (old_avg !== stats.avg)
					this.trigger('change');
			} else { console.log(this.getName(), 'not enough data', count); }
			console.log(this.getName(), 'stat refreshing done');
		}
	},

	/**
   * The primary "stick val in database" function.  Returns TRUE if a new data point was created
   * @param {number} value                 A value
   * @param {timestamp} timestamp             numeric value of time, in ms
   * @param {bool} supress_device_update Update "device" with point count, last_data, etc..  default:true
   */
	SaveValue(value, timestamp) {
		let rs = false; // return status = TRUE if new Archive
		if (typeof value === 'object' && typeof timestamp === 'number') { // ThrottleSave is sending the model in the "value" arg, and value in the Timestamp arg.
			value = timestamp;
			timestamp = null;
		}
		if (value && value.get)
			// this is a formula event trigger, sending "this".  See poller
			value = value.get('value');
		const raw_value = value;

		const preprocess = this.getUp('preprocess');
		if (preprocess !== 'none')
			value = APP.Convert[preprocess](value, _.extend({}, this.getTagLibrary().attributes, this.attributes));
		if (isNaN(value)) value = 0; // the DSG project requires invalid modbus responses (NaN) to be treated as "0" so formulas work

		if (this.getUp('absvalue'))
			value = Math.abs(value);
		const last_value = this.get('last_changed_value');

		// console.log('save2', Date.now(), this.getName(), value);
		if ((!_.isUndefined(value) && !_.isNull(value)) || this.getUp('formula')) {
			const save = {
				raw_value, // store the formatted value
				updated: timestamp > 1000000 ? timestamp : Date.now()
			};
			if (Date.now() - this.get('updated') < 100)
				return false; // disallow rapidly updating tags.  Refs #2.21.1.6

			const type = this.getUp('type');
			switch (type) {
			case 'boolean':
				value = !!value; // cast to true/false
				break;
			case 'number':
			case 'totalizer':
			case 'rate':
				if (Number(value) === value)
					value = Number(value); // cast "123" to 123 for fuck sakes
				break;
			}
			switch (type) {
			case 'boolean':
			case 'number':
				const last = this.getState(); // the current state from the database, prior to this new update.
				const time_field = last ? 'on_time' : 'off_time';
				// const since_field = last ? 'on_since' : 'off_since';
				if (this.getState(value) && !last) { // transition from "off" on "on"
					save.cycles = (this.get('cycles') || 0) + 1;
					save.on_since = Date.now();
				} else if (!this.getState(value) && last) { // It is OFF, but was ON
					save.off_since = Date.now();
				}
				const tdelta = save.updated - (this.get('updated') || save.updated);
				// console.log(this.getName(),"uopdatetime", timestamp, new Date(save.updated),tdelta,new Date(this.get('updated')), this.getUp('data_rate') * 2,(tdelta < this.getUp('data_rate') * 2))
				if (tdelta < this.getUp('data_rate') * 5 * 1000)
					save[time_field] = (this.get(time_field) || 0) + (tdelta / (1000 * 60 * 60));
				// console.log(this.getName(), save[time_field],time_field)
				// save[time_field] = (Date.now() - (save(since_field) || this.get(since_field) || Date.now())) / (1000 * 60 * 60);
				// console.log(this.getName(), save, this.get(time_field), save.updated, this.get('updated'));
			}

			const formula_value = this.ApplyFormula(value);
			if (typeof formula_value !== 'undefined')
				value = formula_value;

			if (this.isNumber() && this.getUp('decimal_shift'))
				value *= 10 ** this.getUp('decimal_shift');

			const elapsed = save.updated - this.get('last_write_time') || Date.now();
			const deadband = this.getUp('deadband');
			const rate = this.getUp('data_rate');

			// check for value absurdity, but only if the number is outside of a defined range ("100" is the default, so that's undefined, i guess)
			if (this.isNumber()) {
				if (value < (this.getUp('absurd_low') || value)) {
					console.warn(`Absurd Value for ${this.getName()} = ${value}. Should b e greater than ${this.getUp('absurd_low')}`);
					return false;
				}
				if (value > (this.getUp('absurd_high') || value)) {
					console.warn(`Absurd Value for ${this.getName()} = ${value}. Should be lower than ${this.getUp('absurd_high')}`);
					return false;
				}
			}
			let reason = 'update'; // defaults reason.  No deadband or polling rate.
			let delta = 0; // difference between this poll and last poll

			if (rate > 0 && elapsed >= rate * 1000)
				reason = 'rate';

			// Accumulate the totalizer
			if (type === 'totalizer') {
				const elapsed_time = this.get('updated')
					? Date.now() - this.get('updated')
					: 0;

				let value_rate = this.getUp('value_rate');
				if (value_rate > 0) {
					value_rate *= 1000;
					// const oldvalue = value;
					delta = (value * elapsed_time) / value_rate;
					value = Number(this.get('value')) + delta;
				} else if (this.get('last_zero_value')) {
					value -= this.get('last_zero_value');
				}
			}
			if (type === 'rate') { // rate of change data type
				if (reason === 'rate') { // polling rate reason for change
					let value_rate = this.getUp('value_rate') * 1000;
					const deltat = this.get('updated') ? Date.now() - this.get('updated') : 0;
					const last_rate_value = this.get('last_rate_value') || 0;
					const deltav = value - last_rate_value;
					save.last_rate_value = value;
					console.log("RATE", this.getName(), "dt>", deltat, "dv>", deltav, "val>", value, "last>", last_rate_value)
					value = deltav * value_rate / deltat
				}
				else { // we ONLY want to do "rate" here.  probably
					reason = 'update'
					value = last_value; 
				}

			}

			save.value = value;
			if (this.isNumber()) {
				if (isNaN(value))
					return false;
				if (delta === 0) // totalizer sets it's own delta, so dont overwrite it
					delta = value - last_value;
				if (delta >= deadband || delta <= -deadband)
					reason = 'deadband';

				if (type === 'number') {
					if (value !== 0 && last_value === 0) // record when the tag goes from a zero "off" state to an on state
						reason = 'start';
					if (value === 0 && last_value !== 0) // same thing, only off.
						reason = 'stop';
				}
			} else if (value !== last_value) {
				reason = 'changed';
			}

			const alarm = this.getAlarmState(value);
			if (this.get('alarm_state') !== alarm) {
				const alarm_set = this.SetAlarmState(alarm, value, true, timestamp);
				save[`${alarm}s`] = this.get(`${alarm}s`) + 1;
				// polling_rate_mod *= 2.5;
				Object.assign(save, alarm_set);
			}

			save.reason = reason;
			if (value !== last_value)
				save.changed = Date.now();

			if (reason !== 'update' || (this.get('alarm_state') !== alarm)) {
				save.last_changed_value = value;
				save.delta = delta;

				// if (!supress_device_update)
				if (this.isBoolean() && delta !== 0)
					this.NotifyTag(this.getState(value) ? 'on' : 'off', this.getState(value) ? 'on' : 'off');

				if ((this.isNumber() && this.getUp('retention') > 0) || (!this.isNumber() && reason !== 'rate'))
					if (reason === 'rate' || reason === 'changed' || (reason === 'deadband' && delta !== 0) || (reason === 'start' || reason === 'stop')) { // getting to be a lot of reasons here...
					// only number values should be logged by rates.
					// look at this crap
						this.ArchiveValue(save.value, reason, alarm, timestamp);
						if (!this.get('earliest_data_archive'))
							save.earliest_data_archive = Date.now();
						save.last_write_time = Date.now();
						save.points = this.get('points') + 1;
						const reasons = `${reason}s`;
						save[reasons] = this.get(reasons) ? this.get(reasons) + 1 : 1;
						save.average = (this.get('average') || 0) + (save.value * (1 / (save.points - 1)));
						this.UpdateDevice(value, last_value);
						rs = true;
					}
				this.set(save);
				// console.log('          ', this.getName(), 'save', rate, save);
			} else if (type === 'totalizer' || Date.now() - this.get('on_screen') < 24 * 60 * 60 * 1000) // only update if the widget is in use, to save bandwidth
				this.set(save);

			// console.log(">>>>>>>>>>", save);
			// this.set(save);

			// this.SetAlarmArmTags();
		}
		return rs;
	},

	/**
	 * Update device statistics.
	 */
	UpdateDevice(value, last_value) {
		const device = APP.models.devices.get(this.get('d_id'));
		const next = Date.now();
		const set = {
			points   : device.get('points') + 1,
			last_data: next
		};
		if (this.getUp('symbol') === this.getUp('running_symbol')) {
			if (value)
				set.run_hours = (device.get('run_hours') || 0) + ((Date.now() - this.get('updated')) / (1000 * 60 * 60));

			if (this.getUp('log_onoff')) {
				const event = {
					event      : false,
					datetime   : Date.now(),
					tag_id     : this.id,
					device_id  : this.get('d_id'),
					alarm_state: 'info'
				};
				if (!value && last_value) {
					event.event = `${device.getName()} turned OFF`;
					this.NotifyDevice(device, false);
				}
				if (value && !last_value) {
					event.event = `${device.getName()} turned ON`;
					this.NotifyDevice(device, true);
				}
				if (event.event) {
					console.log(event.event);
					APP.models.events.create(event);
					APP.models.events_archive.create(event);
				}
			}
		}
		device.set(set);
	},
	PrintLogLine(value, reason, alarm_state = '') {
		let deltav;
		let deltat = 0;
		const BOLD = '\x1b[1m';
		const PLAIN = '\x1b[0m';
		const now = Date.now();
		const col = [];
		const w = {
			// widths;
			tag_name   : 15,
			value      : 20,
			address    : 8,
			reason     : 10,
			alarm_state: 15
		};
		const type = this.getUp('type');
		let address = `${this.getUp('address')}`;
		if (['modbus', 'modbustcp'].indexOf(this.getUp('protocol')) >= 0)
			if (address === 'null')
				address = '--'; // yes, the string "null"
			else if (typeof address === 'string')
				address += ''; // just for consistency
			else if (typeof address === 'number')
				// "00100" shows up as "100" for modbus
				address = address.toString().padStart(5, '0');
			else
				address = address.padStart(5, '0');
		if (address === null || address === 'null')
			address = '--';

		const tag_name = this.getName();

		col[0] = BOLD + tag_name.padEnd(w.tag_name) + PLAIN;
		col[1] = address.padEnd(w.address);
		switch (typeof value) {
		case 'number':
			col[2] = value.toLocaleString().padStart(w.value);
			break;
		case 'boolean':
			col[2] = APP.Tools.strip_html_tags(this.getValue(value)).padStart(w.value);
			break;
		default:
			col[2] = value.padStart(w.value);
		}
		col[2] = `${BOLD}${col[2]}${PLAIN}  `;
		col[3] = reason.padEnd(w.reason);
		col[4] = alarm_state.padEnd(w.alarm_state);

		deltav = value - this.get('last_changed_value');
		deltat = ((now - this.get('last_write_time')) / 1000).toFixed(1);
		switch (type) {
		case 'boolean':
			deltav = ['-', ' ', '+'][deltav + 1];
			col[5] = `Δv:${deltav}, Δt:${deltat}/${this.getUp('data_rate')}`;
			break;
		default:
			col[5] = `Δv:${deltav.toLocaleString()}/${this.getUp('deadband')}, Δt:${deltat}/${this.getUp('data_rate')}`;
		}
		console.log('   +', col.join(' '));
	},
	ArchiveValue(value, reason, alarm_state, timestamp) {
		const now = timestamp || Date.now();
		const tag_name = this.getName();
		this.PrintLogLine(value, reason || '', alarm_state || '');
		//		APP.Log(this.getName() + ": " + value + ", " + reason, alarm, this);
		const record = {
			t: this.id,
			n: tag_name,
			v: value,
			d: now,
			r: reason
		};
		APP.models.data.create(record);
		APP.models.data_archive.create({
			t: this.id,
			v: value,
			d: now
		});
	},

	/**
	* If the tag "state" is zero, it should clear all the alarm_arm tag alarms
	* likewise, if it goes non-zer, it should set them
	* */
	SetAlarmArmTags() {
		// set alarm state of other tags that use this tag as "alarm_arm"
		//

		// 	const tl_id = this.get('tl_id');
		// 	const state = this.getState();
		// 	if (state !== this.last_state) {
		// 		const other_tags = APP.models.tags.where({
		// 			d_id: this.get('d_id'),
		// 		});
		// 		for (let ot = 0; ot < other_tags.length; ot++)
		// 			if (other_tags[ot].getUp('alarm_arm') === tl_id) {
		// 				console.log('Arming other tag', other_tags[ot].getName(), state, this.last_state);
		// 				_.defer(other_tags[ot].SetAlarmState.bind(this));
		// 			}

		// 		this.last_state = state; // cache the last state, so every value change doesn't run this
		// 	}
	},

	/**
	* TagLibrary can supply a tag formula whic is evaled.
	* - #VALUE = the tags current value
	* - #KW = a device-referencing variable, getting this symbol with the same prefix (= G1_KW)
	* - #G1_KW = a tag-referencing variable, gets replaced with the current value
	* @param {float} value The current tag value, for convenience
	*/
	ApplyFormula(value) {
		let return_val;

		let formula = this.getUp('formula'); 
		const orig_formula = formula;
		if (formula === 'NULL' || formula === null)
			return value;
		if (formula.length > 0) {
			const vars = formula.match(/#([0-9A-Z_:]+)?/gi);
			for (const v in vars) {
				if (vars[v] !== '#VALUE') {
					let tag_name = vars[v];
					if (tag_name.indexOf('_') === -1) {
						// a device-referenced tag, such as "#KW", without prefix
						const self_name = this.get('tag_name');
						tag_name = tag_name.replace('#', `${self_name.slice(0, self_name.indexOf('_'))}_`);
					}
					const tag = APP.models.tags.findWhere({ tag_name: tag_name.replace('#', '') });
					if (tag) {
						// const last_updated = Date.now() - tag.get('updated');
						// if (last_updated > tag.getUp('data_rate') * 1000 * 10) {
						if (tag.getStale()) {
							// console.warn('Stale tag, skipping formula', formula, tag_name, last_updated, tag.getUp('polling_rate'));
							if (this.getUp('ignorestale'))
								value = 0
							else {
								console.log(`  ƒ  ${this.getName()}: ${tag_name} is stale, skipping formula`, formula);
								return NaN;
							}
						}
						else 
							value = Number(tag.get('value')); // error check me
					} else {
						console.error('* Missing formula tag', tag_name);
						APP.models.tags._where({
							// load up the missing tag. (pollers dont load the entire collection)
							tag_name: tag_name.replace('#', '')
						});
						return NaN; // undefined
					}
				}
				value = value || 0;
				formula = formula.replace(vars[v], value);
			}
			formula = formula.replace('AVERAGE', 'APP.Tools.Average');
			try {
				formula = formula.trim();
				return_val = eval(formula);
				// if (this.get("value") !== return_val) // these may not be equalling as much as i had hoped.
    		    //   console.log(`  ƒ  ${this.getName()}: ${orig_formula} \n\t= ${formula} \n\t= ${return_val}`);
			} catch (e) {
				console.error('* Bad formula (orig)', this.getName(), orig_formula);
				console.error('* Bad formula (eval)', this.getName(), formula);
				console.error('* error:', e);
				return NaN; // undefined
			}
		}
		return Number.isNaN(return_val) ? '--' : return_val;
	},
	getAlarmState(value) {
		// if ((Date.now() - this.get('updated')) / 1000 > this.getUp('rate'))
		// 	return "stale";
		const tl = this.getTagLibrary();
		let level = 'normal';
		if (value === undefined) {
			value = this.get('value');

			// check if there is a arm_tag, and if it is "armed" (non-zero)
			if (tl.getUp('alarm_arm')) {
				const symbol = this.getUp('alarm_arm');
				const atl = APP.models.tag_library.findWhere({
					// find the alarm tag library
					symbol,
					d_id: this.get('d_id')
				});
				if (atl) {
					const arm_tag = APP.models.tags.findWhere({
						d_id : this.get('d_id'),
						tl_id: atl.get('_id')
					});
					if (arm_tag && !arm_tag.getState())
						// the associated "arm" tag is false, so this is not an alarm
						return 'off'; // https://roadtrip.telegauge.com/tasks/view/2.8.23
				}
			}
		}

		switch (tl.attributes.type) {
		case 'number':
			if (
				(APP.Tools.is_numeric(tl.attributes.warning_low) && value <= tl.attributes.warning_low)
					|| (APP.Tools.is_numeric(tl.attributes.warning_high) && value >= tl.attributes.warning_high)
			)
				level = 'warning';
			if (
				(APP.Tools.is_numeric(tl.attributes.alarm_low) && value <= tl.attributes.alarm_low)
					|| (APP.Tools.is_numeric(tl.attributes.alarm_high) && value >= tl.attributes.alarm_high)
			)
				level = 'alarm';
			break;
		case 'boolean':
			if (value)
				level = tl.attributes.alarm_on || 'normal';
			else
				level = tl.attributes.alarm_off || 'normal';
			break;
		}
		return level;
	},
	getStale() {
		if (APP.design_time)
			return false;
		// stale_time: set to falsey to disable stale checking
		const mod = APP.GetSetting('stale_time', 5);
		const data_rate = this.getUp('data_rate');
		if (data_rate < 0)
			return false; // <0 rates == never poll
		const stale = mod && (Date.now() - this.get('updated')) / 1000 > data_rate * mod;
		return stale;
	},

	/**
	* Sets alarm_state.  If Arm time, then if the alarm level is increasing, it waits for a timeout.
	* If the alarm level is decreasing, there is no timeout.
	* @param {[type]} level [description]
	* @param {num} value an optional values
	* @param {bool} quick don't do the save, just return data and the caller will save
	*/
	SetAlarmState(level, value, quick, timestamp) {
		let set = {};
		// console.log('set alarm', this.getName(), level, value);
		if (!level)
			level = this.getAlarmState();
		const levels = ['normal', 'warning', 'warning_ack', 'alarm', 'alarm_ack', 'stale', 'off'];
		const old = this.get('alarm_state');
		let timeout = this.getUp('arm_time') * 1000;
		if (levels.indexOf(level) < levels.indexOf(old) || timeout === 0) {
			// alarm state decreasin
			if (old.indexOf(level) === -1)
				if (!this.alarm_timer) {
					// the timer is running, so this must be near alarm, so dont re-log it back to normal.
					// clearTimeout(this.alarm_timer);
					// this.alarm_timer = false;
					set = this.SaveAlarmState(level, value, quick, timestamp);
				}

			// console.log("SET1", set);
		} else if (levels.indexOf(level) > levels.indexOf(old)) {
			// alarm state increasing
			if (!this.alarm_timer) {
				if (level === 'normal')
					timeout = 0;
				this.alarm_timer = setTimeout(() => {
					const newlevel = this.getAlarmState();
					this.alarm_timer = false;
					// console.log(level, newlevel);
					if (newlevel === level)
						this.SaveAlarmState(level, value, false, timestamp);
				}, timeout);
			}
		}
		return set;
	},
	SaveAlarmState(level, value, quick, timestamp) {
		// console.log('savvve', level, value);
		if (level === 'stale')
			return {}; // let's ignore alarm state
		const set = {
			alarm_state   : level,
			alarm_time    : Date.now(),
			alarm_ack_id  : false,
			alarm_ack_time: false
		};
		// get the old alarm level, but remove any acks, since a warning_ack is still a warning.
		const oldlevel = this.get('alarm_state').replace('_ack', '');

		if (!quick)
			this.set(set);

		if (oldlevel !== level) {
			//
			let event;

			if (this.getUp('type')==='boolean' && (level==='info' || oldlevel==='info')) {
				event = `[tags:${this.id}] turned [value:${value}]`;
				if (level !== 'info')
					event += ` (${level})`
			} else {
				event = `[tags:${this.id}]: ${oldlevel} > ${level}`;
				if (typeof value !== 'undefined')
					event += ` @ [value:${value}]`;
			}
			let log_alarm_state = level !== 'off' && level !== 'on' && oldlevel !== 'off'; // ignore "OFF" events entirely
			if (oldlevel === 'stale')
				log_alarm_state = false;
				

			//	log_alarm_state = false;
			// if (!log_alarm_state) {
			// 	// but, check to see if we should log the Device ON/OFF event
			// 	log_alarm_state = this.getUp('symbol') === this.getDeviceLibrary().get('running_state');
			// Why don't we do this in Device itself?  Because any open window would react to .listenTo, whereas this has no listeners.
			// It's a check when the event happens
			// 	event = `[devices:${this.get('d_id')}] turned ${
			// 		level === 'off' ? 'OFF' : 'ON'
			// 	}`;
			// 	level = 'off';
			// 	console.log(event);
			// }

			if (log_alarm_state) {
				const create = {
					event,
					datetime   : timestamp || Date.now(),
					tag_id     : this.id,
					device_id  : this.get('d_id'),
					alarm_state: level
				};
				console.log('LOG>', event);
				APP.models.events.create(create);
				APP.models.events_archive.create(create);
			}
			if (['alarm', 'warning', 'normal'].indexOf(level) >= 0)
				_.defer(this.NotifyTag.bind(this, level, value));
		}
		return set;
	},
	ResetTotalizer() {
		const oldval = this.get('value');
		this.ArchiveValue(0, 'zero');
		this.ArchiveValue(oldval, 'reset');
		const stats = this.get('stats') || {};
		console.log(stats);
		const total = stats.total || 0;
		const resets = stats.resets || 0;
		stats.avg = ((total * resets) + oldval) / (resets + 1);
		stats.total = total + oldval;
		stats.resets = resets + 1;

		const set = {
			last_zero_value   : oldval,
			value             : 0,
			last_changed_value: 0,
			updated           : Date.now() - 200, // the 200ms is to get around the rapid-update tag fix
			changed           : Date.now() - 200,
			last_zero_date    : Date.now() - 200,
			stats
		};
		this.set(set);
		// tag.SaveValue(0); // adds 0 to the totalizer, which does nothing besides check alarms and such
		console.log(`* Zeroed ${this.getName()}. oldval ${oldval}`);
	},

	/** Convenience type functions, returning the type of value the tag is. */
	isNumber() {
		const type = this.getUp('type');
		return ['number', 'totalizer', 'rate'].indexOf(type) >= 0;
	},
	isBoolean() {
		return this.getUp('type') === 'boolean';
	},
	isString() {
		const type = this.getUp('type');
		return ['string', 'code'].indexOf(type) >= 0;
	},
	isDate() {
		return this.getUp('type') === 'date';
	},

	// SaveAlarmState(state) { },
	all_fields() {
		// this.LinkModels();
		const rs = _.extend({}, this.getDeviceLibrary().attributes, this.getDevice().attributes, this.getTagLibrary().attributes, this.attributes);
		rs.valuef = this.getValue();
		return rs;
	},
	CheckForStale() {
		if (this.getStale())
			this.set('alarm_state', 'stale');
	}
});
