module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Dash</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("dash", ["solid","blocks","lines"],dash)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Cap</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("cap", ["butt","round","square"],cap)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td>\n			<input type=\'checkbox\' '+
((__t=(value? "checked": ""))==null?'':__t)+
' id=\'value\' class=\'field\'> Value\n		</td>\n		<td>\n			<input type=\'checkbox\' '+
((__t=(outline? "checked": ""))==null?'':__t)+
' id=\'outline\' class=\'field\'> Outline\n		</td>\n	</tr>\n	<!-- <tr>\n		<td></td>\n		<td>\n			<input type=\'checkbox\' '+
((__t=(unit? "checked": ""))==null?'':__t)+
' id=\'unit\' class=\'field\'> Unit\n		</td>\n	</tr> -->\n	<tr>\n		<td><input type=\'checkbox\' '+
((__t=(needle? "checked": ""))==null?'':__t)+
' id=\'needle\' class=\'field\'> Needle\n		</td>\n		<td>\n			<input type=\'checkbox\' '+
((__t=(zeroatstart? "checked": ""))==null?'':__t)+
' id=\'zeroatstart\' class=\'field\'> Zero at Beginning\n		</td>\n	</tr>\n	<tr>\n		<td><input type=\'checkbox\' '+
((__t=(unit? "checked": ""))==null?'':__t)+
' id=\'unit\' class=\'field\'> Unit</td>\n	</tr>\n	<tr>\n		<th>Thickness</th>\n		<td>\n			<input type="range" min=1 max=100 step=10 value=\''+
((__t=(thickness))==null?'':__t)+
'\' id=\'thickness\' class=\'field live\' list=\'tickmarks\'>\n		</td>\n	</tr>\n	<tr>\n		<td>Start</td>\n		<td>Sweep</td>\n	</tr>\n	<tr>\n		<td>\n			<input type="range" min=0 max=360 step=1 value=\''+
((__t=(startangle))==null?'':__t)+
'\' id=\'startangle\' class=\'field live\' list=\'tickmarks\'>\n		</td>\n		<td>\n			<input type="range" min=0 max=360 step=1 value=\''+
((__t=(ticksangle))==null?'':__t)+
'\' id=\'ticksangle\' class=\'field live\' list=\'tickmarks\'>\n		</td>\n	</tr>\n</table>\n<datalist id=tickmarks>\n	<option>45</option>\n	<option>90</option>\n	<option>180</option>\n	<option>225</option>\n	<option>270</option>\n</datalist>\n';
}
return __p;
};
