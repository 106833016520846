module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'icon\'>\n	'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'\n</div>\n<div id=\'details\'>\n	<div class=\'vendor\'>'+
((__t=(name || vendor))==null?'':__t)+
'</div>\n<div class=\'ip\'>'+
((__t=(ip))==null?'':__t)+
'</div>\n<div class=\'mac\'>'+
((__t=(mac))==null?'':__t)+
'</div>\n</div>\n<div id=\'status\'>\n	<span class=\'status\'>'+
((__t=(status))==null?'':__t)+
'</span> : <span class=\'time\'>'+
((__t=(APP.Format.livetime(status_time)))==null?'':__t)+
'</span>\n</div>\n';
}
return __p;
};
