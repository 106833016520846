module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Poller</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New Poller</button>\n<button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Import from Other Installation</button>\n\n	<p>A <i>Poller</i> is a program that continuously gathers data from the devices assigned to it.</p>\n\n<p>Each poller must speak a supported protocol, such as Modbus.  Once a poller is created, devices must select\n	which poller they are attached to.</p>\n\n';
}
return __p;
};
