module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' class=\'field live\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Widget</th>\n		<!-- <td>'+
((__t=(APP.UI.select_from_object("widget_name",APP.widget_list,widget_name)))==null?'':__t)+
'</td> -->\n		<td>\n			'+
((__t=(APP.UI.select_from_object("widget_name",["Gauge","Value","Compass","LinearGauge","ArcGauge","Bar","Rampchart"],widget_name,"field refresh")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tags</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Width</th>\n		<td>\n			<input type="range" min=20 max=500 step=5 value=\''+
((__t=(widget_width))==null?'':__t)+
'\' id=\'widget_width\' class=\'field live\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Height</th>\n		<td>\n			<input type="range" min=20 max=500 step=5 value=\''+
((__t=(widget_height))==null?'':__t)+
'\' id=\'widget_height\' class=\'field live\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Padding</th>\n		<td>\n			<input type="range" min=0 max=50 step=5 value=\''+
((__t=(widget_padding))==null?'':__t)+
'\' id=\'widget_padding\' class=\'field live\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>Widget Props</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<textarea id=\'widget_props\' class=\'field json tall\'>'+
((__t=(JSON.stringify(Object.keys(widget_props).length ? widget_props : WID[widget_name].prototype.props, null, "\t")))==null?'':__t)+
'</textarea>\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'border\' value=\'true\' '+
((__t=(border? "checked": ""))==null?'':__t)+
' class=\'field live\'> Draw Border\n			<input type=\'checkbox\' id=\'spread\' value=\'true\' '+
((__t=(spread? "checked": ""))==null?'':__t)+
' class=\'field live\'> Spread out\n		</td>\n	</tr>\n\n</table>\n';
}
return __p;
};
