module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset>\n	<legend>Nest Settings</legend>\n	<table>\n		<tr>\n			<th>Email</th>\n			<td>\n				<input type=\'email\' class=\'field\' id=\'options.email\' value=\''+
((__t=(options.email))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Password</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.password\' value=\''+
((__t=(options.password))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n	</table>\n</fieldset>\n';
}
return __p;
};
