module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Title</th>\n		<td>\n			'+
((__t=(APP.UI.input("title",title)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tags</th>\n		<td>'+
((__t=(APP.UI.chooser("tag_ids", "tags", tag_ids,"multi", {type:["totalizer","number"]})))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label",["none","tag_name","name","prefix","symbol"],label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'zero\' value=\'true\' '+
((__t=(zero? "checked": ""))==null?'':__t)+
' class=\'field live\'> Include Zeros\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'unity\' value=\'true\' '+
((__t=(unity? "checked": ""))==null?'':__t)+
' class=\'field live\'> Use Unity Radius\n			</td>\n			</tr>\n			<tr>\n				<td colspan=2>\n					<input type=\'checkbox\' id=\'trace\' value=\'true\' '+
((__t=(trace? "checked": ""))==null?'':__t)+
' class=\'field live\'> Show Trace\n		</td>\n	</tr>\n	<!-- <tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'unity\' value=\'true\' '+
((__t=(unity? "checked": ""))==null?'':__t)+
' class=\'field live\'> Draw Zeros\n		</td>\n	</tr> -->\n</table>\n';
}
return __p;
};
