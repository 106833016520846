

/*
██████  ████████
██   ██    ██
██   ██    ██
██   ██    ██
██████     ██
*/
DEF.screens.DT = {};
DEF.screens.DT.Main = Backbone.Marionette.CollectionView.extend({
	className: 'screens',
	id: 'SCREENS',
	collectionEvents: {
		update: 'Update',
	},
	Update() {
		console.log('update')
	},
})
DEF.screens.DT.child = Backbone.Marionette.View.extend({
	className: 'screen noselect',
	template : require('./templates/screen.html'),
	templateContext() {
		const rs = this.model.getUps();
		rs.widgets = this.GetWidgetLayout();
		// console.log(rs);
		return rs;
	},
	attributes() {
		return { id: this.model.id };
	},
	events: {
		click: 'ShowDetails'
	},
	modelEvents: {
		change: 'render'
	},
	onRender() {
		// const scale = (APP.design_time ? 150 : 70) / this.model.getUp('height');

		// this.$el.find('.preview').css({
		// 	width : `${this.model.getUp('width') * scale}px`,
		// 	height: `${this.model.getUp('height') * scale}px`,
		// });

		this.$el.addClass(this.model.get('kind'));
		if (this.model.get('enabled'))
			this.$el.removeClass('disabled');
		else
			this.$el.addClass('disabled');

	},
	ShowDetails(e) {
		// const cmd = 'details';
		if (APP.design_time)
			// APP.Route(`#DT/edit/${APP.Format.url(this.model.get('name'))}`);
			APP.Route(`#DT/screens/${this.model.get('_id')}`);

		else
			APP.Route(`#RT/${APP.Format.url(this.model.get('name'))}`);


		e.stopPropagation();
	},

	/**
	 * This generatews a gif or something for the screenshots.  This has saved DOM elements over the CSS method.
	 */
	GetWidgetLayout() {
		const Width = this.model.getUp('width');
		const Height = this.model.getUp('height');
		const dom = document.createElement('CANVAS');
		const height = APP.design_time ? 150 : 70;
		const width = Width * height / Height;
		dom.width = width;
		dom.height = height;
		const canvas = dom.getContext('2d');
		canvas.scale(height / Height, height / Height);
		canvas.strokeStyle = 'rgba(255,255,255,0.6)';
		canvas.lineWidth = 10;
		canvas.fillStyle = 'rgba(255,255,255,0.4)';
		// console.log(canvas);

		const widgets = this.model.getWidgets();

		for (let w = 0; w < widgets.length; w++) {
			const wid = widgets[w];
			wid.DrawTinyView(canvas, wid.left(), wid.top(), wid.width(), wid.height());
		}
		canvas.stroke();
		canvas.fill();
		const dataurl = dom.toDataURL('image/gif', 1.0);
		// console.log(dataurl);
		return `<img src='${dataurl}' class='screenthing' draggable="false">`;
	},

	/**
	 * This generates a CSS-only screen with plaeholder widgets.  Useful for screen icons
	 */
	GetWidgetLayout2() {
		let out = '';
		const widgets = this.model.getWidgets();

		const width = this.model.getUp('width');
		const height = this.model.getUp('height');
		for (let w = 0; w < widgets.length; w++) {
			const wid = widgets[w];
			const pos = `left: ${wid.left() / width * 100}%;`
				+ `top: ${wid.top() / height * 100}%;`
				+ `width: ${wid.width() / width * 100}%;`
				+ `height: ${wid.height() / height * 100}%`;
			out += `<div class='widget' style='${pos}'>`;
			//	out += `<div class='icon'>${APP.Tools.icon(widgets[w].attributes.widget)}</div>`;
			out += '</div>';
		}
		return out;
	}
});

DEF.screens.DT.Details = DEF.TG.Details.extend({
	module  : 'screens',
	template: require('./templates/details.html'),
	templateExtras() {
		return {
			dim: `${this.model.get('width')}x${this.model.get('height')}`
		};
	},
	ui: {
		preset  : '#preset',
		field   : '.field',
		commands: '#details_footer .link',
		action  : '.action'
	},
	events: {
		'input @ui.preset'  : 'ApplyPreset',
		'change @ui.field'  : 'Save',
		'click @ui.action'  : 'DoAction',
		'click @ui.commands': 'Command'
	},
	modelEvents: {
		'change:master_id': 'render'
	},
	DoAction(e) {
		const command = e.currentTarget.id;
		switch (command) {
		case 'view':
			APP.Route(`#RT/${APP.Format.url(this.model.get('name'))}`);
			break;
		case 'edit':
			APP.Route(`#DT/edit/${APP.Format.url(this.model.get('name'))}`);
			break;
		}
	},
	ApplyPreset(e) {
		console.log(e.currentTarget.id, e.currentTarget.value);
		let h;


		let w;
		[w, h] = e.currentTarget.value.split('x');
		this.model.set({ width: w, height: h });
		this.render();
	},
	Delete() {
		if (confirm("Are you sure you want to delete this screen, and all of it's widgets?")) {
			const id = this.model.id;

			const widgets = APP.models.widgets.where({ screen_id: id });
			for (const w in widgets) {
				const widget = widgets[w];
				console.log('  ', widget.getName());
				widget.destroy();
			}

			this.model.destroy();
			APP.Tools.DeployTags();
			APP.Route(`#DT/${this.model.collection_name}`); // refresh doesn't exist.  and neither does the model, so route to the collection
		}
	}
});


/*
██████  ████████
██   ██    ██
██████     ██
██   ██    ██
██   ██    ██
*/
DEF.screens.RT = {};
DEF.screens.RT.Main = Backbone.Marionette.CollectionView.extend({
	id: 'screen',
	ui: {
		box: '#SCREEN_BOX'
	},
	// return the widget def appropriate to the engine
	childView (m) {
		const widget = m.get('widget');
		return WID[widget];
	},
	// Intercept buildChildView to apply a screen prefix
	buildChildView(child, ChildViewClass, childViewOptions) {
		// console.log('Buiuld ch', child.attributes);
 
		if (this.options.prefix)
			child = this.ApplyPrefix(child, this.options.prefix);
		const options = _.extend({ model: child }, childViewOptions);
		const view = new ChildViewClass(options);
		return view;
	},
	// Apply a ascreen prefix to a widget
	ApplyPrefix(child, prefix) {
		// console.log(prefix);
		if (!prefix || prefix === "$PREFIX") return child;
		if (!prefix || prefix === "#PREFIX#") return child;
		let attr = child.attributes;
		// console.log(attr);
		if (attr.screen_id === APP.current_screen.id) {
			attr = JSON.stringify(attr);
			const replacements = this.ReplacePrefix(child, attr, prefix);
			for (const r in replacements)
				attr = attr.replace(r, replacements[r]);

			attr = attr.replace("$PREFIX", APP.current_screen.prefix);
			attr = attr.replace("#PREFIX#", APP.current_screen.prefix);

			attr = JSON.parse(attr);
			attr._old_id = attr._id;
			attr._id += prefix;
			const model = new DEF.widgets.Model(attr);
			return model;
		}
		return child;
	},
	// Scan through list of _ids and look for a prefix-friendly replacement
	ReplacePrefix(child, attr, prefix) {
		let model;
		let new_model = false;
		let name;
		let old_prefix;
		const replacements = {};
		const matches = attr.match(/[a-f\d]{24}/ig);
		// console.log('IDS', matches);
		for (const m in matches) {
			const match = matches[m];
			if (match !== APP.current_screen.id && match !== child.get('_id')) {
				// tags
				model = APP.models.tags.get(match);
				if (model) {
					name = model.get('tag_name');
					old_prefix = model.getUp('prefix');
					name = name.replace(old_prefix, prefix);
					if (name !== model.get('tag_name')) {
						new_model = APP.models.tags.findWhere({ tag_name: name });
						if (new_model)
							replacements[match] = new_model.get('_id');
					}
				}
				// device
				if (!new_model) {
					model = APP.models.devices.get(match);
					if (model) {
						old_prefix = model.getUp('prefix');
						if (old_prefix !== prefix) {
							new_model = APP.models.devices.findWhere({ prefix, dl_id: model.get('dl_id') });
							if (new_model)
								replacements[match] = new_model.get('_id');
						}
					}
				}
			}
		}
		return replacements;
	},
	initialize() {
		$(window).resize(this.ScaleScreen.bind(this));
		this.listenTo(APP.models.widgets, 'change:zindex', this.ReRender);
		// this.listenTo(APP.models.widgets, 'add', this.ReRender)

		// this.$el.append("<canvas id='screen_canvas' width='" + this.model.getUp('width') + "px' height='" + this.model.getUp('height') + "px'></canvas>"); // full-screen canvas for widgets that want to use a full screen canvas
	},
	events: {
		click: 'onMouseClick',
		keyup: 'onKeyUp'
	},
	onDestroy() { },
	onMouseClick() {
		this.HideTooltip();
	},
	onKeyUp(e) {
		// console.log(e);
	},
	ScaleScreen() {
		if (this.model) {
			const w = this.model.getUp('width');
			const h = this.model.getUp('height');
			const W = this.$el.parent().width() || $('body').width();
			const H = this.$el.parent().height() || $('body').height();

			const scale = Math.min(W / w, H / h);
			this.model.scale = scale;

			const size = {
				width    : `${w}px`,
				height   : `${h}px`,
				transform: `scale(${scale})`
			};
			// size = {
			// 	width: W + "px",
			// 	height: H + "px",
			// }
			this.$el.css(size);
		}
	},
	onBeforeRender() {
		this.ScaleScreen();
	},
	onRender() {
		document.title = this.model.get('name') + (this.model.prefix ? ` ${this.model.prefix}` : '');
	},
	onDomRefresh() {
		APP.trigger('paintscreen');

		if (this.collection) {
			if (!APP.design_time) {
				APP.USER.set({
					pageviews : APP.USER.get('pageviews') + 1,
					last_visit: Date.now()
				});
				$('.tooltip').click(APP.ShowTooltip).dblclick(this.DoubleClick);
			}
			// $(".go_to_screen").removeClass("current");
			// console.log($(".screen" + this.model.id));
			// $(".screen" + this.model.id).addClass("current");
			this.ComputeStateEquations();
		}
	},

	/**
	 * Re-draw the whole screen.  Useful when zindex cha nges and the widget order must be redrawn
	 */
	ReRender () {
		this.collection.comparator = 'zindex';
		this.collection.sort();
		this.collection.trigger('reset');
	},
	HideTooltip() {
		try {
			APP.root.getRegion('main').currentView.getChildView('tooltip_box').remove();
		} catch (e) {
			console.log();
		}
	},
	DoubleClick(e) {
		let route = '#widget/';
		route += $(e.currentTarget).closest('.widget').attr('id');
		if (APP.current_screen && APP.current_screen.prefix) { // include the prefix in the new URL
			const regex = `${APP.current_screen.prefix}$`;
			const replace = `/${APP.current_screen.prefix}`;
			const search = new RegExp(regex);
			route = route.replace(search, replace); // the id has the prefix embedded in it, for some reason
		}
		console.log(route);
		APP.Route(route);
	},

	/**
	 * determine each widget's node's energized states
	 * @return {[type]} [description]
	 */
	ComputeStateEquations () {
		const widgets = this.collection.filter(this.options.filter); // this seems weird.  re-filtering.
		APP.current_screen._eq_cache = {};
		for (const w in widgets) {
			APP.current_screen._eq_count = 0;
			const widget = widgets[w];
			// console.log('widget', widget.getName());
			const nodes = widget.get('nodes');
			if (nodes) {
				const view = this.children.findByModel(widget);
				if (view) {
					const connectors = view.get_conn_paths();
					for (const c in connectors) {
						const conn = connectors[c];
						if (conn.data('from').id === widget.id) {
							//	console.log('widget', widget.getName(), c);
							const eq = view.get_connector_state_equation(conn);
							// if (!eq) {
							// 	debugger;
							// 	view.get_connector_state_equation(conn);
							// }

							// const bbox = conn.bbox();
							// $(`<div>${eq}</div>`).css({ position: 'absolute', left: bbox.x, top: bbox.y, width: bbox.width, height: bbox.height, 'text-align': 'center', 'font-size': '7px', color: 'white', border: '1px solid #333', 'background-color': '#1239' }).appendTo('#screen');

							conn.data('eq', eq);

							// console.log(widget.getName(), eq);
							//
							// const length = conn.length();
							// const point = conn.pointAt(length / 2);
							// view.draw.text(eq).fill('white').font({ size: 10, anchor: 'middle' }).move(point.x - widget.get('left'), 5 + point.y - widget.get('top'));
						}
					}
				}
			}
		}
	}
});


DEF.screens.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html')
});
