module.exports = DEF.widgets.RT.vector.extend({
	group            : [],
	selector_size    : 50,
	connector_padding: 50,
	geo              : {
		min_width : 40,
		max_width : 200,
		min_height: 40,
		max_height: 200,
		square    : true,
	},
	props: {
		width   : 80,
		height  : 80,
		shape   : 'hub',
		material: 'iron',
		label   : '',
		size    : 20,
	},
	connector_class  : 'pipes',
	split_node_widget: 'Hub',
	Initialize() {
		this.connector_padding = this.getProp('size') * 3; // the little extenders that hang off each widget
	},
	Draw() {
		const size = Math.min(this.W, this.H) / 2; // this.getProp('size');
		this.draw
			.rect(this.W, this.H)
			.center(this.W2, this.H2)
			.attr(this.GetAttr(45, this.model.get('top'), 1));

		console.log('p', this.connector_padding);
		this.AddNode(this.W2, this.H2 - size, 'N');
		this.AddNode(this.W2 + size, this.H2, 'E');
		this.AddNode(this.W2 - size, this.H2, 'W');
		this.AddNode(this.W2, this.H2 + size, 'S');
		// this.DrawConnectors();
	},

	/**
   * Draw a connector from pointA to pointB
   * @param  {[type]} dir1 [description]
   * @param  {[type]} x1   [description]
   * @param  {[type]} y1   [description]
   * @param  {[type]} dir2 [description]
   * @param  {[type]} x2   [description]
   * @param  {[type]} y2   [description]
   */
	DrawConnector(dir1, x1, y1, dir2, x2, y2) {
		const path = this.get_connector_path(dir1, x1, y1, dir2, x2, y2);
		const line = APP.current_screen.draw.nested();
		line.fill('none');
		line.back();
		this.DrawConnectorPath(line, path);
		return line;
	},
	DrawConnectorPath(line, path) {
		path = this.simplify_connector_path(path);
		let x1;
		let y1;
		let x2;
		let y2;
		let z;
		let dir;
		let lastdir;
		const size = this.getProp('size');
		const radius = size * 3;
		let start = radius;
		let end = radius;

		line.path(path).attr(this.style.static);

		const parts = path.match(/[MmLlSsQqLlHhVvCcSsQqTtAaZz,]|[\d\.-]+/g);
		while (parts.length) {
			const part = parts.shift();
			if (parts.length === 1)
				end = 0;
			switch (part) {
			case 'M':
				x1 = Number(parts.shift());
				z = Number(parts.shift()); // comma
				y1 = Number(parts.shift());
				start = 0;
				break;
			case 'h':
				x2 = x1 + Number(parts.shift());
				y2 = y1;
				this.DrawHorizontal(line, size, x1, y1, x2, y2, start, end);
				dir = x1 > x2 ? 'W' : 'E';
				if (start)
					this.DrawCorner(line, size, lastdir, dir, x1, y1, radius);
				x1 = x2;
				y1 = y2;
				start = radius;
				lastdir = dir;
				break;
			case 'v':
				x2 = x1;
				y2 = y1 + Number(parts.shift());
				this.DrawVertical(line, size, x1, y1, x2, y2, start, end);
				dir = y1 > y2 ? 'N' : 'S';
				if (start)
					this.DrawCorner(line, size, lastdir, dir, x1, y1, radius);
				x1 = x2;
				y1 = y2;
				start = radius;
				lastdir = dir;
				break;
			case 'L':
				x2 = Number(parts.shift());
				z = Number(parts.shift()); // comma
				y2 = Number(parts.shift());
				this.DrawVertical(line, x1, y1, x2, y2);
				x1 = x2;
				y1 = y2;
				break;
			}
		}
		return line;
	},
	GetCutoutAttr() {
		return {
			fill          : '#333',
			stroke        : 'rgba(255,255,255,0.4)',
			'stroke-width': 4,
		};
	},
	GetAttr(angle, loc, radius) {
		let shine = 0;
		if (angle === 90)
			shine = 0.5 + loc / APP.current_screen.getUp('height') / 4;
		else
			shine = 0.75 - loc / APP.current_screen.getUp('width') / 2;

		const gradient = this.get_gradient(shine, radius);
		switch (angle) {
		case 0:
			gradient.from(1, 0).to(0, 0);
			break;
		case 45:
			gradient.from(0, 1);
			break;
		case 90:
			gradient.from(0, 1).to(0, 0);
			break;
		case 135:
			gradient.from(1, 1);
			break;
		case 225:
			gradient.from(1, 0);
			break;
		case 315:
			gradient.from(0, 1);
			break;
		}

		return {
			fill   : gradient,
			opacity: 1,
			stroke : 'rgba(0,0,0,0.5)',
		};
	},
	get_gradient(shine, radius) {
		let gradient;
		const mode = radius ? 'radial' : 'linear';
		switch (this.getProp('material')) {
		case 'pvc':
			var w = 0.05;
			gradient = APP.current_screen.draw.gradient(mode, (stop) => {
				stop.at(0, '#00A');
				stop.at(shine - w, '#22F');
				stop.at(shine - w, '#2F2');
				stop.at(shine, '#2F2');
				stop.at(shine + w, '#2F2');
				stop.at(shine + w, '#22F');
				stop.at(1, '#22A');
			});
			break;
		case 'iron':
		default:
			gradient = APP.current_screen.draw.gradient(mode, (stop) => {
				stop.at(0, '#333');
				stop.at(shine, '#eee');
				stop.at(1, '#333');
			});
		}
		if (radius)
			gradient.radius(radius);
		return gradient;
	},
	DrawHorizontal(draw, size, x1, y1, x2, y2, start = 0, end = 0) {
		let X1 = x1 + start;
		let X2 = x2 - end;
		if (x2 < x1) {
			X1 = x2 + end;
			X2 = x1 - start;
		}
		if (X2 - X1 < 20)
			return;

		draw
			.rect(X2 - X1, size * 2)
			.move(X1 + 15, y1 - size + 15)
			.attr({ fill: '#000', opacity: 0.2 });
		draw
			.rect(X2 - X1, size * 2)
			.move(X1, y1 - size)
			.attr(this.GetAttr(90, y1));
		draw
			.rect(10, size * 2.2)
			.move(X1, y1 - size * 1.1)
			.attr(this.GetAttr(90, y1));
		draw
			.rect(10, size * 2.2)
			.move(X2 - 10, y1 - size * 1.1)
			.attr(this.GetAttr(90, y1));

		draw
			.rect(X2 - X1 - 30, size * 2 - 20)
			.move(X1 + 15, y1 + 10 - size)
			.radius(size / 2)
			.attr(this.GetCutoutAttr());
	},
	DrawVertical(draw, size, x1, y1, x2, y2, start = 0, end = 0) {
		let Y1 = y1 + start;
		let Y2 = y2 - end;
		if (y2 < y1) {
			Y1 = y2 + end;
			Y2 = y1 - start;
		}
		if (Y2 - Y1 <= size)
			return;

		draw
			.rect(size * 2, Y2 - Y1)
			.move(x1 - size, Y1)
			.attr({ fill: '#000', opacity: 0.2 });
		draw
			.rect(size * 2, Y2 - Y1)
			.move(x1 - size, Y1)
			.attr(this.GetAttr(0, x1));
		draw
			.rect(size * 2.2, 10)
			.move(x1 - size * 1.1, Y1)
			.attr(this.GetAttr(0, x1));
		draw
			.rect(size * 2.2, 10)
			.move(x1 - size * 1.1, Y2 - 10)
			.attr(this.GetAttr(0, x1));
	},
	DrawCorner(draw, size, from, to, x, y, radius) {
		let path;
		let angle;
		const fromto = from + to;

		// vertical flange
		const dy = ['NE', 'ES', 'NW', 'WS'].indexOf(fromto) >= 0 ? radius - 10 : 0 - radius;
		draw
			.rect(size * 2.2, 10)
			.move(x - size * 1.1, y + dy)
			.attr(this.GetAttr(0, x));

		// horizontal flange
		const dx = ['SE', 'NE', 'WN', 'WS'].indexOf(fromto) >= 0 ? radius - 10 : 0 - radius;
		draw
			.rect(10, size * 2.2)
			.move(x + dx, y - size * 1.1)
			.attr(this.GetAttr(90, y));

		switch (fromto) {
		case 'SE':
		case 'WN':
			angle = 225;
			path = `M${x - size},${y - radius + 10} `;
			path += `C${x - size} ${y}, ${x} ${y + size}, ${x + radius - 10}, ${y
          + size}`;
			path += `L ${x + radius - 10},${y - size}`;
			path += `C${x + size},${y - size}, ${x + size},${y - size}, ${x
          + size},${y - radius + 10}`;
			break;
		case 'NW':
		case 'ES':
			angle = 45;
			path = `M${x + size},${y + radius - 10} `;
			path += `C${x + size} ${y}, ${x} ${y - size}, ${x - radius + 10}, ${y
          - size}`;
			path += `L ${x - radius + 10},${y + size}`;
			path += `C${x - size},${y + size}, ${x - size},${y + size}, ${x
          - size},${y + radius - 10}`;
			break;
		case 'NE':
		case 'WS':
			angle = 315;
			path = `M${x + size},${y + radius - 10} `;
			path += `C${x + size},${y + size}, ${x + size},${y + size}, ${x
          + radius
          - 10},${y + size}`;
			path += `L ${x + radius - 10},${y - size}`;
			path += `C${x} ${y - size}, ${x - size} ${y}, ${x - size}, ${y
          + radius
          - 10}`;
			break;
		case 'EN':
		case 'SW':
			angle = 135;
			path = `M${x - radius + 10},${y - size} `;
			path += `C${x - size},${y - size}, ${x - size},${y - size}, ${x
          - size},${y - radius + 10}`;
			path += `L ${x + size},${y - radius + 10}`;
			path += `C${x + size} ${y}, ${x} ${y + size}, ${x - radius + 10}, ${y
          + size}`;
			break;
		}
		draw.path(path).attr(this.GetAttr(angle, x, 2.1));
	},

	/**
   * Test to see if a node is connected internally to another node
   * @param  {[type]} dir1   [description]
   * @param  {[type]} index1 [description]
   * @param  {[type]} dir2   [description]
   * @param  {[type]} index2 [description]
   * @return {[type]}        [description]
   */
	isConnected(dir1, index1, dir2, index2) {
		return true;
	},
	EnergizeNode(node, energized) {
		this.EnergizePath(this.node, energized);
	},
	GetStateEquation(dir, index) {
		const connectors = this.model.get('connectors');
		let eqs;
		eqs = [];
		for (const d in connectors)
			if (d !== dir) {
				const node = connectors[d][1];
				//	if (node.id != this.model.id && node.dir != dir) {
				const eq = this.get_connector_state_equation_from_node(node);
				if (eq && typeof eq === 'string' && eqs.indexOf(eq) === -1)
					eqs.push(eq);
			}
		//	}

		// // Filter out the "true" results, to avoid a bunch of "true || true" stuff
		// if (eqs.length > 0)
		// 	for (let i = eqs.length; i >= 0; i--)
		// 		if (eqs[i - 1] === true)
		// 			eqs = eqs.splice(i - 1, -1);

		//	console.log(this.getProp('shape'), eqs);

		//	console.log('node', dir, index, eqs.join(' || '), eqs);
		if (eqs.length === 1)
			return eqs[0];
		if (eqs.length === 0)
			return false;
		return `(${eqs.join(' || ')})`;
	},
});
