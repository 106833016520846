module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	var icons = {
		'a':APP.Tools.icon('arrow-circle-down'),
		'd':APP.Tools.icon('arrow-circle-up')
	}

__p+='\n<table>\n	<thead>\n		<tr>\n			<th>\n				'+
((__t=(APP.Tools.icon(collection)))==null?'':__t)+
'\n					'+
((__t=(collection.replace("_"," ")))==null?'':__t)+
'\n			</th>\n			';

				for (var c =0; c<cols.length; c++) {
					var sort = "";
					var col = cols[c];
					var regx = new RegExp(col+'\\|(.)\\&');
					var dbsort = APP.Tools.store("dbsort",collection)||"";
					var match = dbsort.match(regx);
					if (match) {
						var dbsorts = dbsort.split("&");
						var index = dbsorts.indexOf(col+"|"+match[1])
						var style="";
						sort = icons[match[1]];
						if (index>=0) {
							style = "";
							sort = "<span style='opacity: "+(1-index/dbsorts.length)+"'>"+sort+"</span>";
						}
					}
					print("<th id='"+col+"' class='sort "+col+"'>"+col.replace("_"," ")+sort+"</th>");
				}
			
__p+='\n		</tr>\n	</thead>\n	<tbody id=\'data\'></tbody>\n</table>\n\n'+
((__t=(datalists))==null?'':__t)+
'\n\n';
}
return __p;
};
