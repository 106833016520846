module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var show_screen=show_screen||"";

__p+='\n\n	<table>\n		<tr>\n			<th>Screen</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("show_screen", "screens", show_screen)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n';
}
return __p;
};
