module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>\n  '+
((__t=(ip))==null?'':__t)+
'\n</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'</div>\n\n<table>\n  <tr>\n    <th>IP</th>\n    <td>\n      '+
((__t=(ip))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>MAC address</th>\n    <td>\n      '+
((__t=(mac))==null?'':__t)+
'\n  </tr>\n  <tr>\n    <th>Vendor</th>\n    <td>\n      '+
((__t=(vendor))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th colspan=2>OS</th>\n  </tr>\n  <tr>\n    <td colspan=2>\n      '+
((__t=(osNmap))==null?'':__t)+
'\n    </td>\n  </tr>\n</table>\n\n<h2>Services</h2>\n';
 if (!openPorts) { 
__p+='\n  <div id=\'note\'>No services found</div>\n  ';
} else { 
__p+='\n    <table>\n      ';
 
      for (var i in openPorts) {
      var port = openPorts[i];
        
      
__p+='\n        <tr>\n          <th>\n\n            '+
((__t=(port.port))==null?'':__t)+
'\n          </th>\n          <td>\n            '+
((__t=(port.service))==null?'':__t)+
'\n            ';
 if (port.port==22) print("<a href='ssh://"+ip+"'>"+APP.Tools.icon("link")+"</a>") 
__p+='\n            ';
 if (port.port==80) print("<a target='_new' href='http://"+ip+"'>"+APP.Tools.icon("link")+"</a>") 
__p+='\n            ';
 if (port.port==443) print("<a  target='_new' href='https://"+ip+"'>"+APP.Tools.icon("link")+"</a>") 
__p+='\n          </td>\n        </tr>\n        ';
 } 
__p+='\n    </table>\n    ';
}
__p+='\n\n\n      <h2>Settings</h2>\n      <table>\n        <tr>\n          <th>\n            Icon\n          </th>\n          <td>\n            '+
((__t=(APP.UI.fontawesome("icon",icon)))==null?'':__t)+
'\n          </td>\n        </tr>\n        <tr>\n          <th>\n            Name\n          </th>\n          <td>\n            '+
((__t=(APP.UI.input("name",name,"text",vendor)))==null?'':__t)+
'\n          </td>\n        </tr>\n        <!-- <tr>\n          <th>\n            Vendor\n          </th>\n          <td>\n            '+
((__t=(APP.UI.input("vendor",vendor)))==null?'':__t)+
'\n          </td>\n        </tr>\n        <tr>\n          <th>\n            machine\n          </th>\n          <td>\n            '+
((__t=(APP.UI.input("machine",machine)))==null?'':__t)+
'\n          </td>\n        </tr>\n        <tr>\n          <th>\n            mac\n          </th>\n          <td>\n            '+
((__t=(APP.UI.input("mac",mac)))==null?'':__t)+
'\n          </td>\n        </tr>\n        <tr>\n          <th>\n            ip\n          </th>\n          <td>\n            '+
((__t=(APP.UI.input("ip",ip)))==null?'':__t)+
'\n          </td>\n        </tr> -->\n      </table>\n\n\n\n      <div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n		database\n	</div>\n\n      <div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
'\n		delete\n	</div>\n\n      <div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
'\n		duplicate\n	</div>\n      </div>\n';
}
return __p;
};
