module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';


 var tag_address_url = tag_address_url || ""
 var homepage_url = homepage_url || ""
 var model = model || ""
 var manufacturer = manufacturer || ""
 var partnumber = partnumber || ""

__p+='\n<h1 title=\'\'>\n	'+
((__t=(APP.Tools.icon('device_library')))==null?'':__t)+
'\n	'+
((__t=(device))==null?'':__t)+
'\n</h1>\n\n\n<table>\n	<tr>\n		<th>Name</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'device\' value=\''+
((__t=(device))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Protocol</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("protocol",["none","global","ethernetip","machine","modbus", "bacnet","monnit","arduino","nest","netatmo","simulator","strava","telegauge","wemo","wunderground"],protocol)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th> Polling Rate (sec)\n		</th>\n		<td>\n			<input class=\'field\' type=\'number\' id=\'polling_rate\' value=\''+
((__t=(polling_rate))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th> Data Rate (sec)\n		</th>\n		<td>\n			<input class=\'field\' type=\'number\' id=\'polling_rate\' value=\''+
((__t=(data_rate))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Image</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'image_url\' value=\''+
((__t=(image_url))==null?'':__t)+
'\' placeholder="http://...">\n			'+
((__t=(APP.UI.fontawesome("icon2",image_url,"image_url")))==null?'':__t)+
'\n\n		</td>\n	</tr>\n	<tr>\n		<th>Primary Symbol</th>\n		<td>\n			<!-- <input class=\'field\' type=\'text\' id=\'primary_symbol\' value=\''+
((__t=(primary_symbol))==null?'':__t)+
'\'> -->\n			'+
((__t=(APP.UI.select_from_collection("primary_symbol", APP.models.tag_library.where({dl_id:id}), "symbol", "name", primary_symbol)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Online Symbol\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("running_symbol", APP.models.tag_library.where({dl_id:id}), "symbol", "name", running_symbol)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Fault Symbol\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("fault_symbol", APP.models.tag_library.where({dl_id:id}), "symbol", "name", fault_symbol)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Alarm Arm Symbol\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("alarm_arm", APP.models.tag_library.where({dl_id:id}), "symbol", "name", alarm_arm)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n		Reset Run Time\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("runtime_reset", ['never','daily','weekly','monthly','yearly'], runtime_reset)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<!-- <tr>\n		<th>\n			Manual&nbsp;\n			<a href=\''+
((__t=(manual))==null?'':__t)+
'\'>\n				'+
((__t=(APP.Tools.icon("search")))==null?'':__t)+
'\n			</a>\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("manual", APP.models.files.where({}), "path", "name", manual)))==null?'':__t)+
'\n		</td>\n	</tr> -->\n	<tr>\n		<th>\n			Device\'s Screen\n		</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_collection("device_screen_id","screens","_id","name",device_screen_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Log On/Off Events\n		</th>\n		<td>\n			<input type=\'checkbox\' class=\'field\' id=\'log_onoff\' '+
((__t=(log_onoff? "checked": ""))==null?'':__t)+
'>\n		</td>\n		</tr>\n		<tr>\n		<th colspan=2>Default Tags:</th>\n	</tr>\n	<tr>\n		<td colspan=2><textarea id=\'tag_library\' placeholder=\'{}\' class=\'field json\'></textarea></td>\n	</tr>\n	<tr>\n		<th colspan=2>Notes</th>\n	</tr>\n	<tr>\n		<td colspan=2><textarea placeholder="device library notes..." id=\'notes\' class=\'field full\'>'+
((__t=(notes))==null?'':__t)+
'</textarea>\n		</td>\n	</tr>\n</table>\n<h2>Extra Info</h2>\n<table>\n	<tr>\n		<th>\n			Manufacturer\n		</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'manufacturer\' value=\''+
((__t=(manufacturer))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Make / Model\n		</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'model\' value=\''+
((__t=(model))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Part Number\n		</th>\n		<td>\n			<input class=\'field\' type=\'text\' id=\'partnumber\' value=\''+
((__t=(partnumber))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>\n			Manufacturer Homepage\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input class=\'field\' type=\'url\' id=\'homepage_url\' value=\''+
((__t=(homepage_url))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>\n			Manual/Address Map/API URL\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input class=\'field\' type=\'url\' id=\'tag_address_url\' value=\''+
((__t=(tag_address_url))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n</table>\n\n\n	<h2>Tools</h2>\n	';
 if (!partnumber || !manufacturer) { 
__p+='\n		<button id="devfile" disabled class="action btn">Download DeviceFile JSON</button>\n		<div><i>Manufacturer and Part Number is required to generate a DeviceFile.</i></div>\n	';
 } else { 
__p+='\n		<button id="devfile"  class="action btn">Download DeviceFile JSON</button>\n	';
 } 
__p+='\n\n<div id=\'options\'></div>\n\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
' database\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
' delete\n	</div>\n\n	<div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
' duplicate\n	</div>\n</div>\n';
}
return __p;
};
