DEF.TG.ImportRecords = Backbone.Marionette.View.extend({
	request : require('request'),
	template: require('../dt/templates/import.html'),
	name_map: {
		device_library: 'device',
		reports       : 'name',
		users         : 'name',
		files         : 'name',
		screens       : 'name',
		pollers       : 'name',
		tag_library   : 'symbol',

	},
	templateContext() {
		const rs = this.options; // mode
		rs.dburl = APP.Tools.store('import', 'dburl') || SETTINGS.dburl;
		return rs;
	},
	ui: {
		cmd    : '.command',
		dburl  : '#dburl',
		results: '#results',
	},
	events: {
		'click @ui.cmd': 'DoCommand',
	},
	onDomRefresh() {
		this.DoScan();
	},
	DoCommand(e) {
		const cmd = e.currentTarget.id;
		const val = e.currentTarget.val;
		switch (cmd) {
		case 'scan':
			this.DoScan();
			break;
		default:
			this.ImportRecord(e);

		}
	},
	ImportRecord(e) {
		const name = $(e.currentTarget).attr('value');
		console.log(name);
		$(e.currentTarget).html(APP.Tools.icon('check'));
		switch (this.options.mode) {
		case 'device_library':
			this.Import_DeviceLibrary(name);
			break;
		case 'screens':
			this.Import_ScreenLibrary(name);
			break;
		case 'reports':
		case 'name':
		default:
			this.Import_Records(name);
			break;
		}
	},
	Import_Records(name) {
		const record = this.get_record(this.name_map[this.options.mode], name);
		delete record._id;
		APP.models[this.options.mode].create(record);
		return true;
	},
	Import_ScreenLibrary(name) {
		const record = this.get_record(this.name_map[this.options.mode], name);
		const model_id = record._id;
		const master_id = record.master_id;
		const master = this.records.filter(m => m._id === master_id);
		if (master && master[0] && master[0].width) {
			record.width = master[0].width;
			record.height = master[0].height;
		}
		delete record.views;
		delete record.master_id;
		delete record._id;
		const model = APP.models[this.options.mode].create(record);
		_.delay(() => { model.trigger('change'); }, 500);
		this.listenToOnce(APP.models[this.options.mode], 'add', (model) => {
			console.log(model_id, model);
			const dburl = `${this.ui.dburl.val()}/widgets`;
			this.request(dburl, (error, response, body) => { // load the widgets
				const json = JSON.parse(body);
				for (const j in json) {
					let record = json[j];
					if (record.screen_id === model_id) {
						delete record._id;
						delete record.connectors;
						record.screen_id = model.get('_id');
						const tag_lookup = record._tag_lookup; // copy the tag_lookup record
						if (tag_lookup) { // we gotta replace all the model IDs from the other side with the IDs from this site
							let body2 = JSON.stringify(record); // re-stringify the record, for find&replace
							const body3 = body2; // cache a copy for comparison later
							for (const t in tag_lookup) {
								const [collection, name] = tag_lookup[t].split(':');
								const model = APP.models[collection].find(m => m.getName() === name);
								if (model) // we found a match..
									body2 = body2.replace(t, model.id); // replace the external site's model ids with this site's id
							}
							if (body2 !== body3) // if different
								record = JSON.parse(body2); // re-parse the record, this one with local mode ids
						}

						APP.models.widgets.create(record);
					}
				}
			});
		});
	},
	Import_DeviceLibrary(name) {
		const record = this.get_record(this.name_map[this.options.mode], name);
		const model_id = record._id;
		delete record._id;
		const model = APP.models[this.options.mode].create(record);
		this.listenToOnce(APP.models[this.options.mode], 'add', (model) => {
			console.log(model_id, model);
			const dburl = `${this.ui.dburl.val()}/tag_library`;
			this.request(dburl, (error, response, body) => {
				const json = JSON.parse(body);
				for (const j in json) {
					const record = json[j];
					if (record.dl_id == model_id) {
						delete record._id;
						record.dl_id = model.get('_id');
						APP.models.tag_library.create(record);
						console.log(record.symbol);
					}
				}
			});
		});
	},

	get_record(field, name) {
		for (const r in this.records)
			if (this.records[r][field] === name)
				return this.records[r];
		return false;
	},
	DoScan() {
		$('button#scan').html(`${APP.Tools.icon('working')} Scan`);
		this.request(`${this.ui.dburl.val()}/settings`, (error, response, body) => {
			const json = JSON.parse(body);
			for (const j in json)
				if (json[j].key === 'installation')
					$('fieldset legend').html(json[j].value);
		});
		const dburl = `${this.ui.dburl.val()}/${this.options.mode}`;
		this.request(dburl, (error, response, body) => {
			$('button#scan').html(`${APP.Tools.icon('search')} Scan`);
			if (response && response.statusCode === 200) {
				APP.Tools.store('import', 'dburl', this.ui.dburl.val());
				this.CatchResults(body);
			} else {
				$('fieldset').css('display', 'none');
				console.log('error:', error); // Print the error if one occurred
				console.log('statusCode:', response && response.statusCode); // Print the response status code if a response was received
			}
		});
	},
	CatchResults(body) {
		let html = '';
		// try {
		const json = JSON.parse(body);
		this.records = json;
		$('this.ui.results').show();
		$('fieldset').css('display', 'block');
		if (typeof json[0] === 'object') {
			html = '<table>';
			for (const j in json) {
				const rec = json[j];
				const name = rec[this.name_map[this.options.mode]];
				html += '<tr>';
				html += `<th>${name}</th>`;
				html += '<td>';
				html += `<a class='link command' value='${name}' id='import'>${APP.Tools.icon('download')}</a>`;
				html += '</td>';
				html += '</tr>';
			}
			html += '</table>';
			this.ui.results.html(html);
		}
	//	} catch (e) {
	//		console.error('bad json');
	//	}
	},
});

