module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Log Name</th>\n		<td>\n			<input type=\'text\' id=\'name\' value=\''+
((__t=(name))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Title</th>\n		<td>\n			<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2 class=\'text-center\'>\n			Description (optional)\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<textarea class=\'field\' id=\'description\'>'+
((__t=(description))==null?'':__t)+
'</textarea>\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>\n			Manual Tag Entry (optional)\n		</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			'+
((__t=(APP.UI.chooser("tags", "tags", tags,"multi")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' '+
((__t=(showprefix?"checked":""))==null?'':__t)+
' id=\'showprefix\' class=\'field\'>\n			Include Prefix\n		</td>\n	</tr>\n</table>';
}
return __p;
};
