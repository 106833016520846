module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	var on_time = on_time || 0;
	var off_time = off_time || 0;

__p+='\n<table>\n	<tr>\n		<th>Type</th>\n		<td>'+
((__t=(type))==null?'':__t)+
'/'+
((__t=(cast))==null?'':__t)+
'</td>\n		<th>Color</th>\n		<td><input type=\'color\' id=\'color\' value=\''+
((__t=(color))==null?'':__t)+
'\'></td>\n	</tr>\n	<tr>\n		<th>Raw Value</th>\n		<td>\n			'+
((__t=(raw_value))==null?'':__t)+
'\n		</td>\n		<th>Points</th>\n		<td>\n			'+
((__t=(APP.Format.number(points)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Earliest Data</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(earliest_data)))==null?'':__t)+
'\n		</td>\n		<th>\n			Retention\n			<span class=\'tooltiptext\'>'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' How long this tag is kept in the primary data table.\n				It\'s kept forever in the archive.</span>\n\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(retention)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Earliest Archive</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(earliest_data_archive)))==null?'':__t)+
'\n		</td>\n		<th>\n			'+
((__t=(APP.Lang('Alarms')))==null?'':__t)+
'\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(alarms)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(updated)))==null?'':__t)+
'\n		</td>\n		<th>\n			'+
((__t=(APP.Lang('Warnings')))==null?'':__t)+
'\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(warnings)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Changed</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(changed)))==null?'':__t)+
'\n		</td>\n		<th>\n			'+
((__t=(APP.Lang('Normals')))==null?'':__t)+
'\n		</th>\n		<td>\n			'+
((__t=(APP.Format.number(normals)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Polling rate</th>\n		<td>\n			Every '+
((__t=(APP.Format.duration(polling_rate)))==null?'':__t)+
'\n		</td>\n		<th>\n			Data rate\n			<span class=\'tooltiptext\'>'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' How ofte data is recorded, even if it isn\'t\n				changing</span>\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(data_rate)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>On Time</th>\n		<td>\n			'+
((__t=(APP.Format.duration(on_time*3600)))==null?'':__t)+
'\n		</td>\n		<th>Off Time</th>\n		<td>\n			'+
((__t=(APP.Format.duration(off_time*3600)))==null?'':__t)+
'\n		</td>\n	</tr>\n	';
 if (formula) { 
__p+='\n	<tr>\n		<td colspan=4>\n			<hr>\n		</td>\n	</tr>\n	<tr>\n		<th>Formula</th>\n		';
 if (formula.length>20) { 
__p+='\n	</tr>\n	<tr>\n		<td colspan=4>'+
((__t=(formula))==null?'':__t)+
'</td>\n		';
 } else { 
__p+='\n		<td colspan=4>'+
((__t=(formula))==null?'':__t)+
'</td>\n		';
 } 
__p+='\n	</tr>\n	\n	';
 } 
__p+='\n	';
 if (stats.reasons) { 
__p+='\n	<tr>\n		<td colspan=4>\n			<hr>\n		</td>\n	</tr>\n	<tr>\n		<th>Data Stats</th>\n		<td colspan=3>\n			<table>\n				<tr>\n					<td>Zeros: '+
((__t=(zeros))==null?'':__t)+
'</td>\n					<td>Rates: '+
((__t=(rates))==null?'':__t)+
'</td>\n					<td>Deadbands: '+
((__t=(deadbands))==null?'':__t)+
'</td>\n				</tr>\n			</table>\n		</td>\n	</tr>\n	';
 if (stats.avgdelta) { 
__p+='\n	<tr>\n		<th>Average Delta</th>\n		<td>'+
((__t=(stats.avgdelta))==null?'':__t)+
'</td>\n		<th>Tag Deadband</th>\n		<td>'+
((__t=(deadband))==null?'':__t)+
'</td>\n	</tr>\n	';
 } 
__p+='\n	';
 } 
__p+='\n</table>\n<hr>\n<table>\n	<tr>\n		<th class=\'pull-center\'>Device Lib</th>\n		<th class=\'pull-center\'>Device</th>\n		<th class=\'pull-center\'>Tag Lib</th>\n	</tr>\n	<tr>\n		<td class=\'pull-center\'>'+
((__t=(tag.getf('dl_id')))==null?'':__t)+
'</td>\n		<td class=\'pull-center\'>'+
((__t=(tag.getf('d_id')))==null?'':__t)+
'</td>\n		<td class=\'pull-center\'>'+
((__t=(tag.getf('tl_id')))==null?'':__t)+
'</td>\n	</tr>\n\n</table>\n';
}
return __p;
};
