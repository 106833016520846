module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var tag_id=tag_id||"";

__p+='\n\n<table>\n	<tr>\n		<th>Labels</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("label", {"symbol":"Symbol","tag_name":"Tag Name","name":"Name","prefix":"Prefix"},label, "field") ))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id, "single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>';
}
return __p;
};
