module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n\n	<tr>\n		<th>Label</th>\n		<td>\n			'+
((__t=(APP.UI.input("label2",label2)))==null?'':__t)+
'</br>\n			'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Direction</th>\n		<td>\n			'+
((__t=(APP.UI.radio_from_object("dir",{"N":"North","E":"East","W":"West","S":"South"},dir)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Shape</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("shape",{"pump":"Pump","vacuum":"Vacuum"},shape)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'value\' value=\'true\' '+
((__t=(value? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Show Value\n\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
