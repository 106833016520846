
/*
██   ██ ████████ ███    ███ ██
██   ██    ██    ████  ████ ██
███████    ██    ██ ████ ██ ██
██   ██    ██    ██  ██  ██ ██
██   ██    ██    ██      ██ ███████
*/

// note: so - it makes better sense to extend marionette bases, rather than "html"
// because some of them are itemviews, some are collection views.  But, what we really
// need is just a way to create the canvas, based on the "engine".

DEF.widgets.RT.html = DEF.widgets.RT.base.extend({
	className: "widget",
	//template: require("./templates/widget.html"),
	engine: "html"
});
WID.default = DEF.widgets.RT.html;
