module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var node = node || '';

__p+='\n<h1>\n	'+
((__t=(name))==null?'':__t)+
'\n</h1>\n<table class=\'\'>\n	<tr>\n		<th>Protocol</th>\n		<td>\n			'+
((__t=(protocol))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			'+
((__t=(online?"Uptime":"Downtime"))==null?'':__t)+
'\n		</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(poller_startstop)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>State</th>\n		<td>\n			'+
((__t=(state.replace("* ",APP.Tools.icon('working')+"&nbsp;")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(state_updated)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Restarts</th>\n		<td>\n			'+
((__t=(APP.Format.number(restarts)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Machine</th>\n		<td>\n			'+
((__t=(current_machine))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Node</th>\n		<td>\n			'+
((__t=(node))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th colspan=2>Status</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			'+
((__t=(status))==null?'':__t)+
'&nbsp;\n		</td>\n	</tr>\n</table>\n\n<h2>Commands</h2>\n<button id=\'restart\' '+
((__t=(online? "": "Xdisabled"))==null?'':__t)+
' class=\'action btn\'>Restart</button>\n<button id=\'refresh\' '+
((__t=(online? "": "Xdisabled"))==null?'':__t)+
' class=\'action btn\'>Refresh</button>\n<!-- <button id=\'discover\' '+
((__t=(online? "": "disabled"))==null?'':__t)+
' class=\'action btn\'>Discover</button> -->\n<!-- <button id=\'ping\' '+
((__t=(online? "": "Xdisabled"))==null?'':__t)+
' class=\'action btn\'>Ping</button> -->\n\n<h2>Settings</h2>\n\n<table>\n	<tr>\n		<th>Protocol</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("protocol",["machine","ethernetip","bacnet","modbus","modserve","modbustcp","monnit","arduino","netatmo","simulator","strava","telegauge","wemo","wunderground"],protocol)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Name</th>\n		<td>\n			<input type=\'text\' id=\'name\' class=\'field\' value=\''+
((__t=(name))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Machine</th>\n		<td>\n			<input type=\'text\' id=\'machine\' class=\'field\' value=\''+
((__t=(machine))==null?'':__t)+
'\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Icon</th>\n		<td>\n			'+
((__t=(APP.UI.fontawesome("icon",icon)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<!-- <tr>\n		<th colspan=2>Options</th>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<textarea id=\'options\' class=\'field json\' />\n		</td>\n	</tr> -->\n\n</table>\n\n\n\n\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'database\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n		database\n	</div>\n\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
'\n		delete\n	</div>\n\n	<div class=\'link\' id=\'duplicate\'>\n		'+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
'\n		duplicate\n	</div>\n</div>\n';
}
return __p;
};
