module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<fieldset>\n	<legend>Strava Settings</legend>\n	<table>\n		<tr>\n			<th>Access Token</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.access_token\' value=\''+
((__t=(options.access_token))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Client ID</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.client_id\' value=\''+
((__t=(options.client_id))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Client Secret</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.client_secret\' value=\''+
((__t=(options.client_secret))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n		<tr>\n			<th>Redirect URI</th>\n			<td>\n				<input type=\'text\' class=\'field\' id=\'options.redirect_uri\' value=\''+
((__t=(options.redirect_uri))==null?'':__t)+
'\'>\n			</td>\n		</tr>\n	</table>\n</fieldset>\n';
}
return __p;
};
