module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'map\'>\n\n	<table>\n		<tr>\n			<td colspan=2 id=\'poller\' class=\'box '+
((__t=(poller_state))==null?'':__t)+
'\' rowspan=2>\n				'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' '+
((__t=(poller_name))==null?'':__t)+
'</td>\n			<td colspan=2 class=\'bbottom '+
((__t=(poller_state))==null?'':__t)+
'\'></td>\n			<td colspan=2 rowspan=2></td>\n			<td colspan=2 id=\'data\' class=\'box\' rowspan=2>\n				'+
((__t=(APP.Tools.icon('data')))==null?'':__t)+
' '+
((__t=(tag.getValue()))==null?'':__t)+
'</td>\n		</tr>\n\n		<tr>\n			<td colspan=2 class=\'bright '+
((__t=(poller_state))==null?'':__t)+
'\'></td>\n		</tr>\n\n		<tr>\n			<td colspan=4 class=\'bright '+
((__t=(poller_state))==null?'':__t)+
'\'></td>\n			<td colspan=3 class=\'bright\'></td>\n			<td></td>\n		</tr>\n		<tr>\n			<td colspan=2 rowspan=2 id=\'device_library\' class=\'box\'>\n				'+
((__t=(APP.Tools.icon('device_library')))==null?'':__t)+
' '+
((__t=(tag.getDeviceLibrary().getName()))==null?'':__t)+
'</td>\n			<td class=\'bbottom\'></td>\n			<td colspan=2 rowspan=2 id=\'device\' class=\'box\'>\n				'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' '+
((__t=(tag.getDevice().getName()))==null?'':__t)+
'</td>\n			<td class=\'bbottom\'></td>\n			<td colspan=2 rowspan=2 id=\'tag\' class=\'box\'>\n				'+
((__t=(APP.Tools.icon('tag')))==null?'':__t)+
' '+
((__t=(tag.getName()))==null?'':__t)+
'</td>\n		</tr>\n		<tr>\n			<td></td>\n			<td></td>\n		</tr>\n\n		<tr>\n			<td rowspan=2 class=\'bright\'></td>\n			<td colspan=6></td>\n			<td rowspan=2 class=\'bleft\'></td>\n		</tr>\n		<tr>\n			<td colspan=2 class=\'bbottom\'></td>\n			<td colspan=2 rowspan=2 id=\'tag_library\' class=\'box\'>\n				'+
((__t=(APP.Tools.icon('tag_library')))==null?'':__t)+
' '+
((__t=(tag.getTagLibrary().getName()))==null?'':__t)+
'</td>\n			<td colspan=2 class=\'bbottom\'></td>\n		</tr>\n		<tr>\n			<td colspan=3></td>\n			<td colspan=3></td>\n		</tr>\n\n	</table>\n</div>\n';
}
return __p;
};
