module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';


var range_low = range_low || 0,
range_high = range_high ||0,
delta  = delta ||0,
delta_1h= delta_1h ||0
var unit = unit || ""
var on_time  = on_time || 0
var off_time = off_time || 0
var decimals = decimals || 0

__p+='\n\n<div id=\'value\'>\n	<span class="value">\n		'+
((__t=(valuef))==null?'':__t)+
'\n		<span class="unit">\n			'+
((__t=(APP.Unit.humanize(user_unit)))==null?'':__t)+
'\n		</span>\n	</span>\n	';

			if (unit_list) {
			
__p+='\n	<div id=\'unitselector\'>\n		';

		//console.log("u", user_unit, unit, default_unit)
		
__p+='\n		<input type=\'radio\' '+
((__t=(default_unit==user_unit? "checked": ""))==null?'':__t)+
' name=\'unit\' id=\'unit\'\n			value=\''+
((__t=(APP.Unit.dehumanize(default_unit)))==null?'':__t)+
'\'>\n		<b>\n			'+
((__t=(APP.Unit.humanize(default_unit)))==null?'':__t)+
'\n		</b>\n		<br>\n		';

				for (var u in unit_list) {
				
__p+='\n		<input type=\'radio\' '+
((__t=(unit_list[u]==user_unit? "checked": ""))==null?'':__t)+
' name=\'unit\' id=\'unit\' value=\'\n						'+
((__t=(unit_list[u]))==null?'':__t)+
'\'>\n		'+
((__t=(APP.Unit.humanize(unit_list[u])))==null?'':__t)+
'\n		<br>\n		';

				}
				
__p+='\n	</div>\n	';

			}
			
__p+='\n	<div id="meter">\n	</div>\n</div>\n\n<table>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(updated)))==null?'':__t)+
' ago\n		</td>\n\n		<th>Changed</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(changed)))==null?'':__t)+
' ago\n		</td>\n	</tr>\n	';
 if (typeof stats != "undefined" && stats.avg && stats.max) { 
__p+='\n	<tr>\n		<td colspan=4>\n			<hr>\n		</td>\n		<div id=\'asof\'>stats as of '+
((__t=(APP.Format.livetime(stats.as_of)))==null?'':__t)+
' ago, from about\n			'+
((__t=(APP.Format.duration(retention)))==null?'':__t)+
'\n			of data\n			';
 if(stats.count) { 
__p+=' ('+
((__t=(APP.Format.number(stats.count)))==null?'':__t)+
' points) ';
 } 
__p+='\n		</div>\n	</tr>\n	<tr>\n		<th>\n			Average (mean)\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' Mathematical mean of all recent values (SUM/COUNT)</span>\n		</th>\n		<td>\n			'+
((__t=(stats.avg.toFixed(decimals)))==null?'':__t)+
'\n		</td>\n		<th>\n			Standard Deviation\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' Standard Deviation is a measure of how much the value is likely to stray\n				from the mean. Indicated in the chart above\n				by the lighter green color.</span>\n		</th>\n		<td>\n			'+
((__t=(stats.stdDev.toFixed(2)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>\n			Minimum\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The lowest value recently collected</span>\n		</th>\n		<td>\n			'+
((__t=(stats.min.toFixed(1)))==null?'':__t)+
'\n\n		</td>\n		<th>\n			Maximum\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The maximum value recently collected</span>\n		</th>\n		<td>\n			'+
((__t=(stats.max.toFixed(1)))==null?'':__t)+
'\n		</td>\n	</tr>\n	';
 if (stats.q3) { 
__p+='\n	<tr>\n		<th>\n			First Quartile\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' Sorting all values from low to high, the first quartile is the value after\n				travelling 25% of the way though the list.\n				Represented in the small box plot above as the left edge of the rectangle. Half of all data falls within\n				the rectange.</span>\n		</th>\n		<td>\n			'+
((__t=(stats.q1.toFixed(1)))==null?'':__t)+
'\n		</td>\n		<th>\n			Third Quartile\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' Sorting all values from low to high, the first quartile is the value after\n				travelling 75% of the way though the list.\n				Represented in the small box plot above as the right edge of the rectangle.</span>\n		</th>\n		<td>\n			'+
((__t=(stats.q3.toFixed(1)))==null?'':__t)+
'\n		</td>\n	</tr>\n	';
 } 
__p+='\n	';
 } 
__p+='\n\n	<tr>\n		<td colspan=4>\n			<hr>\n		</td>\n	</tr>\n	<tr>\n		<th>'+
((__t=(APP.Format.capitalize(runtime_reset.replace('never',''))))==null?'':__t)+
' Off Time\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The total amount of time this tag was not "on".</span>\n\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(off_time*3600)))==null?'':__t)+
' \n		</td>\n		<th>'+
((__t=(APP.Format.capitalize(runtime_reset.replace('never',''))))==null?'':__t)+
' On Time\n			<span class=\'tooltiptext\'>\n				'+
((__t=(APP.Tools.icon('info')))==null?'':__t)+
' The total amount of time this tag was "on".</span>\n		</th>\n		<td>\n			'+
((__t=(APP.Format.duration(on_time*3600)))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n<hr>\n<div class=\'widget_links\'>\n	<div id=\'Gauge\' class=\'widget_link \'>\n		'+
((__t=(APP.Tools.icon("Gauge")))==null?'':__t)+
' Gauge</div>\n	<div id=\'Trend\' class=\'widget_link needsdata\'>\n		'+
((__t=(APP.Tools.icon("Trend")))==null?'':__t)+
' Trend</div>\n	<!-- <div id=\'Days\' class=\'widget_link needsdata\'>\n			'+
((__t=(APP.Tools.icon("Days")))==null?'':__t)+
' Days</div> -->\n	<!-- <div id=\'Bar\' class=\'widget_link needsdata\'>'+
((__t=(APP.Tools.icon("Bar")))==null?'':__t)+
' Bar</div> -->\n	<div id=\'LinearGauge\' class=\'widget_link needsdata\'>\n		'+
((__t=(APP.Tools.icon("LinearGauge")))==null?'':__t)+
' LinearGauge</div>\n	<div id=\'Histogram\' class=\'widget_link needsdata\'>\n		'+
((__t=(APP.Tools.icon("Histogram")))==null?'':__t)+
' Histogram</div>\n</div>';
}
return __p;
};
