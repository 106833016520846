module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n  <tr>\n    <th>\n      Shape:\n    </th>\n    <td>\n      '+
((__t=(APP.UI.select_from_object("shape",["none","node","dot","ground","down_arrow","up_arrow","left_arrow","right_arrow"],shape)))==null?'':__t)+
'\n    </td>\n  </tr>\n  <tr>\n    <th>Label: </th>\n    <td>'+
((__t=(APP.UI.input("label",label)))==null?'':__t)+
'</td>\n  </tr>\n  <tr>\n    <th>Tag for Power Sink</th>\n    <td>\n      '+
((__t=(APP.UI.chooser("sink", "tags", sink)))==null?'':__t)+
'\n    </td>\n  </tr>\n</table>';
}
return __p;
};
