module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'header\'>\n	<div id=\'close\' class=\'command\'>\n		'+
((__t=(APP.Tools.icon('close')))==null?'':__t)+
'\n	</div>\n	';
 if (device_screen_id) {
		var screen = APP.models.screens.get(device_screen_id);
		if (screen) {
		
__p+='\n	<div id=\'devicelink\'><a href="'+
((__t=(screen.getLink()))==null?'':__t)+
'/'+
((__t=(prefix))==null?'':__t)+
'">'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' '+
((__t=(prefix))==null?'':__t)+
'</a>\n	</div>\n	';
 } } 
__p+='\n	<div id=\'title\'>\n		'+
((__t=(APP.Tools.icon(type)))==null?'':__t)+
'\n		'+
((__t=(tag_name))==null?'':__t)+
' <span class=\'name\'>'+
((__t=(name))==null?'':__t)+
'</span>\n	</div>\n	<div id=\'description\'>'+
((__t=(description))==null?'':__t)+
'</div>\n\n	<div id=\'alarm_state\' class=\''+
((__t=(alarm_state))==null?'':__t)+
'\'></div>\n\n	<div id=\'commands\'>\n		<div class=\'command\' id=\'details\'>'+
((__t=(APP.Tools.icon("tags")))==null?'':__t)+
' details</div>\n		<div class=\'command\' id=\'device\'>'+
((__t=(APP.Tools.icon("cog")))==null?'':__t)+
' device</div>\n		<div class=\'command\' id=\'connections\'>'+
((__t=(APP.Tools.icon("plug")))==null?'':__t)+
' connections</div>\n		<div class=\'command\' id=\'stats\'>'+
((__t=(APP.Tools.icon("th-list")))==null?'':__t)+
' stats</div>\n		<div class=\'command needsdata\' id=\'events\'>'+
((__t=(APP.Tools.icon("events")))==null?'':__t)+
' events</div>\n		<div class=\'command needsdata\' id=\'data\'>'+
((__t=(APP.Tools.icon("data")))==null?'':__t)+
' data</div>\n	</div>\n</div>\n\n<div id=\'content\'></div>\n<div id=\'footer\'>\n	<!-- <div id=\'details\' class=\'command button active\'>details</div> -->\n	<!-- <div id=\'stats\' class=\'command button\'>stats</div> -->\n	<div id=\'edit\' class=\'command button  '+
((__t=(APP.USER.Can("settings")?"":"disabled"))==null?'':__t)+
'\'>\n		'+
((__t=(APP.Tools.icon( "pencil")))==null?'':__t)+
'\n		edit&hellip;</div>\n	<div id=\'notify\' class=\'command button '+
((__t=(APP.USER.Can("notify")?"":"disabled"))==null?'':__t)+
' \'>\n		'+
((__t=(APP.Tools.icon("mobile-phone")))==null?'':__t)+
'\n		notify</div>\n	<div id=\'export\' class=\'command button '+
((__t=(APP.USER.Can("dataexport")?"":"disabled"))==null?'':__t)+
'\'>\n		'+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n		export</div>\n	';
 if (window.SETTINGS.emails && window.SETTINGS.emails.bugreport) { 
__p+='\n	<div id=\'issue\' class=\'command button '+
((__t=(APP.USER.Can("settings")?"":"disabled"))==null?'':__t)+
'\'>\n		'+
((__t=(APP.Tools.icon("bug")))==null?'':__t)+
'\n		report</div>\n	';
 } 
__p+='\n</div>\n';
}
return __p;
};
