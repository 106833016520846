module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="panel" id="header">\n  <img src=\''+
((__t=(SETTINGS['logo_url'] || 'http://www.telegauge.com/static/img/logo%20with%20text.744dfe5.png'))==null?'':__t)+
' \' id=\'logo\'>\n  <h2>'+
((__t=(APP.Tools.setting('installation')))==null?'':__t)+
'</h2>\n</div>\n';
 if (SETTINGS['login']=='magic') { 
__p+='\n<div class="panel" id="userlist_box"></div>\n\n<div class="panel" id="footer">\n  <div id="message"></div>\n  <div class="flex">\n    <div class="hide right">Email Address:</div>\n    <div>\n      <input type="email" id="email" />\n    </div>\n  </div>\n  <div class="flex">\n    <div>\n      <input class="btn" type="submit" id="link" value="Email a Login Link" />\n    </div>\n  </div>\n</div>\n\n';
 } else { 
__p+='\n<div class="panel" id="userlist_box"></div>\n<input type="hidden" id="login_id" />\n\n<div class="panel" id="footer">\n  <div id="message"></div>\n  <div class="flex">\n    <div class="hide right">Password:</div>\n    <div>\n      <input type="password" id="password" disabled class="disabled" placeholder="password" />\n    </div>\n  </div>\n  <div class="flex">\n    <div>\n      <input class="btn" type="submit" id="login" value="Log In" />\n    </div>\n    <div>\n      <input class="btn" type="submit" id="link" value="Email a Login Link" />\n    </div>\n  </div>\n</div>\n';
 } 
__p+='\n';
}
return __p;
};
