module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Users</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("users")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New User</button>\n<button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Import from Other Installation</button>\n\n	<p>The <i>Users</i> collection holds every user in the system.</p>\n\n<p>Each user has an email address and telephone number used for various reports and notification options.</p>\n\n<p>Also, each user can be assign permissions for various functions within Telegauge.</p>\n\n';
}
return __p;
};
