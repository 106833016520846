module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="'+
((__t=(updated?' ':'new'))==null?'':__t)+
'">\n	<div class=\'tinyflex\'>\n		<div class=\'symbol\'><span style="margin-right: 2px;">'+
((__t=(APP.Tools.icon(type)))==null?'':__t)+
'</span>'+
((__t=(symbol))==null?'':__t)+
' </div>\n		';
 if (address) { 
__p+=' <div class=\'address\'>'+
((__t=(address))==null?'':__t)+
'</div> ';
 } 
__p+='\n		<div class=\'value\'>'+
((__t=(valuef))==null?'':__t)+
'</div>\n	</div>\n	<div class=\'tinyflex\'>\n		<div class=\'name\'>'+
((__t=(name))==null?'':__t)+
'</div>\n		<div class=\'state '+
((__t=(alarm_state))==null?'':__t)+
'\'>'+
((__t=(alarm_state))==null?'':__t)+
'</div>\n	</div>\n</div>\n\n<!-- <table class="'+
((__t=(updated?' ':'new'))==null?'':__t)+
'">\n	<tr>\n		<td>\n			<span class=\'symbol\'>'+
((__t=(APP.Tools.icon(type)))==null?'':__t)+
''+
((__t=(symbol))==null?'':__t)+
'</span> '+
((__t=(name))==null?'':__t)+
'\n		</td>\n		<td align=right>\n			'+
((__t=(valuef))==null?'':__t)+
'\n				'+
((__t=(alarm_state !== "normal" ? APP.Tools.icon(alarm_state,alarm_state,alarm_state) : ""))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n  -->\n';
}
return __p;
};
