module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

console.log("xxxx", connector)
debugger

__p+='\n<div id=\'widget_edit_menu\'>\n	<div class=\'command\' id=\'exit\'>\n		'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' <span class=\'minwidth1200\'>EXIT</span>\n	</div>\n\n	<div class=\'view\'>\n		P&ID Connector\n	</div>\n	<div class=\'view\' id=\'global\'>\n\n\n		<select class=\'prop\' id=\'startmarker\'>\n			<!-- TODO! -->\n			<option></option>\n			<option value=\'none\'>Plain</option>\n			<option value=\'arrow\'>Arrow</option>\n		</select>\n\n		<!-- <span class=\'title\'>Connection</span> -->\n		<select class=\'prop\' id=\'style\'>\n			<option></option>\n			<optgroup label=\'Process\'>\n				<option value=\'process\'>Process</optionvaalu>\n				<option value=\'electrical\'>Electrical</option>\n				<option value=\'pneumatic\'>Pneumatic</option>\n				<option value=\'link\'>Data Link</option>\n			</optgroup>\n			<optgroup label=\'Fluid\'>\n				<option value=\'hot\'>Hot</option>\n			</optgroup>\n\n		</select>\n\n		<select class=\'prop\' id=\'endmarker\'>\n			<option></option>\n			<option value=\'none\'>Plain</option>\n			<option value=\'arrow\'>Arrow</option>\n		</select>\n	</div>\n\n	<div id=\'commands\'>\n		<button class=\'button command\' id=\'delete\'>\n			'+
((__t=(APP.Tools.icon('delete')))==null?'':__t)+
' delete</button>\n		<button class=\'button command\' id=\'split\' value=\'PID\'>\n			'+
((__t=(APP.Tools.icon('circle')))==null?'':__t)+
' node</button>\n		<button class=\'button command\' id=\'split\' value=\'Instrument\'>\n			'+
((__t=(APP.Tools.icon('neuter')))==null?'':__t)+
' intrument</button>\n	</div>\n\n\n	<div class=\'command\' id=\'widgets\'>\n		'+
((__t=(APP.Tools.icon("widgets")))==null?'':__t)+
' <span class=\'minwidth1200\'>WIDGETS</span>\n	</div>\n	';
 if (APP.USER.Can("settings")) { 
__p+='\n	<div id=\'settings\' class=\'command\'>\n		'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
'\n	</div>\n	';
 } 
__p+='\n</div>\n';
}
return __p;
};
