module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Files</h1>\n\n<div id=\'icon\'>'+
((__t=(APP.Tools.icon("files")))==null?'':__t)+
'</div>\n\n<button id=\'create\' class=\'btn\'>\n	'+
((__t=(APP.Tools.icon("add")))==null?'':__t)+
' Create New File</button>\n<!-- <button id=\'import\' class=\'btn command\'>\n	'+
((__t=(APP.Tools.icon("download")))==null?'':__t)+
' Copy from Other Installation</button> -->\n\n<p><i>Files</i> provide access to commonly used documents, such as device manuals, or data backups.</p>\n\n\n';
}
return __p;
};
