module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Type</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("type",{"indicator":"Indicator","behind":"Behind Control","central":"On Central Control","shared":"Shared"},type)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Base</th>\n		<td>\n			'+
((__t=(APP.UI.select_from_object("base",{"stemmed":"Stemmed","inline":"Inline","venturi":"Venturi","sensor":"Sensor"},base)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Stem</th>\n		<td>\n			'+
((__t=(APP.UI.radio_from_object("stem",{"N":"North","E":"East","W":"West","S":"South"},stem)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Upper Label</th>\n		<td>\n			<input type=\'text\' maxlength=5 id=\'label1\' value=\''+
((__t=(label1))==null?'':__t)+
'\' class=\'field live\'\n				style=\'text-transform: uppercase;\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Lower Label</th>\n		<td>\n			<input type=\'text\' maxlength=5 id=\'label2\' value=\''+
((__t=(label2))==null?'':__t)+
'\' class=\'field live\'\n				style=\'text-transform: uppercase;\'>\n		</td>\n	</tr>\n	<tr>\n		<th>Tooltip</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'value\' value=\'true\' '+
((__t=(value? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Show Value\n\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'ring\' value=\'true\' '+
((__t=(ring? "checked" : "" ))==null?'':__t)+
' class=\'field\'> Show Value Ring\n\n		</td>\n	</tr>\n</table>\n';
}
return __p;
};
