module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\''+
((__t=(running_state))==null?'':__t)+
'\' >\n	<div id=\'icon\'>'+
((__t=(APP.Tools.icon(icon, name+" - "+status+" ("+machine+")")))==null?'':__t)+
'</div>\n	<div id=\'status\' class=\''+
((__t=(running_state))==null?'':__t)+
'\' title=\''+
((__t=(protocol))==null?'':__t)+
'\'></div>\n	<div id=\'name\'>'+
((__t=(name))==null?'':__t)+
'</div>\n</div>\n';
}
return __p;
};
