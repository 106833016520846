module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'smallnote\'>\n	'+
((__t=(APP.Tools.icon("info")))==null?'':__t)+
' Use this form to submit a problem with this value. The report will be either sent to the\n	appropriate person,\n	or added to the issue list of your issue tracker, if configured to do so.\n</div>\n\n<table style=\'width:95%\'>\n	<tr>\n		<th>Issue</th>\n		<td><input type=\'text\' id=\'title\' class=\'field full-width\'></td>\n	</tr>\n	<tr>\n		<th>\n			Description\n		</th>\n		<td>\n			<textarea id=\'desc\' rows=5 class=\'field full-width\'></textarea>\n		</td>\n	</tr>\n	<tr>\n		<td>&nbsp;</td>\n		<td>\n			<div class=\'btn\' id=\'submit\'>'+
((__t=(APP.Tools.icon('bug')))==null?'':__t)+
' Submit</div>\n			<div id=\'result\' class=\'smallnote\'></div>\n		</td>\n	</tr>\n</table>\n<div class=\'smallnote\'>\n	This will be sent to '+
((__t=(window.SETTINGS.emails.bugreport))==null?'':__t)+
'.\n</div>';
}
return __p;
};
