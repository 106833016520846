module.exports = DEF.TG.Details.extend({
	template: require('./device_library.html'),
	templateContext() {
		const blocks = this.model.get('blocks') || [];
		// console.log('current>', blocks);
		const newblocks = this.CalculateModbusBlocks();
		return {
			blocks,
			newblocks
		};
	},
	CalculateModbusBlocks() {
		const fns = [
			{ fn: 'readCoils', blocksize: 5000 }, 			// 0
			{ fn: 'readDiscreteInputs', blocksize: 5000 },	// 1
			false,					// 2
			{ fn: 'readInputRegisters', blocksize: 123 }, // 3
			{ fn: 'readHoldingRegisters', blocksize: 125 }	// 4
		];
		let blocks = [];
		const tags = APP.models.tag_library.filter({ dl_id: this.model.id });
		const addresses = tags.map(t => t.get('address')).sort((a, b) => (a < b ? -1 : 1));
		// console.log(addresses);
		let code = false;
		let last_code = false;
		let length = 0;
		let start = false;
		let last_offset;
		const id = 0;
		for (let i = 0; i < addresses.length; i++) {
			if (!addresses[i] || isNaN(addresses[i]))
				continue;
			const address = addresses[i].toString().padStart(5, '0');
			code = Number(address[0]);
			if (isNaN(code) || !fns[code])
				continue;

			const offset = Number(address.slice(1));
			// console.log(code, address, start, offset, offset - start, fns[code].blocksize);
			if (offset - start >= fns[code].blocksize || code !== last_code || offset - last_offset > 100) {
				// blocks.push({
				// 	id  : id++, code: last_code, start, length, fn  : fns[code].fn
				// });
				blocks.push({
					address: (last_code * 10000) + start,
					length
				//	polling_rate: this.model.getUp('polling_rate')
				});
				// id++;
				// console.log("----", JSON.stringify({ code: last_code, start, length, fn: fns[code] }))
				// this.print_modpoll(blocks[blocks.length - 1])
				length = 0;
				start = offset;
				last_offset = offset;
			}
			// console.log(code, address, offset, '|', start, length);

			last_code = code;
			length = offset - start + 1;
			last_offset = offset;
			// if (!blocks[fn])
		}
		// blocks.push({
		// 	id, code: last_code, start, length, fn  : fns[code].fn
		// });
		blocks.push({
			address: (last_code * 10000) + start,
			length
		//	polling_rate: this.model.getUp('polling_rate')
		});
		blocks.map(b => b.address = String(b.address).padStart(5, '0'));
		blocks = blocks.slice(1);
		// console.log(blocks)
		// blocks.map(b => this.print_modpoll(b));
		return blocks;
	},
	print_modpoll(block) {
		console.log(`modpoll 0.0.0.0 -r ${block.start} -c ${block.length} -t ${block.code}`);
	},
	DoExtraField(e) {
		const id = e.currentTarget.id;
		let val = e.currentTarget.value;
		const field = e.currentTarget.dataset.field;
		const index = e.currentTarget.dataset.id;
		if (field === 'address')
			val = val.padStart(5, '0');
		else
			val = parseInt(val, 10);
		console.log('>', id, val, field, index);
		const blocks = this.model.get('blocks');
		const block = blocks[index] || {};
		block[field] = val;
		this.model.set('blocks', blocks);
		this.render();
	},
	DoAction(e) {
		const id = e.currentTarget.id;
		console.log(id);
		switch (id) {
		case 'addblock':
			const blocks = this.model.get('blocks') || [];
			blocks.push({ address: 30001, length: 20 });
			this.model.set('blocks', blocks);
			this.render();
			break;
		case 'useblocks':
			const newblocks = this.CalculateModbusBlocks();
			this.model.set('blocks', newblocks);
			this.render();
			break;
		}
	}
});
