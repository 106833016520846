module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n    <tr>\n        <th>Title</th>\n        <td>\n            <input class=\'field\' type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\'>\n        </td>\n    </tr>\n    <tr>\n        <th>URL</th>\n        <td>\n            <textarea class=\'field\' type=\'text\' id=\'url\'>'+
((__t=(url))==null?'':__t)+
'</textarea>\n        </td>\n    </tr>\n    <tr>\n        <th>Refresh Every</th>\n        <td>\n            '+
((__t=(APP.UI.select_from_object("refresh", {5:"5 seconds",15:"15 seconds", 30:"30 seconds", 60:"1 minute",120:"2 minutes", 600:"5 minutes",1800:"15 minutes"}, refresh) ))==null?'':__t)+
'\n        </td>\n    </tr>\n</table>\n';
}
return __p;
};
