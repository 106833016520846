module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='	<tr>\n		<th>Name</th>\n		<td>'+
((__t=(name))==null?'':__t)+
'</td>\n		<th>Protocol</th>\n		<td>'+
((__t=(protocol))==null?'':__t)+
'</td>\n		<td rowspan=6 class=\'icon_cell\'>\n			'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<th>Enabled</th>\n		<td>'+
((__t=(enabled))==null?'':__t)+
'</td>\n		<th>Online</th>\n		<td>'+
((__t=(online))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>State</th>\n		<td>'+
((__t=(state))==null?'':__t)+
'</td>\n		<th>Running State</th>\n		<td>'+
((__t=(running_state))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>Restarts</th>\n		<td>'+
((__t=(restarts))==null?'':__t)+
'</td>\n		<th>Last Start/Stop</th>\n		<td>'+
((__t=(APP.Format.datetime(poller_startstop)))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>Target Machine</th>\n		<td>'+
((__t=(machine))==null?'':__t)+
'</td>\n		<th>Current Machine</th>\n		<td>'+
((__t=(current_machine))==null?'':__t)+
'</td>\n	</tr>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.datetime(state_updated)))==null?'':__t)+
'\n		</td>\n	</tr>\n\n';
}
return __p;
};
