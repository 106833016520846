module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>'+
((__t=(model_name))==null?'':__t)+
'</h1>\n<textarea id=\'json\'></textarea>\n\n<div id=\'details_footer\'>\n	<div class=\'link\' id=\'save\'>\n		'+
((__t=(APP.Tools.icon("save")))==null?'':__t)+
' save\n	</div>\n	<div class=\'link\' id=\'delete\'>\n		'+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
' delete\n	</div>\n	<div class=\'link\' id=\'revert\'>\n		'+
((__t=(APP.Tools.icon('undo')))==null?'':__t)+
' revert\n	</div>\n\n</div>\n';
}
return __p;
};
