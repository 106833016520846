module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'box\'>\n	<div id=\'logo\'>\n		<span class="helper"></span>\n		<img src=\''+
((__t=(url))==null?'':__t)+
'\'>\n	</div>\n<meter id=\'loading\' min=0 max=16></meter>\n<div id=\'log\'>\n		<div class=\'progress_icon\'>\n			'+
((__t=(APP.Tools.icon('loading')))==null?'':__t)+
'\n			<br \\>\n			loading...\n		</div>\n</div>\n<div id=\'verion\'>\n		'+
((__t=(require("../../../version.html")()))==null?'':__t)+
'\n	</div>\n</div>\n';
}
return __p;
};
