DEF.layout.DT_BOTTOMBAR = Backbone.Marionette.View.extend({
	template: false,
	getTemplate() {
		if (APP.current_screen.selected_connector)
			return require(`./templates/connector_${APP.current_screen.selected_connector[0].data('class')}.html`);


		const length = Object.keys(this.options.screen.selection).length;
		if (length === 0)
			return require('./templates/nowidget.html');
		if (length === 1)
			return require('./templates/singlewidget.html');
		return require('./templates/multiwidget.html');
	},
	templateContext() {
		return {
			count    : Object.keys(this.options.screen.selection).length,
			wids     : Object.keys(this.options.screen.selection),
			selection: this.options.screen.selection,
			connector: APP.current_screen.selected_connector,
			zoom     : this.last_zoom || 0,
			hue      : 0
		};
	},
	regions: {
		props: '#widget_props'
	},
	ui: {
		command: '.command',
		prop   : '.prop',

		tool   : '.tool',
		toolset: '.toolset .label',

		zoom: '#zoom',
		hue : '#hue',

		newwidget: '#widget_list .widget_tile',

		dimensions: '#dimensions input',
		left      : '#dimensions #left',
		top       : '#dimensions #top',
		width     : '#dimensions #width',
		height    : '#dimensions #height',

		settings: '.settings'
	},
	events: {
		'change @ui.dimensions' : 'ChangeDimension',
		'mouseup @ui.dimensions': 'ChangeDimension',
		'click @ui.command'     : 'Command',
		'input @ui.prop'        : 'Command',
		'click @ui.newwidget'   : 'CreateWidget',
		'click @ui.tool'        : 'ClickTool',
		'click @ui.toolset'     : 'ToggleTools',
		'change @ui.settings'   : 'ChangeSettings',
		'input @ui.zoom'        : 'Zoom',
		'input @ui.hue'         : 'Hue'
	},
	onRender () {
		this.SetUpListeners();
		$('#MAIN_BOX').bind('mousewheel', this.Mousewheel, false);

		$('#MAIN_BOX').addClass('bottom_bar_margin');
		//	_.defer(function() {
		if (Object.keys(this.options.screen.selection).length > 0)
			this.ToggleTools();
		//	}.bind(this));
		this.mouse = { // snapshot mouse position, for connector splitting.
			x: this.options.screen.mouse.mx,
			y: this.options.screen.mouse.my
		};
	},
	onDestroy() {
		console.log('DESTR');
		this.RemoveListeners();
		this.trigger('exit');
		$('#MAIN_BOX').removeClass('bottom_bar_margin');
		this.RemoveListeners();
	},
	Mousewheel(e) {
		console.log(e);
	},
	SetUpListeners() {
		this.RemoveListeners();
		this.listenTo(this.options.screen, 'select_widget', this.render);
		this.listenTo(this.options.screen, 'deselect_widget', this.render);

		// Stupid javascript requires a reference to the function to remove, so no go on anon functions
		this.clipfn = {
			cut  : this.Cut.bind(this),
			copy : this.Copy.bind(this),
			paste: this.Paste.bind(this)
		};
		document.addEventListener('cut', this.clipfn.cut);
		document.addEventListener('copy', this.clipfn.copy);
		document.addEventListener('paste', this.clipfn.paste);


		this.widget = false;
		const wids = Object.keys(this.options.screen.selection);
		if (wids.length === 1) {
			this.widget = this.options.screen.selection[wids[0]].widget;
			// this.listenTo(this.widget, "change:left change:top change:width change:height", this.update_dimensions);
			this.listenTo(this.widget, {
				'change:left'  : this.update_left,
				'change:top'   : this.update_top,
				'change:width' : this.update_width,
				'change:height': this.update_height
			});
		}
	},
	RemoveListeners() {
		this.stopListening(this.options.screen);
		if (this.widget)
			this.stopListening(this.widget);
		if (this.clipfn) {
			document.removeEventListener('cut', this.clipfn.cut);
			document.removeEventListener('copy', this.clipfn.copy);
			document.removeEventListener('paste', this.clipfn.paste);
		}
	},
	ChangeDimension(e) {
		const id = e.currentTarget.id;
		const val = e.currentTarget.value;
		console.log(id, val);
		if (this.widget)
			this.widget.set(id, Number(val));
	},
	ChangeSettings(e) {
		const id = e.currentTarget.id;
		switch (id) {
		case 'snap':
			// localStorage.setItem(key, e.currentTarget.checked ? "on" : "off");
			APP.Tools.store('dt', id, e.currentTarget.checked ? 'on' : 'off');
			break;
		case 'grid':
			// localStorage.setItem(key, e.currentTarget.value);
			APP.Tools.store('dt', id, e.currentTarget.value);
			break;
		}
	},
	update_left(obj, val) {
		this.ui.left.val(Number(val));
	},
	update_top(obj, val) {
		this.ui.top.val(Number(val));
	},
	update_width(obj, val) {
		this.ui.width.val(Number(val));
	},
	update_height(obj, val) {
		this.ui.height.val(Number(val));
	},
	ToggleTools(e) {
		const multitool = (Object.keys(this.options.screen.selection).length > 1) ? 'multitool' : 'singletool';
		let id = APP.Tools.store('dt', multitool) || 'align';
		let time = 0;
		if (e) {
			id = $(e.currentTarget).data('set');
			time = 100;
		}
		$(`.toolset#${id} .tools:not(.expanded)`).toggle(time);
		$(`.toolset:not(#${id}) .tools.expanded`).toggle(time).removeClass('expanded');
		$(`.toolset#${id} .tools`).addClass('expanded');
		APP.Tools.store('dt', multitool, id);
	},
	ClickTool(e) {
		const fn = APP.Format.capitalize($(e.currentTarget).parent().parent().attr('id'));
		console.log('tool', fn, e.currentTarget.id);
		if (this[fn])
			this[fn](e.currentTarget.id);
	},

	Command(e) {
		let w;
		const cmd = e.currentTarget.id;
		console.log('cmd', cmd);
		if (APP.current_screen.connector_functions && APP.current_screen.connector_functions[cmd]) {
			APP.current_screen.connector_functions[cmd](APP.current_screen.selected_connector[0], this.mouse, e.currentTarget);
			this.render();
			return;
		}
		switch (cmd) {
		case 'settings':
			APP.Route('#DT');
			break;
		case 'widgets':
			this.ToggleWidgetPanel();
			break;
		case 'duplicate':
			this.DuplicateWidgets(this.options.screen.selection);
			break;
		case 'delete':
			for (w in this.options.screen.selection)
				this.DeleteWidget(w);

			break;
		case 'exit':
			this.Exit();
			break;
		}
	},

	/**
	 * stack widgets in some direction
	 * @param  {[type]} mode [description]
	 * @return {[type]}      [description]
	 */
	Stack(mode) {
		const list = {};
		let keys = [];
		let location = false;
		let widget;
		const size = ['left', 'right'].indexOf(mode) === -1 ? 'height' : 'width';
		const sign = ['right', 'bottom'].indexOf(mode) === -1 ? 1 : -1;

		const padding = $('#stack_include_padding').is(':checked') ? 5 : 0;
		// localStorage.setItem("dt_stack_include_padding", padding);
		APP.Tools.store('dt', 'stack_include_padding', padding);

		for (const wid in this.options.screen.selection) {
			const selection = this.options.screen.selection[wid];
			widget = selection.widget;
			if (typeof (list[widget[mode]()]) === 'undefined')
				list[widget[mode]()] = [];
			list[widget[mode]()].push(widget);
		}
		keys = Object.keys(list).sort((a, b) => a * sign > b * sign);
		console.log(keys);
		for (const i in keys)
			for (const j in list[keys[i]]) {
				widget = list[keys[i]][j];
				if (location === false) {
					location = widget[mode]();
				} else {
					const fn = `set${APP.Format.capitalize(mode)}`;
					widget[fn](location);
				}
				location += (widget[size]() + padding) * sign;
				console.log(mode, size, location);
			}
	},

	/**
	 * APply some size functions
	 * @param  {[type]} mode [description]
	 * @return {[type]}      [description]
	 */
	Size(mode) {
		for (const wid in this.options.screen.selection) {
			const selection = this.options.screen.selection[wid];
			const widget = selection.widget;

			switch (mode) {
			case 'default':
				const geo = this.GetWidgetGeometry(widget.get('widget'));
				widget.set({ width: geo.width, height: geo.height });
				break;
			case 'square':
				const max = Math.max(widget.width(), widget.height());
				widget.set({ width: max, height: max });
				break;
			case 'grid':
				widget.Move(widget.left(), widget.top(), widget.width(), widget.height(), 'sw-resize');
				break;
			}
		}
	},

	/**
	 * Grow the widget in the direction
	 * @param  {[type]} mode [description]
	 * @return {[type]}      [description]
	 */
	Fill(mode) {
		let bail = 0;
		let grid = Number(APP.Tools.store('dt', 'grid') || 10);
		if (['left', 'top'].indexOf(mode) >= 0)
			grid = -grid;


		for (const wid in this.options.screen.selection) {
			const selection = this.options.screen.selection[wid];
			const box = {
				left  : selection.widget.left(),
				top   : selection.widget.top(),
				right : selection.widget.right(),
				bottom: selection.widget.bottom()
			};
			while (bail < 200) {
				if (!this.isEmptySpot(box.left, box.top, box.right - box.left, box.bottom - box.top, wid))
					break;
				box[mode] += grid;
				bail++;
			}
			const fn = `set${APP.Format.capitalize(mode)}`;
			selection.widget[fn](box[mode] - grid, true);
		}
	},

	/**
	 * Moves the widgets so there is equal distance between (or around)
	 * @param {string} mode
	 */

	Distribute(mode) {
		let min = Infinity;
		let max = -Infinity;
		let x = 0;
		let size = 0;
		let count = 0;
		const widgets = this.options.screen.selection;
		for (const wid in widgets) {
			count++;
			const selection = widgets[wid];
			switch (mode) {
			case 'horizontal': // horizontal
				min = Math.min(min, selection.left);
				max = Math.max(max, selection.right);
				size += selection.width;
				break;
			case 'vertical': // horizontal
				min = Math.min(min, selection.top);
				max = Math.max(max, selection.bottom);
				size += selection.height;
			}
		}
		const sorted = {};
		const sortprop = mode === 'horizontal' ? 'left' : 'top';
		Object.keys(this.options.screen.selection)
			.sort((a, b) => widgets[a][sortprop] - widgets[b][sortprop])
			.map(key => (sorted[key] = widgets[key]));


		const delta = Math.floor((max - min - size) / (count - 1));
		console.log(delta, size, max, min);
		x = min;
		for (const wid in sorted) {
			const selection = widgets[wid];
			switch (mode) {
			case 'horizontal':
				selection.widget.set('left', x);
				x += delta + selection.width;
				break;
			case 'vertical':
				selection.widget.set('top', x);
				x += delta + selection.height;
			}
		}
	},

	/**
	 * ALign selected widgets in a direction
	 * @param  {string} mode [left|top|right|bottom]
	 * @return {null}      [description]
	 */
	Align(mode) {
		let newval;
		const min = ['left', 'top'].indexOf(mode) === -1 ? 'max' : 'min'; // left and top are Math.min
		for (const w in this.options.screen.selection) {
			const selection = this.options.screen.selection[w];
			newval = (typeof newval === 'undefined') ? selection[mode] : Math[min](selection[mode], newval);
		}
		const cmd = `set${APP.Format.capitalize(mode)}`;
		for (const w in this.options.screen.selection)
			this.options.screen.selection[w].widget[cmd](newval);
	},
	Resize(mode) {
		let w;
		let min;
		let dim;
		let newval;
		[min, dim] = mode.split('_');
		for (w in this.options.screen.selection) {
			const selection = this.options.screen.selection[w];
			newval = (typeof newval === 'undefined') ? selection[dim] : Math[min](selection[dim], newval);
		}
		const cmd = `set${APP.Format.capitalize(dim)}`;
		for (w in this.options.screen.selection)
			this.options.screen.selection[w].widget[cmd](newval);
	},

	/**
	 * SHow the widget selection panel based on the current APP.widget_list
	 * @param  {bool_label} hide hide it, dont toggle it
	 * @return {[type]}      [description]
	 */
	ToggleWidgetPanel(hide) {
		if ($('#widgets.command').hasClass('selected') || hide) {
			$('#widgets.command').removeClass('selected');
			$('#widget_list').toggle(100);
			setTimeout(() => {
				$('#widget_list').remove();
			}, 100);
		} else {
			let html = "<div id='widget_list' style='display:none'>";
			const widgets = this.GetWidgetList();
			for (const g in widgets) {
				html += ("<div class='group'>");
				html += `<div class='title'>${g}</div>`;
				html += "<div class='widget_tiles'>";
				for (const w in widgets[g])
					html += (`<div class='widget_tile' id='${widgets[g][w]}'>${APP.Tools.icon(widgets[g][w])} ${widgets[g][w]}</div>`);

				html += '</div>';
				html += '</div>';
			}
			html += '</div>';

			$('#widgets.command').addClass('selected');
			this.$el.append(html);
			this.$el.find('#widget_list').toggle(200);
		}
	},
	GetWidgetList() {
		const widgets = APP.widget_list;
		// const protocols = _.uniq(APP.models.device_library.pluck('protocol'));
		//
		// ** I pulled out custom Protocol widgets until they can be properly developed.  Right now, they're kinda lame
		//
		// for (const p in protocols) {
		// 	const protocol = protocols[p];
		// 	if (typeof protocol === 'string')
		// 		try {
		// 			const Protocol = APP.Format.capitalize(protocol);
		// 			if (!widgets.Protocol)
		// 				widgets.Protocol = [];
		// 			if (widgets.Protocol.indexOf(Protocol) === -1) {
		// 				WID[Protocol] = require(`../pollers/${protocol}/widget/widget.js`);
		// 				widgets.Protocol.push(Protocol);
		// 				APP.widget_icons[Protocol] = APP.models.pollers.where({ protocol })[0].get('icon');
		// 			}
		// 		} catch (e) {
		// 		// console.log('no p');
		// 		}
		// }


		return widgets;
	},
	Exit() {
		this.destroy();
		APP.Tools.DeployTags();
		APP.Route(`#RT/${APP.Format.url(APP.current_screen.get('name'))}`);
	},
	Cut(e) {
		if ($('#widget_props').length === 0) {
			this.Copy(e);
			for (const w in this.options.screen.selection)
				this.DeleteWidget(w);
		}
	},
	Copy(e) {
		if ($('#widget_props').length === 0) {
			e.preventDefault();
			const json = JSON.stringify(this.options.screen.selection);
			e.clipboardData.setData('text/plain', json);
			console.log('copy>', json);
		}
	},
	Paste(e) {
		if ($('#widget_props').length === 0) {
			const clipboard = e.clipboardData.getData('text/plain');
			//	console.log('pasted>', clipboard);
			if (clipboard) {
				const json = this.ValidatePastedData(clipboard);
				if (json)
					this.DuplicateWidgets(json);
				console.log(this.options.screen.selection);
			}
		}
	},
	ValidatePastedData(clipboard) {
		let json = false;
		try {
			json = JSON.parse(clipboard);
			const keys = Object.keys(json);
			for (const k in keys)
				if (!json[keys[k]].widget)
					return false;
		}	catch (e) {
			console.log('bad clipboard data', clipboard);
		}
		return json;
	},

	/**
	 * Duplicates a set of widgets.
	 * @param {Object} wids the selection (this.options.screen.selection)
	 */
	DuplicateWidgets(wids, findemptyspot = true) {
		const kinds = {};
		for (const wid in wids)
			if (wids[wid].widget.attributes) // copy and paste sends over just attributes, since there is no model
				kinds[wids[wid].widget.attributes.widget] = true;
			else
				kinds[wids[wid].widget.widget] = true;

		if (!APP.EnsureWidgets(Object.keys(kinds), this.DuplicateWidgets.bind(this, wids)))
			return;

		wids = _.clone(wids);
		const dupmodels = [];
		this.options.screen.UnselectWidget();

		const bounds = this.GetBoundingBox(wids);
		// console.log('bounds', bounds);
		let loc = { x: bounds.x, y: bounds.y };
		if (findemptyspot)
			loc = this.FindEmptySpot(bounds.x, bounds.y, bounds.w, bounds.h);
		// console.log('loc', loc);
		const dx = bounds.x - loc.x;
		const dy = bounds.y - loc.y;
		for (const wid in wids) {
			// const model = APP.models.widgets.get(wid);
			const model = wids[wid].widget;
			let geo = {};

			if (model.get)
				geo = {
					left  : model.get('left'),
					top   : model.get('top'),
					width : model.get('width'),
					height: model.get('height')
				};
			else
				geo = {
					left  : model.left,
					top   : model.top,
					width : model.width,
					height: model.height
				};
			geo.left -= dx;
			geo.top -= dy;

			dupmodels.push(this.DuplicateWidget(model, geo));
		}
		this.options.screen.UnselectWidget();
		this.listenToOnce(APP.models.widgets, 'add', () => {
			for (const d in dupmodels) {
				console.log('selecty', dupmodels[d].id);
				this.options.screen.SelectWidget(dupmodels[d].id);
			}
			this.options.screen.trigger('select_widget');
		});

		// _.delay(() => {
		// 	console.log(dupmodels);
		// 	for (const d in dupmodels) {
		// 		console.log('selecty', dupmodels[d].id);
		// 		this.options.screen.SelectWidget(dupmodels[d].id);
		// 	}
		// }, 200);
		// _.delay(() => {
		// 	this.options.screen.SelectWidgetsInBox(loc.x * APP.current_screen.scale, loc.y * APP.current_screen.scale,
		// 	          bounds.w * APP.current_screen.scale, bounds.h * APP.current_screen.scale);
		// }, 100);
		// _.delay(() => {
		// 	this.options.screen.SelectNewWidgets();
		// });
	},

	/**
	 * Duplicate a widget.  Supply an optional location,
	 * @param {string} model a widget model, or a attributes object
	 * @param {Object} loc {x,y,w,h}
	 */
	DuplicateWidget(model, loc) {
		// const model = APP.models.widgets.get(wid);
		if (model) {
			// if (!loc) {
			// 	this.options.screen.UnselectWidget();
			// 	loc = this.GetWidgetGeometry(model.get('widget'), model.get('left'), model.get('top'), model.get('width'), model.get('height'));
			// }
			let attr = {};
			if (model.GetCloneAttributes)
				attr = _.extend(model.GetCloneAttributes(), loc);
			else
				attr = _.extend(model, loc);

			delete attr._id;
			delete attr.connectors;
			attr.screen_id = APP.current_screen.id;
			const widget = APP.models.widgets.create(attr, { at: -1 });

			this.listenToOnce(APP.models.widgets, 'add', this.DrawNewWidget.bind(this, widget, true));

			// console.log(widget, widget.cid);
			// this.options.screen.SelectWidget(widget.cid);

			// this.listenToOnce(APP.models.widgets, 'add', (m) => {
			// 	console.log('select wid', m.id);
			// 	const id = m.id;
			// 	this.options.screen.SelectWidget(m);
			// 	// _.defer(this.options.screen.SelectWidget.bind(this, id));
			// });
			return widget;
		}
		return false;
	},
	DeleteWidget(wid) {
		this.options.screen.UnselectWidget(wid);
		const model = APP.models.widgets.get(wid);
		model.trigger('destroy');
		APP.models.widgets.remove(wid);
		$(`#${wid}.overlay`).remove();
		model.destroy();
	},
	CreateWidget(e) {
		const widget_kind = e.currentTarget.id;
		if (!APP.EnsureWidgets([widget_kind], this.CreateWidget.bind(this, e)))
			return;

		this.options.screen.UnselectWidget();
		const attr = {
			widget   : widget_kind,
			screen_id: APP.current_screen.id
		};
		const geo = this.GetWidgetGeometry(widget_kind);

		// don't know why I have to include the prototype.  Sometimes new widgets don't have props.
		// The downside is now all widget props are written to the database, which makes it hard to change defaults for existing widgets
		//		_.extend(attr, geo);
		_.extend(attr, WID[widget_kind].prototype.props, geo);
		console.log("new widget", attr);

		this.ToggleWidgetPanel(true)
		const widget = APP.models.widgets.create(attr, { at: -1 }); // the {at:-1} isn't working
		this.options.screen.UnselectWidget();
		this.listenToOnce(APP.models.widgets, 'add', this.DrawNewWidget)
		// this.listenTo(APP.models.widgets, 'add', this.DrawNewWidget)
	},

	/**
	 * Animates in a new widget, though weirdly, only the last in a set gets animated.
	 * @param {[type]} widget [description]
	 */
	DrawNewWidget(widget) {
		console.log('drawnewwidget', widget.id);
		widget.trigger('render');
		// const w2 = this.options.screen.SelectWidget(widget.get('_id'));
		// console.log(widget.id, attr);
		// if (skip_anim) {
			_.defer(() => {
				this.options.screen.SelectWidget(widget.id);
			});
		// }		else {
			// $(`#${widget.id}.overlay, #${widget.id}`).css({ transform: 'scale(4)', opacity: 0 });
			// _.defer(() => {
			// 	$(`#${widget.id}.overlay, #${widget.id}`).css({
			// 		transform : 'scale(1)',
			// 		opacity   : 1,
			// 		transition: 'transform 500ms cubic-bezier(0.23, 1, 0.32, 1), opacity 500ms cubic-bezier(0.23, 1, 0.32, 1)'
			// 	});
			// 	this.options.screen.SelectWidget(widget.id);
			// });
		// }
	},

	/**
	 * Calculate initial dimension and location for this newly created widget
	 * @param {[type]} widget_kind [description]
	 */
	GetWidgetGeometry(widget_kind, x, y, w, h) {
		const width = w || WID[widget_kind].prototype.props.width || 150;
		const height = h || WID[widget_kind].prototype.props.height || 150;
		const left = x || APP.current_screen.getUp('width') / 2;
		const top = y || APP.current_screen.getUp('height') / 2;

		const geo = {
			width,
			height,
			left,
			top
		};

		const loc = this.FindEmptySpot(left, top, width, height);

		geo.left = loc.x;
		geo.top = loc.y;
		return geo;
	},
	GetBoundingBox(wids) {
		const box = {
			l: 10000, t: 10000, b: 0, r: 0
		};
		for (const w in wids) {
			const wid = wids[w];
			box.l = Math.min(box.l, wid.left);
			box.t = Math.min(box.t, wid.top);
			box.r = Math.max(box.r, wid.right);
			box.b = Math.max(box.b, wid.bottom);
		}
		return {
			x: box.l, y: box.t, w: box.r - box.l, h: box.b - box.t
		};
	},
	FindEmptySpot(x, y, w, h) {
		let dx = w / 2;
		let dy = 0;
		// dir = ['t', 'r', 'd', 'l'],
		// dir = ['r', 'd', 'l', 't'],
		//	dir = ['d', 'l', 't', 'r'],
		const dir = ['l', 't', 'r', 'd'];
		let diri = 0;
		const dird = {};
		dird.r = { dx: w, dy: 0 };
		dird.d = { dx: 0, dy: h };
		dird.l = { dx: -w, dy: 0 };
		dird.t = { dx: 0, dy: -h };

		for (let steps = 0; steps < 20; steps++) // length of side wall
			for (let j = 0; j <= 1; j++) { // 2 steps before the length increases
				for (let substep = 0; substep < steps; substep++) {
					x += dx;
					y += dy;
					$('#screen').append(`<div class='overlay selected' style='left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px;'>`);
					const empty = this.isEmptySpot(x, y, w, h);
					if (empty)
						return { x, y };
				}

				diri++;
				diri %= 4;
				dx = dird[dir[diri]].dx;
				dy = dird[dir[diri]].dy;
			}
		return { x: (APP.current_screen.getUp('width') - w) / 2, y: (APP.current_screen.getUp('height') - h) / 2 };
	},

	/**
	 * Find an empty spot
	 * @param {[type]} x [description]
	 * @param {[type]} y [description]
	 * @param {[type]} w [description]
	 * @param {[type]} h [description]
	 * @param {string} ignore wid of widget to ignore
	 */
	isEmptySpot(X, Y, W, H, ignore) {
		// https://roadtrip.telegauge.com/tasks/view/2.8.18  this function is not reliable.  :(
		if (W < 0 || H < 0)
			return false;
		const widgets = APP.current_screen.getWidgets();
		const screen_width = APP.current_screen.getUp('width');
		const screen_height = APP.current_screen.getUp('height');
		// console.log(X, Y, X + W, X + W > APP.current_screen.get('width'), Y + H, Y + H > APP.current_screen.get('height'));
		if (X < 0 || Y < 0 || X + W > APP.current_screen.getUp('width') || Y + H > APP.current_screen.getUp('height'))
			return false;

		for (const wid in widgets) {
			if (widgets[wid].id === ignore)
				continue;
			const widget = widgets[wid].attributes;

			const a = {
				x: X,
				y: Y,
				w: W,
				h: H
			};
			const b = {
				x: widget.left,
				y: widget.top,
				w: widget.width,
				h: widget.height
			};
			// if (a.x < 0 || a.y < 0 || a.x + a.w > screen_width || a.y + a.h > screen_width)
				// return false;
			if (a.x === b.x && a.y === b.y)
				return false;
			if (APP.Tools.check_collision(a, b))
				return false;
		}
		return true;
	},
	Zoom(e) {
		const $screen = $('#screen');
		const screen = this.options.screen;
		const scale = APP.current_screen.scale + parseFloat(e.currentTarget.value);

		const css = { transform: `scale(${scale})` };

		if (e.currentTarget.value > 0)
			$('#SCREEN_BOX').addClass('zoomed');
		else
			$('#SCREEN_BOX').removeClass('zoomed');

		console.log(this.options.screen.mouse.mx);

		if (this.options.screen.mouse.mx)
			css['transform-origin'] = `${screen.mouse.mx}px ${screen.mouse.my}px`;
		else
			css['transform-origin'] = `${APP.current_screen.get('width') / 2}px ${APP.current_screen.get('height') / 2}px`;
		$screen.css(css);
		this.last_zoom = parseFloat(e.currentTarget.value);
	},
	Hue(e) {
		$('#screen').css({
			filter: `hue-rotate(${e.currentTarget.value}deg)`
		});
	}


});
