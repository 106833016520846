module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var node = node || '';

__p+='\n<table class=\''+
((__t=(running_state))==null?'':__t)+
'\'>\n	<tr>\n		<td colspan=3 class=\'protocol\'>\n			'+
((__t=(protocol))==null?'':__t)+
'\n			';
 if (node) { 
__p+='\n			<div style="float:right">\n				Node '+
((__t=(node))==null?'':__t)+
'\n			</div>\n			';
 } 
__p+='\n		</td>\n	</tr>\n	<tr>\n		<td rowspan=2 class=\'icon\'>\n			'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'\n		</td>\n		<td class=\'name\'>\n			'+
((__t=(name))==null?'':__t)+
' @\n				'+
((__t=(current_machine))==null?'':__t)+
'\n		</td>\n		<td class=\'state\'>\n			'+
((__t=(state.replace("* ",APP.Tools.icon('working')+"&nbsp;")))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2 class=\'status\'>\n			'+
((__t=(status))==null?'':__t)+
'&nbsp;\n		</td>\n	</tr>\n</table>\n<div id=\'device_box\'></div>\n';
}
return __p;
};
