module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'controlbox\'>\n	<div class=\'btn btn-small\' id=\'save\'>'+
((__t=(APP.Tools.icon("save")))==null?'':__t)+
' Save</div>\n<div class=\'btn btn-small\' id=\'revert\'>'+
((__t=(APP.Tools.icon("revert")))==null?'':__t)+
' Revert</div>\n</div>\n<textarea type=\'text\' class=\'json\' id=\'data\'></textarea>\n';
}
return __p;
};
