DEF.layout = DEF.layout || {};

require('./dt_bottombar.js');
require('./widget_props.js');
require('./tag_chooser.js');
require('./import_records.js');


DEF.layout.DT = Backbone.Marionette.View.extend({
	id      : 'DT',
	template: require('./templates/dt.html'),
	regions : {
		details : '#DETAILS',
		main    : '#CONTENT',
		menu    : '#MENU',
		pollers : '#poller_status',
		stats   : '#stats',
		machines: '#machine_status',
		help    : '#HELPPANEL'
	},
	ui: {
		menuitem: '#MENU .menuitem',
		exit    : '#exit',
		showhide: '#showhide',
		showhelp: '#showhelp',
		content : '#CONTENT'
	},
	events: {
		'click @ui.menuitem': 'ShowDetails',
		'click @ui.exit'    : 'ExitDT',
		'click @ui.showhide': 'ToggleDetails',
		'click @ui.showhelp': 'ToggleHelp',
		'click @ui.content' : 'SelectNone'
	},
	onRender() {
		const status = new DEF.pollers.DT.StatusList({
			collection: APP.models.pollers,
			childView : DEF.pollers.DT.Status
		});
		this.showChildView('pollers', status);

		const machines = new DEF.layout.DT.MachineList({
			collection: APP.models.devices,
			childView : DEF.layout.DT.machines
		});
		this.showChildView('machines', machines);


		const stats = new DEF.layout.DT.Stats({});
		this.showChildView('stats', stats);
	},
	SelectNone() {
		// APP.Route('#DT');
	},
	ShowDetails(e) {
		const cmd = e.currentTarget.id;
		APP.Route(`#DT/${cmd}`);
		$(this.regions.help).css({ display: 'none', right: 0 });

		e.stopPropagation();
	},
	ExitDT() {
		setTimeout(APP.Tools.DeployTags.bind(this),1000);
		APP.Route(APP.current_screen ? `#RT/${APP.Format.url(APP.current_screen.get('name'))}` : '/');
	},
	ToggleHelp() {
		$(this.regions.help).css({ display: 'initial' });

		if ($(this.regions.help).css('right') === '0px') {
			require('../help/help.view.js');
			const details = new DEF.layout.DT.HelpDetails({ topic: this.mode });
			this.showChildView('help', details);
			$(this.regions.help).css({ right: '315px' });
		} else {
			$(this.regions.help).css({ right: 0 });
		}
	},
	ToggleDetails(show) {
		if (typeof show !== 'boolean')
			show = $('#CONTENT').hasClass('hidedetails');
		if (show) {
			$('#CONTENT').removeClass('hidedetails').addClass('showdetails');
			$('#DETAILS').removeClass('hidedetails').addClass('showdetails');
			$('.tinybuttons').removeClass('hidedetails').addClass('showdetails');
			this.ui.showhide.html(APP.Tools.icon('caret-square-right'));
		} else {
			$('#CONTENT').removeClass('showdetails').addClass('hidedetails');
			$('#DETAILS').removeClass('showdetails').addClass('hidedetails');
			$('.tinybuttons').removeClass('showdetails').addClass('hidedetails');
			this.ui.showhide.html(APP.Tools.icon('caret-square-left'));
		}
		$(this.regions.help).css({ display: 'none', right: 0 });
	}

});

DEF.layout.DT.machine = Backbone.Marionette.View.extend({
	className: 'tag',
	template : require('./templates/machine.html'),
	events   : {
		click: 'Route'
	},
	modelEvents: {
		'change:value': 'render'
	},
	templateContext() {
		const symbol = this.model.getUp('symbol').toLowerCase();
		const icons = {
			disk   : 'hard-drive',
			netup  : 'upload',
			netdown: 'download',
			load   : 'line-chart'
		};
		return {
			symbol,
			percent: this.model.getPercent(),
			icon   : APP.Tools.icon(icons[symbol])
		};
	},
	Route() {
		APP.Route(`#DT/tags/${this.model.id}`);
	}
});

DEF.layout.DT.machinetags = Backbone.Marionette.CollectionView.extend({
	className : 'machine_tags',
	childView : DEF.layout.DT.machine,
	template  : _.template(''),
	collection: APP.models.tags,
	comparator: 'tag_name',
	viewFilter    : function filter(m) {
		const symbols = ['DISK', 'LOAD', 'NETDOWN', 'NETUP'];
		return m.model.getUp('prefix') === this.model.get('prefix') && symbols.indexOf(m.model.getUp('symbol')) >= 0;
	}

});

DEF.layout.DT.machines = Backbone.Marionette.View.extend({
	className: 'machine',
	template : _.template('<%=prefix%><div id="tags"></div>'),
	regions  : {
		body: {
			el            : '#tags',
			replaceElement: true
		}
	},
	onRender() {
		this.showChildView('body', new DEF.layout.DT.machinetags({
			model: this.model
		}));
	}

});

DEF.layout.DT.MachineList = Backbone.Marionette.CollectionView.extend({
	childView: DEF.layout.DT.machines,
	viewFilter   : function filter(m) {
		return m.model.getUp('protocol') === 'machine';
	}
});


DEF.layout.DT.Stats = Backbone.Marionette.View.extend({
	template: require('./templates/stats.html')

	// All this shit below was causing the Stats thing to repaint itself for every modification in the database
	// And to make it worse, it appeared to not clear out old events, so each new event added to a list of events
	// that fired every time.  So, I added a few devices, suddenly 150,000 events have to fire to paint this stupid panel

	// onRender() {
	// 	const collections = ['tag_library', 'device_library', 'tags', 'devices', 'pollers', 'users'];
	// 	for (const c in collections) {
	// 		// this.stopListening(APP.models[collections[c]]);
	// 		this.listenTo(APP.models[collections[c]], 'add', this.render);
	// 		this.listenTo(APP.models[collections[c]], 'remove', this.render);
	// 	}
	// },
});
DEF.layout.DT_SCREEN = Backbone.Marionette.View.extend({
	el: '#screen', // We're doing #screen here, so it doesn't have to re-draw it.
	template: false,
	initialize() {
		this.selection = {}
		this.mouse = { down: false }
		console.log('DESIGN TIMEEEE')
		//		this.listenTo(this, "snapshot", this.SnapshotWidgets);
	},
	regions: {
		widget_props: '#widget_prop_box',
	},
	ui: {
		widgets: '.overlay',
		command: '.command',
		// props  : '#widget_prop_box',
	},
	// childViewTriggers: {
	// 	'mouse:down': 'PropDown',
	// 	'mouse:move': 'PropMove',
	// 	'mouse:up'  : 'PropUp',
	// },
	events: {
		'click @ui.command': 'Command',

		'mousedown @ui.widgets': 'MouseDown',
		'mousemove @ui.widgets': 'MouseDrag',
		'mouseup @ui.widgets': 'MouseUp',

		mousedown: 'ScreenDown',
		mousemove: 'ScreenMove',
		mouseup: 'ScreenUp',
	},
	// Test() {
	// 	console.log('widget collection update')
	// },
	onBeforeRender() {
		this.$el.append("<div id='widget_prop_box'></div>") // append a spot to hold the widget props.  This seems to clean itself up.
	},
	/**
	 * The onRender function is called when the view is rendered. 
	 * It sets up the bottom bar and listens to the models for changes
	 */
	onRender() {
		this.timenow = Date.now()
		_.delay(this.AddOverlays.bind(this), 100)
		this.bottombar = new DEF.layout.DT_BOTTOMBAR({ screen: this })
		this.listenTo(this.bottombar, 'exit', this.HideProps.bind(this))
		APP.root
			.getRegion('main')
			.currentView.showChildView('edit_box', this.bottombar)
		this.listenTo(APP.models.widgets, 'add', this.AddOverlays);
		this.listenTo(APP.models.widgets, 'remove', this.AddOverlays);
		// this.listenTo(APP.models.widgets, 'update', this.AddOverlays)
	},
	onDomRefresh() {
		$(document).on('keydown', this.DoKey.bind(this))
		$(document).on('keydown keyup', this.DoMetaKey.bind(this))
	},
	onDetach() {
		$(document).off('keydown')
		this.HideProps()
	},
	onDestroy() {
		this.selection = {}
		console.log('destroy DT')
	},
	DoMetaKey(e) {
		this.mouse.cmd = e.metaKey
		this.mouse.shift = e.shiftKey
		this.mouse.ctrl = e.ctrlKey
	},
	DoKey(e) {
		const snap = APP.snap

		// console.log(e.key);
		const grid = Number(APP.Tools.store('dt', 'grid') || 10)

		if (
			this.selection &&
			!this.props &&
			!e.metaKey &&
			!e.ctrlKey &&
			!e.shiftKey
		) {
			APP.snap = false
			switch (e.key) {
				case 'w':
					this.MoveWidgets('n-resize', 0, -grid)
					break
				case 'e':
					this.MoveWidgets('n-resize', 0, grid)
					break
				case 'x':
					this.MoveWidgets('s-resize', 0, -grid)
					break
				case 'c':
					this.MoveWidgets('s-resize', 0, grid)
					break
				case 'a':
					this.MoveWidgets('w-resize', -grid, 0)
					break
				case 's':
					this.MoveWidgets('w-resize', grid, 0)
					break
				case 'd':
					this.MoveWidgets('e-resize', -grid, 0)
					break
				case 'f':
					this.MoveWidgets('e-resize', grid, 0)
					break
				case 'ArrowLeft':
					this.MoveWidgets('move', -grid, 0)
					break
				case 'ArrowRight':
					this.MoveWidgets('move', grid, 0)
					break
				case 'ArrowUp':
					this.MoveWidgets('move', 0, -grid)
					break
				case 'ArrowDown':
					this.MoveWidgets('move', 0, grid)
					break
			}
			this.SnapshotWidgets()
		} else {
			switch (e.key) {
				case 'Escape':
					this.HideProps()
					break
			}
		}
		APP.snap = snap
	},

	UpdateSnaps() {
		if (APP.Tools.store('dt', 'snap') === 'off') return

		// if (!this.mouse.down) {
		APP.snap = { x: [], y: [] }
		const snaps = ''

		// THIS method works very fast, but it includes the master sceen.
		//  EDIT: so?
		//
		const widgets = $('.overlay')
		for (let w = 0; w < widgets.length; w++) {
			const widget = $(widgets[w])
			if (this.selection[widget.attr('id')]) continue
			const left = parseInt(widget.css('left'))
			const top = parseInt(widget.css('top'))
			const width = widget.width()
			const height = widget.height()
			// APP.snap.x.push(widget.get("left"), widget.get("left") + widget.get("width"));
			// APP.snap.y.push(widget.get("top"), widget.get("top") + widget.get("height"));
			APP.snap.x.push(left, left + width)
			APP.snap.y.push(top, top + height)
		}
		// var widgets = APP.models.widgets.where({
		// 	screen_id: APP.current_screen.id
		// });
		// for (var w = 0; w < widgets.length; w++) {
		// 	var widget = widgets[w];
		// 	APP.snap.x.push(widget.get("left"), widget.get("left") + widget.get("width"));
		// 	APP.snap.y.push(widget.get("top"), widget.get("top") + widget.get("height"));
		// }
		APP.snap.x = _.uniq(APP.snap.x)
		APP.snap.y = _.uniq(APP.snap.y)
		// this.DrawSnaps();
		// }
	},
	DrawSnaps() {
		if (APP.Tools.store('dt', 'snap') === 'off') return
		this.UndrawSnaps()
		this.UpdateSnaps()
		clearTimeout(this._guidetimer)
		this._guidetimer = setTimeout(() => {
			let html = ''
			for (const x in APP.snap.x)
				html += `<div class='snap' style='left:${APP.snap.x[x]}px;top:0px;bottom:0px'></div>`

			for (const y in APP.snap.y)
				html += `<div class='snap' style='top:${APP.snap.y[y]}px;left:0px;right:0px'></div>`

			this.$el.append(html)
			_.defer(() => {
				$('.snap').addClass('snap_border')
				$('.overlay').addClass('exposed')
			})
		}, 1500)
	},
	UndrawSnaps() {
		clearTimeout(this._guidetimer)
		$('.snap').removeClass('snap_border').remove()
		$('.overlay').removeClass('exposed')
	},
	// 
	/**
	 * With modern Marionette, some sort of event order difference made a regular AddOverlays
	 * simply not work.  New widgets would not be selectable.  This delay seems to help set
	 * the event order.  It's not great, but it helps.
	 *
	 * @return  {[type]}  [return description]
	 */
	AddOverlays() {
		_.delay(this.AddOverlays2.bind(this),50);
	},
	AddOverlays2() {
		const sel = '#SCREEN_BOX > #screen'
		console.log('>AddOverlay', $(`${sel} > .widget.standard`).length, APP.models.widgets.length)
		$('.overlay').remove()
		let append = ''
		$(`${sel} > .widget.standard`).each(function div (x, y) {
			// console.log(x);
			// const dims = $(this).attr('style').match(/[0-9]+/g);
			// const style = `left: ${dims[0]}px; top: ${dims[1]}px; width: ${(dims[2] | 0) + 4}px; height: ${(dims[3] | 0) + 4}px; z-index: ${dims[4]}`;
			let style = $(this).attr('style')
			style = style.replace('background-color', 'custom-background-color') // .overlay would inheret an opaque background color and hide the widget!
			append += `<div class='overlay noselect' style='${style}' id='${this.id}'></div>\n`
		})
		$(sel).append(append)
		$('.stale').removeClass('stale')
		APP.trigger('dt_addoverlays')
	},
	SelectWidgetsInBox(x, y, w, h) {
		// this.UnselectWidget();
		const widgets = APP.models.widgets.where({
			screen_id: APP.current_screen.id,
		})
		for (const i in widgets) {
			const widget = widgets[i]
			const boxa = {
				x: widget.get('left'),
				y: widget.get('top'),
				w: widget.get('width'),
				h: widget.get('height'),
			}
			const boxb = {
				x: x / APP.current_screen.scale,
				y: y / APP.current_screen.scale,
				w: w / APP.current_screen.scale,
				h: h / APP.current_screen.scale,
			}
			if (APP.Tools.check_collision(boxa, boxb)) this.SelectWidget(widget.id)
			else this.UnselectWidget(widget.id)
		}
	},
	SelectWidget(widget_id, toggle) {
		let widget = false
		if (widget_id) {
			if (typeof widget_id === 'string') {
				widget = APP.models.widgets.get(widget_id)
			} else {
				widget = widget_id
				widget_id = widget.id
			}
			if (widget)
				if (!this.selection[widget_id]) {
					this.selection[widget_id] = {
						widget,
						left: widget.left(),
						top: widget.top(),
						width: widget.width(),
						height: widget.height(),
						right: widget.right(),
						bottom: widget.bottom(),
					}
					$(`#${widget_id}.overlay`).addClass('selected')
				} else if (toggle) {
					this.UnselectWidget(widget_id)
				}
		}
		return widget
	},
	UnselectWidget(widget_id) {
		if (widget_id) {
			if (this.selection[widget_id]) {
				$(`#${widget_id}.overlay`).removeClass('selected')
				delete this.selection[widget_id]
				this.HideProps()
				this.UndrawSnaps()
			}
		} else {
			const widgets = Object.keys(this.selection)
			for (let w = 0; w < widgets.length; w++) this.UnselectWidget(widgets[w])
			this.HideProps()
			this.UndrawSnaps()
		}
	},
	SnapshotWidgets() {
		const widgets = Object.keys(this.selection)
		for (let w = 0; w < widgets.length; w++) {
			const widget = this.selection[widgets[w]].widget
			this.selection[widgets[w]].left = widget.left()
			this.selection[widgets[w]].top = widget.top()
			this.selection[widgets[w]].width = widget.width()
			this.selection[widgets[w]].height = widget.height()
		}
	},
	SnapshotMouse(e, mode) {
		this.mouse = {
			mx: e.clientX, // mousex
			my: e.clientY, // mousey
			cmd: e.metaKey,
			shift: e.shiftKey,
			down: true,
			drag: false,
		}

		this.mouse.mode = mode
		switch (mode) {
			case 'prop':
				this.mouse.mx = e.clientX - $('#widget_props')[0].offsetLeft
				this.mouse.my = e.clientY - $('#widget_props')[0].offsetTop
		}
		// switch (mode) {
		// case 'widget':
		// 	this.mouse.down = true;
		// 	this.mouse.drag = false;
		// 	break;
		// case 'screen':
		// 	this.mouse.down = true;
		// 	this.mouse.screen = true;
		// 	break;
		// }
	},
	ScreenDown(e) {
		this.SnapshotMouse(e, 'screen')
		this.mouse.selected = Object.keys(this.selection).length
	},
	ScreenMove(e) {
		if (this.mouse.down)
			switch (this.mouse.mode) {
				case 'screen':
					this.DrawMarquee(e)
					this.mouse.drag = true
					break
				case 'widget':
					let dx = (e.clientX - this.mouse.mx) / APP.current_screen.scale
					let dy = (e.clientY - this.mouse.my) / APP.current_screen.scale
					if (this.mouse.shift)
						if (
							Math.abs(e.clientX - this.mouse.mx) <
							Math.abs(e.clientY - this.mouse.my)
						)
							dx = 0
						else dy = 0

					if (this.mouse.cmd && !this.mouse.drag)
						// drag is false at first
						this.bottombar.DuplicateWidgets(this.selection, false)

					this.mouse.drag = true
					this.MoveWidgets(this.cursor, dx, dy)
					break
				// case 'prop':
				// 	const left = e.clientX - this.mouse.mx; // / APP.current_screen.scale;
				// 	const top = e.clientY - this.mouse.my;// / APP.current_screen.scale;
				// 	$('#widget_props').css({
				// 		left,
				// 		top,
				// 	});
				// 	break;
			}
	},
	ScreenUp(e) {
		//		if (this.mouse.down) {
		if (this.mouse.mode === 'screen' && this.mouse.drag) this.UnDrawMarquee()
		else if (e.currentTarget.id === 'screen') this.UnselectWidget()
		this.mouse.down = false
		this.mouse.drag = false
		this.mouse.mode = false
		this.trigger('deselect_widget')
		//	}
	},
	MouseDown(e) {
		this.SnapshotMouse(e, 'widget')
		this.mouse.newselection = false
		if (!this.selection[e.currentTarget.id]) {
			if (!this.mouse.shift) this.UnselectWidget()
			this.SelectWidget(e.currentTarget.id)
			this.mouse.newselection = true
		}
		this.DrawSnaps()
		this.SnapshotWidgets()
		this.mouse.selected = Object.keys(this.selection).length
		e.stopPropagation()
	},
	MouseDrag(e) {
		if (!this.mouse.down) {
			const parentOffset = $(e.currentTarget).parent().offset()
			const cursor = this.GetCursor(
				e.offsetX,
				e.offsetY,
				e.currentTarget.offsetWidth,
				e.currentTarget.offsetHeight
			)
			$(e.currentTarget).css('cursor', cursor)
		}
	},
	MouseUp(e) {
		if (this.mouse.down) {
			if (!this.mouse.drag && !this.mouse.newselection)
				if (this.props && !this.props.isDestroyed) {
					this.HideProps()
				} else {
					this.ShowProps(e.currentTarget.id)
				}

			e.stopPropagation()
			this.mouse.down = false
			this.mouse.drag = false
			this.mouse.mode = false
			this.SnapshotWidgets()
			this.UnDrawMarquee()
			const selected = Object.keys(this.selection).length
			if (selected > 0 && selected > this.mouse.selected)
				this.trigger('select_widget')
			else this.trigger('deselect_widget')
		}
		this.mouse.screen = false
		_.delay(this.UndrawSnaps.bind(this), 100) // handle a race where the doubleclick is way < 100ms
	},
	// PropDown(e) {
	// 	console.log('prop down', e);
	// 	this.SnapshotMouse(e, 'prop');
	// },
	// PropMove(e) {
	// 	// console.log('prop move', e);
	// 	this.ScreenMove(e);
	// },
	// PropUp(e) {
	// 	console.log('prop up');
	// 	this.mouse.down = false;
	// 	this.mouse.drag = false;
	// 	this.mouse.mode = false;
	// },
	UnDrawMarquee() {
		$('#marquee').remove()
		this.mouse.screen = false
	},
	DrawMarquee(e) {
		const x1 = this.mouse.mx
		const x2 = e.clientX
		const y1 = this.mouse.my
		const y2 = e.clientY

		const x = Math.min(this.mouse.mx, e.clientX)
		const y = Math.min(this.mouse.my, e.clientY)
		const w = Math.max(this.mouse.mx, e.clientX) - x
		const h = Math.max(this.mouse.my, e.clientY) - y
		if ($('#marquee').length === 0)
			$('#screen').append("<div id='marquee'></div>")

		$('#marquee').css({
			left: `${x / APP.current_screen.scale}px`,
			top: `${y / APP.current_screen.scale}px`,
			width: `${w / APP.current_screen.scale}px`,
			height: `${h / APP.current_screen.scale}px`,
		})
		this.SelectWidgetsInBox(x, y, w, h)
	},
	MoveWidgets(mode, dx, dy) {
		if (dx === 0 && dy === 0) return

		dx = Number(dx)
		dy = Number(dy)
		let de = 0
		let dn = 0
		let ds = 0
		let dw = 0
		let resize = true

		switch (mode) {
			case 'nw-resize':
				de = -dx
				dn = dy
				dw = dx
				ds = -dy
				break
			case 'ne-resize':
				dn = dy
				de = dx
				ds = -dy
				break
			case 'sw-resize':
				de = -dx
				dw = dx
				ds = dy
				break
			case 'se-resize':
				de = dx
				ds = dy
				break
			case 'w-resize':
				dw = dx
				de = -dx
				break
			case 'e-resize':
				de = dx
				break
			case 'n-resize':
				dn = dy
				ds = -dy
				break
			case 's-resize':
				ds = dy
				break
			case 'move':
			default:
				resize = false
				dw = dx
				dn = dy
				break
		}

		const widgets = Object.keys(this.selection)
		for (let w = 0; w < widgets.length; w++) {
			const select = this.selection[widgets[w]]
			select.widget.Move(
				dw + Number(select.left),
				dn + Number(select.top),
				de + Number(select.width),
				ds + Number(select.height),
				mode,
				widgets.length === 1
			)
		}
		this.HideProps()
	},

	Command(e) {},
	GetCursor(dx, dy, width, height) {
		let cursor = 'move'

		const w = width * 0.1
		const e = width - width * 0.1
		const n = height * 0.1
		const s = height - height * 0.1

		if (dx < w && dy < n) cursor = 'nw-resize'
		else if (dx > e && dy < n) cursor = 'ne-resize'
		else if (dx < w && dy > s) cursor = 'sw-resize'
		else if (dx > e && dy > s) cursor = 'se-resize'
		else if (dx < w) cursor = 'w-resize'
		else if (dx > e) cursor = 'e-resize'
		else if (dy < n) cursor = 'n-resize'
		else if (dy > s) cursor = 's-resize'

		this.cursor = cursor // TODO this is a dumb way to know if you're resizing
		return cursor
	},
	ShowProps(wid) {
		this.props = wid
		console.log(this.mouse.mode)
		this.mouse.mode = 'props'

		const widget = APP.models.widgets.get(wid)
		const widget_name = widget.getUp('widget')
		const props = WID[widget_name].prototype.props
		let path = ''
		// if (props.group) // widgets can be grouped.  like "oneline"
		// 	 path += `${props.group}/`;
		if (
			APP.models.device_library
				.pluck('protocol')
				.indexOf(widget_name.toLowerCase()) === -1
		)
			path = `../widgets/${widget_name}/props.html`
		else path = `../pollers/${widget_name.toLowerCase()}/widget/props.html`

		console.log(path)
		let template
		if (
			APP.models.device_library
				.pluck('protocol')
				.indexOf(widget_name.toLowerCase()) === -1
		)
			template = require(`../widgets/${widget_name}/props.html`)
		else
			template = require(`../pollers/${widget_name.toLowerCase()}/widget/props.html`)

		this.props = new DEF.layout.DT_WIDGETPROPS({
			model: widget,
			// template: require("../widgets/" + widget.get('widget') + "/props.html"),
			template,

			// attachElContent: function (html) {
			// 	console.log("att");
			// 	$("#TOOLTIP_BOX").html(html);
			//
			// 	return this;
			// },
		})
		this.showChildView('widget_props', this.props)
		// this.listenTo(this.props, 'mouse:down', this.PropDown);
		// this.listenTo(this.props, 'mouse:move', this.PropMove);
		// this.listenTo(this.props, 'mouse:up', this.PropUp);
		$('#widget_props').appendTo('body')
	},
	HideProps() {
		if (this.mouse.mode === 'props') this.mouse.mode = 'screen'
		if (this.props && this.props.destroy) {
			this.props.destroy()
			this.props = false
		}
	},
})
