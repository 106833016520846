/**
 * Special classes on each field:
 *
 * .field = save the change to the model
 * .live = render with each change of input (dragging sliders...)
 * .draw = re-render the whole view
 */
DEF.layout.DT_WIDGETPROPS = Backbone.Marionette.View.extend({
	id      : 'widget_props',
	template: false,
	templateContext() { // include the widget's default props
		const out = $.extend({}, WID[this.model.attributes.widget].prototype.props, this.model.attributes);
		return out;
	},
	ui: {
		titlebar   : '#title',
		//		command    : '.command', // for buttons and things, i guess.  Look at Day.js
		field      : '.field', // Props that have class='field' are saved as props in the model
		//	input     : 'input.field', // ...but really, only save "input" fields
		livefield  : '.field.live', // live = change the widget with each change
		refresh    : '.field.refresh', // refresh = re-display props when this field changes (useful for showing/hiding additional options)
		// liverefresh: '.field.live.refresh',
		database   : '#database',
		stylebtn   : '#stylebtn',
		customstyle: '#customstyle',
		position   : '.position',
		close      : '#close',
		json       : '.json',
		save       : '#save',
		tagchooser : '.tagchooser',
		helpbox    : '#helpbox',
		default    : '.field.default',
		delete_tag : '.tagchooser .item .delete'
	},
	events: {
		// 'mousedown @ui.titlebar': 'MouseDown',
		// 'mousemove @ui.titlebar': 'MouseMove',
		// 'mouseup @ui.titlebar'  : 'MouseUp',
		'click @ui.database'  : 'ShowJSON',
		'click @ui.stylebtn'  : 'ShowStyle',
		'keyup @ui.json'      : 'Validate',
		'keyup @ui.livejson'  : 'ValidateSave',
		'change @ui.field'    : 'Save',
		'change @ui.refresh'  : 'render',
		'change @ui.position' : 'SetPosition',
		'change @ui.default'  : 'SaveDefault',
		'input @ui.livefield' : 'Save',
		'click @ui.command'   : 'DoCommand',
		// 'input @ui.liverefresh': 'render',
		'click @ui.save'      : 'SaveJSON',
		'click @ui.close'     : 'Close',
		// 'click @ui.delete'    : 'DeleteTag',
		'click @ui.tagchooser': 'ToggleTagChooser',
		'mouseenter @ui.field': 'ShowHelp',
		'mouseleave @ui.field': 'HideHelp',
		'click @ui.delete_tag': 'DeleteTag'
	// "click": "HideTagChooser"
		// "click": "Click"
	},
	onRender() {
		this.DrawHeader();
		_.defer(this.SetPosition.bind(this));

		// this.SetPosition();
	},
	onDomRefresh() {
		const Draggable = require('draggable');
		new Draggable(this.$el[0], { handle: this.$el.find('#title')[0] });
		this.$el.css({ width: 'auto', height: 'auto' });
	},

	SetPosition() {
		let left = (this.model.right() * APP.current_screen.scale) + 10;
		let top = this.model.top() * APP.current_screen.scale;
		if (left + this.$el.width() > $(window).width() - 40)
			left = (this.model.left() * APP.current_screen.scale) - this.$el.width() - 40;
		if (top + this.$el.height() > $(window).height() - 40)
			top = $(window).height() - this.$el.height() - 40;
		if (left < 0) {
			left = 20;
			top += this.model.height();
		}
		if (top + this.$el.height() > $(window).height() - 40)
			top = $(window).height() - this.$el.height() - 40;
		if (top < 0)
			top = 5;
		this.$el.css({
			left,
			top,
			opacity: 1
		//	transform: "scale(" + 1 / APP.current_screen.scale + ")"
		});
		// _.delay(() => {
		// 	console.log('listen on click');
		// 	$('.delete').off('click', this.DeleteTag);
		// 	$('.delete').on('click', this.DeleteTag);
		// }, 100); // delayed, for tag choosers that appear in tabs, which themselves have a delayed paint.
	},
	SaveDefault(e) {
		const id = e.currentTarget.id;
		const value = e.currentTarget.value;
		if (typeof value !== 'object') {
			const widget = this.model.get('widget');
			APP.Tools.store(`prop_${widget}`, id, value);
			WID[widget].prototype.props[id] = value;
			console.log('default set', id, value);
		}
	},
	ShowHelp(e) {
		const id = e.currentTarget.id;
		const widget = this.model.get('widget');
		let help = WID[widget].prototype.help[id];
		if (!help && id === 'stylebtn')
			help = 'Apply custom CSS';
		if (!help && id === 'database')
			help = 'Edit the raw values for this widget.  Be careful!';
		if (help) {
			const $box = this.$el.find('#helpbox');
			this.$el.width(this.$el.width()); // prevent new help text from expanding, horizontally, the prop window
			clearTimeout(this.helptimer); // this timer also controls hiding, so stop hiding
			if ($box.is(':visible'))
				$box.html(`${APP.Tools.icon('info-circle')} ${help}`);
			else
				this.helptimer = setTimeout(() => {
					$box.toggle(150);
					$box.html(`${APP.Tools.icon('info-circle')} ${help}`);
				}, 2000);
		}
	},
	HideHelp() {
		if (this.$el.find('#helpbox').is(':visible')) {
			clearTimeout(this.helptimer);
			this.helptimer = setTimeout(() => {
				this.$el.find('#helpbox').toggle(250);// .html('');
			}, 500);
		}
	},
	DrawHeader() {
		let header = "<div id='header'>";
		header += `<div id='title'>${APP.Tools.icon(this.model.get('widget'))} ${this.model.get('widget')}</div>`;
		header += `<div class='command field' id='stylebtn'>${APP.Tools.icon('code-fork')}</div>`;
		header += `<div class='command field' id='database'>${APP.Tools.icon('database')}</div>`;
		header += `<div class='command' id='close'>${APP.Tools.icon('close')}</div>`;
		header += '</div>';
		this.$el.prepend(header);

		const footer = "<div id='helpbox' style='display:none'></div>";
		this.$el.append(footer);
	},
	Save(e) {
		let id = e.currentTarget.id;
		let val = e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value;
		if (val !== undefined) { // the tag chooser Search field triggers a change
			if (isNumber(val))
				val = Number(val);

			if (id.indexOf('.') > 0) { //   "field.a:5" => "field: {a:5}"
				[id, val] = APP.Tools.explode_field_id(id, val);
				val = APP.Tools.deep_extend(this.model.get(id) || {}, val);
				this.model.trigger(`change:${id}`);
			}

			switch (e.currentTarget.type) {
			case 'textarea':
				if ($(e.currentTarget).hasClass('json'))
					try {
						val = JSON.parse(val);
					} catch (err) {
						console.warn('Invalid JSON first pass');
						try {
							val = JSON.parse(val.replace(/'/g, '"').replace(/([_a-zA-Z0-9-]+):([\s"a-zA-Z0-9-]+)/g, '"$1":$2'));
							e.currentTarget.value = JSON.stringify(val, null, '\t');
							this.Validate();
						} catch (e) {
							console.warn('Invalid JSON');
						}
					}


				this.model.set(id, val);
				break;
			case 'checkbox':
				if (typeof val === 'object') // this is a complex field, like "traces" for Trend.
					this.model.set(id, val);
				else
					this.model.set(id, e.currentTarget.checked);
				break;
			default:
				this.model.set(id, val);
				break;
			}
			if (e.currentTarget.className.indexOf('draw') !== -1)
				this.model.trigger('redraw');
			console.log('.field', id, val);
			this.model.GenerateIDtoName();
		}
	},
	// DoCommand() {
	// 	debugger;
	// },
	ShowJSON() {
		let html = `<textarea id='json' class='json'>${JSON.stringify(this.model.toJSON(), null, '\t')}</textarea>`;
		html += "<br><button class='button' id='save'>Save</button>";
		this.$el.html(html);
		this.DrawHeader();
		this.bindUIElements();
		_.defer(this.SetPosition.bind(this));
	},
	ShowStyle() {
		let html = `<textarea id='customstyle' class='json'>${JSON.stringify(this.model.get('customstyle') || {}, null, '\t')}</textarea>`;
		html += "<br><button class='button' id='save'>Save</button>";
		this.$el.html(html);
		this.DrawHeader();
		this.bindUIElements();
		_.defer(this.SetPosition.bind(this));
	},
	Validate(save = false) {
		if (this.ui.json.val())
			try {
				const json = JSON.parse(this.ui.json.val());
				this.ui.json.removeClass('badjson');
				if (save)
					this.SaveJSON(false);
				return true;
			} catch (e) {
				this.ui.json.addClass('badjson');
				return false;
			}
		return false;
	},
	ValidateSave() {
		return this.Validate(true);
	},
	SaveJSON(render = true) {
		if (this.Validate())
			if (this.ui.customstyle.val())
				this.model.set('customstyle', JSON.parse(this.ui.customstyle.val()));
			else
				this.model.set(JSON.parse(this.ui.json.val()));
		if (render)
			this.render();
	},
	Close() {
		this.destroy();
	},
	HideTagChooser(e) {
		console.log(e.currentTarget);
		this.removeRegion('chooser');
		$(e.currentTarget).removeClass('active');
		this.render();
	},
	ToggleTagChooser(e) {
		// console.log('toggle tag chooser', e.currentTarget, e.target, $(e.currentTarget).hasClass('tagchooser'));
		if (!(e.currentTarget === e.target || $(e.target).hasClass('item'))) // this verifes that a child element was not clicked
			return false; // TODO apply this method to the props itself.
		const id = e.currentTarget.id;
		const options = $(e.currentTarget).data();
		if (!$(e.currentTarget).hasClass('active')) {
			$(e.currentTarget).addClass('active');
			this.addRegion('chooser', `#${e.currentTarget.id}`);
			options.field_id = id;
			options.collection_name = options.collection;
			options.collection = APP.models[options.collection_name];
			options.filterkey = $(e.currentTarget).data('filterkey');
			options.filtervalue = $(e.currentTarget).data('filtervalue');
			options.model = this.model;
			const chooser = new DEF.layout.DT_TAGCHOOSER(options);
			this.listenTo(chooser, 'chooser:select chooser:close', this.render);
			this.showChildView('chooser', chooser);
			this.SetPosition();
			// const prop = $('#widget_props .tagchooser #chooser');
			// const top = $(window).height() - prop.height() - prop.offset().top;
		} else if (options.mode !== 'multi') {
			this.removeRegion('chooser');
			$(e.currentTarget).removeClass('active');
		}
		e.stopPropagation();
		return true;
	},
	DeleteTag(e) {
		console.log('del');
		const tag_id = e.currentTarget.parentElement.id;
		const field = e.currentTarget.parentElement.parentElement.id;
		let tags = this.model.get(field);
		const set = {};

		if (_.isArray(tags))
			tags.splice(tags.indexOf(tag_id), 1);
		else
			tags = false;

		set[field] = tags;
		this.model.set(set);
		e.currentTarget.parentElement.remove();
		//	thi.reder();
		e.stopPropagation();
	},
	Del2(e) {
		debugger;
	}
});
