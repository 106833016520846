module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var title=title||"";
var display=display||"";
var d_id=d_id||"";

__p+='\n\n	<table>\n		<tr>\n			<th>Title</th>\n			<td>\n				<input type=\'text\' id=\'title\' value=\''+
((__t=(title))==null?'':__t)+
'\' class=\'field\'>\n			</td>\n		</tr>\n		<tr>\n			<th>By Device</th>\n			<td>\n				'+
((__t=(APP.UI.chooser("d_id", "devices", d_id)))==null?'':__t)+
'\n			</td>\n		</tr>\n	</table>\n	'+
((__t=(APP.UI.tabs("mode",mode,{alarms:"Alarms",events:"Events"})))==null?'':__t)+
'\n		<table class=\'tabcontent\' data-for="mode" id=\'alarms\'>\n			<tr>\n				<th>Filter</th>\n				<td>\n					'+
((__t=(APP.UI.checkbox('alarm',alarm,APP.Lang('Alarms'))))==null?'':__t)+
'\n						'+
((__t=(APP.UI.checkbox('alarm_ack',alarm_ack,APP.Lang('Alarms') + " (acknowledged)")))==null?'':__t)+
'\n							<br>\n							'+
((__t=(APP.UI.checkbox('warning',warning,APP.Lang('Warnings'))))==null?'':__t)+
'\n								'+
((__t=(APP.UI.checkbox('warning_ack',warning_ack,APP.Lang('Warnings') +" (acknowledged)")))==null?'':__t)+
'\n									<br>\n									'+
((__t=(APP.UI.checkbox('stale',stale,"Stale")))==null?'':__t)+
'\n				</td>\n			</tr>\n\n		</table>\n		<div class=\'tabcontent\' data-for="mode" id=\'events\'>\n			Hello!\n		</div>\n';
}
return __p;
};
