module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Notifications for\n	'+
((__t=(APP.Tools.icon("tag")))==null?'':__t)+
'\n		'+
((__t=(tag_name))==null?'':__t)+
'\n</h2>\n\n\n<table class="notify_form" width="100%">\n	<tbody>\n		<tr> \n			<td valign="top">\n				<strong>Mobile Number:</strong>\n				<br>\n				<input type="tel" class="user_settings" required="required" id="phone" value="'+
((__t=(APP.USER.get('phone')))==null?'':__t)+
'">\n			</td>\n			<td>\n				<strong>Subscribers:</strong>\n			</td>\n		</tr>\n		<tr>\n			<td>\n				<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.off_sms))==null?'':__t)+
' id="off_sms"> '+
((__t=(APP.Tools.icon('off')))==null?'':__t)+
' Turns Off\n			</td>\n			<td rowspan=3 id=\'subscribers\'></td>\n		</tr>\n		<tr>\n			<td>\n				<input type="checkbox" class="notification_checkbox" '+
((__t=(checked.on_sms))==null?'':__t)+
' id="on_sms"> '+
((__t=(APP.Tools.icon('on')))==null?'':__t)+
' Turns On\n			</td>\n		</tr>\n		<tr>\n			<td>\n				<div class=\'button notifycmd\' id=\'smstest\'>test</div>\n			</td>\n		</tr>\n		<tr>\n			<td colspan=2>\n				';
 if (!window.SETTINGS.twilio) {  
__p+='\n					<div class=\'note alarm\'>\n						'+
((__t=(APP.Tools.icon("alarm")))==null?'':__t)+
' SMS API is not set up</div>\n					';
 } else {
__p+='\n						<div class=\'smallnote\'>\n							'+
((__t=(APP.Tools.icon("info")))==null?'':__t)+
' SMS notifications are sent from\n								<b>\n									'+
((__t=(window.SETTINGS.twilio.phone))==null?'':__t)+
'\n								</b>\n						</div>\n						';
 } 
__p+='\n			</td>\n		</tr>\n		<!-- <tr>\n			<td colspan="2">\n				<input type="checkbox" class="notification_checkbox" id="sms_at_night" checked="checked"> Notify at Night? (8pm-8am)\n			</td>\n		</tr> -->\n	</tbody>\n</table>\n';
}
return __p;
};
