module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>FILE</h1>\n\n';
 if (!id) { 
__p+='\n  <div id=\'new_record\'>\n        '+
((__t=(APP.Tools.icon("plus")))==null?'':__t)+
'\n        Create New File<hr>\n    ';
 } 
__p+='\n\n    <table>\n        <tr>\n            <th>Name</th>\n            <td>\n                <input type=\'text\' id=\'name\' class=\'field\' value=\''+
((__t=(name))==null?'':__t)+
'\'>\n            </td>\n        </tr>\n        <tr>\n					<th>Icon</th>\n					<td>\n						'+
((__t=(APP.UI.select_from_object("icon",_.invert({"file":"file",
							 "archive":"file-archive",
							 "audio":"file-audio",
							 "code":"file-code",
							 "excel":"file-excel",
							 "image":"file-image",
							 "PDF":"file-pdf",
							 "powerpoint":"file-powerpoint",
							 "text":"file-text",
							 "video":"file-video",
							 "word":"file-word"}), icon)))==null?'':__t)+
'\n					</td>\n				</tr>\n				<tr>\n					<th colspan=1>\n						URL\n					</th>\n					<td colspan=1>\n						<input type=\'text\' id=\'path\' class=\'field\' value=\''+
((__t=(path))==null?'':__t)+
'\' placeholder=\'http://...\'>\n					</td>\n				</tr>\n\n				</table>\n\n\n\n        ';
 if (!id) { 
__p+='\n        </div>\n  ';
 } 
__p+='\n\n    <h2>Commands</h2>\n    <button id=\'download\' class=\'action btn\'>\n      '+
((__t=(APP.Tools.icon('download')))==null?'':__t)+
' Download</button>\n\n    <h2>Preview</h2>\n    ';
 if (content) { 
__p+='\n      <div id=\'content\'>'+
((__t=(content))==null?'':__t)+
'</div>\n      ';
 } else { 
__p+='\n        <iframe id=\'preview\' src=\''+
((__t=(path))==null?'':__t)+
'\'></iframe>\n        ';
 } 
__p+='\n\n          <div id=\'details_footer\'>\n        <div class=\'link\' id=\'database\'>\n            '+
((__t=(APP.Tools.icon("database")))==null?'':__t)+
'\n            database\n        </div>\n\n          <div class=\'link\' id=\'delete\'>\n            '+
((__t=(APP.Tools.icon('remove')))==null?'':__t)+
'\n            delete\n        </div>\n\n          <div class=\'link\' id=\'duplicate\'>\n            '+
((__t=(APP.Tools.icon('copy')))==null?'':__t)+
'\n            duplicate\n        </div>\n          </div>\n';
}
return __p;
};
