DEF.pollers.DT = {};
DEF.pollers.settings = {
	fitbit      : require('../../pollers/fitbit/settings.js'),
	nest        : require('../../pollers/nest/settings.js'),
	netatmo     : require('../../pollers/netatmo/settings.js'),
	strava      : require('../../pollers/strava/settings.js'),
	wunderground: require('../../pollers/wunderground/settings.js'),
	modbustcp   : require('../../pollers/modbustcp/views/poller.js'),
	modserve    : require('../../pollers/modserve/views/poller.js'),
	monnit      : require('../../pollers/monnit/views/poller.js'),
	modbus      : require('../../pollers/modbus/views/poller.js'),
	machine     : require('../../pollers/machine/views/poller.js')
};
DEF.pollers.DT.Main = Backbone.Marionette.CollectionView.extend({
	className         : 'pollers',
	template          : require('./templates/main.html'),
	childViewContainer: '#pollers',
	id                : 'POLLERS'
	// XattachHtml: function(collectionView, childView, index) {
	// 	if (collectionView.isBuffering) {
	// 		if (index % 4 == 0)
	//
	// 			collectionView._bufferedChildren.splice(index, 0, childView);
	// 	} else {
	// 		if (!collectionView._insertBefore(childView, index)) {
	// 			collectionView._insertAfter(childView);
	// 		}
	// 	}
	// },
});

DEF.pollers.DT.child = Backbone.Marionette.View.extend({
	className: 'poller noselect',
	template : require('./templates/poller.html'),
	regions  : {
		devices: '#device_box'
	},
	ui: {
		command: '#command'
	},
	events: {
		click               : 'ShowDetails',
		'change @ui.command': 'SendCommand'
	},
	modelEvents: {
		change: 'render'
	},
	onRender() {
		$(this.el).attr('id', this.model.id);

		const poller_id = this.model.id;
		this.showChildView('devices', new Backbone.Marionette.CollectionView({
			id        : 'devices',
			collection: APP.models.devices,
			childView : DEF.devices.DT.small_device,
			viewFilter (m) {
				m = m.model;
				return m.get('poller_id') === poller_id;
			}
		}));
	},
	ShowDetails(e) {
		const cmd = 'details';
		APP.Route(`#DT/pollers/${this.model.id}/${cmd}`);
		e.stopPropagation();
	},
	SendCommand() {
		console.log('command', this.ui.command.val());
		this.model.set({ command: this.ui.command.val(), commanded_by: APP.USER.id });
	}
});
DEF.pollers.DT.Details = Backbone.Marionette.View.extend({
	id      : 'POLLERS',
	template: require('./templates/poller_layout.html'),
	regions : {
		details : '#details',
		settings: '#pollersettings',
		network : '#network_devices'
	},
	onRender() {
		const cd = new DEF.pollers.DT.CommonDetails({ model: this.model });
		this.showChildView('details', cd);

		const poller_name = this.model.get('protocol');
		console.log(poller_name);

		if (DEF.pollers.settings[poller_name]) {
			const dd = new DEF.pollers.settings[poller_name]({
				model: this.model
			});
			this.showChildView('settings', dd);
		}

		const NetworkDevices = Backbone.Marionette.CollectionView.extend({
			tagName   : 'table',
			collection: APP.models.network,
			viewFilter (m) {
				m = m.model
				return m.get('protocol') === poller_name;
			},
			childView: Backbone.Marionette.View.extend({
				tagName : 'tr',
				template: _.template('<th><%=ip%></th><td><%=name||vendor%></td>')
			}),
			emptyView: Backbone.Marionette.View.extend({
				template: _.template(`<i>No compatible devices found for <b>${poller_name}</b> protocol.</i>`)
			})
		});
		this.showChildView('network', new NetworkDevices({}));
	}
});
DEF.pollers.DT.CommonDetails = DEF.TG.Details.extend({
	module     : 'pollers',
	template   : require('./templates/details.html'),
	modelEvents: {
		'change status': 'testRender'
	},
	// a stupid, quick thing to defeat the render, during fast-updating pollers
	testRender() {
		if (document.activeElement.className !== 'field')
			this.render();
	},
	DoAction(e) {
		const command = e.currentTarget.id;
		console.log(command);
		this.model.set({ command, commanded_by: APP.USER.id });
	}
});

DEF.pollers.DT.StatusList = Backbone.Marionette.CollectionView.extend({
	className: 'poller_status'
});
DEF.pollers.DT.Status = Backbone.Marionette.View.extend({
	className  : 'poller',
	modelEvents: {
		change: 'render'
	},
	events: {
		click: 'Click'
	},
	template: require('./templates/status.html'),
	initialize() {
		_.defer(this.Ping.bind(this));
	},
	Ping() {
		if (this.model.get('online') === 1) {
			this.model.set({ command: 'ping' });
			this.timer = setTimeout(this.SetMissing.bind(this), 5000); // give Pong 5 seconds to come back
			this.listenToOnce(this.model, 'change:command', this.CheckForPong);
		}
	},
	SetMissing() {
		console.log('missing', this.model.getName());
		this.model.SetStatus(0, 'MISSING', 'No reply to ping');
		this.model.SetRunningState();
	},
	CheckForPong() {
		if (this.model.get('command') === 'pong')
			//	this.model.set({command: '--'});
			clearTimeout(this.timer); // clear that 5 second timer.
		else
			this.listenToOnce(this.model, 'change:command', this.CheckForPong);
	},

	Click() {
		APP.Route(`#DT/pollers/${this.model.id}`);
	}
});

DEF.pollers.SimpleTable = Backbone.Marionette.View.extend({
	tagName  : 'table',
	className: 'table-left table',
	template : require('./templates/page_summary.html')
});

DEF.pollers.page = Backbone.Marionette.View.extend({
	template: require('./templates/page.html'),
	templateContext() {
		const rs = this.model.all_fields();
		rs.model = this.model;
		return rs;
	},
	regions: {
		devices: '#devices',
		events : '#event_log_box',
		summary: '#summary'
	},
	ui: {
		command: '.btn'
	},
	modelEvents: {
		change: 'render'
	},
	events: {
		'click @ui.command': 'Command'
	},
	onRender() {
		const p_id = this.model.id;
		document.title = `Poller: ${this.model.getName()}`;

		const Summary = Backbone.Marionette.View.extend({
			tagName  : 'table',
			className: 'table-left table',
			template : require('./templates/page_summary.html')
		});

		this.showChildView('summary', new Summary({ model: this.model }));

		this.showChildView('devices', new DEF.database.DT.Table({
			collection     : APP.models.devices,
			collection_name: 'devices',
			childView      : DEF.database.DT.child,
			filter(m) {
				return m.get('poller_id') === p_id;
			}
		}));

		this.showChildView('events', new DEF.layout.LOG_BOX.Main({
			collection: APP.models.events,
			filter(m) {
				if (m.get('poller_id'))
					return m.get('poller_id') === p_id;

				if (m.get('tag_id')) {
					const tag = APP.models.tags.get(m.get('tag_id'));
					if (tag)
						return tag.getUp('poller_id') === p_id;
				}
				if (m.get('device_id')) {
					const device = APP.models.devices.get(m.get('device_id'));
					if (device)
						return device.getUp('poller_id') === p_id;
				}
				return false;
			}
		}));
	},
	Command(e) {
		console.log(e.currentTarget.id);
		switch (e.currentTarget.id) {
		case 'edit':
			APP.Route(`#DT/pollers/${this.model.id}`);
			break;
		case 'exit':
			APP.Route('#');
			break;
		case 'restart':
		case 'refresh':
			this.model.set('command', e.currentTarget.val);
			break;
		}
	}
});

DEF.pollers.DT.empty_details = DEF.TG.EmptyDetails.extend({
	template: require('./templates/empty.html')
});


DEF.pollers.DT.small_poller = Backbone.Marionette.View.extend({
	className  : 'poller block',
	id         : 'status',
	template   : _.template('<%=APP.Tools.icon(icon, name)%>'),
	modelEvents: {
		'change running_state': 'render'
	},
	events: {
		click: 'Click'
	},
	initialize() {
	},
	onBeforeRender() {
		this.$el.removeClass('ONLINE OFFLINE WARNING');
		this.$el.addClass(this.model.get('running_state'));
	},
	Click() {
		if (APP.design_time)
			APP.Route(`#DT/pollers/${this.model.id}`);
		else
			APP.Route(`#pollers/${this.model.id}`);
	}
});
