WID = {};


APP.widget_list = {
	Experimental: [
		'LineChart', 'PolarChart', 'Screen', 'Newsfeed', 'Triangle', 'DashLights', 'Tone'
	],
	'P&ID': [
		'PID', 'Continue', 'Instrument', 'Valve', 'Vessel', 'SimplePump', 'HeatExchanger'
	],
	OneLine: [
		'Node', 'Generator', 'Breaker'
	],
	Pipes: [
		'PipeHub', 'Tank', 'Pump'
	],
	Charts: [
		'Trend', 'BarChart', 'PieChart', 'XYChart', 'Histogram', 'Radar', 'Pictograph', 'TimeBar', 'Sankey', 'Balance'
	],
	Gauges: [
		'Gauge', 'Compass', 'LinearGauge', 'ArcGauge', 'Bar', 'Rampchart', 'TankLevel'
	],
	Tables: [
		'Table', 'Annunciator', 'Grid', 'List', 'Days'
	],
	Input: [
		'Menu', 'Button', 'Slider', 'Toggle', 'Keypad', 'Checkbox'
	],
	External: [
		'Website', 'DarkSky', 'Windguru'
	],
	Miscellaneous: [
		'Label', 'Value', 'Webcam', 'Group', 'LED', 'Image', 'Maintenance', 'OpLog'
	]
};

APP.widget_icons = {
	Annunciator  : 'th-large',
	ArcGauge     : 'circle-notch',
	Bar          : 'ellipsis-h',
	Balance      : 'bullseye',
	BarChart     : 'bar-chart',
	Breaker      : 'toggle-off',
	Button       : 'stop',
	Checkbox     : 'check-square',
	Compass      : 'compass',
	Continue     : 'flask',
	DarkSky      : 'cloud',
	DashLights   : 'battery-half',
	Days         : 'calendar',
	Gauge        : 'tachometer',
	Generator    : 'neuter',
	Grid         : 'th',
	Group        : 'object-group',
	HeatExchanger: 'pause',
	Histogram    : 'bar-chart',
	Image        : 'image',
	Instrument   : 'neuter',
	Keypad       : 'keyboard',
	Label        : 'font',
	LED          : 'dot-circle',
	LinearGauge  : 'thermometer-half',
	LineChart    : 'area-chart',
	List         : 'ellipsis-v',
	Maintenance  : 'calendar-check',
	Menu         : 'bars',
	Node         : 'circle',
	PID          : 'circle',
	Newsfeed     : 'newspaper',
	OpLog        : 'pencil-square',
	PhaseBalance : 'bullseye',
	Pictograph   : 'tree',
	PieChart     : 'pie-chart',
	PipeHub      : 'circle',
	PolarChart   : 'pie-chart',
	Pump         : 'arrow-circle-right',
	SimplePump   : 'arrow-circle-right',
	Radar        : 'sun',
	Rampchart    : 'signal',
	Sankey       : 'map',
	Screen       : 'desktop',
	Slider       : 'arrows-h',
	Table        : 'table',
	Tank         : 'square',
	TankLevel    : 'tint',
	TimeBar      : 'bar-chart',
	Toggle       : 'toggle-on',
	Tone         : 'volume-up',
	Trend        : 'line-chart',
	Triangle     : 'caret-up',
	Value        : 'hashtag',
	Valve        : 'venus',
	Vessel       : 'hourglass',
	Webcam       : 'video-camera',
	Website      : 'wifi',
	Windguru     : 'cloud',
	XYChart      : 'line-chart'
};

WID.PipeHub = require('../widgets/PipeHub/PipeHub.js');

// #2.8.45 - widgets are lazy loaded now, by SetScreen, CreateWidget and Group(widget)

// WID = {
// 	Label: require.ensure(["../widgets/Label/Label.js"], function() {
// 		WID.Label = require("../widgets/Label/Label.js");
// 	}, "label"),
// 	Screen: require.ensure(["../widgets/Screen/Screen.js"], function() {
// 		WID.Screen = require("../widgets/Screen/Screen.js");
// 	}, "screen"),
// 	Webcam: require.ensure(["../widgets/Webcam/Webcam.js"], function() {
// 		WID.Webcam = require("../widgets/Webcam/Webcam.js");
// 	}, "webcam"),
// 	Value: require.ensure(["../widgets/Value/Value.js"], function() {
// 		WID.Value = require("../widgets/Value/Value.js");
// 	}, "value"),
// 	Group: require.ensure(["../widgets/Group/Group.js"], function() {
// 		WID.Group = require("../widgets/Group/Group.js");
// 	}, "group"),
//
// 	// Input
// 	Button: require.ensure(["../widgets/Button/Button.js"], function() {
// 		WID.Button = require("../widgets/Button/Button.js");
// 	}, "button"),
// 	Slider: require.ensure(["../widgets/Slider/Slider.js"], function() {
// 		WID.Slider = require("../widgets/Slider/Slider.js");
// 	}, "slider"),
//
// 	// Table
// 	Table: require.ensure(["../widgets/Table/Table.js"], function() {
// 		console.log("loading table");
// 		WID.Table = require("../widgets/Table/Table.js");
// 	}, 'table'),
// 	Grid: require.ensure(["../widgets/Grid/Grid.js"], function() {
// 		WID.Grid = require("../widgets/Grid/Grid.js");
// 	}, 'grid'),
// 	Annunciator: require.ensure(["../widgets/Annunciator/Annunciator.js"], function() {
// 		WID.Annunciator = require("../widgets/Annunciator/Annunciator.js");
// 	}, 'annunciator'),
// 	Days: require.ensure(["../widgets/Days/Days.js"], function() {
// 		WID.Days = require("../widgets/Days/Days.js");
// 	}, 'days'),
//
//
//
// 	// Externals
// 	Website: require.ensure(["../widgets/Website/Website.js"], function() {
// 		WID.Website = require("../widgets/Website/Website.js");
// 	}, 'website'),
// 	Windguru: require.ensure(["../widgets/Windguru/Windguru.js"], function() {
// 		WID.Windguru = require("../widgets/Windguru/Windguru.js");
// 	}, 'windguru'),
// 	DarkSky: require.ensure(["../widgets/DarkSky/DarkSky.js"], function() {
// 		WID.DarkSky = require("../widgets/DarkSky/DarkSky.js");
// 	}, 'darksky'),
//
// 	// One line
// 	//Generator: require("../widgets/OneLine/Generator/Generator.js"),
//
//
// 	// Chart
// 	BarChart: require.ensure(["../widgets/BarChart/BarChart.js"], function() {
// 		WID.BarChart = require("../widgets/BarChart/BarChart.js");
// 	}, 'barchart'),
// 	PieChart: require.ensure(["../widgets/PieChart/PieChart.js"], function() {
// 		WID.PieChart = require("../widgets/PieChart/PieChart.js");
// 	}, 'piechart'),
// 	PolarChart: require.ensure(["../widgets/PolarChart/PolarChart.js"], function() {
// 		WID.PolarChart = require("../widgets/PolarChart/PolarChart.js");
// 	}, 'polarchart'),
// 	Trend: require.ensure(["../widgets/Trend/Trend.js"], function() {
// 		WID.Trend = require("../widgets/Trend/Trend.js");
// 	}, 'trend'),
// 	LineChart: require.ensure(["../widgets/LineChart/LineChart.js"], function() {
// 		WID.LineChart = require("../widgets/LineChart/LineChart.js");
// 	}, 'linechart'),
// 	XYChart: require.ensure(["../widgets/XYChart/XYChart.js"], function() {
// 		WID.XYChart = require("../widgets/XYChart/XYChart.js");
// 	}, 'xychart'),
// 	Histogram: require.ensure(["../widgets/Histogram/Histogram.js"], function() {
// 		WID.Histogram = require("../widgets/Histogram/Histogram.js");
// 	}, 'histogram'),
//
// 	// Gauge
// 	Gauge: require.ensure(["../widgets/Gauge/Gauge.js"], function() {
// 		WID.Gauge = require("../widgets/Gauge/Gauge.js");
// 	}, 'gauge'),
// 	LinearGauge: require.ensure(["../widgets/LinearGauge/LinearGauge.js"], function() {
// 		WID.LinearGauge = require("../widgets/LinearGauge/LinearGauge.js");
// 	}, 'lineargauge'),
// 	Compass: require.ensure(["../widgets/Compass/Compass.js"], function() {
// 		WID.Compass = require("../widgets/Compass/Compass.js");
// 	}, 'compass'),
// 	ArcGauge: require.ensure(["../widgets/ArcGauge/ArcGauge.js"], function() {
// 		WID.ArcGauge = require("../widgets/ArcGauge/ArcGauge.js");
// 	}, 'arcgauge'),
// 	Bar: require.ensure(["../widgets/Bar/Bar.js"], function() {
// 		WID.Bar = require("../widgets/Bar/Bar.js");
// 	}, 'bar')
// };
//
