module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'header\'>\n <h1>'+
((__t=(url))==null?'':__t)+
'</h1>\n</div>\n\n<div id=\'body\'>\n	<embed src=\''+
((__t=(url))==null?'':__t)+
'\' style=\'width:100%;height:100%\'>\n</div>\n\n<div id=\'footer\'>\n		<button class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon('exit')))==null?'':__t)+
' Exit</button>\n		<button class=\'btn pull-right\' id=\'download\'>'+
((__t=(APP.Tools.icon('download')))==null?'':__t)+
' Download</button>\n</div>\n';
}
return __p;
};
