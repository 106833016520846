module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr>\n	<th>Prefix</th>\n	<td>'+
((__t=(prefix))==null?'':__t)+
'</td>\n</tr>\n<tr>\n	<th>Enabled</th>\n	<td>\n		'+
((__t=(enabled))==null?'':__t)+
'\n	</td>\n	<th>Online</th>\n	<td>\n		'+
((__t=(online))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Last Data</th>\n	<td>'+
((__t=(APP.Format.datetime(last_data)))==null?'':__t)+
'</td>\n	<th>Next Poll</th>\n	<td>'+
((__t=(APP.Format.datetime(next_poll)))==null?'':__t)+
'</td>\n</tr>\n<tr>\n	<th>Connection</th>\n	<td colspan=3>\n		'+
((__t=(connection_string))==null?'':__t)+
'\n	</td>\n</tr>\n<tr>\n	<th>Status</th>\n	<td colspan=3>'+
((__t=(status))==null?'':__t)+
'</td>\n</tr>\n	';
}
return __p;
};
