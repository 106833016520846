/*
██████   ██████  ██      ██      ███████ ██████
██   ██ ██    ██ ██      ██      ██      ██   ██
██████  ██    ██ ██      ██      █████   ██████
██      ██    ██ ██      ██      ██      ██   ██
██       ██████  ███████ ███████ ███████ ██   ██
*/


DEF.pollers = {};

DEF.pollers.Initialize = function pinit(callback, constraints) {
	// Why is the "DEF" here?  because DEF.devices.Model may have gotten extended since this
	// file was originally slurped.
	const options = {
		model     : DEF.pollers.Model,
		url       : `${SETTINGS.dburl}/pollers`,
		comparator: function sort(m) {
			return m.get('current_machine') + m.get('protocol');
		}
	};
	return APP.InitializeModels('pollers', options, callback, constraints);
};

DEF.pollers.Model = DEF.TG.Model.extend({
	idAttribute    : '_id',
	collection_name: 'pollers',
	defaults       : {
		protocol        : 'modbus',
		name            : 'New Poller',
		notes           : '',
		options         : {}, // protocol-specific options, like API keys
		machine         : '*', // define which machine the poller runs on, based on hostname
		current_machine : '', // the machine it is currently running on.  (related to .machine)
		command         : '--',
		enabled         : true,
		online          : false,
		running_state   : 'UNTOUCHED', // "OFFLINE","ONLINE","WARNING"
		state           : 'UNTOUCHED', // what the poller is doing
		status          : '', // one-line status to display in DT, such as "Reading weather for JAX"
		state_updated   : -1,
		state_code      : false,
		last_data       : -1,
		icon            : 'tachometer',
		poller_startstop: '', // date when the poller started, or stopped
		restarts        : 0
	},
	db_spreadsheet: ['protocol', 'name', 'machine', 'icon'],
	db_columns    : ['running_state', 'protocol', 'poller_startstop', 'current_machine'],
	db_filters    : ['running_state', 'protocol', 'current_machine'],
	initialize() {
		//	this.on("change:online", this.SetRunningState);
	},
	getName() {
		return this.get('name');
	},
	getIcon() {
		return APP.Tools.icon(this.get('icon'));
	},
	getAlarmState() {
		switch (this.get('running_state')) {
		case 'OFFLINE':
			return 'alarm';
		case 'WARNING':
			return 'warning';
		}
		return 'normal';
	},
	getDevices() {
		return APP.models.devices.where({ poller_id: this.id });
	},
	SetRunningState() {
		let d;
		let running_state = 'UNTOUCHED';
		const devices = APP.models.devices.where({ poller_id: this.id });

		if (!this.get('online')) {
			running_state = 'OFFLINE';
			for (d in devices)
				if (devices[d].get('online'))
					devices[d].SetStatus(0, 'NOPOLLER', 'Poller has exited.');
		} else {
			running_state = 'ONLINE';
			for (d in devices)
				if (!devices[d].get('online')) {
					running_state = 'WARNING';
					//	console.log(devices[d].getName(), devices[d].get('online'));
					break;
				}
		}
		if (running_state !== this.get('running_state'))
			this.set('running_state', running_state);
		// console.log("******", running_state, this.get('online'), this.getName());
		return running_state;
	},
	SetDeviceStatus(online, state, status_text, extras) {
		const devices = this.getDevices();
		for (const d in devices)
			devices[d].SetStatus(online, state, status_text, extras);
	}

// SetStatus: function (online, state, status_text, extras) {
// 	if (_.isObject(status_text)) {
// 		status_text = status_text[Object.keys(status_text)[0]];
// 	}
// 	json = {
// 		state: state.toUpperCase(),
// 		online: !!online,
// 		state_updated: Date.now(),
// 		status: status_text || ""
// 	};
// 	if (extras)
// 		json = _.extend(json, extras);
// 	//console.log(json);
// 	if (online)
// 		json.exit_code = "";
// 	//	console.log(json);
// 	this.set(json);
// 	if (status_text)
// 		console.info("POLLER STATUS:", this.getName(), online, state, status_text);
// }
});
