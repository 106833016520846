module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'panel\' id=\'header\'>\n	<h1>'+
((__t=(APP.Tools.icon("tags")))==null?'':__t)+
' '+
((__t=(tag_name))==null?'':__t)+
' - '+
((__t=(name))==null?'':__t)+
'</h1>\n</div>\n\n<div class=\'panel\' id=\'page_box\'>\n	<div id=\'beadcrumbs\'>\n		<a class=\'crumb\' href=\'#\'>\n			'+
((__t=(APP.Tools.icon('home')))==null?'':__t)+
'</a> >\n		<a class=\'crumb\' href=\'#pollers/'+
((__t=(model.get('poller_id')))==null?'':__t)+
'\'>\n			'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' '+
((__t=(APP.models.pollers.get(model.getUp('poller_id')).getName()))==null?'':__t)+
'</a> >\n		<a class=\'crumb\' href=\'#devices/'+
((__t=(model.getUp('d_id')))==null?'':__t)+
'\'>\n			'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' '+
((__t=(APP.models.devices.get(model.getUp('d_id')).getName()))==null?'':__t)+
'</a> >\n		<a class=\'crumb\' href=\'#tags/'+
((__t=(model.id))==null?'':__t)+
'\'>\n			'+
((__t=(APP.Tools.icon('tags')))==null?'':__t)+
' '+
((__t=(model.getName()))==null?'':__t)+
'</a>\n	</div>\n\n	<div class=\'note\'>'+
((__t=(APP.Tools.icon("info-circle")))==null?'':__t)+
' This page is very much a work-in-progress.  Feedback welcomed.</div>\n\n	<h2>Detail</h2>\n\n	<div id=\'details\' class=\'flex\'>\n		';

			var fields =  Object.keys(model.attributes);
			for (var f in fields) { 

			var field = model.attributes[fields[f]];
			
__p+='\n			<div>\n				<div class=\'label\'>'+
((__t=(fields[f]))==null?'':__t)+
'</div><div class=\'value\'>'+
((__t=(model.getf(fields[f])))==null?'':__t)+
'</div>\n			</div>\n		';
 } 
__p+='\n	</div>\n</div>\n\n<div class=\'panel\' id=\'footer\'>\n	<div class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n	<div class=\'btn\' id=\'edit\'>'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' Edit</div>\n</div>\n';
}
return __p;
};
