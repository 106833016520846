module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h1>Widgets</h1>\n\n<p>\n  A <b>Widget</b> is a graphical representation of data. There are many types of widgets. Each widget has properties that define how the widget is displayed.\n</p>\n\n<p>\n  When editing a <a href=\'#DT/help/screens\'>screen</a>, there is a\n  <span class=\'bottombarbutton\'>\n    '+
((__t=(APP.Tools.icon('widgets')))==null?'':__t)+
' WIDGETS</span>\n  button, which shows a panel listing each available widget. Clicking on a widget will cause the new widget to be created on the screen, attempting to find an empty space.</p>\n</p>\n\n<p>\n  Widgets can be resized and moved via the mouse. Additionally, when a widget is selected, the <a href=\'#DT/help/bottombar\'>Bottom Bar</a> will show a variety of tools, allowing for finer control over the widget position.\n  <p>\n\n    <p>\n      Click again on a selected widget to bring up the property panel. Each widget has it\'s own property panel, with unique settings particular to that widget. A common property is the <a href=\'#DT/help/tagchooser\'>Tag Chooser</a> which allows for selecting a <a href=\'#DT/help/tags\'>tag</a> (or other type of data)</p>\n\n    <p>Some widgets, notably a Gauge, take a lot of their properties from the tag. The gauge will draw it\'s min and max value based on the tag\'s range.</p>\n\n\n\n    '+
((__t=(APP.Tools.icon( "widgets", "Icon for Widgets", "watermark")))==null?'':__t)+
'\n';
}
return __p;
};
