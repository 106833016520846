module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var blocks = blocks || [];
var blocks_status = blocks_status || [];

__p+='\n<h2>Modbus Options</h2>\n<fieldset>\n	<legend>\n		'+
((__t=(APP.UI.select_from_object("connection.type",{tcp:"TCP",serial:"Serial (RTU)"} ,connection.type)))==null?'':__t)+
' \n	</legend>\n	';
 if (connection.type == 'serial') { 
__p+='\n		<table>\n			<tr>\n				<th>Serial Port &nbsp;/dev/</th>\n				<td><input class=\'field\' type=\'text\' id=\'connection.serial\' value=\''+
((__t=(connection.serial))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Baud</th>\n				<td>\n					'+
((__t=(APP.UI.select_from_object("connection.baud",[9600,19200] ,connection.baud || 9600)))==null?'':__t)+
'\n				</td>\n			</tr>\n			<tr>\n				<th>Data Bits</th>\n				<td>'+
((__t=(APP.UI.select_from_object("connection.databits",[7,8] ,connection.databits || 8)))==null?'':__t)+
'</td>\n			</tr>\n			<tr>\n				<th>Parity</th>\n				<td>\n					'+
((__t=(APP.UI.select_from_object("connection.parity",["none","even","off"] ,connection.parity)))==null?'':__t)+
'\n				</td>\n			</tr>\n			<tr>\n				<th>Stop Bits</th>\n				<td>\n					'+
((__t=(APP.UI.select_from_object("connection.stopbits",[0,1] ,connection.stopbits || 1)))==null?'':__t)+
'\n				</td>\n			</tr>\n		</table>\n\n	';
 } else { 
__p+='\n		<table>\n			<tr>\n				<th>Host</th>\n				<td><input class=\'field\' type=\'text\' id=\'connection.host\' value=\''+
((__t=(connection.host))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Port</th>\n				<td><input class=\'field\' type=\'number\' id=\'connection.port\' value=\''+
((__t=(connection.port))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Device ID</th>\n				<td><input class=\'field\' type=\'number\' id=\'connection.unitId\' value=\''+
((__t=(connection.unitId))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Address Offset</th>\n				<td><input class=\'field\' type=\'number\' id=\'connection.addressOffset\' value=\''+
((__t=(connection.addressOffset))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Timeout (sec)</th>\n				<td><input class=\'field\' type=\'number\' id=\'connection.timeout\' value=\''+
((__t=(connection.timeout))==null?'':__t)+
'\'></td>\n			</tr>\n			<tr>\n				<th>Reconnect (sec)</th>\n				<td><input class=\'field\' type=\'number\' id=\'connection.reconnectTimeout\' value=\''+
((__t=(connection.reconnectTimeout))==null?'':__t)+
'\'></td>\n			</tr>\n		</table>\n	';
 } 
__p+='\n</fieldset>\n\n			<h2>Modbus Blocks</h2>\n			<div class=\'modpoll\'>\n				';

				for (var b in modblocks) {
					//console.log(modblocks[b]);
					
__p+='\n					<table class=\'modtile '+
((__t=(modblocks[b].state))==null?'':__t)+
'\'>\n						';
 if (modblocks[b].datetime) { 
__p+='\n						<tr>\n							<td colspan=2 class=\'status '+
((__t=(modblocks[b].state))==null?'':__t)+
'\'>\n								'+
((__t=(APP.Tools.icon(modblocks[b].status_icon)))==null?'':__t)+
'\n								'+
((__t=(modblocks[b].status))==null?'':__t)+
' @ '+
((__t=(APP.Format.livetime(modblocks[b].datetime)))==null?'':__t)+
' ago\n							</td>\n						</tr>\n						';
 } 
__p+='\n						<tr>\n							<td>\n								<b>Address:</b> '+
((__t=(parseInt(connection.addressOffset)+parseInt(modblocks[b].address)))==null?'':__t)+
'\n							</td>\n							<td>\n								<b>Length:</b> '+
((__t=(modblocks[b].length))==null?'':__t)+
'\n							</td>\n						</tr>\n						<tr>\n							<td colspan=2 class=\'modcmd\'><input readonly value=\''+
((__t=(modblocks[b].modpoll))==null?'':__t)+
'\'></td>\n						</tr>\n					</table>\n				';

				}
				
__p+='\n			</div>\n			<!-- <button class=\'btn action\' id=\'addblock\'>\n				'+
((__t=(APP.Tools.icon('add')))==null?'':__t)+
' Add Block</button>\n			<button class=\'btn action\' id=\'defaults\'>'+
((__t=(APP.Tools.icon('revert')))==null?'':__t)+
' Revert to Defaults</button> -->\n\n\n<!-- \n	"connection" : {\n	"host" : "192.168.104.200",\n	"port" : 502,\n	"port" : 502,\n	"timeout" : 10000,\n	"reconnectTimeout" : 30000,\n	"unitId" : 1,\n	"addressOffset" : 0\n	},\n	 -->\n';
}
return __p;
};
