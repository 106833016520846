/*
 ██████  █████  ███    ██ ██    ██  █████  ███████
██      ██   ██ ████   ██ ██    ██ ██   ██ ██
██      ███████ ██ ██  ██ ██    ██ ███████ ███████
██      ██   ██ ██  ██ ██  ██  ██  ██   ██      ██
 ██████ ██   ██ ██   ████   ████   ██   ██ ███████
*/

// http://fabricjs.com/ ?
//
//
DEF.widgets.RT.canvas = DEF.widgets.RT.base.extend({
	tagName  : 'canvas',
	className: 'widget',
	template : _.template(''),
	engine   : 'canvas',
	initialize() {
		this.InitializeCanvas();
	},
	InitializeCanvas() {
		const ctx = this.$el[0].getContext('2d');

		this.canvas = ctx;
		this.$el[0].addEventListener('mousedown', this._onMouseDown.bind(this));
		this.$el[0].addEventListener('mousemove', this._onMouseMove.bind(this));
		this.$el[0].addEventListener('mouseup', this._onMouseUp.bind(this));
		this.$el[0].addEventListener('mousewheel', this._onMouseWheel.bind(this));

		this.$el[0].addEventListener('touchstart', this._onMouseDown.bind(this));
		this.$el[0].addEventListener('touchmove', this._onMouseMove.bind(this));
		this.$el[0].addEventListener('touchend', this._onMouseUp.bind(this));
		this.$el[0].addEventListener('gesturechange', this._onMouseWheel.bind(this));
		this.ClearCanvas();
	},
	_onMouseWheel(e) {
		this.mouse = { x: e.offsetX, y: e.offsetY, wheel: e.wheelDelta };
		const box = this.GetClickBox(e.offsetX, e.offsetY);
		if (box) {
			this.mouse.target = box;
			this.$el.css('cursor', box.cursor);
			if (box.wheel)
				box.wheel(e);
		}
		this.onMouseWheel(e);
	},
	_onMouseDown(e) {
		this.mouse = { down: true };
		if (e.targetTouches) {
			const rect = e.target.getBoundingClientRect();
			this.mouse.x = (e.targetTouches[0].pageX - rect.left) / APP.current_screen.scale;
			this.mouse.y = (e.targetTouches[0].pageY - rect.top) / APP.current_screen.scale;
			console.log('touch', this.mouse.x, this.mouse.y);
		} else {
			this.mouse.x = e.offsetX;
			this.mouse.y = e.offsetY;
			console.log('click', this.mouse.x, this.mouse.y);
		}
		console.log('down', this.mouse);

		const box = this.GetClickBox(e.offsetX, e.offsetY);
		if (box) {
			this.mouse.target = box;
			this.$el.css('cursor', box.cursor);
		}
		this.onMouseDown(e);
	},
	_onMouseMove(e) {
		if (!this.mouse)
			this.mouse = { down: false };
		if (this.mouse.down) {
			// console.log('mousedrag', this.mouse.target);

			this.mouse.dx = e.offsetX - this.mouse.x;
			this.mouse.dy = e.offsetY - this.mouse.y;
			if (this.$el.css('cursor') === 'grab')
				this.$el.css('cursor', 'grabbing');
		} else {
			// console.log('mousemove');

			this.mouse.x = e.offsetX;
			this.mouse.y = e.offsetY;
			const box = this.GetClickBox(e.offsetX, e.offsetY);
			this.$el.css('cursor', box.cursor || 'pointer');
			if (box.move)
				box.move();
		}
		if (this.mouse.target)
			if (this.mouse.target.drag && this.mouse.down) { this.mouse.target.drag(); }


		this.onMouseMove(e);
	},
	_onMouseUp(e) {
		const box = this.GetClickBox(this.mouse.x, this.mouse.y);
		if (box && box.click)
			box.click();
		this.onMouseUp(e);
		this.$el.css('cursor', box.cursor);
		this.mouse = { down: false, target: false };
	},
	onDestroy() {
		// override me
	},
	onMouseWheel(e) {
		// override me
	},
	onMouseDown(e) {
		// override me
	},
	onMouseMove(e) {
		// override me
	},
	onMouseUp(e) {
		// override me
	},
	DrawEmpty() {
		console.warn('depricated');
		this.draw_text(20, 20, '?', '#999', this.model.height());
	},

	/**
	 * Set a UI element for clicking
	 * @param  {string} name    name of the box (unique)
	 * @param  {Object} box     {x,y,w,h}
	 * @param  {options} options {click,cursor,drawing}
	 * @return {[type]}         [description]
	 */
	SetClickBox(name, box, options = {}) {
		if (!this._clickbox)
			this._clickbox = {};
		this._clickbox[name] = _.extend({
			name,
			box,
			cursor: 'pointer'
		}, options);
	},
	GetClickBox(x, y) {
		// x -= this.model.get('left');
		// y -= this.model.get('top');
		for (const b in this._clickbox) {
			const box = this._clickbox[b];
			// console.log(box);
			if (x >= box.box.x && x <= box.box.x + box.box.w && y >= box.box.y && y <= box.box.y + box.box.h)
				return box;
		}
		return false;
	},
	ClearCanvas(color) {
		const W = Number(this.model.width());
		const H = Number(this.model.height());

		this.canvas.canvas.width = W; // for some fucky reason, the canvas defaults to 300x150.
		this.canvas.canvas.height = H;

		this.W = W;
		this.H = H;
		if (color)	 {
			this.canvas.fillStyle = color;
			this.canvas.fillRect(0, 0, W, H);
		} else { this.canvas.clearRect(0, 0, W, H); }
	},
	draw_rect(x, y, w, h) {
		this.canvas.strokeStyle = '#5ff';
		this.canvas.lineWidth = 1;
		this.canvas.rect(x, y, w - 1, h - 1);
		this.canvas.stroke();
	},
	draw_circle(x, y, r, stroke = false, fill = false, width = 1) {
		this.canvas.save();
		this.canvas.beginPath();
		this.canvas.strokeStyle = stroke;
		this.canvas.lineWidth = width;
		this.canvas.fillStyle = fill;
		this.canvas.arc(x, y, r, 0, 2 * Math.PI, false);
		if (stroke)
			this.canvas.stroke();
		if (fill)
			this.canvas.fill();
		this.canvas.restore();
	},
	// x & y in percent (0..1)
	draw_arc(x, y, r, color, w, start, stop) {
		console.log('arc', start, stop);
		r = Math.max(r, 1);
		start = (start - 0.25) * Math.PI * 2;
		stop = (stop - 0.25) * Math.PI * 2;
		this.canvas.beginPath();
		this.canvas.strokeStyle = color;
		this.canvas.lineWidth = w;
		this.canvas.lineCap = 'round';
		this.canvas.arc(x, y, r, start, stop, false); // you can see the arc now
		this.canvas.stroke();
	},
	draw_label(x, y, text, align = 'left', size = 14, color = '#fff') {
		if (text === null)
			text = '';
		return this.draw_text(x, y, text, color, size, align, '"Lucida Grande", Lucida, Verdana, sans-serif');
	},
	draw_value(x, y, value, unit = '', align = 'right', size = 15) {
		if (!unit)
			unit = '';
		let start = 0;
		this.canvas.font = `${size}px monospace`;
		const totalwidth = this.canvas.measureText(value + unit).width;
		switch (align) {
		case 'center':
			start = -totalwidth / 2;
			start += this.draw_text(x + start, y, value, '#aaa', size, 'left', 'monospace');
			this.draw_text(x + start, y + 1, `${unit}`, '#666', size - 1, 'left', 'monospace');
			break;
		case 'left':
			start = this.draw_text(x + start, y, value, '#aaa', size, 'left', 'monospace');
			this.draw_text(x + start, y + 1, `${unit}`, '#666', size - 1, 'left', 'monospace');
			break;
		case 'right':
			start = this.draw_text(x + start, y + 1, `${unit}`, '#666', size - 1, 'right', 'monospace');
			this.draw_text(x - start, y, value, '#aaa', size, 'right', 'monospace');
			break;
		}


		return totalwidth;
	},
	draw_text(x, y, text, color = '#aaa', size = 15, align = 'center', font = 'monospace') {
		const offset = size / 2;
		this.canvas.fillStyle = color;
		const old_font = this.canvas.font;
		if (size)
			this.canvas.font = `${size}px ${font}`;

		const text_width = this.canvas.measureText(text).width;
		switch (align) {
		case 'left':
			this.canvas.fillText(text, x, y + offset);
			break;
		case 'right':
			this.canvas.fillText(text, x - text_width, y + offset);
			break;
		default:
			this.canvas.fillText(text, x - text_width / 2, y + offset);
		}
		if (font)
			this.canvas.font = old_font;
		return text_width;
	},
	draw_scale(box, min, max, mode = 'val', pow) {
		if (max < min)
			return;
		let size;
		let length;
		const color = '#333';
		const color_bright = '#999';
		let deltas;
		let d;
		let x;
		const timezone = 3600 * 1000 * 4;
		this.canvas.save();
		// box.h -= 15;
		if (mode === 'date')
			size = box.w;
		else
			size = box.h;

		const div = size > 100 ? 50 : 25;
		const range = max - min;
		let delta = div / size * range;
		let start = min % (range / 10);
		//	this.canvas.save();
		this.canvas.strokeStyle = color;
		this.canvas.lineWidth = 1;
		this.canvas.setLineDash([]);
		this.canvas.fillStyle = 'white';
		if (mode === 'date') {
			// figure out divisions, sprung to the closest human-readable value
			deltas = [10, 30, 60, 300, 600, 3600, 7200, 21600, 43200, 86400];
			for (d in deltas) {
				delta = deltas[d] * 1000;
				if (delta / range * size > div)
					break;
			}
			// console.log(range);

			start = min % delta;
			if (delta === 86400000)
				start -= timezone;
			let last_datepos = 0;
			this.canvas.beginPath();
			for (x = -start; x < range; x += delta) {
				const X = ((x / range) ** this.model.get('date_pow')) * box.w + box.x;
				if (X > box.x) {
					this.canvas.moveTo(X, box.y);
					this.canvas.lineTo(X, box.y + box.h);
					const datetext = APP.Format.simpletime(min + x);
					const datesize = this.canvas.measureText(datetext).width + 10;
					if (X > last_datepos) { //  checks to see if there's room to draw the label
						this.canvas.fillText(datetext, X + 0, box.y + box.h - 5 + 15);
						last_datepos = X + datesize;
					}
				}
			}
			this.canvas.stroke();

			// Draw day lines
			this.canvas.beginPath();
			this.canvas.strokeStyle = color_bright;
			delta = 86400000;
			start = min % delta - timezone;

			let X;
			for (x = -start; x < range; x += delta) {
				X = ((x / range) ** this.model.get('date_pow')) * box.w + box.x;
				this.canvas.moveTo(X, box.y);
				this.canvas.lineTo(X, box.y + box.h);
			}
			X = (0 + ((Date.now() - max) / range)) ** this.model.get('date_pow') * box.w + box.w + box.x;
			this.canvas.moveTo(X, box.y);
			this.canvas.lineTo(X, box.y + box.h);
			this.canvas.moveTo(X + 2, box.y);
			this.canvas.lineTo(X + 2, box.y + box.h);

			this.canvas.stroke();
		} else {
			this.canvas.fillStyle = '#0005';
			this.canvas.fillRect(0, 0, 30, box.h);
			this.canvas.fillStyle = 'white';

			deltas = [0.1, 0.2, 0.5, 1, 2, 3, 4, 5, 10, 15, 20, 30, 50, 100, 150, 200, 300, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 10001];
			for (d in deltas) {
				delta = deltas[d];
				if (delta / range * size > div)
					break;
			}
			if (delta === 10001) // 1001 is beyond the range of the pre-defined divisions, so calculate one based on range
				delta = (Math.floor(div / size * range / 10000) * 10000);

			//			console.log('delta', delta);
			this.canvas.beginPath();
			for (x = range; x > 0; x -= delta) {
				const Y = (1 - ((1 - x / range) ** pow)) * box.h + box.y;
				if (Y > box.y && Y <= box.y + box.h) {
					this.canvas.moveTo(box.x, Y);
					this.canvas.lineTo(box.x + box.w + 10, Y);
					this.canvas.fillText(APP.Format.number(max - x), box.x + 4, Y - 5);
				}
			}
			this.canvas.stroke();

			// zero line
			this.canvas.beginPath();
			this.canvas.strokeStyle = color_bright;
			const zeroy = max / (max - min) * box.h + box.y;
			this.canvas.moveTo(box.x, zeroy);
			this.canvas.lineTo(box.x + box.w, zeroy);
			this.canvas.stroke();
		}
		//	box.h += 15;
		this.canvas.restore();
	},
	draw_linear_scale(x, y, w, h, min, max) { },
	draw_radial_scale(x, y, r, min, max) { },
	draw_box_scale(x, y, w, h, minx, maxx, miny, maxy) { },
	draw_button(x, y, w, h, text, cmd) { }
});
