module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'panel\' id=\'header\'>\n	<h1>'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
' '+
((__t=(prefix))==null?'':__t)+
' - '+
((__t=(name))==null?'':__t)+
'</h1>\n</div>\n\n<div class=\'panel\' id=\'page_box\'>\n	<div id=\'beadcrumbs\'>\n		<a class=\'crumb\' href=\'#\'>\n			'+
((__t=(APP.Tools.icon('home')))==null?'':__t)+
'</a> >\n		<a class=\'crumb\' href=\'#pollers/'+
((__t=(model.get('poller_id')))==null?'':__t)+
'\'>\n			'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' '+
((__t=(APP.models.pollers.get(model.get('poller_id')).getName()))==null?'':__t)+
'</a> >\n		<a class=\'crumb\' href=\'#devices/'+
((__t=(model.id))==null?'':__t)+
'\'>\n			'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' '+
((__t=(model.getName()))==null?'':__t)+
'</a>\n	</div>\n\n\n	<div id=\'notes\'>'+
((__t=(notes))==null?'':__t)+
'</div>\n\n	<h2>Details</h2>\n	<div id=\'summary\'></div>\n\n	<h2>Tags</h2>\n	<div id=\'tags\'></div>\n\n	<h2>Events</h2>\n	<div id=\'event_log_box\'></div>\n\n</div>\n\n\n\n<div class=\'panel\' id=\'footer\'>\n	<div href="#" class=\'btn\' id=\'exit\'>'+
((__t=(APP.Tools.icon("exit")))==null?'':__t)+
' Exit</div>\n	<div href="#" class=\'btn pull-right\' id=\'edit\'>'+
((__t=(APP.Tools.icon("edit")))==null?'':__t)+
' Edit</div>\n</div>\n\n<!--\n{\n	"enabled"           : true,\n	"dl_id"             : "57a32944ff22f1475aeedb68",\n	"online"            : false,\n	"prefix"            : "S1",\n	"connection"        : {},\n	"connection_string" : "Simulating 5 tags",\n	"points"            : 4941110,\n	"state"             : "NOPOLLER",\n	"state_updated"     : 1489587301462,\n	"last_data"         : 1489588346787,\n	"next_poll"         : 1489583476856,\n	"poller_id"         : "589e1d940790cb049d8e56da",\n	"notes"             : "",\n	"_id"               : "57a32e01ff22f1475aeedec8",\n	"polling_rate"      : 100,\n	"blocks"            : [],\n	"status"            : "Poller has exited.",\n	"name"              : "mbp.local"\n}\n-->\n';
}
return __p;
};
