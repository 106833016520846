DEF.users = {};


DEF.users.Initialize = function init(callback, constraints) {
	const options = {
		model     : DEF.users.Model,
		url       : `${SETTINGS.dburl}/users`,
		comparator: 'name',
	};
	return APP.InitializeModels('users', options, callback, constraints);
};

DEF.users.Model = DEF.TG.Model.extend({
	idAttribute: '_id',
	defaults   : {
		name         : 'New User',
		image_url    : 'user',
		online       : false,
		email        : '',
		phone        : '',
		password     : '$2a$10$D/VocwgUaqeqe4I7T96xvOX7qFapedKbtYgT9yGzNuMfaBqf7NSFu', // <-- bcrypt
		password_hint: '',
		last_login   : false,
		last_visit   : false,
		perm         : {},
		home         : '', // name of screen used for their home screen
		mobilehome   : '', // name of screen used for their mobile home screen
		pageloads    : 0, // number of times the user loads Telegauge
		pageviews    : 0, // number of times the user views a screen
		notifications: 0, // number of notifications received
	},
	collection_name: 'users',
	db_columns     : ['email', 'perm', 'last_visit', 'pageloads', 'pageviews'],
	db_spreadsheet : ['name', 'email', 'image_url'],
	db_filters     : ['perm'],
	initialize() {
		this.on('change:cmd', this.Command);
	},
	getName() {
		return this.get('name');
	},
	getIcon() {
		return APP.Format.image(this.get('image_url'));
	},
	getf(field) {
		switch (field) {
		case 'image_url':
			return `<img src='${this.get('image_url')}'>`;
		default:
			return this.get(field);
		}
	},
	GetCloneAttributes() {
		const attr = JSON.parse(JSON.stringify(this.attributes));
		attr.name = `Son of ${attr.name}`;
		return attr;
	},
	Can(perm) {
		return !!this.get(`perm.${perm}`);
	},
	Command(model, cmd) {
		console.log('EVENT', model, cmd);
		switch (cmd) {
		case 'logout':
			APP.Tools.store('auth', 'uid', false);
			window.location.reload();
			break;
		}
	},
});
