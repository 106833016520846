module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var tag_id=tag_id||"";

__p+='\n\n<table>\n	<tr>\n		<th>Tag</th>\n		<td>\n			'+
((__t=(APP.UI.chooser("tag_id", "tags", tag_id,"single", {type:"number"})))==null?'':__t)+
'\n		</td>\n	</tr>\n	<tr>\n		<td colspan=2>\n			<input type=\'checkbox\' id=\'live\' value=\'true\' '+
((__t=(live? "checked": ""))==null?'':__t)+
' class=\'field \'>\n			<span title=\'Update the tag while scrolling, rather than when finished\'>Live scrolling</span>\n		</td>\n	</tr>\n\n</table>\n';
}
return __p;
};
