module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table>\n	<tr>\n		<th>Device</th>\n		<td>\n			'+
((__t=(device))==null?'':__t)+
'\n		</td>\n		<th>Protocol</th>\n		<td>\n			'+
((__t=(protocol))==null?'':__t)+
'\n		</td>\n	</tr>\n</table>\n\n<div id=\'devices\'>\n	';

var html = "";
var models = APP.models.devices.where({
	dl_id:dl_id
});
for (var m in models) {
	html += "<div class='device' id='"+models[m].id+"'>"+models[m].get('prefix')+"</div>";
}
print(html);

__p+='\n</div>\n';
}
return __p;
};
