module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'preview\' Xstyle="width:'+
((__t=(width/10))==null?'':__t)+
'px;height:'+
((__t=(height/10))==null?'':__t)+
'px">\n	'+
((__t=(widgets))==null?'':__t)+
'\n</div>  \n<B>'+
((__t=(name))==null?'':__t)+
'</B>\n<span class=\'dim\'>:\n	'+
((__t=(width))==null?'':__t)+
' &#10005;\n		'+
((__t=(height))==null?'':__t)+
'\n</span>\n';
}
return __p;
};
