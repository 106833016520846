module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';


var reset_totalizer = reset_totalizer !== undefined ? reset_totalizer : "never";
var value_rate = value_rate !== undefined ? value_rate : 3600;
var last_zero_date = last_zero_date !== undefined ? last_zero_date : false
var last_zero_value = last_zero_value !== undefined ? last_zero_value : false
var decimals = decimals || 0;

var Duration = function(time) {
	var labels = {0: "Absolute", 1:"Unit per Second", 60: "Unit per minute", 3600:"Unit per Hour"}
	return labels[time] || "Unknown";
}

__p+='\n\n<div id=\'value\'>\n	<span class="value">'+
((__t=(valuef))==null?'':__t)+
'<span class="unit">'+
((__t=(APP.Unit.humanize(user_unit)))==null?'':__t)+
'</span></span>\n	';

				if (unit_list) {
				
__p+='\n	<div id=\'unitselector\'>\n		<input type=\'radio\' '+
((__t=(APP.Unit.dehumanize(unit)==APP.Unit.dehumanize(user_unit)? "checked": ""))==null?'':__t)+
' name=\'unit\'\n			id=\'unit\' value=\''+
((__t=(APP.Unit.dehumanize(default_unit)))==null?'':__t)+
'\'>\n		<b>'+
((__t=(APP.Unit.humanize(default_unit)))==null?'':__t)+
'</b>\n		<br>\n		';

					for (var u in unit_list) {
					
__p+='\n		<input type=\'radio\' '+
((__t=(unit_list[u]==user_unit? "checked": ""))==null?'':__t)+
' name=\'unit\' id=\'unit\' value=\''+
((__t=(unit_list[u]))==null?'':__t)+
'\'>\n		'+
((__t=(APP.Unit.humanize(unit_list[u])))==null?'':__t)+
'\n		<br>\n		';

					}
					
__p+='\n	</div>\n	';

				}
				
__p+='\n</div>\n</div>\n\n<table>\n	<tr>\n		<th>Updated</th>\n		<td>\n			'+
((__t=(APP.Format.livetime(updated)))==null?'':__t)+
' ago\n		</td>\n\n	</tr>\n	<th>Resets</th>\n	<td>\n		'+
((__t=(reset_totalizer))==null?'':__t)+
' \n	</td>\n		<td colspan=2>\n			<button class=\'button right\' style=\'float:right\' id=\'reset\'>Reset to zero</button>\n		</td>\n	</tr>\n	';
 if (typeof stats != "undefined" && stats.avg && stats.max) { 
__p+='\n	<tr>\n		<th>Average Value at Reset</th>\n		<td>'+
((__t=(stats.avg.toFixed(decimals||2)))==null?'':__t)+
'<span class="unit">'+
((__t=(APP.Unit.humanize(user_unit)))==null?'':__t)+
'</span></td>\n	</tr>\n	<th>Last Zero Value</th>\n	<td colspan=2>\n		'+
((__t=(((last_zero_value||0).toFixed(decimals||2))))==null?'':__t)+
'<span class="unit">'+
((__t=(APP.Unit.humanize(user_unit)))==null?'':__t)+
'</span>\n	</td>\n	<tr>\n		<th>Last Zero Reset</th>\n		<td colspan=2>'+
((__t=(APP.Format.datetime(last_zero_date)))==null?'':__t)+
'</td>\n	<tr>\n	</tr>\n	<tr>\n		<th>Resets</th>\n		<td>'+
((__t=(stats.resets || 0))==null?'':__t)+
'</td>\n	</tr>\n	';
 } 
__p+='\n</table>';
}
return __p;
};
