module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id=\'icon\'>'+
((__t=(APP.Tools.icon(icon)))==null?'':__t)+
'</div>\n	<div id=\'name\'>'+
((__t=(name))==null?'':__t)+
'</div>\n';
}
return __p;
};
